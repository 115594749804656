import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatPaginator as MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { merge } from 'rxjs';

import { ApplicationSettings } from '../../shared/application-settings';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';

import { WorktaskService } from '../worktask-service';
import { IWorktaskSearch } from '../worktask-model/worktasksearch';
import { IWorktasks } from '../worktask-model/worktasks';

@Component({
	templateUrl: './worktask-list.component.html',
	styleUrls: ['./worktask-list.component.css']
})

export class WorktaskListComponent implements OnInit, AfterViewInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	displayedColumns: string[] = ['Id', 'Name', 'nextaction', 'EstimatedHours', 'Priority', 'Application', 'Status'];
	isLoadingResults = true;

	public UserSearches: IUsersearch[];
	public pageSize = '10';
	public skip = 0;
	public recordTotal: number = 0;

	public Name = '';
	public Priority = '';
	public Application = '';
	public AssignedUser = '';
	public Status = '';

	public pageTitle = 'Work Tasks List';
	public errorMessage = '';
	public dataSource: any;

	constructor(private userSearchService: UsersearchService
		, public applicationSettings: ApplicationSettings
		, private _appService: WorktaskService) {
	}

	ngOnInit(): void {
		this.skip = 0;
		let x  = +localStorage.getItem('rows');
		if(x>0)  this.pageSize = x.toString();
	}

	ngAfterViewInit(): void {
		this.userSearchService.getUserSearchesByForm(this.pageTitle)
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.sort.direction = 'asc';
		this.sort.active = 'Id';

		this.UserSearches.forEach(i => {
			if(i.Item=='Name')
				this.Name = i.Value;

			if(i.Item=='Priority')
				this.Priority = i.Value;

			if(i.Item=='Application')
				this.Application = i.Value;

			if(i.Item=='AssignedUser')
				this.AssignedUser = i.Value;

			if(i.Item=='Status')
				this.Status = i.Value;

			if(i.Item=='orderByString') {
				if (this.applicationSettings.getOrderByDirection(i.Value)==='asc')
					this.sort.direction = 'asc';
				else
					this.sort.direction = 'desc';

				this.sort.active = this.applicationSettings.getOrderByName(i.Value);
			}

			if (i.Item == 'Skip')
				this.skip = i.Value;

		});

		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
		merge(this.sort.sortChange, this.paginator.page)
			.subscribe(()=> this.getData());

		this.getData();
	}

	pageSizeValueChanged(): void {
		this.applicationSettings.setPageSize(this.pageSize.toString());
		this.getData();
	}

	getData(): any {
		let search: IWorktaskSearch = this._appService.getFreshSearch();

		search.Name = this.Name;
		search.Priority = this.Priority;
		search.Application = this.Application;
		search.AssignedUser = this.AssignedUser;
		search.Status = this.Status;
		search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
		search.take = parseInt(this.pageSize);
		search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

		this._appService.getRecordSet(search).subscribe((obj: IWorktasks) => this.onSelectedRecordSetReceived(obj),
		(error: any) => {
			this.errorMessage = <any> error;
		});
	}

	private onSelectedRecordSetReceived(obj: IWorktasks){
		this.dataSource = new MatTableDataSource(obj.data);
		this.recordTotal = obj.total;
	}

	searchClick(): void {
		this.skip = 0;
		this.getData();
	}

	public searchClearClick(): void {
		this.Name = '';
		this.Priority = '';
		this.Application = '';
		this.AssignedUser = '';
		this.Status = '';
		this.skip = 0;
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.getData();
	}

	resetError() : void {
		this.errorMessage = '';
	}
}