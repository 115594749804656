import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControlName } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IEmployeequestion } from '../employeequestion-model/employeequestion';

import { Subscription } from 'rxjs/Subscription';
import { EmployeequestionService } from '../employeequestion-service';
import { TrainingManagementService } from '../../trainingmanagement-service';
import { IEmployeequestions } from '../Employeequestion-model/Employeequestions';
import { IEmployeequestionSearch } from '../Employeequestion-model/EmployeequestionSearch';

import { UsersearchService } from '../../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../../admin/usersearches/usersearch-model/usersearch';

@Component({
	templateUrl: './employeequestion-custom.component.html',
	styleUrls: ['employeequestion-custom.component.css']
})

export class EmployeequestionCustomComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
	public page = 'Edit EmployeeQuestions';
	errorMessage: string;
	employeequestionForm: FormGroup;
	employeequestion: IEmployeequestion;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public EmployeequestionSearch: IEmployeequestionSearch;
	public ListData: IEmployeequestions;
	public disableNextRecordButton: boolean = true;
	public disablePreviousRecordButton = true;

	public LessonId: number;

	constructor(private fb: FormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private employeeQuestionService: EmployeequestionService
		, private trainingManagementService: TrainingManagementService
		) {
	}

	ngOnInit(): void {
		this.employeequestionForm = this.fb.group({
			QuestionName: '',
			CoursesId: '',
			QuestionId: '',
			ContentOrder: '',
			ContentName: '',
			ContentDescription: '',
			ContentReviewed: '',
			ContentStatus: '',
			ContentNotes: '',
			QuestionUser: '',
			Employee: '',
			createddate: '',
			createdby: '',
			updateddate: '',
			updatedby: '',
		});
		this.sub = this.route.params.subscribe(
			params => {
				this.LessonId = +params['Id'];
				this.record = +params['CurrentPage'];
				this.getSelectedRecordSet();
			}
		);
	}

	public getSelectedRecordSet():void{
		this.EmployeequestionSearch = this.employeeQuestionService.getFreshSearch();
		this.EmployeequestionSearch.LessonId = this.LessonId.toString();

		this.trainingManagementService.startQuiz(this.EmployeequestionSearch)
			.subscribe(() => {console.log('Quiz started');
				this.employeeQuestionService.getRecordSet(this.EmployeequestionSearch)
				.subscribe((obj: IEmployeequestions) => this.onSelectedRecordSetReceived(obj),
				(error: any) => {
					this.errorMessage = < any > error;
			});	
			},
			(error: any) => {
				this.errorMessage = < any > error;
		});

		
	}

	private onSelectedRecordSetReceived(obj: IEmployeequestions){
		this.ListData = obj;
		this.recordTotal = obj.total;
		console.log(this.ListData);
		console.log(this.recordTotal);
		this.getEmployeequestion(this.ListData.data[this.record]);
	}

	private showHideButtons(){

		let count = 0;
		let record = 0;
		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.employeequestion.Id)
				record = count;
		});

		this.record = +this.ListData.skip + record;

		if (this.record ==this.ListData.total)
			this.disableNextRecordButton = true;
		else
			this.disableNextRecordButton = false;

		//Hide Previous Button here
		//console.log('current record: ' + this.record);
		if (this.record==1)
			this.disablePreviousRecordButton = true;
		else
			this.disablePreviousRecordButton = false;

		this.buttonPushed = false;

	}

	saveEmployeequestion(returnToList: boolean, advanceToNextRecord: boolean): void {
		this.buttonPushed = true;

		if(advanceToNextRecord)
			this.advanceToNextRecord();
		else
			this.advanceToPreviousRecord();
	}

	advanceToNextRecord(): void {
		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.employeequestion.Id)
				record = count;
		});

		this.getEmployeequestion(this.ListData.data[record])
	}


	getEmployeequestion(emp: IEmployeequestion): void {
			this.employeequestion = emp;

			this.employeequestionForm.patchValue({
				Id: this.employeequestion.Id,
				QuestionName: this.employeequestion.LessonName,
				CoursesId: this.employeequestion.CoursesId,
				QuestionId: this.employeequestion.Id,
				// ContentOrder: this.employeequestion.ContentOrder,
				ContentName: this.employeequestion.ContentName,
				ContentDescription: this.employeequestion.ContentDescription,
				ContentReviewed: this.employeequestion.ContentReviewed,
				ContentStatus: this.employeequestion.ContentStatus,
				ContentNotes: this.employeequestion.ContentNotes,
				QuestionUser: this.employeequestion.LessonUser,
				Employee: this.employeequestion.Employee,
				TestId: this.employeequestion.TestId,
				createddate: this.employeequestion.CreatedDate,
				createdby: this.employeequestion.CreatedBy,
				updateddate: this.employeequestion.UpdatedDate,
				updatedby: this.employeequestion.UpdatedBy,
			});
	
			this.showHideButtons();
	}



	advanceToPreviousRecord(): void {
		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.employeequestion.Id)
				record = count;
		});

		record = record -2;
		this.getEmployeequestion(this.ListData.data[record])
	}

	

	ngOnDestroy(): void {
	}

	deleteEmployeequestion(): void {
		this.buttonPushed = true;
	}

	cancel(): void {
		this.router.navigate(['/training/employeecourses']);
	}


	
	quizFinished(): void{
		   this.trainingManagementService.completeQuiz(this.EmployeequestionSearch)
		   .subscribe(() => console.log('lesson completed'),
		   (error: any) => {
			   this.errorMessage = < any > error;
			});
		this.cancel();
	}
}
