<section class="about-banner d-none d-sm-none d-md-block">
    <div class="banner-caption">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <h1 class="text-center">FANUC Robotics</h1>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="about">
  <div class="container">
    <div class="row">
      <!-- <div class="col-xs-12"> -->
      <div class="col-sm-7 col-12 text-center">
        <h1>Industrial Robots</h1>
        <p>We are <a target="_blank" href="https://www.fanucamerica.com/product/robots/">FANUC</a> Authorized System
          Integrators.</p>
        <p>We have a strong team of both hardware and software programers. This allows us to interface with both
          your next robot project with all of your internal systems, and/or the cloud. Get live status and statistics
          of your floor from your smart phone or on your desktop.
        </p>
        <p>We believe training is important. We regularly attend FANUC Robotics training and FANUC ASI Conference.</p>


      </div>
      <div class="col-sm-5">
        <img src="../assets/FANUCRobot1.jpg" alt="FANUC Robot" />
      </div>
      <!-- </div> -->
    </div>
    <div class="row">
      <!-- <div class="col-xs-12"> -->
      <div class="col-sm-5 ">
        <img src="../assets/eiarshop2.jpg" alt="Trade Show" />
      </div>
      <div class="col-sm-7 col-12 text-center">
        <h1>We are local Western Canadian Robotic Integrators</h1>
      </div>
      <!-- </div> -->
    </div>
  </div>
</section>
<section>
  <div class="row text-center col-12 col-md-12">
    <div class="col-sm-5 ">
      <h1>Here is a FANUC COBOT video with payloads up to 25Kg.</h1>
      </div>
    <div class="col-sm-7 ">
        <youtube-player
        videoId="DMclBsvU0Xc"
        [height]="300"
        [width]="400"
      ></youtube-player>
    </div>
    
    
  </div>
</section>

<section>
  <div class="row text-center col-12 col-md-12">
    <div class="col-sm-12 ">
      &nbsp;
    </div>
  </div>
</section>