 
<nav class="navbar navbar-expand-lg fixed-top" style="background-color: white;">
	<div class="container-fluid"> 
			<a class="navbar-left" [routerLink]="['/welcome']">
				<img src="../assets/logo-inner.png" class="inner-pg">
			</a>
			<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
		aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
		<span class="navbar-toggler-icon"></span>
	  </button>
	  <div class="collapse navbar-collapse" id="navbarNav">
		<ul class="navbar-nav">
				<li class="nav-item">
					<a  class="nav-link" aria-current="page" [routerLink]="['welcome']">Home</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" aria-current="page" [routerLink]="['services']">Services</a>
				</li>
				<li class="nav-item">
					<a (click)="toggleNav()" class="nav-link" [routerLink]="['about-us']">About Us</a>
				</li>
				<li class="nav-item">
					<a (click)="toggleNav()" class="nav-link" [routerLink]="['platforms']">Platforms</a>
				</li>
				<li class="nav-item">
					<a (click)="toggleNav()" class="nav-link" [routerLink]="['fanuc']">FANUC</a>
				</li>
				<li class="nav-item">
					<a (click)="toggleNav()" class="nav-link" [routerLink]="['contact']">Contact</a>
				</li>

				<li class="admin nav-item dropdown" *ngIf="auth.isAdmin()&&auth.loggedIn()">
			
					<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown">
						Admin
					</a>
					<div class="dropdown-menu" >
						<a (click)="toggleNav()" [routerLink]="['/change-password']" class="dropdown-item admin">Change Password</a>
						<a (click)="toggleNav()" [routerLink]="['/aspnetusers']" class="dropdown-item admin">Users</a>

						<a (click)="toggleNav()" [routerLink]="['/roles']" class="dropdown-item admin">Roles</a>

						<a (click)="toggleNav()" [routerLink]="['/branches']" class="dropdown-item">Branches</a>

						<a (click)="toggleNav()" [routerLink]="['/audits']" class="dropdown-item">Audit Log</a>

						<a *ngIf="auth.loggedIn() && auth.isInRoles('HelpAdmin')" (click)="toggleNav()" [routerLink]="['/helps']" class="dropdown-item">Help Creator</a>

						<a (click)="toggleNav()" [routerLink]="['/lookuplists']" class="dropdown-item">Lookup Lists</a>
					</div>
				</li>
				<li class="admin nav-item dropdown" *ngIf="auth.isAdmin()&&auth.loggedIn()">
					<a class="nav-link dropdown-toggle" href="#" id="navbarEIAR" role="button" data-bs-toggle="dropdown">EIAR</a>
					<div class="dropdown-menu" >
						<a (click)="toggleNav()" [routerLink]="['/dashboard']" class="dropdown-item admin">Dashboard</a>
						<a (click)="toggleNav()" [routerLink]="['/worktasks']" class="dropdown-item admin">Work Tasks</a>
						<a (click)="toggleNav()" [routerLink]="['/dailylog']" class=" dropdown-item admin">Daily Logs</a>
						<a (click)="toggleNav()" [routerLink]="['/worktasklogsreport']" class="dropdown-item admin">Work Task Logs Report</a>
						<a (click)="toggleNav()" class="dropdown-item admin" >- - -</a>
						<a (click)="toggleNav()" [routerLink]="['/companies']" class="dropdown-item admin">Companies</a>
						<a (click)="toggleNav()" class="dropdown-item admin" >- - -</a>
					    <a (click)="toggleNav()" [routerLink]="['/parts']" class="dropdown-item admin" >Parts</a>
                        <a (click)="toggleNav()" [routerLink]="['/materials']" class="dropdown-item admin" >Materials</a>
                        <a (click)="toggleNav()" [routerLink]="['/equipments']" class="dropdown-item admin" >Equipment</a>
						<a (click)="toggleNav()" [routerLink]="['/jobs']" class="dropdown-item admin" >Jobs</a>
						<a (click)="toggleNav()" class="dropdown-item admin" >- - -</a>
						<a (click)="toggleNav()" [routerLink]="['/machine16']" class="dropdown-item admin" >Robot Cell</a>
						<a (click)="toggleNav()" [routerLink]="['/plc-service-config']" class="dropdown-item admin" >PLC Service Config</a>
						<a (click)="toggleNav()" [routerLink]="['/tasks']" class="dropdown-item admin" >Tasks</a>
						<a (click)="toggleNav()" class="dropdown-item admin" >- - -</a>
						<a *ngIf="auth.loggedIn()" [routerLink]="['/greens']" class="dropdown-item">Greens</a>
						<a (click)="toggleNav()" class="dropdown-item admin" >- - -</a>
						<a (click)="toggleNav()" [routerLink]="['/branchlabelprinters-iplist']" class="dropdown-item admin" >Printer IP List Test</a>
						
					</div>
				</li>

				<li class="admin nav-item dropdown" *ngIf="auth.isAdmin()&&auth.loggedIn()">
					<a class="nav-link dropdown-toggle" href="#" id="navbarTraining" role="button" data-bs-toggle="dropdown">Training</a>
					<div class="dropdown-menu" >
						<a (click)="toggleNav()" [routerLink]="['/training/courses']" class="dropdown-item admin">Courses</a>
						<a (click)="toggleNav()" [routerLink]="['/training/tests']" class="dropdown-item admin">Tests</a>
						<a (click)="toggleNav()" [routerLink]="['/training/employeecourses']" class="dropdown-item admin">Employee Courses</a>
					</div>
				</li>

				<li class="nav-item">
					<a (click)="toggleNav()" class="nav-link" *ngIf="!auth.loggedIn()"
						[routerLink]="['/login']">Login</a>
					<a (click)="toggleNav()" class="nav-link" *ngIf="auth.loggedIn()" (click)="auth.logout()"
						[routerLink]="['welcome']">Log Off</a>
				</li>
			</ul>
		</div>
	</div> 
</nav> 
