import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class JwtHttpInterceptor implements HttpInterceptor {
	constructor(
		private router:Router
	) {}
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		let authHeader = '';

		const webApitoken = localStorage.getItem('eiar_token');
		if (webApitoken) {
			authHeader = `JWT ${webApitoken}`;
		}

		if (request.headers.get('Authorization')) {
			authHeader = request.headers.get('Authorization')!;
		}
		let acceptHeader='';
		
		if (request.headers.get('Accept')) {
			acceptHeader = request.headers.get('Accept');
		}

		let clone: HttpRequest<any>;
		
		if (authHeader) {
			if(acceptHeader=='application/vnd.vimeo.*+json;version=3.4'){
				clone = request.clone({
					setHeaders: {
						Accept: `application/vnd.vimeo.*+json;version=3.4`,
						Authorization: authHeader
					}
				});
			}		
			else{
			clone = request.clone({
				setHeaders: {
					Accept: `application/json`,
					Authorization: authHeader
				}
			});
		}
		} else {
			clone = request.clone({
				setHeaders: {
					Accept: `application/json`,
					'Content-Type': `application/json`
				}
			});     
		}
		return next.handle(clone)
		.pipe(
			retry(1),
			catchError((error: HttpErrorResponse) => { 
			  if (!(error.error instanceof ErrorEvent)) {
                 if(error.status==401){				
					this.router.navigate(['/unauthorized']);
				 }
			  } 
			  return throwError(error); 
			}) 
		  );
	}
}
