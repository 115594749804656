import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { ICompanylandlocation} from './Companylandlocation-model/Companylandlocation';
import { ICompanylandlocations } from './Companylandlocation-model/Companylandlocations';
import { ICompanylandlocationSearch } from './Companylandlocation-model/CompanylandlocationSearch';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';

@Injectable()
export class CompanylandlocationService {
	private baseUrl;
	public pageTitle = 'CompanyLandLocations List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Companylandlocations';
	}

	getCompanylandlocation(id: number):  Observable<ICompanylandlocation> {
		if (id === 0) {
			return of(this.initializeCompanylandlocation());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<ICompanylandlocation>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveCompanylandlocation(companylandlocation: ICompanylandlocation): Observable<any> {
		if (companylandlocation.Id === 0) {
			return this.createCompanylandlocation(companylandlocation);
		}
		return this.updateCompanylandlocation(companylandlocation);
	}

	private createCompanylandlocation(companylandlocation: ICompanylandlocation): Observable<any> {
		companylandlocation.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/companylandlocationcreate', companylandlocation)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateCompanylandlocation(companylandlocation: ICompanylandlocation): Observable<ICompanylandlocation> {
		return this.authHttp.put<ICompanylandlocation>(this.baseUrl, companylandlocation)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteCompanylandlocation(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeCompanylandlocation(): ICompanylandlocation {
		return {
			Id: 0,
			CompanyId: 0,
			LegalLandDescription: '',
			Latitude: '',
			Longitude: '',
			Acres: 0,
			SoilType: '',
			Notes: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as ICompanylandlocation;
	}

	public getRecordSet(search: ICompanylandlocationSearch): Observable<ICompanylandlocations> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('CompanyId', search.CompanyId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('LegalLandDescription', search.LegalLandDescription, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Acres', search.Acres, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('SoilType', search.SoilType, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Notes', search.Notes, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('CompanyId', search.CompanyId ? search.CompanyId : '')
			.set('LegalLandDescription', search.LegalLandDescription ? search.LegalLandDescription : '')
			.set('Acres', search.Acres ? search.Acres : '')
			.set('SoilType', search.SoilType ? search.SoilType : '')
			.set('Notes', search.Notes ? search.Notes : '')
		};

		return this.authHttp.get<ICompanylandlocations>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<ICompanylandlocations>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : ICompanylandlocationSearch { 
		let search: ICompanylandlocationSearch = {
			Id: 1,
			CompanyId: '',
			LegalLandDescription: '',
			Acres: '',
			SoilType: '',
			Notes: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.error.ErrorDescription}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: ICompanylandlocationSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('CompanyId', search.CompanyId ? search.CompanyId : '')
			.set('LegalLandDescription', search.LegalLandDescription ? search.LegalLandDescription : '')
			.set('Acres', search.Acres ? search.Acres : '')
			.set('SoilType', search.SoilType ? search.SoilType : '')
			.set('Notes', search.Notes ? search.Notes : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
}