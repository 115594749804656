import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'
import { CustomercontactListComponent } from './customercontact-list/customercontact-list.component';
import { CustomercontactEditComponent } from './customercontact-edit/customercontact-edit.component';
import { CustomercontactService } from './customercontact-service';
import { MatProgressSpinnerModule as MatProgressSpinnerModule }  from '@angular/material/progress-spinner';

@NgModule({
	declarations: [
		CustomercontactListComponent
		, CustomercontactEditComponent
	],
	imports: [
		MatProgressSpinnerModule,
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		RouterModule.forChild([
			{ path: 'customercontacts', component: CustomercontactListComponent },
			{ path: 'customercontact-details/:Id', component: CustomercontactEditComponent },
		])
	],
	providers: [
		CustomercontactService
	]
})
export class CustomercontactModule { }
