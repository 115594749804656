import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControlName, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ITestcontent } from '../testcontent-model/testcontent';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';
import { Subscription } from 'rxjs/Subscription';
import { TestcontentService } from '../testcontent-service';
import { ITestcontents } from '../Testcontent-model/Testcontents';
import { ITestcontentSearch } from '../Testcontent-model/TestcontentSearch';

import { UsersearchService } from '../../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../../admin/usersearches/usersearch-model/usersearch';

@Component({
	templateUrl: './testcontent-edit.component.html',
	styleUrls: ['testcontent-edit.component.css']
})

export class TestcontentEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit TestContents';
	errorMessage: string;
	testcontentForm: FormGroup;
	testcontent: ITestcontent;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public TestcontentSearch: ITestcontentSearch;
	public ListData: ITestcontents;
	public showNextRecordButton: boolean = true;
	public progressStatus=false;

	constructor(private fb: FormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private testcontentService: TestcontentService,
		) {
	}

	ngOnInit(): void {
		this.testcontentForm = this.fb.group({
			Id: '',
			ContentId: '',
			Status: ['',[Validators.maxLength(250)]],
			Notes: [''],
			ReviewedDate: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getTestcontent(id);
				this.progressStatus = true;
			}
		);
	}

	public getSelectedRecordSet():void{
		this.testcontentService.getRecordSet(this.TestcontentSearch)
			.subscribe((obj: ITestcontents) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: ITestcontents){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.testcontent.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

		this.progressStatus = false;

	}

	saveTestcontent(returnToList: boolean, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

		if (this.testcontentForm.valid) {
			const obj = Object.assign({ }, this.testcontent, this.testcontentForm.value);
			this.testcontentService.saveTestcontent(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord,addNewRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		if (returnToList) {
			this.testcontentForm.reset();
			this.router.navigate(['/training/testcontents']);
		} 
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
		else if (addNewRecord)
		{
			this.router.navigate(['/testcontent-details', 0]);
			this.testcontentForm.reset();
			let obj = this.testcontentService.initializeTestcontent();
			this.onTestcontentRetrieved(obj);
		}
			// when the record id is zero then it is a new record.
		else if (data !== null && this.testcontent.Id === 0) {
			this.router.navigate(['/training/testcontent-details', data.Id]);
		} 
		else if (this.testcontent.Id != 0){
			this.getTestcontent(this.testcontent.Id);
		}

		this.buttonPushed = false;
		this.progressStatus = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.testcontent.Id)
				record = count;
		});

		this.getTestcontent(this.ListData.data[record].Id)
	}

	getTestcontent(id: number): void {
		this.testcontentService.getTestcontent(id)
			.subscribe((obj: ITestcontent) => this.onTestcontentRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onTestcontentRetrieved(testcontent: ITestcontent): void {
		if (this.testcontentForm) {
			this.testcontentForm.reset();
		}
		this.testcontent = testcontent;
		if (this.testcontent.Id === 0) {
			this.page = 'Add TestContents';
		} else {
			this.page = 'Edit TestContents';
		}

		this.testcontentForm.patchValue({
			Id: this.testcontent.Id,
			ContentId: this.testcontent.ContentId,
			Status: this.testcontent.Status,
			Notes: this.testcontent.Notes,
			ReviewedDate: this.testcontent.ReviewedDate,
			CreatedDate: this.testcontent.CreatedDate,
			CreatedBy: this.testcontent.CreatedBy,
			UpdatedDate: this.testcontent.UpdatedDate,
			UpdatedBy: this.testcontent.UpdatedBy,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteTestcontent(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.testcontent.ContentId}?`)) {
			this.testcontentService.deleteTestcontent(this.testcontent.Id)
				.subscribe(() => this.onSaveComplete(true, null, false,false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/training/testcontents']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('TestContents List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.TestcontentSearch = this.testcontentService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'ContentId')
				this.TestcontentSearch.ContentId = i.Value;

			if (i.Item == 'Status')
				this.TestcontentSearch.Status = i.Value;

			if (i.Item == 'Notes')
				this.TestcontentSearch.Notes = i.Value;

			if (i.Item == 'ReviewedDate')
				this.TestcontentSearch.ReviewedDate = i.Value;

			if (i.Item == 'orderByString')
				this.TestcontentSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.TestcontentSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
}
