
<div class='panel panel-primary animaPlaceholder' *ngIf = 'test'>
	<div class='panel-heading'>
		<span *ngIf = 'test' >{{ page }} :{{ test.Id }}</span>
		<span class= 'pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class= 'panel-body'>
		<div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<form class='form-horizontal' novalidate [formGroup]='testForm'>
			<fieldset>
			<div class='panel-body'>
				<div class='form-group'>
					<label class='col-md-2 control-label'> Lesson</label>
					<div class='col-md-10'>
						<mat-select class="form-control"formControlName='LessonsId'>
							@for (item of lessonsList; track item) {
							  <mat-option [value]="item.Id">{{item.Name}}</mat-option>
							}
						</mat-select>
					</div>
				</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Employee</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='Employee' />
							<span *ngIf = "testForm.controls['Employee'].invalid  && testForm.controls['Employee'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Score Numerator</label>
						<div class='col-md-10'>
							<input class='form-control' type='number' formControlName='ScoreNumerator' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Score Denominator</label>
						<div class='col-md-10'>
							<input class='form-control' type='number' formControlName='ScoreDenominator' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Notes</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='Notes' />
							<span *ngIf = "testForm.controls['Notes'].invalid  && testForm.controls['Notes'].errors.maxlength" class="text-danger">
									Only 0 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Lesson Start Date</label>
						<div class='col-md-10'>
							<input class='form-control' type='date' formControlName='LessonStartDate' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Lesson End Date</label>
						<div class='col-md-10'>
							<input class='form-control' type='date' formControlName='LessonEndDate' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Quiz Start Date</label>
						<div class='col-md-10'>
							<input class='form-control' type='date' formControlName='QuizStartDate' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Quiz End Date</label>
						<div class='col-md-10'>
							<input class='form-control' type='date' formControlName='QuizEndDate' />
						</div>
					</div>
			</div>
			</fieldset>
		</form>

			<div class='row' style='padding: 15px;'>
				<div class= 'col-md-12'>
					<span>
						<button class='btn btn-primary btn-edit-sml'
							[disabled]='buttonPushed'
								(click)='saveTest(false, false,false)'
									style='width: 110px; margin-right:10px'
									[disabled]='!testForm.valid || progressStatus||progressLessonsStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
								</button>
								<button class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveTest(true, false,false)'
									style='width: 150px; margin-right:10px'
									[disabled]='!testForm.valid || progressStatus||progressLessonsStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
								</button>
								<button *ngIf='showNextRecordButton && test.Id ' class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveTest(false, true,false)'
									style='width: 190px; margin-right:10px'
									[disabled]='!testForm.valid || progressStatus||progressLessonsStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
								</button>
								<button  class='btn btn-primary btn-edit-sml' *ngIf="!test.Id"  
									  [disabled]='buttonPushed'
									  (click)='saveTest(false, false,true)'
									  style ='width: 200px; margin-right:10px'
									  [disabled]='!testForm.valid || progressStatus||progressLessonsStatus'>
									   <span class='glyphicon glyphicon-floppy-save'></span> Save & Add New Record &nbsp;
								  </button>
							</span>

						<span  class='pull-right remove-float-sml'>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='cancel()'
								style='width: 150px; margin-right:10px'
								[disabled]='!testForm.valid || progressStatus||progressLessonsStatus'>
								Cancel &nbsp;
							</button>
							<button class='btn btn-primary btn-edit-sml' *ngIf="test.Id" 
								[disabled]='buttonPushed'
								(click)='deleteTest()'
								style='width: 150px; margin-right:10px'
								[disabled]='!testForm.valid || progressStatus||progressLessonsStatus'>
								<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
							</button>
						</span>
					</div>
				</div>

				<div class='row'>
					<div class='col-md-12'>
						<span> Updated By: <label *ngIf='test'>{{test.UpdatedBy}}</label></span>
						<span> Time: <label *ngIf='test'>{{test.UpdatedDate}}</label></span>
					</div>
				</div>
	</div>
</div>
<div>
<mat-spinner color="primary" class="spinner" *ngIf="progressStatus||progressLessonsStatus"></mat-spinner>
</div>