<div class='panel'>
    <div class='panel-body'>
        <div [innerHTML]="helpViewData">
        </div>
        <div [innerHTML]="helpDeveloperData" style="margin-top: 30px;" *ngIf="auth.isInRoles('Developer')">
        </div>
        <div style="margin-top: 100px;" *ngIf="auth.isInRoles('HelpAdmin')">
            <a href="" [routerLink]="['/help-details',helpId]">Update Data</a>
        </div>
    </div>
</div>