import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-platform',
  templateUrl: './platform.component.html',
  styleUrls: ['./platform.component.css']
})
export class PlatformComponent implements OnInit {

  constructor(private meta: Meta, private titleService: Title) { 
    this.meta.addTag({ name: 'description', content: 'A compiled list of software we use to create custom solutions for your business.'});
    this.titleService.setTitle('Platform');
  }

  ngOnInit() {
  }

}
