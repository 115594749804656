import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControlName } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IWorklog } from '../worklog-model/worklog';

import { Subscription } from 'rxjs/Subscription';
import { WorklogService } from '../worklog-service';
import { IWorklogs } from '../Worklog-model/Worklogs';
import { IWorklogSearch } from '../Worklog-model/WorklogSearch';

import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { ApplicationSettings } from '../../shared/application-settings';

@Component({
	templateUrl: './worklog-edit.component.html',
	styleUrls: ['worklog-edit.component.css']
})

export class WorklogEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
	public page = 'Edit Work Logs';
	errorMessage: string;
	worklogForm: FormGroup;
	worklog: IWorklog;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public WorklogSearch: IWorklogSearch;
	public ListData: IWorklogs;
	public showNextRecordButton: boolean = true;

	public inWorkTaskId: number;   //TODO need to opass in the work log ID and use this

	public timeHours: number[] = [0,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22];
	public timeMinutes: number[] = [0,15,30,45];

	public workType: string[] = ['', 'Software PC', 'Software PLC', 'Support', 'Travel', 'Sales', 'Research', 'Personal Development', 'Design', 'Mechanical', 'Electrical', 'Assembly', 'Schmatic', 'Drawings', 'Robot', 'Admin', 'Other'];

	constructor(private fb: FormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private worklogService: WorklogService
		, public applicationSettings: ApplicationSettings
		) {
	}

	ngOnInit(): void {
		this.worklogForm = this.fb.group({
			Id: '',
			WorkTaskId: '',
			StartTimeHour: 0,
			StartTimeMinute: 0,
			EndTimeHour: 0,
			EndTimeMinute: 0,
			Hours: '',
			Type: '',
			Billable: '',
			Description: '',
			StartingKms: '',
			EndingKms: '',
			Notes: '',
			WorkDate: '',
			Person: '',
			Vehicle: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
			TotalKms: '',
		});
		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.inWorkTaskId =  +params['Id2']; 
				this.getWorklog(id);
			}
		);
	}

	public getSelectedRecordSet():void{
		this.worklogService.getRecordSet(this.WorklogSearch)
			.subscribe((obj: IWorklogs) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: IWorklogs){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.worklog.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

	}

	saveWorklog(returnToList: boolean, advanceToNextRecord: boolean): void {
		this.buttonPushed = true;

		if (this.worklogForm.valid) {
			let obj: IWorklog = Object.assign({ }, this.worklog, this.worklogForm.value);

			if (!this.checkDate(obj.WorkDate)) {
					this.buttonPushed = false;
					return;
			}

			obj.WorkTaskId = this.inWorkTaskId;

			this.worklogService.saveWorklog(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean): void {
		if (returnToList) {
			this.worklogForm.reset();
			this.router.navigate(['/worktask-details', this.inWorkTaskId]);
		} else {
			// when the record id is zero then it is a new record.
			if (data !== null && this.worklog.Id === 0) {
				this.router.navigate(['/worklog-details', data.Id, this.inWorkTaskId]);
			} 
		}

		this.buttonPushed = false;
	}

	public checkDate(value:any): boolean {

		console.log(value);
		if(!value){
			if (confirm(`There is no work date.  Do you want to proceed?`)) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}


	getWorklog(id: number): void {
		this.worklogService.getWorklog(id)
			.subscribe((obj: IWorklog) => this.onWorklogRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onWorklogRetrieved(worklog: IWorklog): void {
		if (this.worklogForm) {
			this.worklogForm.reset();
		}
		this.worklog = worklog;
		if (this.worklog.Id === 0) {
			this.page = 'Add Work Logs';
		} else {
			this.page = 'Edit Work Logs';
		}

		let total = 0;
		total = this.worklog.EndingKms - this.worklog.StartingKms;

		this.worklogForm.patchValue({
			Id: this.worklog.Id,
			WorkTaskId: this.worklog.WorkTaskId,
			
			StartTimeHour: this.worklog.StartTimeHour,
			StartTimeMinute: this.worklog.StartTimeMinute,
			EndTimeHour: this.worklog.EndTimeHour,
			EndTimeMinute: this.worklog.EndTimeMinute,

			Hours: this.worklog.Hours,
			Type: this.worklog.Type,
			Billable: this.worklog.Billable,
			Description: this.worklog.Description,
			StartingKms: this.worklog.StartingKms,
			EndingKms: this.worklog.EndingKms,
			Notes: this.worklog.Notes,
			WorkDate: this.worklog.WorkDate,
			Person: this.worklog.Person,
			Vehicle: this.worklog.Vehicle,
			CreatedDate: this.worklog.CreatedDate,
			CreatedBy: this.worklog.CreatedBy,
			UpdatedDate: this.worklog.UpdatedDate,
			UpdatedBy: this.worklog.UpdatedBy,

			TotalKms: total,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteWorklog(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.worklog.WorkTaskId}?`)) {
			this.worklogService.deleteWorklog(this.worklog.Id)
				.subscribe(() => this.onSaveComplete(true, null, false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/worktask-details', this.inWorkTaskId]);
	}


	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Work Logs List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.WorklogSearch = this.worklogService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'WorkTaskId')
				this.WorklogSearch.WorkTaskId = i.Value;

			if (i.Item == 'orderByString')
				this.WorklogSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.WorklogSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}

	public calcKms():void {
		let start = 0;
		let end = 0;
		let total = 0;

		if (this.worklogForm.valid) {
			let obj: IWorklog = Object.assign({ }, this.worklog, this.worklogForm.value);
			start = obj.StartingKms;
			end = obj.EndingKms;
			total = end - start;
		}
		this.worklogForm.patchValue({
			TotalKms: total
		})
	}

	public calcHrs():void {
		let hrs = 0;
		let minutes = 0;
		if (this.worklogForm.valid) {
			let obj: IWorklog = Object.assign({ }, this.worklog, this.worklogForm.value);
			hrs = obj.EndTimeHour - obj.StartTimeHour;
			minutes = obj.EndTimeMinute - obj.StartTimeMinute;
			hrs = hrs + minutes / 60;
		}
		this.worklogForm.patchValue({
			Hours: hrs
		})
	}
}
