import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  constructor(private meta: Meta, private titleService: Title) {
    this.meta.addTag({ name: 'description', content: 'Our company is based on these three principles: Innovation, Integration, Impact.'});
    this.titleService.setTitle('About Us');
   }

  ngOnInit() {
  }

}