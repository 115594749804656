import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'

import { TaskListComponent } from './task-list/task-list.component';
import { TaskEditComponent } from './task-edit/task-edit.component';
import { TaskService } from './task-service';
import { MatProgressSpinnerModule as MatProgressSpinnerModule }  from '@angular/material/progress-spinner';
import { CustomerModule } from '../customers/customer-module';
import { DialogsModule } from '../shared/dialogs/dialogs.module';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
	declarations: [
		TaskListComponent
		,TaskEditComponent
	],
	imports: [
		CustomerModule,
		MatProgressSpinnerModule,
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		DialogsModule,
		NgSelectModule,
		RouterModule.forChild([
			{ path: 'tasks', component: TaskListComponent },
			{ path: 'task-details/:Id', component: TaskEditComponent },
		])
	],
	providers: [
		TaskService
	]
})
export class TaskModule { }
