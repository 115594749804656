
<div class='panel panel-primary animaPlaceholder'>
	<div class='panel-heading'>
	<div class='row'>
		<div class='col-md-4'>
			{{pageTitle}}
		</div>
		<div class='col-lg-2 col-md-2 col-xs-4 options-bar'>
			<button class='btn btn-default btn-sm center-block btn-full' [routerLink]="['/training/test-details/0']" [disabled]="progressStatus">Add New Test</button>
		</div>
		<div class='col-lg-2 col-md-2 col-xs-4 options-bar'>
		<button class='btn btn-default btn-sm center-block btn-full' (click)='showHideSearchClick()' [disabled]="progressStatus">{{SearchText}}</button>
		</div>
		<div class='col-lg-2 col-md-2 col-xs-4 options-bar'>
			<button class='btn btn-default btn-sm center-block btn-full' (click)='toExcel()' [disabled]="progressStatus">To Excel</button>
		</div>
		<div class='col-lg-1 col-md-1 col-xs-4 options-bar'>
			<label>Rows</label>&nbsp;&nbsp;
			<select [(ngModel)]='pageSize' (change)='pageSizeValueChanged()'>
				<option *ngFor='let item of applicationSettings.pageSizes'>{{item}}</option>
			</select>
		</div>
		<div class='col-lg-1 col-md-1 col-xs-4 options-bar'>
			<button class='btn btn-default btn-sm center-block btn-full' (click)='help()'>how-to</button>
		</div>
	</div>
	</div>

	<div class='panel-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='excelFile'>
					<button class='btn btn-primary btn-sm' (click)='hideFileLink()'>hide link</button>
					<span>excel File:<a href = "javascript:void(0)"(click) = "openExcelFile()" > {{ excelFile}}</a></span>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<div class='row filters' *ngIf='showHideSearch'>
		<div class='col-md-9'>
			<div class='row'>
			<div class='col-md-4'>
				<label class='inline-label'> Lesson</label>
				<mat-select class="form-control"[(ngModel)]='LessonsId'>
					<mat-option [value]="">All Lessons</mat-option>
					@for (item of lessonsList; track item) {
					  <mat-option [value]="item.Id">{{item.Name}}</mat-option>
					}
				</mat-select>
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Employee</label>
				<input type='text' class='form-control block-element' placeholder=' Employee' [(ngModel)]='Employee' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Score Numerator</label>
				<input type='text' class='form-control block-element' placeholder=' Score Numerator' [(ngModel)]='ScoreNumerator' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Score Denominator</label>
				<input type='text' class='form-control block-element' placeholder=' Score Denominator' [(ngModel)]='ScoreDenominator' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Notes</label>
				<input type='text' class='form-control block-element' placeholder=' Notes' [(ngModel)]='Notes' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Lesson Start Date</label>
				<input type='date' class='form-control block-element' [(ngModel)]='LessonStartDate' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Lesson End Date</label>
				<input type='date' class='form-control block-element' [(ngModel)]='LessonEndDate' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Quiz Start Date</label>
				<input type='date' class='form-control block-element' [(ngModel)]='QuizStartDate' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Quiz End Date</label>
				<input type='date' class='form-control block-element' [(ngModel)]='QuizEndDate' />
			</div>
			</div>
		</div>
		<div class='col-md-3'>
			<div class='row'>
				<div class='col-md-12 col-xs-4 text-right' >
					<button class='btn btn-primary btn-sm btn-search' (click)='searchClick()' [disabled]="progressStatus||progressLessonsStatus">Search</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right' >
					<button class='btn btn-primary btn-sm btn-search' (click)='searchAndHideClick()' [disabled]="progressStatus||progressLessonsStatus">Search and Hide</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right' >
					<button class='btn btn-primary btn-sm btn-search' (click)='searchClearClick()' [disabled]="progressStatus||progressLessonsStatus">Clear-Search</button>
				</div>
			</div>
		</div>
		</div>
	</div>
</div>

<div class='table-container'>
<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
	<ng-container matColumnDef='Id'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
		<td mat-cell *matCellDef='let e'>
			<a class='btn btn-default' style='min-width: 80px;' [routerLink]="['/training/test-details', e.Id]"  >{{e.Id}}</a>
		</td>
	</ng-container>
	<ng-container matColumnDef='LessonsId'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'> Lessons Id</th>
		<td mat-cell *matCellDef='let e' class='col2' >{{e.LessonsId}}</td>
	</ng-container>
	<ng-container matColumnDef='LessonName'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Lesson Name</th>
		<td mat-cell *matCellDef='let e' class='col3' >{{e.LessonName}}</td>
	</ng-container>
	<ng-container matColumnDef='Employee'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Employee</th>
		<td mat-cell *matCellDef='let e' class='col3' >{{e.Employee}}</td>
	</ng-container>
	<ng-container matColumnDef='ScoreNumerator'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> Score Numerator</th>
		<td mat-cell *matCellDef='let e' class='col4' >{{e.ScoreNumerator}}</td>
	</ng-container>
	<ng-container matColumnDef='ScoreDenominator'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'> Score Denominator</th>
		<td mat-cell *matCellDef='let e' class='col5' >{{e.ScoreDenominator}}</td>
	</ng-container>
	<ng-container matColumnDef='Notes'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col6'> Notes</th>
		<td mat-cell *matCellDef='let e' class='col6' >{{e.Notes}}</td>
	</ng-container>
	<ng-container matColumnDef='LessonStartDate'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col7'> Lesson Start Date</th>
		<td mat-cell *matCellDef='let e' class='col7' >{{e.LessonStartDate}}</td>
	</ng-container>
	<ng-container matColumnDef='LessonEndDate'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col8'> Lesson End Date</th>
		<td mat-cell *matCellDef='let e' class='col8' >{{e.LessonEndDate}}</td>
	</ng-container>
	<ng-container matColumnDef='QuizStartDate'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col9'> Quiz Start Date</th>
		<td mat-cell *matCellDef='let e' class='col9' >{{e.QuizStartDate}}</td>
	</ng-container>
	<ng-container matColumnDef='QuizEndDate'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col10'> Quiz End Date</th>
		<td mat-cell *matCellDef='let e' class='col10' >{{e.QuizEndDate}}</td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
	<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
</div>
<mat-paginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>
<div>
<mat-spinner color="primary" class="spinner" *ngIf="progressStatus||progressLessonsStatus"></mat-spinner>
</div>