import { Component } from '@angular/core';
import { SpinnerService } from './spinner.service';

@Component({
    selector: 'spinner-component',
    'template': `
    <div class="spinner" *ngIf="active">
        <div class="rect1"></div>
        <div class="rect2"></div>
        <div class="rect3"></div>
        <div class="rect4"></div>
        <div class="rect5"></div>
    </div>
    `,
    styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent {
    public active: boolean = false;

    public constructor(spinner: SpinnerService) {
        spinner.status.subscribe((status: boolean) => {
            this.active = status;
        });
    }
}