import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IWorktask} from './Worktask-model/Worktask';
import { IWorktasks } from './Worktask-model/Worktasks';
import { IWorktaskSearch } from './Worktask-model/WorktaskSearch';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';

@Injectable()
export class WorktaskService {
	private baseUrl;
	public pageTitle = 'Work Tasks List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Worktasks';
	}

	getWorktask(id: number):  Observable<IWorktask> {
		if (id === 0) {
			return of(this.initializeWorktask());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IWorktask>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveWorktask(worktask: IWorktask): Observable<any> {
		if (worktask.Id === 0) {
			return this.createWorktask(worktask);
		}
		return this.updateWorktask(worktask);
	}

	private createWorktask(worktask: IWorktask): Observable<any> {
		worktask.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl, worktask)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateWorktask(worktask: IWorktask): Observable<IWorktask> {
		return this.authHttp.put<IWorktask>(this.baseUrl, worktask)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteWorktask(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeWorktask(): IWorktask {
		return {
			Id: 0,
			Name: '',
			BranchId: 0,
			Description: '',
			EstimatedHours: 0,
			Priority: 0,
			CompletedDate: '',
			Application: '',
			Type1: '',
			Type2: '',
			Type3: '',
			Notes: '',
			InstalledDate: '',
			AssignedUser: '',
			nextaction: '',
			DevNotes: '',
			Status: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IWorktask;
	}

	public getRecordSet(search: IWorktaskSearch): Observable<IWorktasks> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('Name', search.Name, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Priority', search.Priority, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Application', search.Application, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('AssignedUser', search.AssignedUser, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Status', search.Status, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('Name', search.Name ? search.Name : '')
			.set('Priority', search.Priority ? search.Priority : '')
			.set('Application', search.Application ? search.Application : '')
			.set('AssignedUser', search.AssignedUser ? search.AssignedUser : '')
			.set('Status', search.Status ? search.Status : '')
		};

		return this.authHttp.get<IWorktasks>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IWorktasks>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IWorktaskSearch { 
		let search: IWorktaskSearch = {
			Id: 1,
			Name: '',
			Priority: '',
			Application: '',
			AssignedUser: '',
			Status: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
		 message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	 }
	 getWorkTaskLogsReport(application,startdate,enddate):  Observable<any> {
		return this.authHttp
		.get<any>( `${this.baseUrl}/getworktasklogsreport?application=${application}&startdate=${startdate}&enddate=${enddate}`)
		.pipe(retry(1), catchError(this.processError));

	}
}
