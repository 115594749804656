
<div class='panel panel-primary animaPlaceholder'>
	<div class='panel-heading'>
		<span *ngIf = 'employeequestion'>{{employeequestion.LessonName}}</span >
		<span class='pull-right'>
			{{record}} of {{recordTotal}}
		</span>
		<div class='has-error' *ngIf='errorMessage'>{{ errorMessage }}</div>
	</div>
<div class='container-fluid'>
	<div class='panel-body'>
		<form class='form-horizontal' novalidate [formGroup]='employeequestionForm'>
			<fieldset>
				<div class='row'>
<!-- Column 1 -->
<div class='col-md-12'>
<div class='row'>
<!-- Column 1-->

	<div *ngIf=employeequestion>
		<span [innerHTML]='employeequestion.ContentDescription'></span>
	</div>
</div>
<!-- End Column 1 -->
</div>
</div>
<div *ngIf="employeequestion">

	<testjoinedresult-cus-tf questionId={{employeequestion.Id}} testId={{employeequestion.TestId}}></testjoinedresult-cus-tf>
		
</div>
<!-- End Column 1-->
				<div class='row'>
					<div class='col-md-12'>
						<span>
							<button class='btn btn-primary'
								[disabled]='disablePreviousRecordButton||buttonPushed'
								(click)='saveEmployeequestion(false, false)'
								style='width: 110px; margin-right:10px'
								>
								<span class='glyphicon glyphicon-floppy-save'></span> Previous &nbsp;
							</button>
							<button class='btn btn-primary'
								[disabled]='buttonPushed||disableNextRecordButton'
								(click)='saveEmployeequestion(false, true)'
								style='width: 150px; margin-right:10px'
								>
								<span class='glyphicon glyphicon-floppy-save'></span> Next &nbsp;
							</button>
							
						</span>
						<span class='pull-right'>
							<button class='btn btn-primary' (click)='quizFinished()' style='width: 200px; margin-right:10px'>
								<span class='glyphicon glyphicon-floppy-save'></span> Complete and Save&nbsp;
							</button>
							<button class='btn btn-primary' (click)='cancel()' style='width: 150px; margin-right:10px' >
								<span class='glyphicon glyphicon-floppy-save'></span> Cancel &nbsp;
							</button>
						</span>
					</div>
				</div>
			</fieldset>
		</form>
	</div>
	</div>
</div>