import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-fanuc',
  templateUrl: './fanuc.component.html',
  styleUrls: ['./fanuc.component.css']
})
export class FanucComponent implements OnInit {
  apiLoaded = false;
  constructor(private meta: Meta, private titleService: Title) { 
    this.meta.addTag({ name: 'description', content: 'We are FANUC Authorized System Integrators who service and install new or used robots.'});
    this.titleService.setTitle('FANUC');
  }
 

  ngOnInit() {
    if (!this.apiLoaded) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.apiLoaded = true;
    }
  }
  

}
