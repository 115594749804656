import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatPaginator as MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import {merge} from 'rxjs';
import { Router } from '@angular/router';

import { ApplicationSettings } from '../../shared/application-settings';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';

import { TaskService } from '../task-service';
import { ITaskSearch } from '../task-model/taskSearch';
import { ITasks } from '../task-model/tasks';
import { FormControl } from '@angular/forms';
import { LookuplistService } from '../../lookuplists/lookuplist-service';
import { ITaskAction } from '../task-model/taskAction';
import { MatDialog } from '@angular/material/dialog';
import { InformationDialogComponent } from '../../shared/dialogs/information-dialog.component';
import { ILookuplist } from '../../lookuplists/Lookuplist-model/Lookuplist';

@Component({
	templateUrl: './task-list.component.html',
	styleUrls: ['./task-list.component.css']
})

export class TaskListComponent implements OnInit, AfterViewInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	displayedColumns: string[] = ['Id', 'TaskName','Description'];

	Priorities = new FormControl([]);

	public UserSearches: IUsersearch[];
	public pageSize = '10';
	public skip = 0;
	public recordTotal: number = 0;
	pageSizeLst = new FormControl();

	public showHideSearch: boolean = false;
	public SearchText: string = 'Show Filters';

	public TaskName = '';

	public pageTitle = 'Tasks List';
	public errorMessage = '';
	public dataSource: any;
	public excelFile;
	public progressStatusTaskList=true;
	

	public statusList:any;
	public priorityList:ILookuplist[];

	constructor(private userSearchService: UsersearchService
		, public applicationSettings: ApplicationSettings
		, private router: Router
		, private lookuplistService:LookuplistService
		, private _appService: TaskService
		, public dialog: MatDialog) {
	}



	ngOnInit(): void {
		
		this.skip = 0;
		let x  = +localStorage.getItem('rows');
		if(x>0) { this.pageSize = x.toString(); }
		else { this.pageSize = '10';}
		this.pageSizeLst.setValue(this.pageSize);
		if (localStorage.getItem('showHideSearch') === 'true')
		{
			   this.showHideSearch = true;
			   this.SearchText = 'Hide Filters';
		}
		else
		{
			   this.showHideSearch = false;
			   this.SearchText = 'Show Filters';
		 }
	}

	ngAfterViewInit(): void {
		this.userSearchService.getUserSearchesByForm(this.pageTitle)
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
		}
		, (error: any) => {this.errorMessage = < any > error;this.progressStatusTaskList = false;});
	}

	setUserFilters(): void {
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.paginator.pageIndex = 0;

		this.UserSearches.forEach(i => {
			if(i.Item=='TaskName')
				this.TaskName = i.Value;


			if(i.Item=='orderByString') {
				if (this.applicationSettings.getOrderByDirection(i.Value)==='asc')
					this.sort.direction = 'asc';
				else
					this.sort.direction = 'desc';

				this.sort.active = this.applicationSettings.getOrderByName(i.Value);
			}

			if (i.Item == 'Skip')
				this.skip = i.Value;
		});

		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
		merge(this.sort.sortChange, this.paginator.page)
			.subscribe(()=> this.getData());
		this.getData();
	}

	pageSizeValueChanged(rows: string): void {
		this.applicationSettings.setPageSize(rows);
		this.pageSize=rows;
		this.pageSizeLst.setValue(this.pageSize);
		console.log(this.recordTotal);
		console.log(this.pageSize);
		this.getData();
	}

	getData(): any {
	 this.progressStatusTaskList=true; 
	 this.dataSource = null; 
	 this.recordTotal = 0; 
		let search: ITaskSearch = this._appService.getFreshSearch();

		search.TaskName = this.TaskName;
		search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
		search.take = parseInt(this.pageSize);
		search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

		this._appService.getRecordSet(search).subscribe((obj: ITasks) => this.onSelectedRecordSetReceived(obj),
		(error: any) => {
			this.errorMessage = <any> error;
			this.progressStatusTaskList = false;
		});
	}

	private onSelectedRecordSetReceived(obj: ITasks){
		this.dataSource = new MatTableDataSource(obj.data);
		this.recordTotal = obj.total;
		this.progressStatusTaskList = false;
		console.log(obj);
	}

	searchClick(): void {
		this.skip = 0;
		this.paginator.pageIndex = 0;
		this.getData();
	}

	public searchClearClick(): void {
		this.TaskName = '';
	
		this.skip = 0;
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.paginator.pageIndex = 0;
		this.getData();
	}

	resetError() : void {
		this.errorMessage = '';
	}
	showHideSearchClick() {
		 if (this.showHideSearch) 
		 {
			 this.showHideSearch = false; 
			localStorage.setItem('showHideSearch', 'false'); 
			this.SearchText = 'Show Filters'; 
		}
		else
		{
			 this.showHideSearch = true; 
			localStorage.setItem('showHideSearch', 'true'); 
			  this.SearchText = 'Hide Filters'; 
		}
	}
	searchAndHideClick() {
		 this.skip = 0;
		 this.paginator.pageIndex = 0;
		this.getData();
		 setTimeout(() => {
		     this.showHideSearchClick();
		 }, 500);
	 }
	public help()
	{
		   const url = this.router.serializeUrl(
		       this.router.createUrlTree([`/help-view/tasks`])
		);
		  window.open(url, '_blank');
	 }
	public toExcel(){
			let search: ITaskSearch = this._appService.getFreshSearch();

			search.TaskName = this.TaskName;
			search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
			search.take = parseInt(this.pageSize);
			search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

			this._appService.toExcel(search).subscribe(
				retVal => {
					    this.excelFile = retVal;
				},
				 (error: any) => {
					 this.errorMessage = < any > error;
					 this.progressStatusTaskList = false;
				});
	}
	public hideFileLink()
	{
	   this.excelFile = "";
	}
	public openExcelFile()
	{
	   window.open(`${ this.applicationSettings.retrieveUrl()}${ this.excelFile}`);
	}

}