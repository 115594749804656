import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
// Kendo Controls
/*import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { UploadModule } from '@progress/kendo-angular-upload';
import { EditorModule } from '@progress/kendo-angular-editor';*/

import { CourseListComponent } from './courses/course-list/course-list.component';
import { CourseEditComponent } from './courses/course-edit/course-edit.component';
import { CourseService } from './courses/course-service';
import { MaterialModule } from '../material-module';

import { LessonListComponent } from './lessons/lesson-list/lesson-list.component';
import { LessonEditComponent } from './lessons/lesson-edit/lesson-edit.component';
import { LessonService } from './lessons/lesson-service';

import { ContentListComponent } from './contents/content-list/content-list.component';
import { ContentEditComponent } from './contents/content-edit/content-edit.component';
import { ContentService } from './contents/content-service';
import { CKEditorComponent, CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { QuestionListComponent } from './questions/question-list/question-list.component';
import { QuestionEditComponent } from './questions/question-edit/question-edit.component';
import { QuestionService } from './questions/question-service';

import { AnswerListComponent } from './answers/answer-list/answer-list.component';
import { AnswerEditComponent } from './answers/answer-edit/answer-edit.component';
import { AnswerService } from './answers/answer-service';

import { TestListComponent } from './tests/test-list/test-list.component';
import { TestEditComponent } from './tests/test-edit/test-edit.component';
import { TestService } from './tests/test-service';

import { TestresultListComponent } from './testresults/testresult-list/testresult-list.component';
import { TestresultEditComponent } from './testresults/testresult-edit/testresult-edit.component';
import { TestresultService } from './testresults/testresult-service';

import { TestcontentListComponent } from './testcontents/testcontent-list/testcontent-list.component';
import { TestcontentEditComponent } from './testcontents/testcontent-edit/testcontent-edit.component';
import { TestcontentService } from './testcontents/testcontent-service';

import { EmployeelessonListComponent } from './employeelessons/employeelesson-list/employeelesson-list.component';
import { EmployeelessonEditComponent } from './employeelessons/employeelesson-edit/employeelesson-edit.component';
import { EmployeelessonService } from './employeelessons/employeelesson-service';
import { EmployeelessonCustomComponent } from './employeelessons/employeelesson-custom/employeelesson-custom.component';

import { EmployeecourseListComponent } from './employeecourses/employeecourse-list/employeecourse-list.component';
import { EmployeecourseEditComponent } from './employeecourses/employeecourse-edit/employeecourse-edit.component';
import { EmployeecourseService } from './employeecourses/employeecourse-service';

import { EmployeequestionListComponent } from './employeequestions/employeequestion-list/employeequestion-list.component';
import { EmployeequestionEditComponent } from './employeequestions/employeequestion-edit/employeequestion-edit.component';
import { EmployeequestionService } from './employeequestions/employeequestion-service';
import { EmployeequestionCustomComponent } from './employeequestions/employeequestion-custom/employeequestion-custom.component';

import { EmployeeanswerListComponent } from './employeeanswers/employeeanswer-list/employeeanswer-list.component';
import { EmployeeanswerEditComponent } from './employeeanswers/employeeanswer-edit/employeeanswer-edit.component';
import { EmployeeanswerService } from './employeeanswers/employeeanswer-service';

import { TrainingManagementService } from './trainingmanagement-service';

import { TestJoinedResultComponent } from './testjoinedresults/testjoinedresult-cus-tf/testjoinedresult-cus-tf.component';

//import { TestjoinedresultListComponent } from './testjoinedresults/testjoinedresult-list/testjoinedresult-list.component';
//import { TestjoinedresultEditComponent } from './testjoinedresults/testjoinedresult-edit/testjoinedresult-edit.component';
import { TestjoinedresultService } from './testjoinedresults/testjoinedresult-service';



	@NgModule({
	declarations: [
		  CourseListComponent
		, CourseEditComponent
		, LessonListComponent
		, LessonEditComponent
		, ContentListComponent
		, ContentEditComponent
		, QuestionListComponent
		, QuestionEditComponent
		, AnswerListComponent
		, AnswerEditComponent
		, TestListComponent
		, TestEditComponent
		, TestresultListComponent
		, TestresultEditComponent
		, TestcontentListComponent
		, TestcontentEditComponent
		, EmployeelessonListComponent
		, EmployeelessonEditComponent
		, EmployeelessonCustomComponent
		, EmployeecourseListComponent
		, EmployeecourseEditComponent
		, EmployeequestionListComponent
		, EmployeequestionEditComponent
		, EmployeequestionCustomComponent
		, EmployeeanswerListComponent
		, EmployeeanswerEditComponent
		, TestJoinedResultComponent
	],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		HttpClientModule,
		MaterialModule,
		CKEditorModule,
		RouterModule.forChild([
			{ path: 'training/courses', component: CourseListComponent },
			{ path: 'training/course-details/:Id', component: CourseEditComponent },
			{ path: 'training/lessons', component: LessonListComponent },
			{ path: 'training/lesson-details/:Id/:courseId', component: LessonEditComponent },
			{ path: 'training/contents', component: ContentListComponent },
			{ path: 'training/content-details/:Id/:courseId/:lessonId', component: ContentEditComponent },
			{ path: 'training/questions', component: QuestionListComponent },
			{ path: 'training/question-details/:Id/:courseId/:lessonId', component: QuestionEditComponent },
			{ path: 'training/answers', component: AnswerListComponent },
			{ path: 'training/answer-details/:Id/:courseId/:lessonId/:questionId', component: AnswerEditComponent },
			{ path: 'training/tests', component: TestListComponent },
			{ path: 'training/test-details/:Id', component: TestEditComponent },
			{ path: 'training/testresults', component: TestresultListComponent },
			{ path: 'training/testresult-details/:Id', component: TestresultEditComponent },
			{ path: 'training/testcontents', component: TestcontentListComponent },
			{ path: 'training/testcontent-details/:Id', component: TestcontentEditComponent },

			{ path: 'training/employeelessons', component: EmployeelessonListComponent },
			{ path: 'training/employeelesson-details/:Id', component: EmployeelessonEditComponent },
			{ path: 'training/employeelesson-custom/:Id/:CurrentPage', component: EmployeelessonCustomComponent },

			{ path: 'training/employeecourses', component: EmployeecourseListComponent },
			{ path: 'training/employeecourse-details/:Id', component: EmployeecourseEditComponent },
			
			{ path: 'training/employeequestions', component: EmployeequestionListComponent },
			{ path: 'training/employeequestion-details/:Id', component: EmployeequestionEditComponent },
			{ path: 'training/employeequestion-custom/:Id/:CurrentPage', component: EmployeequestionCustomComponent },
			
			{ path: 'training/employeeanswers', component: EmployeeanswerListComponent },
			{ path: 'training/employeeanswer-details/:Id', component: EmployeeanswerEditComponent },
		
			/*{ path: 'training/testjoinedresults', component: TestjoinedresultListComponent },
			{ path: 'training/testjoinedresult-details/:Id', component: TestjoinedresultEditComponent },*/
		])
	],
	providers: [
		CourseService
		, LessonService
		, ContentService
		, QuestionService
		, AnswerService
		, TestService
		, TestresultService
		, TestcontentService
		, EmployeelessonService
		, EmployeecourseService
		, EmployeequestionService
		, EmployeeanswerService
		, TrainingManagementService
		, TestjoinedresultService
	],
})
export class TrainingModule { }
