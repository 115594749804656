import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IEmployeequestion} from './Employeequestion-model/Employeequestion';
import { IEmployeequestions } from './Employeequestion-model/Employeequestions';
import { IEmployeequestionSearch } from './Employeequestion-model/EmployeequestionSearch';

import { ApplicationSettings } from '../../shared/application-settings';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';

@Injectable()
export class EmployeequestionService {
	private baseUrl;
	public pageTitle = 'EmployeeQuestions List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Employeequestions';
	}

	getEmployeequestion(id: number):  Observable<IEmployeequestion> {
		if (id === 0) {
			return of(this.initializeEmployeequestion());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IEmployeequestion>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveEmployeequestion(employeequestion: IEmployeequestion): Observable<any> {
		if (employeequestion.Id === 0) {
			return this.createEmployeequestion(employeequestion);
		}
		return this.updateEmployeequestion(employeequestion);
	}

	private createEmployeequestion(employeequestion: IEmployeequestion): Observable<any> {
		employeequestion.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/employeequestioncreate', employeequestion)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateEmployeequestion(employeequestion: IEmployeequestion): Observable<IEmployeequestion> {
		return this.authHttp.put<IEmployeequestion>(this.baseUrl, employeequestion)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteEmployeequestion(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeEmployeequestion(): IEmployeequestion {
		return {
			Id: 0,
			LessonName: '',
			CoursesId: 0,
			LessonId: 0,
			ContentName: '',
			ContentDescription: '',
			ContentReviewed: '',
			ContentStatus: '',
			ContentNotes: '',
			LessonUser: '',
			Employee: '',
			TestId: 0,
			CreatedDate: 0,
			CreatedBy: 0,
			UpdatedDate: 0,
			UpdatedBy: 0,
		} as IEmployeequestion;
	}

	public getRecordSet(search: IEmployeequestionSearch): Observable<IEmployeequestions> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('LessonName', search.LessonName, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('CoursesId', search.CoursesId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('LessonId', search.LessonId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('ContentName', search.ContentName, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('ContentDescription', search.ContentDescription, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('ContentReviewed', search.ContentReviewed, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('ContentStatus', search.ContentStatus, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('ContentNotes', search.ContentNotes, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('LessonUser', search.LessonUser, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Employee', search.Employee, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('TestId', search.TestId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('LessonName', search.LessonName ? search.LessonName : '')
			.set('CoursesId', search.CoursesId ? search.CoursesId : '')
			.set('LessonId', search.LessonId ? search.LessonId : '')
			.set('ContentName', search.ContentName ? search.ContentName : '')
			.set('ContentDescription', search.ContentDescription ? search.ContentDescription : '')
			.set('ContentReviewed', search.ContentReviewed ? search.ContentReviewed : '')
			.set('ContentStatus', search.ContentStatus ? search.ContentStatus : '')
			.set('ContentNotes', search.ContentNotes ? search.ContentNotes : '')
			.set('LessonUser', search.LessonUser ? search.LessonUser : '')
			.set('Employee', search.Employee ? search.Employee : '')
		};

		return this.authHttp.get<IEmployeequestions>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IEmployeequestions>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IEmployeequestionSearch { 
		let search: IEmployeequestionSearch = {
			Id: 1,
			LessonName: '',
			CoursesId: '',
			LessonId: '',
			ContentName: '',
			ContentDescription: '',
			ContentReviewed: '',
			ContentStatus: '',
			ContentNotes: '',
			LessonUser: '',
			Employee: '',
			TestId: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.error.ErrorDescription}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: IEmployeequestionSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('LessonName', search.LessonName ? search.LessonName : '')
			.set('CoursesId', search.CoursesId ? search.CoursesId : '')
			.set('LessonId', search.LessonId ? search.LessonId : '')
			.set('ContentName', search.ContentName ? search.ContentName : '')
			.set('ContentDescription', search.ContentDescription ? search.ContentDescription : '')
			.set('ContentReviewed', search.ContentReviewed ? search.ContentReviewed : '')
			.set('ContentStatus', search.ContentStatus ? search.ContentStatus : '')
			.set('ContentNotes', search.ContentNotes ? search.ContentNotes : '')
			.set('LessonUser', search.LessonUser ? search.LessonUser : '')
			.set('Employee', search.Employee ? search.Employee : '')
			.set('TestId', search.TestId ? search.TestId : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
}