import { Component, OnInit } from '@angular/core';
import { ApplicationSettings } from '../../shared/application-settings';
import { WorktaskService } from '../worktask-service';
@Component({
  selector: 'app-worktasklogsreport',
  templateUrl: './worktasklogsreport.component.html',
  styleUrls: ['./worktasklogsreport.component.css']
})
export class WorktasklogsreportComponent implements OnInit {

  public application='';
  public startDate='';
  public endDate='';
	errorMessage: string;
  public progressStatus=false;

  constructor(
    public applicationSettings: ApplicationSettings
    ,private worktaskService:WorktaskService
  ) { }

  ngOnInit(): void {
  }
  getReport(): void {
    if(this.application!="" && this.startDate!="" && this.endDate!=""){
      this.progressStatus=true;
    let url = this.applicationSettings.retrieveUrl() + '/uploadedfiles/tempReports/';
		this.worktaskService.getWorkTaskLogsReport(this.application,this.startDate,this.endDate)
			.subscribe((obj) => {
        this.progressStatus=false;
        if(obj.status==true){
          window.open(url + obj.data, "_blank");
          console.log('pdf created:' + obj.data)
        }
        else{
          alert(obj.message);
        }
      },
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
          
  }
  else{
    alert("Please select all data");
  }
	}
  resetError() : void {
		this.errorMessage = '';
	}
}
