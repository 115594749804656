import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ApplicationSettings } from '../../shared/application-settings';

import { retry, catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { IUsersearch } from './usersearch-model/usersearch';
import { IUsersearcheSearch } from './usersearch-model/usersearcheSearch';
import { IUsersearches } from './usersearch-model/usersearchs';

@Injectable()
export class UsersearchService {
	private baseUrl;

	constructor(private authHttp: HttpClient, private settings: ApplicationSettings) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Usersearches';
	}

	getUsersearch(id: number):  Observable<IUsersearch> {
		if (id === 0) {
			return of(this.initializeUsersearch());
		}

		const url = `${this.baseUrl}/${id}`;
		return this.authHttp.get<IUsersearch>(url)
		.pipe(
			retry(1),
			catchError(this.processError));
	}

	getUserSearchesByForm(form: string):  Observable<IUsersearch[]> {

		const url = `${this.baseUrl}?form=${form}`;
		return this.authHttp.get<IUsersearch[]>(url)
		.pipe(
			retry(1),
			catchError(this.processError));
	}

	saveUsersearch(usersearch: IUsersearch): Observable < any > {
		 if (usersearch.Id === 0) {
		 	return this.createUsersearch(usersearch);
		 }
		return this.updateUsersearch(usersearch);
	}

	private createUsersearch(usersearch: IUsersearch): Observable <any> {
		usersearch.Id = 0;

		return this.authHttp.post(this.baseUrl, usersearch)
		.pipe(
			retry(1),
			catchError(this.processError));
	}

	private updateUsersearch(usersearch: IUsersearch): Observable <IUsersearch> {
		return this.authHttp.put<IUsersearch>(this.baseUrl, usersearch)
		.pipe(
			retry(1),
			catchError(this.processError));
	}

	deleteUsersearch(id: number): Observable < Response > {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
		.pipe(
			retry(1),
			catchError(this.processError));
	}


	initializeUsersearch(): IUsersearch {
		return {
			Id: 0,
			UserId: '',
			Form: '',
			Item: '',
			Value: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IUsersearch;
	}

	saveUsersearches(usersearches: IUsersearch[]): Observable < any > {
		return this.updateUsersearches(usersearches);
	}
	
	private updateUsersearches(usersearches: IUsersearch[]): Observable <IUsersearch> {
	 return this.authHttp.put<IUsersearch>(this.baseUrl + '/UpdateSearches', usersearches)
	 	.pipe(
			retry(1),
			catchError(this.processError));
	 }

	 public setSearch(item: string, value: string, pageTitle: string): IUsersearch {
		var userSearch: IUsersearch = {} as IUsersearch;

		userSearch.Id = 0;
		userSearch.UserId = ''
		userSearch.CreatedBy = ''
		userSearch.CreatedDate = ''
		userSearch.Form = pageTitle;
		userSearch.UpdatedBy = '';
		userSearch.UpdatedDate = '';
		userSearch.Value = value;
		userSearch.Item = item;
		
		return userSearch;
	}

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
		 message = err.error.message;
		} else {
		 message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.log(message);
		return throwError(message);
	 }
	 public getFreshSearch() : IUsersearcheSearch { 
		let search: IUsersearcheSearch = {
			Id: 1,
			UserId: '',
			Form: '',
			Item: '',
			Value: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};
	public getRecordSet(search: IUsersearcheSearch): Observable<IUsersearches> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.setSearch('UserId', search.UserId, 'Usersearches'));
		uSearch.push(this.setSearch('Form', search.Form, 'Usersearches'));
		uSearch.push(this.setSearch('Item', search.Item, 'Usersearches'));
		uSearch.push(this.setSearch('Value', search.Value, 'Usersearches'));
		uSearch.push(this.setSearch('orderByString', search.orderByString, 'Usersearches'));
		uSearch.push(this.setSearch('Skip', search.skip.toString(), 'Usersearches'));

		this.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('UserId', search.UserId ? search.UserId : '')
			.set('Form', search.Form ? search.Form : '')
			.set('Item', search.Item ? search.Item : '')
			.set('Value', search.Value ? search.Value : '')
		};

		return this.authHttp.get<IUsersearches>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IUsersearches>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}
	public toExcel(search: IUsersearcheSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('UserId', search.UserId ? search.UserId : '')
			.set('Form', search.Form ? search.Form : '')
			.set('Item', search.Item ? search.Item : '')
			.set('Value', search.Value ? search.Value : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}

}
