import { Component, OnInit, OnDestroy,  ViewChildren, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControlName, Validators, FormControl  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ICompany } from '../company-model/company';
import { Subscription } from 'rxjs/Subscription';
import { CompanyService } from '../company-service';
import { ICompanys } from '../Company-model/Companys';
import { ICompanySearch } from '../Company-model/CompanySearch';

import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { LookuplistService } from '../../lookuplists/lookuplist-service';
import { CompanyFileDto } from '../company-model/CompanyFileDto';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { MatPaginator as MatPaginator } from '@angular/material/paginator';
import { ApplicationSettings } from '../../shared/application-settings';
import { MatSnackBar } from '@angular/material/snack-bar';
import { formatDate } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../shared/dialogs/confirmation-dialog.component';
import { CompanytraitService } from '../../companytraits/companytrait-service';

@Component({
	templateUrl: './company-edit.component.html',
	styleUrls: ['company-edit.component.css']
})

export class CompanyEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

@ViewChild(MatPaginator) paginator: MatPaginator;
displayedColumns: string[] =  ['Edit', 'FileName', 'FileDescription', 'SortOrder', 'IncludeInReport','Delete'];
displayedColumnsEngagements: string[] =  ['Edit','ContactDate', 'Type', 'ContactName', 'Notes', 'Delete'];
displayedColumnsTraits: string[] =  ['Trait','Delete'];
	public page = 'Edit Company';
	errorMessage: string;
	companyForm: FormGroup;
	companyEngagementForm: FormGroup;
	companyTraitsForm: FormGroup;
	company: ICompany;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;

	public recordTotalEngagements: number = 0;

	public UserSearches: IUsersearch[];
	public CompanySearch: ICompanySearch;
	public ListData: ICompanys;
	public showNextRecordButton: boolean = true;
	public progressStatus=false;
	public provinceList:any;
	public countryList:any;
	fileFormSubmitted: boolean = false;
	fileToEdit: CompanyFileDto;
	public dataSource: any;
	public dataSourceEngagements: any;
	companyId: number;
	public filesForm: FormGroup

	EngagementTypes = new FormControl([]);
	public statusList :any;
	public priorityList :any;
	public typesList :any;
	public engagementTypesList :any;
	public pageSize = '10';

	public progressLoadingTraitsStatus = false;
	public progressCompanyTraitsStatus = false;
	public traitsList :any;
	public dataSourceTraits: any;


	selected = new FormControl(0);


	@ViewChild('inputFile') fileUp: ElementRef;
	@ViewChild('firstPaginator', { static: true}) firstPaginator: MatPaginator;
	@ViewChild('secondPaginator', { static: true}) secondPaginator: MatPaginator;
	constructor(private fb: FormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private companyService: CompanyService
		, private lookuplistService:LookuplistService
		, private applicationSettings:ApplicationSettings
		, public snackBar: MatSnackBar
		, public dialog: MatDialog
		, private companytraitsService:CompanytraitService
		) {
	} 

	options: google.maps.MapOptions = {
		mapId: "DEMO_MAP_ID",
		center: { lat: 53.533841497838814, lng: -113.48806046214992 },
		zoom: 10,
		mapTypeId: 'hybrid'
	  };

	  addMarker(event: google.maps.MapMouseEvent) {
		
		const latLng = event.latLng;
		if (latLng) {
			this.company.Latitude = latLng.lat();
			this.company.Longitude = latLng.lng();
		this.companyForm.patchValue({
			Latitude: latLng.lat(),
			Longitude: latLng.lng(),
		});
		}
	  }

	ngOnInit(): void {
			let x  = +localStorage.getItem('rows');
			if(x>0) { this.pageSize = x.toString(); }
			else { this.pageSize = '10';}

		this.companyForm = this.fb.group({
			Id: '',
			CompanyName: ['',[Validators.maxLength(250)]],
			Address: ['',[Validators.maxLength(4000)]],
			City: ['',[Validators.maxLength(250)]],
			Province: ['',[Validators.maxLength(250)]],
			Country: ['',[Validators.maxLength(250)]],
			PostalCode: ['',[Validators.maxLength(250)]],
			Notes: ['',[Validators.maxLength(4000)]],
			Website: ['',[Validators.maxLength(250)]],
			Email: ['',[Validators.maxLength(250)]],
			Phone: ['',[Validators.maxLength(250)]],
			LinkedIn: ['',[Validators.maxLength(250)]],
			Instagram: ['',[Validators.maxLength(250)]],
			Facebook: ['',[Validators.maxLength(250)]],
			Status: ['',[Validators.maxLength(250)]],
			Priority: ['',[Validators.maxLength(250)]],
			NextContact:'',
			Type: ['',[Validators.maxLength(250)]],
			
			Latitude: '',
			Longitude: '',
		
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});
		
		this.filesForm = this.fb.group({
			fileUpload:['',Validators.required],
			fileDescription:[''],
			sortOrder:[''],
			includeReport:[''],
		})

		this.companyEngagementForm = this.fb.group({
			Id: [0, Validators.required],
			Type: ['', Validators.required],
			ContactName: ['', Validators.required],
			Notes: ['', Validators.required],
			CompanyId: ['', Validators.required],
			ContactDate:[formatDate(new Date(), 'yyyy-MM-dd', 'en-US'), Validators.required]
		})

		this.companyTraitsForm = this.fb.group({
			Id: [0, Validators.required],
			CompanyId: ['', Validators.required],
			Trait: ['',[Validators.maxLength(256),Validators.required]],
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		var currentTab = 0;
		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				if(+params['TabIndex']){
					currentTab = +params['TabIndex'];
				}
				this.companyId = id;
				this.getCompany(id);
				this.progressStatus = true;
			}
		);
		this.getProvince();
		this.getCountry();
		this.getStatusList();
		this.getContactTypeList();
		this.getCompanyTypeList();
		this.getPriorityList();
		this.selected.setValue(currentTab);
	}

	public getSelectedRecordSet():void{
		this.companyService.getRecordSet(this.CompanySearch)
			.subscribe((obj: ICompanys) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: ICompanys){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.company.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if ((this.record-obj.skip) < this.ListData.data.length)
			this.showNextRecordButton = true;
		else
			this.showNextRecordButton = false;

		this.progressStatus = false;

	}

	openSnackBar(message: string, action: string, durationMiliiseconds:number) {
		this.snackBar.open(message, action, {
		  duration: durationMiliiseconds,
		});
	}

	saveCompany(returnToList: boolean, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

		if (this.companyForm.valid) {
			const obj = Object.assign({ }, this.company, this.companyForm.value);
			this.companyService.saveCompany(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord,addNewRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		if (returnToList) {
			this.companyForm.reset();
			this.router.navigate(['/companies']);
		} 
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
		else if(addNewRecord){
			this.router.navigate(['/company-details', 0]);
			this.companyForm.reset();
			let obj=this.companyService.initializeCompany();
			this.onCompanyRetrieved(obj);
		}
			// when the record id is zero then it is a new record.
		else if (data !== null && this.company.Id === 0) {
			this.router.navigate(['/company-details', data.Id]);
		} 
		else if (this.company.Id != 0){
			this.getCompany(this.company.Id);
		}

		this.openSnackBar('Your changes have been successfully saved','Ok',5000);

		this.buttonPushed = false;
		this.progressStatus = false;
	}

	advanceToNextRecord(): void {
		let count = 0;
		let record = 0;
		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.company.Id)
				record = count;
		});
		this.router.navigate(['/company-details', this.ListData.data[record].Id]);
	}

	getCompany(id: number): void {
		this.companyService.getCompany(id)
			.subscribe((obj: ICompany) => this.onCompanyRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onCompanyRetrieved(company: ICompany): void {
		if (this.companyForm) {
			this.companyForm.reset();
		}
		this.company = company;
		if(this.company && this.company.Latitude!=0 &&this.company.Longitude!=0){
			this.options.center = { lat: this.company.Latitude, lng: this.company.Longitude };
			}
		if (this.company.Id === 0) {
			this.page = 'Add Company';
		} else {
			this.page = 'Edit Company';
		}

		this.companyForm.patchValue({
			Id: this.company.Id,
			CompanyName: this.company.CompanyName,
			Address: this.company.Address,
			City: this.company.City,
			Province: this.company.Province,
			Country: this.company.Country,
			PostalCode: this.company.PostalCode,
			Notes: this.company.Notes,
			Website: this.company.Website,
			Email: this.company.Email,
			Phone: this.company.Phone,
			LinkedIn: this.company.LinkedIn,
			Instagram: this.company.Instagram,
			Facebook: this.company.Facebook,
			Status: this.company.Status,
			Priority: this.company.Priority,
			NextContact:this.company.NextContact,
			Type:this.company.Type,
			Latitude: this.company.Latitude,
			Longitude: this.company.Longitude,
			CreatedDate: this.company.CreatedDate,
			CreatedBy: this.company.CreatedBy,
			UpdatedDate: this.company.UpdatedDate,
			UpdatedBy: this.company.UpdatedBy,
		});

		this.getFileCompanyData();
		this.GetAllEngagementsByCompany();
		this.GetCompanyTraits();
		this.GetAllTraits();
		this.getUserSearchData();

	}

	ngOnDestroy(): void {
	}

	deleteCompany(): void {
		this.buttonPushed = true;
			this.companyService.deleteCompany(this.company.Id)
				.subscribe(() => this.onSaveComplete(true, null, false,false)
				, (error: any) => this.errorMessage = <any>error);
	}

	cancel(): void {
		this.router.navigate(['/companies']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Companies List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.CompanySearch = this.companyService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'CompanyName')
				this.CompanySearch.CompanyName = i.Value;

			if (i.Item == 'Address')
				this.CompanySearch.Address = i.Value;

			if (i.Item == 'City')
				this.CompanySearch.City = i.Value;

			if (i.Item == 'Province')
				this.CompanySearch.Province = i.Value;

			if (i.Item == 'Country')
				this.CompanySearch.Country = i.Value;

			if (i.Item == 'PostalCode')
				this.CompanySearch.PostalCode = i.Value;

			if (i.Item == 'Notes')
				this.CompanySearch.Notes = i.Value;

			if (i.Item == 'Website')
				this.CompanySearch.Website = i.Value;

			if (i.Item == 'Email')
				this.CompanySearch.Email = i.Value;

			if (i.Item == 'Phone')
				this.CompanySearch.Phone = i.Value;

			if (i.Item == 'orderByString')
				this.CompanySearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.CompanySearch.skip = i.Value;
			
			if(i.Item=='Status')
				this.CompanySearch.Status = i.Value;
			
			if(i.Item=='Priority'){
				this.CompanySearch.Priority = i.Value;
			}
			if(i.Item=='HideDateGT'){
				this.CompanySearch.HideDateGT = i.Value;
			}


		});
		this.CompanySearch.take = parseInt(this.pageSize);
		this.getSelectedRecordSet();
	}


	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
	getProvince() {
		this.lookuplistService.getLookupListByForm('Companies', 'Province').subscribe(response => {
			this.provinceList = response;
		}	
		, (error: any) => this.errorMessage = < any > error);
	}
	getCountry() {
		this.lookuplistService.getLookupListByForm('Companies', 'Country').subscribe(response => {
			this.countryList = response;
		}	
		, (error: any) => this.errorMessage = < any > error);
	}
	public openLookUpList(form,field)
	{
		   const url = this.router.serializeUrl(
		       this.router.createUrlTree([`/lookuplist-details`,0,form,field])
		);
		  window.open(url,'_blank');
	 }
	 
	onFileSelected(event) {
		if (event.target.files.length > 0) {
		 this.filesForm.patchValue({
		 fileUpload: event.target.files [0],
		 })
		 }
	}
	onSaveFile(){
		this.fileFormSubmitted = true;

		if (this.filesForm.invalid)
		{
			return;
		}

		const fileForm = this.filesForm.value;
		const formData = new FormData();
		formData.append('RecordId', this.company.Id.toString());
		formData.append('FileDescription', fileForm.fileDescription || 0);
		formData.append('IncludeInReport', !!fileForm.includeReport ? '1' : '0');
		formData.append('SortOrder', fileForm.sortOrder || 0);
			formData.append('FileName', fileForm.fileUpload);


		if (this.fileToEdit && this.fileToEdit.Id)
		{
			formData.append('Id', this.fileToEdit.Id.toString());
			formData.append('GeneratedFileName', this.fileToEdit.GeneratedFileName);

			this.companyService.updateFileCompany(formData).subscribe(file => {
				this.fileToEdit = null;
				this.clearForm();
				this.fileUp.nativeElement.value = '';
				this.getFileCompanyData();
			});
		}
		else
		{
			this.companyService.createFileCompany(formData).subscribe(file => {
				this.clearForm();
				this.fileUp.nativeElement.value = '';
				this.getFileCompanyData();
				this.openSnackBar('File changes have been successfully saved','Ok',5000);
			});
				
			}
		}
	editFileData(file){
		this.fileToEdit = file;
		this.filesForm.patchValue({
			fileUpload: this.fileToEdit.FileName,
			fileDescription: this.fileToEdit.FileDescription,
			sortOrder: this.fileToEdit.SortOrder,
			includeReport: this.fileToEdit.IncludeInReport === 1 ? true : false
		});
	}

	clearForm() {
		this.filesForm.reset();
			this.fileFormSubmitted = false;
	}

	open(row) {
		window.open(`${ this.applicationSettings.retrieveUrl()}${ row.FilePath}${ row.GeneratedFileName}`);
	}

	deleteFileData(id) {
		this.companyService.deleteFileCompany(id).subscribe(file => {this.getFileCompanyData();this.openSnackBar('File has been successfully deleted','Ok',5000);})
	}


	//This gets the file grid
	getFileCompanyData(){
		if (this.company)
		{
			if (this.company.Id > 0)
			{
				this.companyService.getAllFilesCompany(this.company.Id).subscribe(response => {
					this.dataSource = new MatTableDataSource(response);
					this.dataSource.paginator = this.firstPaginator;
				});
			}
		}
	}


	GetAllEngagementsByCompany(){
		if (this.company)
		{
			if (this.company.Id > 0)
			{
				this.companyService.GetAllEngagementsByCompany(this.company.Id).subscribe(response => {
					this.dataSourceEngagements = new MatTableDataSource(response);
					this.dataSourceEngagements.paginator = this.firstPaginator;
				});
			}
		}
	}


	SaveEngagement(){
		this.buttonPushed = true;
		this.progressStatus = true;
		this.companyEngagementForm.patchValue({
			CompanyId: this.company.Id
		});

		if (this.companyEngagementForm.invalid)
		{
		this.buttonPushed = false;
		this.progressStatus = false;
			return;
		}
		
		const obj = Object.assign({ }, this.companyEngagementForm.value);
		this.companyService.SaveEngagement(obj).subscribe(() => {
			this.companyEngagementForm.reset();

			this.companyEngagementForm.patchValue({
				Id: 0,
				CompanyId: this.company.Id,
				ContactDate: formatDate(new Date(), 'yyyy-MM-dd', 'en-US')
			});

			this.GetAllEngagementsByCompany();
			this.buttonPushed = false;
			this.progressStatus = false;
			this.openSnackBar('Engagement has been successfully saved','Ok',5000);
		});
	}

	UpdateEngagement(engagement){
		this.companyEngagementForm.patchValue({
			Id: engagement.Id,
			CompanyId: engagement.companyId,
			ContactName: engagement.ContactName,
			ContactDate: engagement.ContactDate,
			Type: engagement.Type,
			Notes: engagement.Notes
		});
	}

	showDialog(type:string,id: number): void {
		this.dialog
		  .open(ConfirmationDialogComponent, {
			data: `Are you sure you want to delete this ${type.toLowerCase()}?`
		  })
		  .afterClosed()
		  .subscribe((confirmed: Boolean) => {
			if (confirmed) {
				if(type=='Engagement'){
				this.DeleteEngagement(id);
				}
				if(type=='File'){
					this.deleteFileData(id);
				}
				if(type=='Company'){
					this.deleteCompany();
				}
				if(type=='Trait'){
					this.deleteCompanytrait(id);
				}
			} else {
			  return;
			}
		  });
	  }

	  deleteCompanytrait(id): void {
		this.buttonPushed = true;
		this.companytraitsService.deleteCompanytrait(id).subscribe(() => {this.GetCompanyTraits();this.openSnackBar('Company trait has been successfully deleted','Ok',5000);}
				, (error: any) => this.errorMessage = <any>error);
		this.buttonPushed = false;
		this.progressStatus = false;
	}

	DeleteEngagement(id) {
		this.buttonPushed = true;
		this.progressStatus = true;
		this.companyService.DeleteEngagement(id).subscribe(() => {this.GetAllEngagementsByCompany();this.openSnackBar('Engagement has been successfully deleted','Ok',5000);});
		this.buttonPushed = false;
		this.progressStatus = false;
	}

	getContactTypeList() {
		this.lookuplistService.getLookupListByForm('CompanyEngagements', 'Type').subscribe(response => {
			this.engagementTypesList = response;
		}	
		, (error: any) => this.errorMessage = < any > error);
	}
	getPriorityList() {
		this.lookuplistService.getLookupListByForm('Companies', 'Priority').subscribe(response => {
			this.priorityList = response;
		}	
		, (error: any) => this.errorMessage = < any > error);
	}
	getStatusList() {
		this.lookuplistService.getLookupListByForm('Companies', 'Status').subscribe(response => {
			this.statusList = response;
		}	
		, (error: any) => this.errorMessage = < any > error);
	}

	getCompanyTypeList() {
		this.lookuplistService.getLookupListByForm('Companies', 'Type').subscribe(response => {
			this.typesList = response;
		}	
		, (error: any) => this.errorMessage = < any > error);
	}

	changeTab(event){
		this.selected.setValue(event);
	}

	GetAllTraits(){
		this.progressLoadingTraitsStatus = true;
		this.lookuplistService.getLookupListByForm('Companies', 'Traits').subscribe(response => {
			this.traitsList = response;
			this.progressLoadingTraitsStatus = false;
		}, (error: any) => {this.errorMessage = < any > error;this.progressLoadingTraitsStatus = false;});
	 }

	 GetCompanyTraits(){
		this.progressCompanyTraitsStatus = true;
		this.companytraitsService.getByCompanyId(this.companyId).subscribe(response => {
			this.dataSourceTraits = new MatTableDataSource(response);
			this.progressCompanyTraitsStatus = false;
		}, (error: any) => {this.errorMessage = < any > error;this.progressCompanyTraitsStatus = false;});
	 }


	 
	SaveTrait(){
		this.buttonPushed = true;
		this.progressCompanyTraitsStatus = true;
		this.companyTraitsForm.patchValue({
			CompanyId: this.company.Id
		});

		if (this.companyTraitsForm.invalid)
		{
		this.buttonPushed = false;
		this.progressCompanyTraitsStatus = false;
			return;
		}
		
		const obj = Object.assign({ }, this.companyTraitsForm.value);
		this.companytraitsService.saveCompanytrait(obj).subscribe(() => {
			this.companyTraitsForm.reset();

			this.companyTraitsForm.patchValue({
				Id: 0,
				CompanyId: this.company.Id,
				Trait: ''
			});

			this.GetCompanyTraits();
			this.buttonPushed = false;
			this.progressCompanyTraitsStatus = false;
			this.openSnackBar('Company Trait has been successfully saved','Ok',5000);
		});
	}

}
