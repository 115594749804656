
<div class='panel animaPlaceholder'>
	<div class='panel-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='excelFile'>
					<button class='btn btn-primary btn-sm' (click)='hideFileLink()'>hide link</button>
					<span>excel File:<a href = "javascript:void(0)"(click) = "openExcelFile()" > {{ excelFile}}</a></span>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				Add New Customer
			</div>
		</div>
	</div>
		<div class='panel-body panel-add'>
		<div class='row'>
			<form class='form-horizontal' novalidate [formGroup]='customerForm'>
				<fieldset>
			<div class='col-md-12'>
				<div class='row'>
					<div class='col-md-3 form-group-item'>
						<label class='inline-label'> Title</label>
						<input class='form-control' type='text' formControlName='Title' />
					</div>
					<div class='col-md-3 form-group-item'>
						<label class='inline-label'> First Name</label>
						<input class='form-control' type='text' formControlName='FirstName' />
						<span *ngIf = "customerForm.controls['FirstName'].invalid  && customerForm.controls['FirstName'].errors.maxlength" class="text-danger">
								Only 250 characters are allowed.
						</span>
					</div>
					<div class='col-md-3 form-group-item'>
					<label class='inline-label'> Last Name</label>
					<input class='form-control' type='text' formControlName='LastName' />
					<span *ngIf = "customerForm.controls['LastName'].invalid  && customerForm.controls['LastName'].errors.maxlength" class="text-danger">
							Only 250 characters are allowed.
					</span>
					</div>
					<div class='col-md-3 form-group-item'>
					<label class='inline-label'> Email</label>
					<input class='form-control' type='text' formControlName='Email' />
							<span *ngIf = "customerForm.controls['Email'].invalid  && customerForm.controls['Email'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
					</div>

					<div class='col-md-3 form-group-item'>
						<label class='inline-label'> Phone Number</label>
						<input class='form-control' type='text' formControlName='PhoneNumber' />
						<span *ngIf = "customerForm.controls['PhoneNumber'].invalid  && customerForm.controls['PhoneNumber'].errors.maxlength" class="text-danger">
								Only 250 characters are allowed.
						</span>
					</div>

					<div class='col-lg-2 col-md-4 col-xs-12 form-group' style="padding-top: 20px;">
						<button class='btn btn-primary btn-sm center-block' style="width:100%;"  
						(click)="SaveCustomer()"
						 [disabled]='progressStatus'>Add</button>
					</div>

				</div>
			</div>
		</fieldset></form>
			</div>

	</div>
</div>

<div class='table-container'>
	<div class='row'>
	<div class='col-md-10'>
	  <mat-form-field>
		<mat-label>Search Customers....</mat-label>
		<input [(ngModel)]='SearchText' matInput placeholder="Ex. First Name, Last Name or Title" #input>
		@if (SearchText) {
			<button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
			  <mat-icon>close</mat-icon>
			</button>
		  }
	  </mat-form-field>
	</div>
	<div class='col-md-2'>
	  <button class='btn btn-primary btn-sm btn-search' (click)='searchClick()' [disabled]="progressStatus">Search</button>
	</div>
</div>

<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
	<ng-container matColumnDef='Id'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
		<td mat-cell *matCellDef='let e'>
			<button (click)="editCustomer(e.Id,inCompanyId)" mat-mini-fab color="default">
				<mat-icon>edit</mat-icon>
			</button>
		</td>
	</ng-container>
	<ng-container matColumnDef='CompanyId'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'> Company Id</th>
		<td mat-cell *matCellDef='let e' class='col2' >{{e.CompanyId}}</td>
	</ng-container>
	<ng-container matColumnDef='Title'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Title</th>
		<td mat-cell *matCellDef='let e' class='col2' >{{e.Title}}</td>
	</ng-container>
	<ng-container matColumnDef='FirstName'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> First Name</th>
		<td mat-cell *matCellDef='let e' class='col3' >{{e.FirstName}}</td>
	</ng-container>
	<ng-container matColumnDef='LastName'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'> Last Name</th>
		<td mat-cell *matCellDef='let e' class='col4' >{{e.LastName}}</td>
	</ng-container>
	<ng-container matColumnDef='Email'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col6'> Email</th>
		<td mat-cell *matCellDef='let e' class='col5' >{{e.Email}}</td>
	</ng-container>
	<ng-container matColumnDef='PhoneNumber'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col7'> Phone Number</th>
		<td mat-cell *matCellDef='let e' class='col6' >{{e.PhoneNumber}}</td>
	</ng-container>
	<ng-container matColumnDef="Delete" >
		<th mat-header-cell *matHeaderCellDef class= 'col6' > Delete </th>
		<td mat-cell *matCellDef ="let element" >
			<button (click)="showDialog('Customer',element.Id)" mat-mini-fab color="warn">
				<mat-icon>delete</mat-icon>
			</button>
		</td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
	<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
</div>
<mat-paginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>
<div>
	<app-loading *ngIf="progressStatus"></app-loading> 
</div>