import { AfterViewInit, Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/debounceTime';
// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs/Rx';
import { GenericValidator } from '../../../shared/generic-validator';
import { UserCreateModel } from '../user-model/user-create';
import { UserService } from '../user-service/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
	templateUrl: './user-create.component.html'
})
export class UserCreateComponent implements OnInit, AfterViewInit {
	@ViewChildren(FormControlName, { read: ElementRef })
	formInputElements: ElementRef[];

	createUserForm: FormGroup;
	pageTitle = 'Create User';
	errorMessage = '';
	user: UserCreateModel;
	progressStatus = true;

	displayMessage: { [key: string]: string } = {};
	private validationMessages: { [key: string]: { [key: string]: string } };
	private genericValidator: GenericValidator;

	constructor(
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private userService: UserService,
		public snackBar: MatSnackBar	
	) {
		this.validationMessages = {
			Email: {
				required: 'Email is required.'
			},
			Password: {
				required: 'Password is required.'
			},
			ConfirmPassword: {
				required: 'Confirm Password is required.'
			},
			FirstName: {
				required: 'First Name is required.'
			},
			LastName: {
				required: 'Last Name is required.'
			}
		};
	}

	ngOnInit(): void {

		this.createUserForm = this.fb.group({
			Username: ['', [Validators.required]],
			FirstName: ['', [Validators.required]],
			LastName: ['', [Validators.required]],
			Email: ['', [Validators.required]],
			Password: ['', [Validators.required]],
			ConfirmPassword: ['', [Validators.required]],
			Initials: '',
			Title: '',
			Address: '',
			City: '',
			Region: '',
			PostalCode: '',
			HomePhone: '',
			CellPhone: '',
			BirthDate: '',
			HireDate: '',
			EmergencyName: '',
			EmergencyPhone: '',
			Notes: '',
			Active: true,
			Image: '',
		});
		this.genericValidator = new GenericValidator(this.validationMessages);
		this.progressStatus = false;
	}

	ngAfterViewInit(): void {
		// Watch for the blur event from any input element on the form.
		const controlBlurs: Observable<any>[] = this.formInputElements.map(
			(formControl: ElementRef) =>
				Observable.fromEvent(formControl.nativeElement, 'blur')
		);

		// Merge the blur event observable with the valueChanges observable
		Observable.merge(this.createUserForm.valueChanges, ...controlBlurs)
			.debounceTime(800)
			.subscribe(value => {
				this.displayMessage = this.genericValidator.processMessages(
					this.createUserForm
				);
			});
	}
	
	cancel(): void {
		this.router.navigate(['/aspnetusers']);
	}
	saveUser(): void {
		this.progressStatus = true;
		if (this.createUserForm.dirty && this.createUserForm.valid) {
			const userCreated = Object.assign(
				{},
				this.user,
				this.createUserForm.value
			);
			this.userService
				.saveUser(userCreated)
				.subscribe(
					() => this.onSaveComplete(),
					(error: any) => {this.errorMessage = error;this.progressStatus = false;}
				);
		} else if (!this.createUserForm.dirty) {
			this.onSaveComplete();
		}
	}

	onSaveComplete(): void {
		this.openSnackBar('Your changes have been successfully saved','Ok',5000);
		this.router.navigate(['/aspnetusers']);
	}

	openSnackBar(message: string, action: string, durationMiliiseconds:number) {
		this.snackBar.open(message, action, {
		  duration: durationMiliiseconds,
		});
	}
}
