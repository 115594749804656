import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '../../admin/auth/auth.service';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
	@ViewChild('navBarToggler', {static: true}) navbarToggler: ElementRef;

	title = '';
	showNav = false;
	mobileDevice: boolean;

	constructor(private router: Router, public auth: AuthService) {
	}
	ngOnInit() {
		this.router.events.subscribe((event) => {
		  // Close the navbar menu on route change
		  // You can adjust the condition based on your requirements
		  if (event instanceof NavigationEnd) {
			document.getElementById('navbarNav').classList.remove('show');
		  }
		});
	  }

	toggleNav(): void {
		this.showNav = !this.showNav;
	}

	navigate(location) {
		this.router.navigate([`/${location}/`]);
	}

	/**
	 * Do not display menu if on mobile and not on welcome screen
	 */
	isOnMobileAndHomeScreen(): boolean {
		const welcomeScreen = this.router.url.indexOf('welcome') !== -1;

		if (!this.mobileDevice) {
			return true;
		} else {
			if (welcomeScreen) {
				return true;
			}

			return false;
		}
	}
}
