<div class='panel panel-primary animaPlaceholder'>
	<div class='panel-heading'>
		<H1>Dashboard</H1>
	</div>
	<div class= 'panel-body'>
<div class='row'>
    <div class='col-md-6'> 
   
    <div class='row'>
    <div class='col-md-3'> 
        <h1><strong>Daily Journal</strong></h1>
    </div>
    <div class='col-md-2'> 
        <button class='btn btn-move-date' color="primary" mat-fab extended (click)='prevDay()'>
            <mat-icon>arrow_back</mat-icon>
          </button>
    </div>
    <div class='col-md-3'> 
        <input class='form-control date-selector' type='date' [(ngModel)]='WorkDate' (ngModelChange)="dateChanged()" />
    </div>

    <div class='col-md-2'> 
        <button class='btn btn-move-date' color="primary" mat-fab extended (click)='nextDay()'>
            <mat-icon>arrow_forward</mat-icon>
          </button>
    </div>

    <div class='col-md-2'> 
        <button  [disabled]='buttonPushed||!journalForm.valid || progressStatus' matTooltip="Save Journal info" class='btn btn-save-journal' color="primary" mat-fab extended (click)='saveJournal()'>
            <mat-icon>save</mat-icon>
           
          </button>
    </div>

    <div class='col-md-12'> 
        <div class="card">
    <form class='form-horizontal' novalidate [formGroup]='journalForm'>
        <fieldset>
        <div class='panel-body'>
                <div class='form-group'>
                    <label class='col-md-2 control-label'> Day Notes</label>
                    <div class='col-md-12'>
                        <ckeditor formControlName="DayNotes" [editor]="Editor"
                            [config]="{ ckfinder: { uploadUrl: this.settings.retrieveUrl() + 'api/Journals/uploadimage' } }" data=""></ckeditor>
                    </div>
                </div>
        </div>
        </fieldset>
    </form>
</div>
</div>
</div>
</div>

<div class='col-md-6'> 
    <h1><strong>Daily Log</strong></h1>
    <div class="col-md-12" *ngIf="currentDate">
    <app-dailylog-list [DateParam]="currentDate"></app-dailylog-list>
    </div>
    <br>
    <h1><strong>Worktasks</strong></h1>
    <div class="col-md-12" *ngIf="currentDate">
    <app-worktask-list-small ></app-worktask-list-small>
    </div>

   
</div>

</div>

</div>
</div>  

<div>
    <mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>