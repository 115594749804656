<div *ngIf="resetdisplay" class="log-in-area">
	<div class="login-form-background">
	<div class="login-form ml-auto mr-auto">
		<div class="form-title">
			{{pageTitle}}
		</div>
		<form class="form-horizontal" novalidate (ngSubmit)="resetPassword()" [formGroup]="resetPasswordForm">
		<div class="form-group">
		<br>
		<br>
		<label class="main-label d-block lh-1 text-black"> User Email Id </label>
		<mat-form-field appearance="fill">
			<mat-label>User Email Id</mat-label>
			<input readonly type="text" required matInput [value]="emailId">
		  </mat-form-field>  
		</div>
	
			<div class="form-group">
			<label class="main-label d-block lh-1 text-black"> Password </label>
			<mat-form-field appearance="fill">
				<mat-label>Enter your password</mat-label>
				<input [type]="hidePwd ? 'password' : 'text'" required matInput formControlName='NewPassword'>
				<mat-icon matSuffix (click)="hidePwd = !hidePwd">{{hidePwd ? 'visibility_off' : 'visibility'}}</mat-icon>
				<mat-error *ngIf="resetPasswordForm.controls['NewPassword'].invalid && resetPasswordForm.controls['NewPassword'].errors.required">Password is <strong>required</strong></mat-error>
			  </mat-form-field>  
			</div>

			<div class="form-group">
				<label class="main-label d-block lh-1 text-black"> Confirm Password </label>
				<mat-form-field appearance="fill">
					<mat-label>Enter your password again</mat-label>
					<input [type]="hidePwdConfirm ? 'password' : 'text'" required matInput formControlName='ConfirmPassword'>
					<mat-icon matSuffix (click)="hidePwdConfirm = !hidePwdConfirm">{{hidePwdConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
					<mat-error *ngIf="resetPasswordForm.controls['ConfirmPassword'].invalid && resetPasswordForm.controls['ConfirmPassword'].errors.required">Password is <strong>required</strong></mat-error>  
				</mat-form-field>  
				</div>
			<button [disabled]='!resetPasswordForm.valid' type="submit" mat-flat-button>Save</button>
			<div class='has-error' *ngIf='errorMessage'>
				{{ errorMessage }}
			</div>
		</form>
	</div>
	</div>
	</div>
	<app-loading *ngIf="progressStatus"></app-loading> 
	
	