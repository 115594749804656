
<div class='panel panel-primary animaPlaceholder' *ngIf = 'material'>
	<div class='panel-heading'>
		<span *ngIf = 'material' >{{ page }} :{{ material.Id }}</span>
		<span class= 'pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class= 'panel-body'>
		<div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
			<mat-tab-group>
			<mat-tab style="color: #101bb3;" label = "Material">
		<form class='form-horizontal' novalidate [formGroup]='materialForm'>
			<fieldset>
			<div class='panel-body'>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Name</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='Name' />
							<span *ngIf = "materialForm.controls['Name'].invalid  && materialForm.controls['Name'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Description</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='Description' />
							<span *ngIf = "materialForm.controls['Description'].invalid  && materialForm.controls['Description'].errors.maxlength" class="text-danger">
									Only 4000 characters are allowed.
							</span>
						</div>
					</div>
			</div>
			</fieldset>
		</form>
			</mat-tab>
			<mat-tab label="Files">
			<div *ngIf = "materialId" >
				<div class='panel-body col-md-6'>
					<form #formElement id="form1" method="post">
					 <div id="dropArea">
						 <div class="form-group" style="padding-top: 11px;">
								 <div class="e-float-input upload-area">
									 <input type="text" id="upload" readonly name="upload" data-required-message="* Select any file" required="" data-msg-containerid="uploadError">
									 <button id="browse" class="e-control e-btn e-info" (click)='browseClick()'>Browse..</button>                        
									 <span class="e-float-line"></span>
									 <label class="e-float-text e-label-top" for="upload">Choose a file</label>
								 </div>   
								 <div id="uploadError"></div>                     
								 <ejs-uploader #defaultupload id='fileupload' allowedExtensions = "image/*" [autoUpload]=false  (selected)='onFileSelected($event)'></ejs-uploader>                                        
						 </div>
						 </div>    
					 <div class="form-group " style="padding-top: 11px;">
							 <div class="e-float-input">
								 <input type="text" id="fileDescription" name="fileDescription" data-required-message="* Enter file description" required="" data-msg-containerid="nameError">
								 <span class="e-float-line"></span>
								 <label class="e-float-text e-label-top" for="fileDescription">File Description</label>
							 </div>   
							 <div id="nameError"></div>                     
						 </div>
												 
						 <div class="form-group" style="padding-top: 11px;">
								 <div class="e-float-input">
									 <input type="number" id="sortOrder" name="sortOrder" data-required-message="* Enter sort order" required="" data-msg-containerid="noError">
									 <span class="e-float-line"></span>
									 <label class="e-float-text e-label-top" for="sortOrder">Sort Order</label>
								 </div>   
								 <div id="noError"></div>                     
						 </div>
										   
					 </form> 
					 <div class="submitBtn">
						 <button class="submit-btn e-btn" id="submit-btn" (click)= "Submit()">Submit</button>                        
					 </div>    
				 </div>
			 <div>
			  <table mat-table [dataSource] = "dataSource" class= 'mat-elevation-z8 table-wdt' matSort >
			   <ng-container matColumnDef="Edit">
			     <th mat-header-cell *matHeaderCellDef class='col1' > EDIT </th>
			     <td mat-cell *matCellDef ="let element"> <button class= 'btn btn-default'
			     style= 'min-width: 50px;' (click) ="editFileData(element)" > <span style="color: rgb(4, 50, 255);"
			     class="glyphicon glyphicon-edit"> EDIT </span ></button></td>
			  </ng-container>
			  <ng-container matColumnDef="FileName" >
			  <th mat-header-cell *matHeaderCellDef class='col2'> File Name </th>
			  <td mat-cell *matCellDef = "let element" >
			    <a href ="javascript:void(0)"
			     (click) = "open(element)" > {{element.FileName}}
			    </a>
			  </td>
			 </ng-container>
			  <ng-container matColumnDef="FileDescription" >
			  <th mat-header-cell *matHeaderCellDef class= 'col4' > Description </th>
			  <td mat-cell *matCellDef = "let element" > {{ element.FileDescription}} </td>
			  </ng-container>
			  <ng-container matColumnDef ="SortOrder">
			  <th mat-header-cell *matHeaderCellDef class= 'col5' > Sort Order </th>
			  <td mat-cell *matCellDef="let element" > {{ element.SortOrder}} </td>
			  </ng-container>
			   <ng-container matColumnDef="IncludeInReport">
			   <th mat-header-cell *matHeaderCellDef class= 'col6' > Include In Report</th>
			   <td mat-cell *matCellDef ="let element" > {{element.IncludeInReport === 1 ? 'True' :
			   'False'}}
			   </td>
			   </ng-container>
			  <ng-container matColumnDef="Delete" >
			  <th mat-header-cell *matHeaderCellDef class= 'col7' > Delete </th>
			  <td mat-cell *matCellDef ="let element" >
			  <button class='btn btn-default btn-xs'
			  style ='min-width: 50px;background: none;border: none;'
			  (click) = "deleteFileData(element.Id)" >
			  <span class= "glyphicon glyphicon-trash text-success text-dlt" ></span>
			  </button>
			  </td>
			  </ng-container>
			 <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
			 <tr mat-row *matRowDef ="let row; columns: displayedColumns;" ></tr>
			 </table>
			 <mat-paginator #firstPaginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>
			 </div>
			 </div>
			 </mat-tab>
			 </mat-tab-group>

			<div class='row' style='padding: 15px;'>
				<div class= 'col-md-12'>
					<span>
								<button class='btn btn-primary'
								[disabled]='buttonPushed'
								(click)='saveMaterial(false, false,false)'
									style='width: 110px; margin-right:10px'
									[disabled]='!materialForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
								</button>
								<button class='btn btn-primary'
									[disabled]='buttonPushed'
									(click)='saveMaterial(true, false,false)'
									style='width: 150px; margin-right:10px'
									[disabled]='!materialForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
								</button>
								<button *ngIf='showNextRecordButton && material.Id' class='btn btn-primary'
									[disabled]='buttonPushed'
									(click)='saveMaterial(false, true,false)'
									style='width: 190px; margin-right:10px'
									[disabled]='!materialForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
								</button>
								<button  class='btn btn-primary'  *ngIf="!material.Id"
								[disabled]='buttonPushed'
								(click)='saveMaterial(false, false,true)'
								style='width: 200px; margin-right:10px'
								[disabled]='!materialForm.valid'>
								<span class='glyphicon glyphicon-floppy-save'></span> Save & Add New Record &nbsp;
							</button>
							</span>

						<span  class='pull-right'>
							<button class='btn btn-primary'
								[disabled]='buttonPushed'
								(click)='cancel()'
								style='width: 150px; margin-right:10px'
								[disabled]='!materialForm.valid || progressStatus'>
								Cancel &nbsp;
							</button>
							<button class='btn btn-primary'
								[disabled]='buttonPushed'
								(click)='deleteMaterial()'
								style='width: 150px; margin-right:10px'
								[disabled]='!materialForm.valid || progressStatus'>
								<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
							</button>
						</span>
					</div>
				</div>

				<div class='row'>
					<div class='col-md-12'>
						<span> Updated By: <label *ngIf='material'>{{material.UpdatedBy}}</label></span>
						<span> Time: <label *ngIf='material'>{{material.UpdatedDate}}</label></span>
					</div>
				</div>
	</div>
</div>
<div>
	<app-loading *ngIf="progressStatus"></app-loading>
</div>