<div class="container">
	<div class="row">
		<section class="about">
			<div class="col-md-12" align="center">
				<h1>About Us</h1>
			</div>
			<div class="col-md-12" align="center">
				<I>My name is Craig Duguid and my passion is to</I>
			</div>
			<div class="col-md-12" align="center">
				<I>solve business problems with innovation and integration.</I>
			</div>
			<div class="col-md-12">
				<br />
				<br />
				<br />
			</div>
		</section>
	</div>
	<div class="row">
		<div class="col-md-1" align="center">
		</div>
		<div class="col-md-5" align="left">
			<img class="content-craig-pic" src="../assets/craig_fl.jpg" alt="Craig Duguid" />
			<p>In September 2013, I embarked on a transformative journey by stepping away 
				from my 20-year career entrenched in technology within major public and 
				private corporations. This marked a deliberate shift as I redirected my 
				energies towards embracing and cultivating my passion for 
				innovation and integration.</p>
			<p>For over a decade, I've had the pleasure of collaborating with exceptional 
				clients on a diverse range of projects, with a primary focus on 
				manufacturing. I'm keen to hear your story. Feel free to reach out if you 
				believe my company can contribute to your success.
			</p>
			<p>
				I thoroughly enjoy "<a target="_blank" href="https://en.wikipedia.org/wiki/The_Goal_(novel)">The Goal</a>" 
				by Eliyahu M. Goldratt, particularly the concept of the "process of ongoing improvements" and the 
				insightful exploration of the "
				<a target="_blank" href="https://en.wikipedia.org/wiki/Theory_of_constraints">Theory of Constraints</a>." My passion lies in achieving a return on 
				investment through innovative solutions.

				I love the book,
				<a target="_blank" href="https://en.wikipedia.org/wiki/The_Goal_(novel)">The Goal</a>
				by Eliyahu M. Goldratt, and the concept of “process of ongoing improvements” and the insite into the
				<a target="_blank" href="https://en.wikipedia.org/wiki/Theory_of_constraints">Theory of Constraints</a>. I am passionate
				about getting a return on investment through innovative solution.
			</p>
			<p>
				Another captivating book that has profoundly inspired me is "Loonshots: How to Nurture the Crazy 
				Ideas That Win Wars, Cure Diseases, and Transform Industries" by Safi Bahcall. 
				It's a compelling read that has the potential to shift your paradigm on approaching 
				the future through innovation within your company.
			</p>
		</div>

		<div class="col-md-5" align="left">
			<img class="content-edmonton-pic" src="../assets/Edmonton.jpg" alt="Edmonton" />
		</div>
		<div class="col-md-1" align="center">
		</div>
	</div>
</div>