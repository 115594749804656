import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { ICustomercontact} from './Customercontact-model/Customercontact';
import { ICustomercontacts } from './Customercontact-model/Customercontacts';
import { ICustomercontactSearch } from './Customercontact-model/CustomercontactSearch';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';

@Injectable()
export class CustomercontactService {
	private baseUrl;
	public pageTitle = 'Customer Contacts List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Customercontacts';
	}

	getCustomercontact(id: number):  Observable<ICustomercontact> {
		if (id === 0) {
			return of(this.initializeCustomercontact());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<ICustomercontact>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveCustomercontact(customercontact: ICustomercontact): Observable<any> {
		if (customercontact.Id === 0) {
			return this.createCustomercontact(customercontact);
		}
		return this.updateCustomercontact(customercontact);
	}

	private createCustomercontact(customercontact: ICustomercontact): Observable<any> {
		customercontact.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/customercontactcreate', customercontact)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateCustomercontact(customercontact: ICustomercontact): Observable<ICustomercontact> {
		return this.authHttp.put<ICustomercontact>(this.baseUrl, customercontact)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteCustomercontact(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeCustomercontact(): ICustomercontact {
		return {
			Id: 0,
			CustomerId: 0,
			Type: '',
			ContactDate: '',
			FutureReminderDate: '',
			Notes: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as ICustomercontact;
	}

	public getRecordSet(search: ICustomercontactSearch): Observable<ICustomercontacts> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('CustomerId', search.CustomerId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Type', search.Type, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('ContactDate', search.ContactDate, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('FutureReminderDate', search.FutureReminderDate, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Notes', search.Notes, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('CustomerId', search.CustomerId ? search.CustomerId : '')
			.set('Type', search.Type ? search.Type : '')
			.set('ContactDate', search.ContactDate ? search.ContactDate : '')
			.set('FutureReminderDate', search.FutureReminderDate ? search.FutureReminderDate : '')
			.set('Notes', search.Notes ? search.Notes : '')
		};

		return this.authHttp.get<ICustomercontacts>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<ICustomercontacts>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : ICustomercontactSearch { 
		let search: ICustomercontactSearch = {
			Id: 1,
			CustomerId: '',
			Type: '',
			ContactDate: '',
			FutureReminderDate: '',
			Notes: '',
			skip: 0,
			orderByString: '',
			take: 10,
			CustomerName:''
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.error.ErrorDescription}`;
		}
		console.error(message);
		return throwError(message);
	}
	updateFileCustomercontact(file: any){
		const url=`${this.baseUrl}/fileupdate`;
		return this.authHttp.put<ICustomercontacts>(url, file)
			.pipe(retry(1),
				catchError(this.processError))
	}
	deleteFileCustomercontact(id: number){
		const url=`${this.baseUrl}/filedelete?id=${id}`;
		return this.authHttp.delete<any>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
	getAllFilesCustomercontact(Id: number){
		const url=`${this.baseUrl}/files?id=${Id}`;
		return this.authHttp.get<ICustomercontacts[]>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
	createFileCustomercontact(file: any){
		const url=`${this.baseUrl}/filecreate`;
		return this.authHttp.post<any>(url, file)
			.pipe(retry(1),
			catchError(this.processError))
	}
	public toExcel(search: ICustomercontactSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('CustomerId', search.CustomerId ? search.CustomerId : '')
			.set('Type', search.Type ? search.Type : '')
			.set('ContactDate', search.ContactDate ? search.ContactDate : '')
			.set('FutureReminderDate', search.FutureReminderDate ? search.FutureReminderDate : '')
			.set('Notes', search.Notes ? search.Notes : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
}