
<body>
	<header>
	</header>
	<div class="row">
		<section class="about">
			<div class="col-md-12" align="center">
				<h1>Edmonton Integration and Robotics</h1>
			</div>
			<div class="col-md-12" align="center">
				<I>Material Handling * Machine Tending * Pick & Place * PLC * Cloud * Direct Printing</I>
			</div>
			<div class="col-md-12">
				<br />
				<br />
				<br />
			</div>
		</section>
	</div>
	<section class="about">
		<div class="container">
			<div class="row">
					
					<div class="col-md-5 col-12 text-center">
						<h1 class="text-center">Low Impact Machine Tending Sample</h1>
						<p>Loading, unloading and starting my HAAS Minimill.</p>
					</div>
					<div class="col-md-7 ">
						<youtube-player
						videoId="6ki0puhMGfs"
						[height]="300"
						[width]="400"
					  ></youtube-player>
					</div>
			</div>
		</div>
	</section>
	<section class="about">
		<div class="container">
			<div class="row">
					<div class="col-md-7">
						<img src="../assets/service_1.jpg" alt="FANUC Robot Arm" />
					</div>
					<div class="col-md-5 col-12 text-center">
						<h1 class="text-center">Machine Tending Cell</h1>
						<p>With a 20 minute part run time this cell often gets over 100 hrs per week of 
							productivity.  Runs lights out after the day shift leaves.</p>
						<p>The cell has a 4 draw part management system, part cleaning, cloud interface and reporting, and 
							status viewable via a cell phone or computer.</p>
					</div>
			</div>
		</div>
	</section>
	<section class="about">
		<div class="container">
			<div class="row">
					
					<div class="col-md-5 col-12 text-center">
						<h1 class="text-center">Dual Palletizing Cell</h1>
						<p>Dual line palletizer.</p>
						<p>Shared pallet cradle.  Vaccum grippers picking and placing up to 75 lb boxes.</p>
					</div>
					<div class="col-md-7 ">
						<img src="../assets/service_2.jpg" alt="FANUC Robot Arm" />
					</div>
			</div>
		</div>
	</section>
	<section class="about">
		<div class="container">
			<div class="row">
				<div class="col-md-7 ">
					<img src="../assets/service_3.jpg" alt="FANUC Robot Arm" />
				</div>
					<div class="col-md-5 col-12 text-center">
						<h1 class="text-center">Feed Palletizer</h1>
						<p>This is a low-cost self-contained feed palletizer. Cabinet, 
							pallet pick location, leveling & pick conveyors all included on 
							a single shippable frame. Can be installed in a partial day.
						</p>
					</div>
					
			</div>
		</div>
	</section>
	
	<header-version></header-version> 
</body>