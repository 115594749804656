import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IBranchlabelprinter} from './Branchlabelprinter-model/Branchlabelprinter';
import { IBranchlabelprinters } from './Branchlabelprinter-model/Branchlabelprinters';
import { IBranchlabelprinterSearch } from './Branchlabelprinter-model/BranchlabelprinterSearch';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';
import { IBranchlabelprintersIpList } from './branchlabelprinter-model/branchlabelprinterslist';

@Injectable()
export class BranchlabelprinterService {
	private baseUrl;
	public pageTitle = 'BranchLabelPrinters List';

	///private readonly _apiKey = '435345345345345345';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Branchlabelprinters';
	}

	getBranchlabelprinter(id: number):  Observable<IBranchlabelprinter> {
		if (id === 0) {
			return of(this.initializeBranchlabelprinter());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IBranchlabelprinter>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveBranchlabelprinter(branchlabelprinter: IBranchlabelprinter): Observable<any> {
		console.log(branchlabelprinter);
		if (branchlabelprinter.Id === 0) {
			return this.createBranchlabelprinter(branchlabelprinter);
		}
		return this.updateBranchlabelprinter(branchlabelprinter);
	}

	private createBranchlabelprinter(branchlabelprinter: IBranchlabelprinter): Observable<any> {
		branchlabelprinter.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/branchlabelprintercreate', branchlabelprinter)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateBranchlabelprinter(branchlabelprinter: IBranchlabelprinter): Observable<IBranchlabelprinter> {
		return this.authHttp.put<IBranchlabelprinter>(this.baseUrl+'/branchlabelprinterupdate', branchlabelprinter)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteBranchlabelprinter(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeBranchlabelprinter(): IBranchlabelprinter {
		return {
			Id: 0,
			Name: '',
			PrinterType: '',
			IP: '',
			BranchId: 0,
			ZPL: '',
			PLCChannel:0,
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IBranchlabelprinter;
	}

	public getRecordSet(search: IBranchlabelprinterSearch): Observable<IBranchlabelprinters> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('Name', search.Name, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('PrinterType', search.PrinterType, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('IP', search.IP, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('BranchId', search.BranchId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('ZPL', search.ZPL, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('PLCChannel', search.PLCChannel, this.pageTitle));	
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('Name', search.Name ? search.Name : '')
			.set('PrinterType', search.PrinterType ? search.PrinterType : '')
			.set('IP', search.IP ? search.IP : '')
			.set('BranchId', search.BranchId ? search.BranchId : '')
			.set('ZPL', search.ZPL ? search.ZPL : '')
			.set('PLCChannel', search.PLCChannel ? search.PLCChannel : '')
		};

		return this.authHttp.get<IBranchlabelprinters>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IBranchlabelprinters>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IBranchlabelprinterSearch { 
		let search: IBranchlabelprinterSearch = {
			Id: 1,
			Name: '',
			PrinterType: '',
			IP: '',
			BranchId: '',
			ZPL:'',
			PLCChannel:'',
			Port: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}
	
	public toExcel(search: IBranchlabelprinterSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('Name', search.Name ? search.Name : '')
			.set('PrinterType', search.PrinterType ? search.PrinterType : '')
			.set('IP', search.IP ? search.IP : '')
			.set('BranchId', search.BranchId ? search.BranchId : '')
			.set('ZPL', search.ZPL ? search.ZPL : '')
			.set('PLCChannel', search.PLCChannel ? search.PLCChannel : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}

	getBrnchLblPrntrData(branchid,type: string):  Observable<any[]> {
	
		const url = `${this.baseUrl}/getbrnchlblprntrdata?branchid=${branchid}&printertype=${type}`;
		return this.authHttp.get<any[]>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
	addFile(file){
		console.log(file);
		const url = `${this.baseUrl}/uploadimage`;
		return this.authHttp.post<any>(url,file)
			.pipe(retry(1),
			catchError(this.processError))
	}
	public getLabelPrinterIpList(branchid,type:string,apikey:string): Observable<IBranchlabelprintersIpList> {
		const url = `${this.baseUrl}/getPrinterList?BranchId=${branchid}&PrinterType=${type}&ApiKey=${apikey}`;
		return this.authHttp.get<IBranchlabelprintersIpList>(url)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IBranchlabelprintersIpList>{
				data: response.data,
				status_code: response.status_code,
				message: response.message
			})));
	}
}