import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatPaginator as MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import {merge} from 'rxjs';
import { Router } from '@angular/router';

import { ApplicationSettings } from '../../shared/application-settings';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';

import { CompanyService } from '../company-service';
import { ICompanySearch } from '../company-model/companysearch';
import { ICompanys } from '../company-model/companys';
import { FormControl } from '@angular/forms';
import { LookuplistService } from '../../lookuplists/lookuplist-service';
import { ICompanyEngagement } from '../company-model/companyEngagement';
import { MatDialog } from '@angular/material/dialog';
import { InformationDialogComponent } from '../../shared/dialogs/information-dialog.component';
import { ILookuplist } from '../../lookuplists/Lookuplist-model/Lookuplist';

@Component({
	templateUrl: './company-list.component.html',
	styleUrls: ['./company-list.component.css']
})

export class CompanyListComponent implements OnInit, AfterViewInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	displayedColumns: string[] = ['Id', 'CompanyName', 'Status','Priority','LastContactDate','NextContact','Address', 'City', 'Phone', 'Notes'];

	Priorities = new FormControl([]);

	public UserSearches: IUsersearch[];
	public pageSize = '10';
	public skip = 0;
	public recordTotal: number = 0;
	pageSizeLst = new FormControl();

	public showHideSearch: boolean = false;
	public SearchText: string = 'Show Filters';

	public CompanyName = '';
	public Address = '';
	public City = '';
	public Province = '';
	public Country = '';
	public PostalCode = '';
	public Notes = '';
	public Website = '';
	public Email = '';
	public Phone = '';
	public Status = '';
	public Priority = '';
	public HideDateGT = '';

	public Type = '';

	public pageTitle = 'Companies List';
	public errorMessage = '';
	public dataSource: any;
	public excelFile;
	public progressStatusCompanyList=true;
	public progressStatusLst=true;
	public progressStatusPriorityLst=true;
	public progressTypeLst=true;

	public statusList:any;
	public priorityList:ILookuplist[];
	public typeList:ILookuplist[];

	constructor(private userSearchService: UsersearchService
		, public applicationSettings: ApplicationSettings
		, private router: Router
		, private lookuplistService:LookuplistService
		, private _appService: CompanyService
		, public dialog: MatDialog) {
	}

	getPriorityList() {
		this.lookuplistService.getLookupListByForm('Companies', 'Priority').subscribe(response => {
			this.priorityList = response;
			this.progressStatusPriorityLst = false;
		}	
		, (error: any) => {this.errorMessage = < any > error;this.progressStatusPriorityLst = false;});
	}
	getStatusList() {
		this.lookuplistService.getLookupListByForm('Companies', 'Status').subscribe(response => {
			this.statusList = response;
			this.progressStatusLst = false;
		}	
		, (error: any) => {this.errorMessage = < any > error;this.progressStatusLst = false;});
	}
	getTypeList() {
		this.lookuplistService.getLookupListByForm('Companies', 'Type').subscribe(response => {
			this.typeList = response;
			this.progressTypeLst = false;
		}	
		, (error: any) => {this.errorMessage = < any > error;this.progressTypeLst = false;});
	}

	ngOnInit(): void {
		this.getPriorityList();
		this.getStatusList();
		this.getTypeList();

		this.skip = 0;
		let x  = +localStorage.getItem('rows');
		if(x>0) { this.pageSize = x.toString(); }
		else { this.pageSize = '10';}
		this.pageSizeLst.setValue(this.pageSize);
		if (localStorage.getItem('showHideSearch') === 'true')
		{
			   this.showHideSearch = true;
			   this.SearchText = 'Hide Filters';
		}
		else
		{
			   this.showHideSearch = false;
			   this.SearchText = 'Show Filters';
		 }
	}

	ngAfterViewInit(): void {
		this.userSearchService.getUserSearchesByForm(this.pageTitle)
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
		}
		, (error: any) => {this.errorMessage = < any > error;this.progressStatusCompanyList = false;});
	}

	setUserFilters(): void {
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.paginator.pageIndex = 0;

		this.UserSearches.forEach(i => {
			if(i.Item=='CompanyName')
				this.CompanyName = i.Value;

			if(i.Item=='Address')
				this.Address = i.Value;

			if(i.Item=='City')
				this.City = i.Value;

			if(i.Item=='Province')
				this.Province = i.Value;

			if(i.Item=='Country')
				this.Country = i.Value;

			if(i.Item=='PostalCode')
				this.PostalCode = i.Value;

			if(i.Item=='Notes')
				this.Notes = i.Value;

			if(i.Item=='Website')
				this.Website = i.Value;

			if(i.Item=='Email')
				this.Email = i.Value;

			if(i.Item=='Phone')
				this.Phone = i.Value;
			
			if(i.Item=='Status')
				this.Status = i.Value;
			
			if(i.Item=='Priority'){
				this.Priority = i.Value;
				this.Priorities.setValue(i.Value.split(','))
			}
			if(i.Item=='HideDateGT'){
				this.HideDateGT = i.Value;
			}
		

			if(i.Item=='orderByString') {
				if (this.applicationSettings.getOrderByDirection(i.Value)==='asc')
					this.sort.direction = 'asc';
				else
					this.sort.direction = 'desc';

				this.sort.active = this.applicationSettings.getOrderByName(i.Value);
			}

			if (i.Item == 'Skip')
				this.skip = i.Value;
		});

		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
		merge(this.sort.sortChange, this.paginator.page)
			.subscribe(()=> this.getData());
		this.getData();
	}

	pageSizeValueChanged(rows: string): void {
		this.applicationSettings.setPageSize(rows);
		this.pageSize=rows;
		this.pageSizeLst.setValue(this.pageSize);
		console.log(this.recordTotal);
		console.log(this.pageSize);
		this.getData();
	}

	getData(): any {
	 this.progressStatusCompanyList=true; 
	 this.dataSource = null; 
	 this.recordTotal = 0; 
		let search: ICompanySearch = this._appService.getFreshSearch();

		search.CompanyName = this.CompanyName;
		search.Address = this.Address;
		search.City = this.City;
		search.Province = this.Province;
		search.Country = this.Country;
		search.PostalCode = this.PostalCode;
		search.Notes = this.Notes;
		search.Website = this.Website;
		search.Email = this.Email;
		search.Phone = this.Phone;
		search.Status = this.Status;
		search.HideDateGT = this.HideDateGT;
	
		search.Priority = this.Priorities.value.toString()==''?'(empty)':this.Priorities.value.toString();
		search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
		search.take = parseInt(this.pageSize);
		search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

		this._appService.getRecordSet(search).subscribe((obj: ICompanys) => this.onSelectedRecordSetReceived(obj),
		(error: any) => {
			this.errorMessage = <any> error;
			this.progressStatusCompanyList = false;
		});
	}

	private onSelectedRecordSetReceived(obj: ICompanys){
		this.dataSource = new MatTableDataSource(obj.data);
		this.recordTotal = obj.total;
		this.progressStatusCompanyList = false;
	}

	searchClick(): void {
		this.skip = 0;
		this.paginator.pageIndex = 0;
		this.getData();
	}

	public searchClearClick(): void {
		this.CompanyName = '';
		this.Address = '';
		this.City = '';
		this.Province = '';
		this.Country = '';
		this.PostalCode = '';
		this.Notes = '';
		this.Website = '';
		this.Email = '';
		this.Phone = '';
		this.Status = '';
		
		let lls = [];
		this.priorityList.forEach(ll => {lls.push(ll.Value);});

		this.Priorities.setValue(lls);
		this.Priority = lls.toString();
		this.skip = 0;
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.paginator.pageIndex = 0;
		this.HideDateGT = '';
		this.getData();
	}

	resetError() : void {
		this.errorMessage = '';
		this.progressStatusCompanyList=false;
		this.progressStatusLst=false;
		this.progressStatusPriorityLst=false;
	}
	showHideSearchClick() {
		 if (this.showHideSearch) 
		 {
			 this.showHideSearch = false; 
			localStorage.setItem('showHideSearch', 'false'); 
			this.SearchText = 'Show Filters'; 
		}
		else
		{
			 this.showHideSearch = true; 
			localStorage.setItem('showHideSearch', 'true'); 
			  this.SearchText = 'Hide Filters'; 
		}
	}
	searchAndHideClick() {
		 this.skip = 0;
		 this.paginator.pageIndex = 0;
		this.getData();
		 setTimeout(() => {
		     this.showHideSearchClick();
		 }, 500);
	 }
	public help()
	{
		   const url = this.router.serializeUrl(
		       this.router.createUrlTree([`/help-view/companies`])
		);
		  window.open(url, '_blank');
	 }
	public toExcel(){
			let search: ICompanySearch = this._appService.getFreshSearch();

			search.CompanyName = this.CompanyName;
			search.Address = this.Address;
			search.City = this.City;
			search.Province = this.Province;
			search.Country = this.Country;
			search.PostalCode = this.PostalCode;
			search.Notes = this.Notes;
			search.Website = this.Website;
			search.Email = this.Email;
			search.Phone = this.Phone;
			search.Status = this.Status;
			search.HideDateGT = this.HideDateGT;
			search.Priority = this.Priorities.value.toString()==''?'(empty)':this.Priorities.value.toString();
			search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
			search.take = parseInt(this.pageSize);
			search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

			this._appService.toExcel(search).subscribe(
				retVal => {
					    this.excelFile = retVal;
				},
				 (error: any) => {
					 this.errorMessage = < any > error;
					 this.progressStatusCompanyList = false;
				});
	}
	public hideFileLink()
	{
	   this.excelFile = "";
	}
	public openExcelFile()
	{
	   window.open(`${ this.applicationSettings.retrieveUrl()}${ this.excelFile}`);
	}

	public GetLastEngagementByCompanyId(companyId){
		this.progressStatusCompanyList=true;
		this._appService.GetLastEngagementByCompanyId(companyId).subscribe((obj: ICompanyEngagement) => 
		{
			this.progressStatusCompanyList=false;
			this.showEngagementDialog(obj);
		}
		,
		(error: any) => {
			this.errorMessage = <any> error;
			this.progressStatusCompanyList=false;
		});
	}

	showEngagementDialog(companyEng:ICompanyEngagement): void {
		this.dialog
		  .open(InformationDialogComponent, {
			minWidth: '300px',
			data: {
				title: 'Last contact details',
				message:'<strong>Contact Date:</strong> '+companyEng.ContactDate+'<br><strong>Type:</strong> '+companyEng.Type+'<br><strong>Contact Name:</strong> '+companyEng.ContactName+'<br><strong>Notes:</strong> '+companyEng.Notes
			  },
		  });
	  }

	  changeDatePicker(): any {
        console.log(this.HideDateGT);
      }	  
}