import { Component, OnInit, AfterViewInit } from '@angular/core';

import { ApplicationSettings } from '../../shared/application-settings';
import { MaterialService } from '../material-service';

@Component({
	templateUrl: './material-help.component.html',
	styleUrls: ['./material-help.component.css']
})

export class MaterialHelpComponent implements OnInit, AfterViewInit {

	public pageTitle = 'Materials Help';
	public errorMessage = '';
	public buttonPushed = false;

	constructor(
		private _appService: MaterialService) {
	}

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
	}

}