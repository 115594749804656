import { Component, ViewChild, OnInit, AfterViewInit, Input } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {merge, Subscription} from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { ApplicationSettings } from '../../shared/application-settings';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';

import { CompanylandlocationService } from '../companylandlocation-service';
import { ICompanylandlocationSearch } from '../companylandlocation-model/companylandlocationsearch';
import { ICompanylandlocations } from '../companylandlocation-model/companylandlocations';
import { MapAdvancedMarker, MapInfoWindow } from '@angular/google-maps';

@Component({
	selector: 'landlocations-company-list-component',
	templateUrl: './landlocation-company-list.component.html',
	styleUrls: ['./landlocation-company-list.component.css']
})

export class LandLocationCompanyListComponent implements OnInit, AfterViewInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	@ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;
	@Input() CompanyIdParam: number;

	displayedColumns: string[] = ['Id', 'LegalLandDescription', 'Acres', 'SoilType'];
	isLoadingResults = true;

	public UserSearches: IUsersearch[];
	public pageSize = '10';
	public skip = 0;
	public recordTotal: number = 0;

	public showHideSearch: boolean = false;
	public SearchText: string = 'Show Filters';

	public CompanyId = '';
	public LegalLandDescription = '';
	public Acres = '';
	public SoilType = '';
	public Notes = '';

	public pageTitle = 'Land Locations List';
	public errorMessage = '';
	public dataSource: any;
	public excelFile;
	public progressStatus=false;

	public sub: Subscription;
	public inCompanyId = '';

	public locationList;


	constructor(private userSearchService: UsersearchService
		, public applicationSettings: ApplicationSettings
		, private router: Router
		, private _appService: CompanylandlocationService
		, private route: ActivatedRoute
	) {
			this.sub = this.route.params.subscribe(
				params => {
					this.inCompanyId = params['Id'];
					this.CompanyId=this.inCompanyId;
				}
			);
	}

	options: google.maps.MapOptions = {
		mapId: "DEMO_MAP_ID",
		center: { lat: 53.533841497838814, lng: -113.48806046214992 },
		zoom: 10,
		mapTypeId: 'hybrid'
	  };

	ngOnInit(): void {
		this.progressStatus = true;
		this.skip = 0;
		let x  = +localStorage.getItem('rows');
		if(x>0) { this.pageSize = x.toString(); }
		else { this.pageSize = '10';}
		if (localStorage.getItem('showHideSearch') === 'true')
		{
			   this.showHideSearch = true;
			   this.SearchText = 'Hide Filters';
		}
		else
		{
			   this.showHideSearch = false;
			   this.SearchText = 'Show Filters';
		 }
	}

	ngAfterViewInit(): void {
		this.userSearchService.getUserSearchesByForm(this.pageTitle)
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.paginator.pageIndex = 0;

		this.UserSearches.forEach(i => {

			if(i.Item=='LegalLandDescription')
				this.LegalLandDescription = i.Value;

			if(i.Item=='Acres')
				this.Acres = i.Value;

			if(i.Item=='SoilType')
				this.SoilType = i.Value;

			if(i.Item=='Notes')
				this.Notes = i.Value;

			if(i.Item=='orderByString') {
				if (this.applicationSettings.getOrderByDirection(i.Value)==='asc')
					this.sort.direction = 'asc';
				else
					this.sort.direction = 'desc';

				this.sort.active = this.applicationSettings.getOrderByName(i.Value);
			}

			if (i.Item == 'Skip')
				this.skip = i.Value;

		});

		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
		merge(this.sort.sortChange, this.paginator.page)
			.subscribe(()=> this.getData());

		this.getData();
	}

	pageSizeValueChanged(): void {
		this.applicationSettings.setPageSize(this.pageSize.toString());
		this.paginator.pageIndex = 0;
		this.getData();
	}

	getData(): any {
		this.progressStatus=true; 
		this.dataSource = null; 
		this.recordTotal = 0; 
		   let search: ICompanylandlocationSearch = this._appService.getFreshSearch();
   
		   search.CompanyId = this.CompanyId;
		   search.LegalLandDescription = this.LegalLandDescription;
		   search.Acres = this.Acres;
		   search.SoilType = this.SoilType;
		   search.Notes = this.Notes;
		   search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
		   search.take = parseInt(this.pageSize);
		   search.orderByString = this.sort.direction + '::' + this.sort.active + '::';
   
		   this._appService.getRecordSet(search).subscribe((obj: ICompanylandlocations) => this.onSelectedRecordSetReceived(obj),
		   (error: any) => {
			   this.errorMessage = <any> error;
		   });
	   }
   
	   private onSelectedRecordSetReceived(obj: ICompanylandlocations){
		   this.dataSource = new MatTableDataSource(obj.data);
		   this.recordTotal = obj.total;
		   this.locationList = obj.data;
		   this.progressStatus = false;
	   }


	searchClick(): void {
		this.skip = 0;
		this.paginator.pageIndex = 0;
		this.getData();
	}

	public searchClearClick(): void {
		this.LegalLandDescription = '';
		this.Acres = '';
		this.SoilType = '';
		this.Notes = '';
		this.skip = 0;
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.paginator.pageIndex = 0;
		this.getData();
	}


	resetError() : void {
		this.errorMessage = '';
		this.progressStatus=false;
	}
	showHideSearchClick() {
		 if (this.showHideSearch) 
		 {
			 this.showHideSearch = false; 
			localStorage.setItem('showHideSearch', 'false'); 
			this.SearchText = 'Show Filters'; 
		}
		else
		{
			 this.showHideSearch = true; 
			localStorage.setItem('showHideSearch', 'true'); 
			  this.SearchText = 'Hide Filters'; 
		}
	}
	searchAndHideClick() {
		 this.skip = 0;
		 this.paginator.pageIndex = 0;
		this.getData();
		 setTimeout(() => {
		     this.showHideSearchClick();
		 }, 500);
	 }
	public help()
	{
		   const url = this.router.serializeUrl(
		       this.router.createUrlTree([`/help-view/landlocation-company`])
		);
		  window.open(url, '_blank');
	 }
	 public toExcel(){
		let search: ICompanylandlocationSearch = this._appService.getFreshSearch();

		search.CompanyId = this.CompanyId;
		search.LegalLandDescription = this.LegalLandDescription;
		search.Acres = this.Acres;
		search.SoilType = this.SoilType;
		search.Notes = this.Notes;
		search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
		search.take = parseInt(this.pageSize);
		search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

		this._appService.toExcel(search).subscribe(
			retVal => {
					this.excelFile = retVal;
			},
			 (error: any) => {
				 this.errorMessage = < any > error;
			});
}
	public hideFileLink()
	{
	   this.excelFile = "";
	}
	public openExcelFile()
	{
	   window.open(`${ this.applicationSettings.retrieveUrl()}${ this.excelFile}`);
	}

	editLocation(id:string, companyid:string): void {
		this.router.navigate(['/landlocation-company-details/'+id+'/'+companyid]);
	}

	onMarkerClick(marker: MapAdvancedMarker) {
		this.infoWindow.openAdvancedMarkerElement(marker.advancedMarker, marker.advancedMarker.title);
	  }
}