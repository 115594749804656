import { AfterViewInit, Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { FormBuilder, FormControlName, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { fromEvent, merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { IUserLogin } from '../auth/user-model/user-login';
import { GenericValidator } from '../../shared/generic-validator';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {
	@ViewChildren(FormControlName, { read: ElementRef })formInputElements!: ElementRef[];
	pageTitle = 'Login';
	loginForm: FormGroup;
	userLogin: IUserLogin;
	public errorMessage = '';
	sub: any;
	auth: AuthService;
	hide = true;
	progressStatus = false;
	displayMessage: { [key: string]: string } = {};
	private validationMessages: { [key: string]: { [key: string]: string } };
	private genericValidator: GenericValidator;
	passwordflag = false;
	resetPasswordForm: FormGroup;

	constructor(private authservice: AuthService, private fb: FormBuilder, public snackBar: MatSnackBar) {
		this.auth = authservice;
		this.validationMessages = {
			Email: {
				required: 'Email is required.'
			},
			Password: {
				required: 'Password is required.'
			}
		};

		this.genericValidator = new GenericValidator(this.validationMessages);
	}

	ngOnInit(): void {
		this.loginForm = this.fb.group({
			Email: ['', [Validators.required]],
			Password: ['', [Validators.required]]
		});
		const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,4}$/;
		this.resetPasswordForm = this.fb.group({
			userEmail: ['', [Validators.required, Validators.pattern(emailPattern)]],

		});
	}

	ngAfterViewInit(): void {
		// Watch for the blur event from any input element on the form.
		const controlBlurs: Observable<any>[] = this.formInputElements.map(
			(formControl: ElementRef) =>
				fromEvent(formControl.nativeElement, 'blur')
		);

		// Merge the blur event observable with the valueChanges observable
		merge(this.loginForm.valueChanges, ...controlBlurs)
			.pipe( debounceTime(800))
			.subscribe(value => {
				this.displayMessage = this.genericValidator.processMessages(
					this.loginForm
				);
			});
	}

	onLogin(): void {
		this.progressStatus = true;
		if (this.loginForm.dirty && this.loginForm.valid) {
			const user = Object.assign(
				{},
				this.userLogin,
				this.loginForm.value
			);
			
			this.auth
				.login(user)
				.subscribe(
					userLogin => {this.userLogin = userLogin; this.progressStatus = false;this.snackBar.open('Welcome back '+this.auth.getUsername(), "Ok", {duration: 10000});},
					error => {this.errorMessage = <any>error;this.progressStatus = false;}
				);
		}
	}
	
	resetPassword() {
		this.errorMessage ="";
		this.resetPasswordForm.reset();
		this.passwordflag = true;
	}
	goToLogin() {
		this.passwordflag = false;
		this.errorMessage ="";
		this.loginForm.reset();
	}
	sendResetEmail() {
		this.progressStatus = true;
		let userEmail = this.resetPasswordForm.value.userEmail;
		this.auth
			.resetEmail(userEmail)
			.subscribe(
				(response) => {this.progressStatus = false;this.snackBar.open(response, "Ok", {duration: 10000});
				this.resetPasswordForm.reset()},
				error => {this.errorMessage = <any>error;this.progressStatus = false;}
			);
	}

	

}
