import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge } from 'rxjs';
import { Router } from '@angular/router';

import { ApplicationSettings } from '../../shared/application-settings';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';

import { BranchlabelprinterService } from '../branchlabelprinter-service';
import { IBranchlabelprinterSearch } from '../branchlabelprinter-model/branchlabelprintersearch';
import { IBranchlabelprinters } from '../branchlabelprinter-model/branchlabelprinters';
import { BrancheService } from '../../admin/branches/branche-service';
import { IBranchlabelprintersIpList } from '../branchlabelprinter-model/branchlabelprinterslist';


@Component({
	templateUrl: './branchlabelprinter-list-ip.component.html',
	styleUrls: ['./branchlabelprinter-list-ip.component.css']
})

export class BranchlabelprinterIPListComponent implements OnInit, AfterViewInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	displayedColumns: string[] = ['IP', 'BranchId'];
	isLoadingResults = true;

	public UserSearches: IUsersearch[];
	public pageSize = '10';
	public skip = 0;
	public recordTotal: number = 0;

	public showHideSearch: boolean = false;
	public SearchText: string = 'Show Filters';

	public apiKey = '25KPE4llQtWSQYKlK7Yg9Adci';
	public PrinterType = '';
	public BranchId = '';
	public pageTitle = 'IP Printers List (Test API)';
	public errorMessage = '';
	public dataSource: any;
	public excelFile;
	public branches;

	constructor(private userSearchService: UsersearchService
		, public applicationSettings: ApplicationSettings
		, private router: Router
		, private _appService: BranchlabelprinterService
		, public branchService: BrancheService
	) {
	}

	ngOnInit(): void {
		let x = +localStorage.getItem('rows');
		if (x > 0) { this.pageSize = x.toString(); }
		else { this.pageSize = '10'; }
		
		this.branchService.getAllBranchesForDropdown().subscribe(response => {
			console.log(response);
			this.branches = response;
		});
	}

	ngAfterViewInit(): void {
	}

	pageSizeValueChanged(): void {
		this.applicationSettings.setPageSize(this.pageSize.toString());
		this.getData();
	}

	getData(): any {
		
		this._appService.getLabelPrinterIpList(this.BranchId,this.PrinterType,this.apiKey).subscribe((obj: IBranchlabelprintersIpList) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = <any>error;
			});
	}

	private onSelectedRecordSetReceived(obj: IBranchlabelprintersIpList) {
		if(obj.status_code==1){
		this.dataSource = new MatTableDataSource(obj.data);
		}else{
			this.errorMessage = obj.message;
		}

	}

	searchClick(): void {
		this.errorMessage = '';
		this.getData();
	}

	public searchClearClick(): void {
		this.PrinterType = '';
		this.BranchId = '';
		this.apiKey = '';
		this.getData();
	}

	resetError(): void {
		this.errorMessage = '';
	}
	showHideSearchClick() {
		if (this.showHideSearch) {
			this.showHideSearch = false;
			localStorage.setItem('showHideSearch', 'false');
			this.SearchText = 'Show Filters';
		}
		else {
			this.showHideSearch = true;
			localStorage.setItem('showHideSearch', 'true');
			this.SearchText = 'Hide Filters';
		}
	}
	searchAndHideClick() {
		this.skip = 0;
		this.getData();
		setTimeout(() => {
			this.showHideSearchClick();
		}, 500);
	}
	public help() {
		const url = this.router.serializeUrl(
			this.router.createUrlTree([`/help-view/branchlabelprinters`])
		);
		window.open(url, '_blank');
	}
	public hideFileLink() {
		this.excelFile = "";
	}
	public openExcelFile() {
		window.open(`${this.applicationSettings.retrieveUrl()}${this.excelFile}`);
	}
	public openApiURL() {
		window.open(`${this.applicationSettings.retrieveUrl()}/api/Branchlabelprinters/getPrinterListAPI?BranchId=${this.BranchId}&PrinterType=${this.PrinterType}&ApiKey=${this.apiKey}`);
	}
}