import { Component, OnInit, AfterViewInit } from '@angular/core';

import { ApplicationSettings } from '../../shared/application-settings';
import { LookuplistService } from '../lookuplist-service';

@Component({
	selector: 'lookuplist-help',
	templateUrl: './lookuplist-help.component.html',
	styleUrls: ['./lookuplist-help.component.css']
})

export class LookuplistHelpComponent implements OnInit, AfterViewInit {

	public pageTitle = 'Lookup Lists Help';
	public errorMessage = '';
	public buttonPushed = false;

	constructor(
		private _appService: LookuplistService) {
	}

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
	}

}