import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControlName, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IEmployeelesson } from '../employeelesson-model/employeelesson';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';
import { Subscription } from 'rxjs/Subscription';
import { EmployeelessonService } from '../employeelesson-service';
import { IEmployeelessons } from '../Employeelesson-model/Employeelessons';
import { IEmployeelessonSearch } from '../Employeelesson-model/EmployeelessonSearch';

import { UsersearchService } from '../../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../../admin/usersearches/usersearch-model/usersearch';

@Component({
	templateUrl: './employeelesson-edit.component.html',
	styleUrls: ['employeelesson-edit.component.css']
})

export class EmployeelessonEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit EmployeeLessons';
	errorMessage: string;
	employeelessonForm: FormGroup;
	employeelesson: IEmployeelesson;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public EmployeelessonSearch: IEmployeelessonSearch;
	public ListData: IEmployeelessons;
	public showNextRecordButton: boolean = true;
	public progressStatus=false;

	public ContentDescriptionHtml = '';

	constructor(private fb: FormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private employeelessonService: EmployeelessonService,
		) {
	}

	ngOnInit(): void {
		this.employeelessonForm = this.fb.group({
			Id: '',
			LessonName: ['',[Validators.maxLength(250)]],
			CoursesId: '',
			LessonId: '',
			ContentOrder: '',
			ContentName: ['',[Validators.maxLength(250)]],
			ContentDescription: [''],
			ContentReviewed: '',
			ContentStatus: ['',[Validators.maxLength(250)]],
			ContentNotes: [''],
			LessonUser: ['',[Validators.maxLength(256)]],
			Employee: ['',[Validators.maxLength(250)]],
			createddate: '',
			createdby: '',
			updateddate: '',
			updatedby: '',
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getEmployeelesson(id);
				this.progressStatus = true;
			}
		);
	}

	public getSelectedRecordSet():void{
		this.employeelessonService.getRecordSet(this.EmployeelessonSearch)
			.subscribe((obj: IEmployeelessons) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: IEmployeelessons){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.employeelesson.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

		this.progressStatus = false;

	}

	saveEmployeelesson(returnToList: boolean, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

		if (this.employeelessonForm.valid) {
			const obj = Object.assign({ }, this.employeelesson, this.employeelessonForm.value);
			this.employeelessonService.saveEmployeelesson(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord,addNewRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		if (returnToList) {
			this.employeelessonForm.reset();
			this.router.navigate(['/training/employeelessons']);
		} 
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
		else if (addNewRecord)
		{
			this.router.navigate(['/employeelesson-details', 0]);
			this.employeelessonForm.reset();
			let obj = this.employeelessonService.initializeEmployeelesson();
			this.onEmployeelessonRetrieved(obj);
		}
			// when the record id is zero then it is a new record.
		else if (data !== null && this.employeelesson.Id === 0) {
			this.router.navigate(['/training/employeelesson-details', data.Id]);
		} 
		else if (this.employeelesson.Id != 0){
			this.getEmployeelesson(this.employeelesson.Id);
		}

		this.buttonPushed = false;
		this.progressStatus = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.employeelesson.Id)
				record = count;
		});

		this.getEmployeelesson(this.ListData.data[record].Id)
	}

	getEmployeelesson(id: number): void {
		this.employeelessonService.getEmployeelesson(id)
			.subscribe((obj: IEmployeelesson) => this.onEmployeelessonRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onEmployeelessonRetrieved(employeelesson: IEmployeelesson): void {
		if (this.employeelessonForm) {
			this.employeelessonForm.reset();
		}
		this.employeelesson = employeelesson;
		if (this.employeelesson.Id === 0) {
			this.page = 'Add EmployeeLessons';
		} else {
			this.page = 'Edit EmployeeLessons';
		}

		this.employeelessonForm.patchValue({
			Id: this.employeelesson.Id,
			LessonName: this.employeelesson.LessonName,
			CoursesId: this.employeelesson.CoursesId,
			LessonId: this.employeelesson.LessonId,
			ContentOrder: this.employeelesson.ContentOrder,
			ContentName: this.employeelesson.ContentName,
			ContentDescription: this.employeelesson.ContentDescription,
			ContentReviewed: this.employeelesson.ContentReviewed,
			ContentStatus: this.employeelesson.ContentStatus,
			ContentNotes: this.employeelesson.ContentNotes,
			LessonUser: this.employeelesson.LessonUser,
			Employee: this.employeelesson.Employee,
			createddate: this.employeelesson.createddate,
			createdby: this.employeelesson.createdby,
			updateddate: this.employeelesson.updateddate,
			updatedby: this.employeelesson.updatedby,
		});

		this.ContentDescriptionHtml = this.employeelesson.ContentDescription;

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteEmployeelesson(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.employeelesson.LessonName}?`)) {
			this.employeelessonService.deleteEmployeelesson(this.employeelesson.Id)
				.subscribe(() => this.onSaveComplete(true, null, false,false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/training/employeelessons']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('EmployeeLessons List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.EmployeelessonSearch = this.employeelessonService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'LessonName')
				this.EmployeelessonSearch.LessonName = i.Value;

			if (i.Item == 'CoursesId')
				this.EmployeelessonSearch.CoursesId = i.Value;

			if (i.Item == 'LessonId')
				this.EmployeelessonSearch.LessonId = i.Value;

			if (i.Item == 'ContentOrder')
				this.EmployeelessonSearch.ContentOrder = i.Value;

			if (i.Item == 'ContentName')
				this.EmployeelessonSearch.ContentName = i.Value;

			if (i.Item == 'ContentDescription')
				this.EmployeelessonSearch.ContentDescription = i.Value;

			if (i.Item == 'ContentReviewed')
				this.EmployeelessonSearch.ContentReviewed = i.Value;

			if (i.Item == 'ContentStatus')
				this.EmployeelessonSearch.ContentStatus = i.Value;

			if (i.Item == 'ContentNotes')
				this.EmployeelessonSearch.ContentNotes = i.Value;

			if (i.Item == 'LessonUser')
				this.EmployeelessonSearch.LessonUser = i.Value;

			if (i.Item == 'Employee')
				this.EmployeelessonSearch.Employee = i.Value;

			if (i.Item == 'createddate')
				this.EmployeelessonSearch.createddate = i.Value;

			if (i.Item == 'createdby')
				this.EmployeelessonSearch.createdby = i.Value;

			if (i.Item == 'updateddate')
				this.EmployeelessonSearch.updateddate = i.Value;

			if (i.Item == 'updatedby')
				this.EmployeelessonSearch.updatedby = i.Value;

			if (i.Item == 'orderByString')
				this.EmployeelessonSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.EmployeelessonSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
}
