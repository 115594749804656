import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {merge} from 'rxjs';
import { Router } from '@angular/router';

import { ApplicationSettings } from '../../../shared/application-settings';
import { IUsersearch } from '../../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../../admin/usersearches/usersearch-service';

import { BrancheService } from '../branche-service';
import { IBrancheSearch } from '../branche-model/branchesearch';
import { IBranches } from '../branche-model/branches';
import { FormControl } from '@angular/forms';

@Component({
	templateUrl: './branche-list.component.html',
	styleUrls: ['./branche-list.component.css']
})

export class BrancheListComponent implements OnInit, AfterViewInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	displayedColumns: string[] = ['Id', 'BranchName', 'BranchNumber','BusinessNumber', 'TollFreeNumber', 'City', 'Province','fax'];
	isLoadingResults = true;

	public UserSearches: IUsersearch[];
	public pageSize = '10';
	public skip = 0;
	public recordTotal: number = 0;

	public showHideSearch: boolean = false;
	public SearchText: string = 'Show Filters';

	public BranchName = '';
	public BusinessNumber = '';
	public TollFreeNumber = '';
	public AddressLine = '';
	public AddressLine2 = '';
	public PostalCode = '';
	public City = '';
	public Province = '';
	public Country = '';
	public Notes = '';
	public BranchNumber = '';
	public fax = '';
	public UPC = '';

	public pageTitle = 'Branches List';
	public errorMessage = '';
	public dataSource: any;
	public excelFile;
	pageSizeLst = new FormControl();
	progressStatus = true;

	constructor(private userSearchService: UsersearchService
		, public applicationSettings: ApplicationSettings
		 , private router: Router
		, private _appService: BrancheService) {
	}

	ngOnInit(): void {
		this.skip = 0;
		let x  = +localStorage.getItem('rows');
		if(x>0) { this.pageSize = x.toString(); }
		else { this.pageSize = '10';}
		this.pageSizeLst.setValue(this.pageSize);
		if (localStorage.getItem('showHideSearch') === 'true')
		{
			   this.showHideSearch = true;
			   this.SearchText = 'Hide Filters';
		}
		else
		{
			   this.showHideSearch = false;
			   this.SearchText = 'Show Filters';
		 }
	}

	ngAfterViewInit(): void {
		this.userSearchService.getUserSearchesByForm(this.pageTitle)
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
		}
		,(error: any) => {this.errorMessage = < any > error;this.progressStatus = false;});
	}

	setUserFilters(): void {
		this.sort.direction = 'asc';
		this.sort.active = 'Id';

		this.UserSearches.forEach(i => {
			if(i.Item=='BranchName')
				this.BranchName = i.Value;

				if(i.Item=='BranchNumber')
				this.BranchNumber = i.Value;

			if(i.Item=='BusinessNumber')
				this.BusinessNumber = i.Value;

			if(i.Item=='TollFreeNumber')
				this.TollFreeNumber = i.Value;

			// if(i.Item=='AddressLine')
			// 	this.AddressLine = i.Value;

			// if(i.Item=='AddressLine2')
			// 	this.AddressLine2 = i.Value;

			// if(i.Item=='PostalCode')
			// 	this.PostalCode = i.Value;

			if(i.Item=='City')
				this.City = i.Value;

			if(i.Item=='Province')
				this.Province = i.Value;

			// if(i.Item=='Country')
			// 	this.Country = i.Value;

			// if(i.Item=='Notes')
			// 	this.Notes = i.Value;

		

			if(i.Item=='fax')
				this.fax = i.Value;

			// if(i.Item=='UPC')
			// 	this.UPC = i.Value;

			if(i.Item=='orderByString') {
				if (this.applicationSettings.getOrderByDirection(i.Value)==='asc')
					this.sort.direction = 'asc';
				else
					this.sort.direction = 'desc';

				this.sort.active = this.applicationSettings.getOrderByName(i.Value);
			}

			if (i.Item == 'Skip')
				this.skip = i.Value;

		});

		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
		merge(this.sort.sortChange, this.paginator.page)
			.subscribe(()=> this.getData());

		this.getData();
	}

	pageSizeValueChanged(rows: string): void {
		this.applicationSettings.setPageSize(rows);
		this.pageSize=rows;
		this.pageSizeLst.setValue(this.pageSize);
		this.getData();
	}

	getData(): any {
	 this.dataSource = null; 
	 this.recordTotal = 0; 
		let search: IBrancheSearch = this._appService.getFreshSearch();

		search.BranchName = this.BranchName;
		search.BusinessNumber = this.BusinessNumber;
		search.TollFreeNumber = this.TollFreeNumber;
		search.BranchNumber = this.BranchNumber;
		search.City = this.City;
		search.Province = this.Province;
		search.fax = this.fax;
		// search.AddressLine = this.AddressLine;
		// search.AddressLine2 = this.AddressLine2;
		// search.PostalCode = this.PostalCode;
	
		
		// search.Country = this.Country;
		// search.Notes = this.Notes;	
		// search.UPC = this.UPC;
		search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
		search.take = parseInt(this.pageSize);
		search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

		this._appService.getRecordSet(search).subscribe((obj: IBranches) => this.onSelectedRecordSetReceived(obj),
		(error: any) => {this.errorMessage = < any > error;this.progressStatus = false;	});
	}

	private onSelectedRecordSetReceived(obj: IBranches){
		this.dataSource = new MatTableDataSource(obj.data);
		this.recordTotal = obj.total;
		this.progressStatus = false;
	}

	searchClick(): void {
		this.skip = 0;
		this.getData();
	}

	public searchClearClick(): void {
		this.BranchName = '';
		this.BusinessNumber = '';
		this.TollFreeNumber = '';
		this.AddressLine = '';
		this.AddressLine2 = '';
		this.PostalCode = '';
		this.City = '';
		this.Province = '';
		this.Country = '';
		this.Notes = '';
		this.BranchNumber = '';
		this.fax = '';
		this.UPC = '';
		this.skip = 0;
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.getData();
	}

	resetError() : void {
		this.errorMessage = '';
	}
	showHideSearchClick() {
		 if (this.showHideSearch) 
		 {
			 this.showHideSearch = false; 
			localStorage.setItem('showHideSearch', 'false'); 
			this.SearchText = 'Show Filters'; 
		}
		else
		{
			 this.showHideSearch = true; 
			localStorage.setItem('showHideSearch', 'true'); 
			  this.SearchText = 'Hide Filters'; 
		}
	}
	searchAndHideClick() {
		 this.skip = 0;
		this.getData();
		 setTimeout(() => {
		     this.showHideSearchClick();
		 }, 500);
	 }
	public help()
	{
		   const url = this.router.serializeUrl(
			this.router.createUrlTree([`/help-view/branches`])
		);
		  window.open(url, '_blank');
	 }
	public toExcel(){
			let search: IBrancheSearch = this._appService.getFreshSearch();

			search.BranchName = this.BranchName;
			search.BusinessNumber = this.BusinessNumber;
			search.TollFreeNumber = this.TollFreeNumber;
			search.City = this.City;
			search.Province = this.Province;
			search.BranchNumber = this.BranchNumber;
			search.fax = this.fax;
			// search.AddressLine = this.AddressLine;
			// search.AddressLine2 = this.AddressLine2;
			// search.PostalCode = this.PostalCode;
			
			// search.Country = this.Country;
			// search.Notes = this.Notes;
		
			//search.UPC = this.UPC;
			search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
			search.take = parseInt(this.pageSize);
			search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

			this._appService.toExcel(search).subscribe(
				retVal => {
					    this.excelFile = retVal;
				},
				(error: any) => {this.errorMessage = < any > error;this.progressStatus = false;	});
	}
	public hideFileLink()
	{
	   this.excelFile = "";
	}
	public openExcelFile()
	{
	   window.open(`${ this.applicationSettings.retrieveUrl()}${ this.excelFile}`);
	}
}