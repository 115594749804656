import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControlName, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IQuestion } from '../question-model/question';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';
import { Subscription } from 'rxjs/Subscription';
import { QuestionService } from '../question-service';
import { IQuestions } from '../Question-model/Questions';
import { IQuestionSearch } from '../Question-model/QuestionSearch';

import { LessonService } from '../../lessons/lesson-service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ApplicationSettings } from '../../../shared/application-settings';
import { IUsersearch } from '../../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../../admin/usersearches/usersearch-service';


@Component({
	templateUrl: './question-edit.component.html',
	styleUrls: ['question-edit.component.css']
})

export class QuestionEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit Questions';
	errorMessage: string;
	questionForm: FormGroup;
	question: IQuestion;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public QuestionSearch: IQuestionSearch;
	public ListData: IQuestions;
	public showNextRecordButton: boolean = true;
	public progressStatus=false;

	public lessonId: number;
	public courseId: number;
	public lessonsList;
	public progressLessonsStatus=false;
	public Editor: any = ClassicEditor;

	constructor(private fb: FormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private questionService: QuestionService
		, private lessonService: LessonService
		, public settings: ApplicationSettings
		) {
	}

	ngOnInit(): void {
		this.questionForm = this.fb.group({
			Id: '',
			LessonId: '',
			Name: ['',[Validators.maxLength(250)]],
			Description: [''],
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});
		this.getLessons();
		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.courseId = +params['courseId'];
				this.lessonId = +params['lessonId'];
				this.getQuestion(id);
				this.progressStatus = true;
			}
		);
	}

	public getSelectedRecordSet():void{
		this.questionService.getRecordSet(this.QuestionSearch)
			.subscribe((obj: IQuestions) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: IQuestions){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.question.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

		this.progressStatus = false;

	}

	saveQuestion(returnToList: boolean, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

		if (this.questionForm.valid) {
			const obj = Object.assign({ }, this.question, this.questionForm.value);
			this.questionService.saveQuestion(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord,addNewRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		if (returnToList) {
			this.questionForm.reset();
			this.router.navigate(['/training/lesson-details', this.lessonId, this.courseId]);
		} else {
			// when the record id is zero then it is a new record.
			if (data !== null && this.question.Id === 0) {
				this.router.navigate(['/training/question-details', data.Id, this.courseId, this.lessonId]);
			} else {
				if (advanceToNextRecord) {
					this.advanceToNextRecord();
				}
			}
		}

		this.buttonPushed = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.question.Id)
				record = count;
		});

		this.getQuestion(this.ListData.data[record].Id)
	}

	getQuestion(id: number): void {
		this.questionService.getQuestion(id)
			.subscribe((obj: IQuestion) => this.onQuestionRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onQuestionRetrieved(question: IQuestion): void {
		if (this.questionForm) {
			this.questionForm.reset();
		}
		this.question = question;
		if (this.question.Id === 0) {
			this.question.LessonId = this.lessonId;
			this.page = 'Add Questions';
		} else {
			this.page = 'Edit Questions';
		}

		this.questionForm.patchValue({
			Id: this.question.Id,
			LessonId: this.question.LessonId,
			Name: this.question.Name,
			Description: this.question.Description,
			CreatedDate: this.question.CreatedDate,
			CreatedBy: this.question.CreatedBy,
			UpdatedDate: this.question.UpdatedDate,
			UpdatedBy: this.question.UpdatedBy,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteQuestion(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.question.LessonId}?`)) {
			this.questionService.deleteQuestion(this.question.Id)
				.subscribe(() => this.onSaveComplete(true, null, false,false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		//this.router.navigate(['/training/questions']);
		this.router.navigate(['/training/lesson-details', this.lessonId, this.courseId]);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Questions List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.QuestionSearch = this.questionService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'LessonId')
				this.QuestionSearch.LessonId = i.Value;

			if (i.Item == 'Name')
				this.QuestionSearch.Name = i.Value;

			if (i.Item == 'Description')
				this.QuestionSearch.Description = i.Value;

			if (i.Item == 'orderByString')
				this.QuestionSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.QuestionSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}

	getLessons() {
		this.lessonService.getAll().subscribe(response => {
			this.lessonsList = response;
			this.progressLessonsStatus = false;
		},(error: any) => {
			this.progressLessonsStatus = true;
			this.errorMessage = <any> error;
		});
	}
}
