import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IEmployeelesson} from './Employeelesson-model/Employeelesson';
import { IEmployeelessons } from './Employeelesson-model/Employeelessons';
import { IEmployeelessonSearch } from './Employeelesson-model/EmployeelessonSearch';

import { ApplicationSettings } from '../../shared/application-settings';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';

@Injectable()
export class EmployeelessonService {
	private baseUrl;
	public pageTitle = 'EmployeeLessons List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Employeelessons';
	}

	getEmployeelesson(id: number):  Observable<IEmployeelesson> {
		if (id === 0) {
			return of(this.initializeEmployeelesson());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IEmployeelesson>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveEmployeelesson(employeelesson: IEmployeelesson): Observable<any> {
		if (employeelesson.Id === 0) {
			return this.createEmployeelesson(employeelesson);
		}
		return this.updateEmployeelesson(employeelesson);
	}

	private createEmployeelesson(employeelesson: IEmployeelesson): Observable<any> {
		employeelesson.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/employeelessoncreate', employeelesson)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateEmployeelesson(employeelesson: IEmployeelesson): Observable<IEmployeelesson> {
		return this.authHttp.put<IEmployeelesson>(this.baseUrl, employeelesson)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteEmployeelesson(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeEmployeelesson(): IEmployeelesson {
		return {
			Id: 0,
			LessonName: '',
			CoursesId: 0,
			LessonId: 0,
			ContentOrder: 0,
			ContentName: '',
			ContentDescription: '',
			ContentReviewed: '',
			ContentStatus: '',
			ContentNotes: '',
			LessonUser: '',
			Employee: '',
			createddate: 0,
			createdby: 0,
			updateddate: 0,
			updatedby: 0,
		} as IEmployeelesson;
	}

	public getRecordSet(search: IEmployeelessonSearch): Observable<IEmployeelessons> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('LessonName', search.LessonName, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('CoursesId', search.CoursesId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('LessonId', search.LessonId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('ContentOrder', search.ContentOrder, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('ContentName', search.ContentName, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('ContentDescription', search.ContentDescription, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('ContentReviewed', search.ContentReviewed, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('ContentStatus', search.ContentStatus, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('ContentNotes', search.ContentNotes, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('LessonUser', search.LessonUser, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Employee', search.Employee, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('createddate', search.createddate, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('createdby', search.createdby, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('updateddate', search.updateddate, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('updatedby', search.updatedby, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('LessonName', search.LessonName ? search.LessonName : '')
			.set('CoursesId', search.CoursesId ? search.CoursesId : '')
			.set('LessonId', search.LessonId ? search.LessonId : '')
			.set('ContentOrder', search.ContentOrder ? search.ContentOrder : '')
			.set('ContentName', search.ContentName ? search.ContentName : '')
			.set('ContentDescription', search.ContentDescription ? search.ContentDescription : '')
			.set('ContentReviewed', search.ContentReviewed ? search.ContentReviewed : '')
			.set('ContentStatus', search.ContentStatus ? search.ContentStatus : '')
			.set('ContentNotes', search.ContentNotes ? search.ContentNotes : '')
			.set('LessonUser', search.LessonUser ? search.LessonUser : '')
			.set('Employee', search.Employee ? search.Employee : '')
			.set('createddate', search.createddate ? search.createddate : '')
			.set('createdby', search.createdby ? search.createdby : '')
			.set('updateddate', search.updateddate ? search.updateddate : '')
			.set('updatedby', search.updatedby ? search.updatedby : '')
		};

		return this.authHttp.get<IEmployeelessons>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IEmployeelessons>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IEmployeelessonSearch { 
		let search: IEmployeelessonSearch = {
			Id: 1,
			LessonName: '',
			CoursesId: '',
			LessonId: '',
			ContentOrder: '',
			ContentName: '',
			ContentDescription: '',
			ContentReviewed: '',
			ContentStatus: '',
			ContentNotes: '',
			LessonUser: '',
			Employee: '',
			createddate: '',
			createdby: '',
			updateddate: '',
			updatedby: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.error.ErrorDescription}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: IEmployeelessonSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('LessonName', search.LessonName ? search.LessonName : '')
			.set('CoursesId', search.CoursesId ? search.CoursesId : '')
			.set('LessonId', search.LessonId ? search.LessonId : '')
			.set('ContentOrder', search.ContentOrder ? search.ContentOrder : '')
			.set('ContentName', search.ContentName ? search.ContentName : '')
			.set('ContentDescription', search.ContentDescription ? search.ContentDescription : '')
			.set('ContentReviewed', search.ContentReviewed ? search.ContentReviewed : '')
			.set('ContentStatus', search.ContentStatus ? search.ContentStatus : '')
			.set('ContentNotes', search.ContentNotes ? search.ContentNotes : '')
			.set('LessonUser', search.LessonUser ? search.LessonUser : '')
			.set('Employee', search.Employee ? search.Employee : '')
			.set('createddate', search.createddate ? search.createddate : '')
			.set('createdby', search.createdby ? search.createdby : '')
			.set('updateddate', search.updateddate ? search.updateddate : '')
			.set('updatedby', search.updatedby ? search.updatedby : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
}