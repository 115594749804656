<div class="panel">
    <!-- <div class="panel-heading"></div> -->
    <div class="panel-body">
        <div class='row'>
            <div class='col-md-8'>
                <div class='has-error' class='text-danger' *ngIf='errorMessage'>
                    <button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
                    {{ errorMessage }}
                </div>
            </div>
        </div>
        <div class='row'>
            <div class= 'col-md-4'>
                <form class="form-horizontal" novalidate [formGroup]="propertiesForm">
                    <div class="form-group">
                        <label class="col-md-10 control-label" for="PLCIP">PLC IP</label>
                        <div class="col-md-10 form-group-item">
                            <input class="form-control" id="PLCIP" type="text" placeholder="PLC IP (required)" formControlName="PLCIP" />
                        </div>
                        <span class="text-danger" *ngIf="displayMessage.PLCIP">
                            {{displayMessage.PLCIP}}
                        </span>
                    </div>
                    <div class="form-group">
                        <label class="col-md-10 control-label" for="PLCPORT">PLC Port</label>
                        <div class="col-md-10 form-group-item">
                            <input class="form-control" id="PLCPORT" type="number" placeholder="PLC PORT (required)" formControlName="PLCPORT" />
                        </div>
                        <span class="text-danger" *ngIf="displayMessage.PLCPORT">
                            {{displayMessage.PLCPORT}}
                        </span>
                    </div>
                    <div class="form-group">
                        <label class="col-md-10 control-label" for="Log_URL_API_Prod">Log URL API</label>
                        <div class="col-md-10 form-group-item">
                            <input class="form-control" id="Log_URL_API_Prod" type="text" placeholder="Log URL API (required)" formControlName="Log_URL_API_Prod" />
                        </div>
                        <span class="text-danger" *ngIf="displayMessage.Log_URL_API_Prod">
                            {{displayMessage.Log_URL_API_Prod}}
                        </span>
                    </div>
                    <div class="form-group">
                        <label class="col-md-10 control-label" for="LogEachSeconds">Log Each Seconds</label>
                        <div class="col-md-10 form-group-item">
                            <input class="form-control" id="LogEachSeconds" type="number" placeholder="Log Each Seconds (required)" formControlName="LogEachSeconds" />
                        </div>
                        <span class="text-danger" *ngIf="displayMessage.LogEachSeconds">
                            {{displayMessage.LogEachSeconds}}
                        </span>
                    </div>
                    <div class="form-group">
                        <label class="col-md-10 control-label" for="LoggingEnabled">Logging Enabled</label>
                        <div class="col-md-10 form-group-item">
                            <input id="LoggingEnabled" type='checkbox' formControlName="LoggingEnabled" />
                        </div>
                        <span class="text-danger" *ngIf="displayMessage.LoggingEnabled">
                            {{displayMessage.LoggingEnabled}}
                        </span>
                    </div>
                    <div class='row'>
                        <div class='col-md-4'>
                            <span>
                                <button class='btn btn-primary btn-edit-sml'
                                    (click)='updateServerPropertiesDelayed()'
                                    [disabled]='(!propertiesForm.valid)||progressStatus'>
                                    <span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
                                </button>
                            </span>
                        </div>
                    </div>
                </form>
            </div>
            <div class= 'col-md-8'>
                <form class="form-horizontal" novalidate [formGroup]="localLogForm">
                    <label class="col-md-10 control-label" for="LocalLog">Latest Log Data</label>
                <textarea rows="15" formControlName='LocalLog' class='form-control' readonly type='text'></textarea>
            </form>
            </div>
    </div>
    </div>
</div>
<div>
	<app-loading *ngIf="progressStatus"></app-loading> 
</div>