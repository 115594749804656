
<div class='card animaPlaceholder'>
	<div class='card-heading'>
		<span *ngIf = 'worklog'>{{page}} :{{worklog.Id}}</span >
		<span class='pull-right'>
			{{record}} of {{recordTotal}}
		</span>
		<div class='has-error' *ngIf='errorMessage'>{{ errorMessage }}</div>
	</div>
<div class='container-fluid'>
	<div class='card-body'>
		<form class='form-horizontal' novalidate [formGroup]='worklogForm'>
			<fieldset>
				<div class='row'>
<!-- Column 1 -->
<div class='col-md-6'>
<div class='row'>
<!-- Column 1-->
<div class='row mt-2'>
	<label class='col-md-2 control-label'> Work Date</label>
		<div class='col-md-10'>
			<input class='form-control' type='date' formControlName='WorkDate' />
		</div>
	</div>
<div class='row mt-2'>
	<label class='col-md-2 control-label'> Description</label>
		<div class='col-md-10'>
			<input class='form-control' type='text' formControlName='Description' />
		</div>
	</div>
			<div class='row mt-2'>
				<input class='form-control' type='hidden' fromControlName='WorkTaskId'>
			<label class='col-md-2 control-label'> Start Time</label>
				<div class='col-md-4 col-4'>
					<select  formControlName="StartTimeHour" class="form-control" (change)='calcHrs()'>
                        <option *ngFor="let item of timeHours" [value]="(item)" >{{item}}</option>
					</select>
				</div>
				<div class='col-md-4 col-4'>
					<select  formControlName="StartTimeMinute" class="form-control" (change)='calcHrs()'>
                        <option *ngFor="let item of timeMinutes" [value]="(item)">{{item}}</option>
					</select>
				</div>
			</div>
			<div class='row mt-2'>
			<label class='col-md-2 control-label'> End Time</label>
				<div class='col-md-4 col-4'>
					<select  formControlName="EndTimeHour" class="form-control" (change)='calcHrs()'>
                        <option *ngFor="let item of timeHours" [value]="(item)">{{item}}</option>
					</select>
				</div>
				<div class='col-md-4 col-4'>
					<select  formControlName="EndTimeMinute" class="form-control" (change)='calcHrs()' >
                        <option *ngFor="let item of timeMinutes" [value]="(item)">{{item}}</option>
					</select>
				</div>
			</div>
	<div class='row mt-2'>
	<label class='col-md-2 control-label'> Hours</label>
		<div class='col-md-4'>
			<input class='form-control' type='number' [readonly]='true' formControlName='Hours' />
		</div>
		<label class='col-md-2 control-label'> Bill</label>
			<div class='col-md-2'>
				<input type='checkbox' placeholder=' Billable'	formControlName='Billable' />
			</div>
		</div>
			<div class='row mt-2'>
			<label class='col-md-2 control-label'> Type</label>
				<div class='col-md-10'>
					<select  formControlName="Type" class="form-control" >
                        <option *ngFor="let item of workType" [value]="(item)">{{item}}</option>
					</select>
				</div>
			</div>
			<div class='row mt-2'>
				<label class='col-md-2 control-label'> Person</label>
					<div class='col-md-10'>
						<select  formControlName="Person" class="form-control" >
							<option *ngFor="let item of applicationSettings.employees" [value]="(item)">{{item}}</option>
						</select>
					</div>
				</div>
		
	<div class='row mt-2'>
	<label class='col-md-2 control-label'> Starting Kms</label>
		<div class='col-md-10'>
			<input class='form-control' type='number' formControlName='StartingKms' (change)='calcKms()' />
		</div>
	</div>
	<div class='row mt-2'>
	<label class='col-md-2 control-label'> Ending Kms</label>
		<div class='col-md-10'>
			<input class='form-control' type='number' formControlName='EndingKms' (change)='calcKms()'/>
		</div>
	</div>

	<div class='row mt-2'>
		<label class='col-md-2 control-label'>Total</label>
			<div class='col-md-10'>
				<input class='form-control' type='number' [readonly]='true' formControlName='TotalKms' />
			</div>
		</div>
	
			
			<div class='row mt-2'>
			<label class='col-md-2 control-label'> Vehicle</label>
				<div class='col-md-10'>
					<select  formControlName='Vehicle' class="form-control" >
                        <option *ngFor="let item of applicationSettings.vehicles" [value]="(item)">{{item}}</option>
					</select>
					<!-- <input class='form-control' type='text' formControlName='Vehicle' /> -->
				</div>
			</div>
			</div>
<!-- End Column 1 -->
</div>

<!-- End Column 1-->
<!-- Column 2 -->
<div class='col-md-6'>
<div class='row'>
<!-- Column 2-->
<div class='row mt-2'>
	<label class='col-md-2 control-label'> Notes</label>
		<div class='col-md-10'>
			<textarea class='form-control' type='text' rows="5" formControlName='Notes'></textarea>

		</div>
	</div>
<!-- End Column 2 -->
</div>
</div>
<!-- End Column 2-->
				<div *ngIf='worklog && worklog.Id' class='row'>
					<div class='col-md-12'>
						<app-worklog-files [worklogId]='worklog.Id'></app-worklog-files>
					</div>
				</div>
				<div class='row' style='padding: 15px;'>
					<div class='col-md-12'>
						<span>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='saveWorklog(false, false)'
								style='width: 110px; margin-right:10px'
								[disabled]='!worklogForm.valid'>
								<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
							</button>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='saveWorklog(true, false)'
								style='width: 150px; margin-right:10px'
								[disabled]='!worklogForm.valid'>
								<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
							</button>
						</span>
						<span  class='pull-right remove-float-sml'>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='cancel()'
								style='width: 150px; margin-right:10px'
								[disabled]='!worklogForm.valid'>
								Cancel &nbsp;
							</button>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='deleteWorklog()'
								style='width: 150px; margin-right:10px'
								[disabled]='!worklogForm.valid'>
								<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
							</button>
						</span>
					</div>
				</div>
				<div class='row'>
					<div class='col-md-12'>
						<span>Updated By: <label *ngIf='worklog'>{{worklog.UpdatedBy}}</label></span>
						<span> Time: <label *ngIf='worklog'>{{worklog.UpdatedDate}}</label></span>
					</div>
				</div>


			</div>

			</fieldset>
		</form>
	</div>
	</div>
</div>