import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControlName, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ITest } from '../test-model/test';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';
import { Subscription } from 'rxjs/Subscription';
import { TestService } from '../test-service';
import { ITests } from '../Test-model/Tests';
import { ITestSearch } from '../Test-model/TestSearch';

import { UsersearchService } from '../../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../../admin/usersearches/usersearch-model/usersearch';
import { LessonService } from '../../lessons/lesson-service';

@Component({
	templateUrl: './test-edit.component.html',
	styleUrls: ['test-edit.component.css']
})

export class TestEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit Tests';
	errorMessage: string;
	testForm: FormGroup;
	test: ITest;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public TestSearch: ITestSearch;
	public ListData: ITests;
	public showNextRecordButton: boolean = true;
	public progressStatus=false;
	public lessonsList;
	public progressLessonsStatus=false;

	constructor(private fb: FormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private testService: TestService
		, private lessonService: LessonService
		) {
	}

	ngOnInit(): void {
		this.testForm = this.fb.group({
			Id: '',
			LessonsId: '',
			Employee: ['',[Validators.maxLength(250)]],
			ScoreNumerator: '',
			ScoreDenominator: '',
			Notes: [''],
			LessonStartDate: '',
			LessonEndDate: '',
			QuizStartDate: '',
			QuizEndDate: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});
		this.getLessons();
		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getTest(id);
				this.progressStatus = true;
			}
		);
	}

	public getSelectedRecordSet():void{
		this.testService.getRecordSet(this.TestSearch)
			.subscribe((obj: ITests) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: ITests){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.test.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

		this.progressStatus = false;

	}

	saveTest(returnToList: boolean, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

		if (this.testForm.valid) {
			const obj = Object.assign({ }, this.test, this.testForm.value);
			this.testService.saveTest(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord,addNewRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		if (returnToList) {
			this.testForm.reset();
			this.router.navigate(['/training/tests']);
		} 
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
		else if (addNewRecord)
		{
			this.router.navigate(['/test-details', 0]);
			this.testForm.reset();
			let obj = this.testService.initializeTest();
			this.onTestRetrieved(obj);
		}
			// when the record id is zero then it is a new record.
		else if (data !== null && this.test.Id === 0) {
			this.router.navigate(['/training/test-details', data.Id]);
		} 
		else if (this.test.Id != 0){
			this.getTest(this.test.Id);
		}

		this.buttonPushed = false;
		this.progressStatus = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.test.Id)
				record = count;
		});

		this.getTest(this.ListData.data[record].Id)
	}

	getTest(id: number): void {
		this.testService.getTest(id)
			.subscribe((obj: ITest) => this.onTestRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onTestRetrieved(test: ITest): void {
		if (this.testForm) {
			this.testForm.reset();
		}
		this.test = test;
		if (this.test.Id === 0) {
			this.page = 'Add Tests';
		} else {
			this.page = 'Edit Tests';
		}

		this.testForm.patchValue({
			Id: this.test.Id,
			LessonsId: this.test.LessonsId,
			Employee: this.test.Employee,
			ScoreNumerator: this.test.ScoreNumerator,
			ScoreDenominator: this.test.ScoreDenominator,
			Notes: this.test.Notes,
			LessonStartDate: this.test.LessonStartDate,
			LessonEndDate: this.test.LessonEndDate,
			QuizStartDate: this.test.QuizStartDate,
			QuizEndDate: this.test.QuizEndDate,
			CreatedDate: this.test.CreatedDate,
			CreatedBy: this.test.CreatedBy,
			UpdatedDate: this.test.UpdatedDate,
			UpdatedBy: this.test.UpdatedBy,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteTest(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.test.LessonsId}?`)) {
			this.testService.deleteTest(this.test.Id)
				.subscribe(() => this.onSaveComplete(true, null, false,false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/training/tests']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Tests List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.TestSearch = this.testService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'LessonsId')
				this.TestSearch.LessonsId = i.Value;

			if (i.Item == 'Employee')
				this.TestSearch.Employee = i.Value;

			if (i.Item == 'ScoreNumerator')
				this.TestSearch.ScoreNumerator = i.Value;

			if (i.Item == 'ScoreDenominator')
				this.TestSearch.ScoreDenominator = i.Value;

			if (i.Item == 'Notes')
				this.TestSearch.Notes = i.Value;

			if (i.Item == 'LessonStartDate')
				this.TestSearch.LessonStartDate = i.Value;

			if (i.Item == 'LessonEndDate')
				this.TestSearch.LessonEndDate = i.Value;

			if (i.Item == 'QuizStartDate')
				this.TestSearch.QuizStartDate = i.Value;

			if (i.Item == 'QuizEndDate')
				this.TestSearch.QuizEndDate = i.Value;

			if (i.Item == 'orderByString')
				this.TestSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.TestSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}

	getLessons() {
		this.lessonService.getAll().subscribe(response => {
			this.lessonsList = response;
			this.progressLessonsStatus = false;
		},(error: any) => {
			this.progressLessonsStatus = true;
			this.errorMessage = <any> error;
		});
	}
}
