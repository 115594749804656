
<div class="log-in-area">
	<div class="login-form-background">
	<div class="login-form ml-auto mr-auto">
	
		<div *ngIf="!passwordflag && !auth.loggedIn()">
		<div class="form-title">
			Sign In
		</div>
	
		<form class="form-horizontal" (ngSubmit)="onLogin()" [formGroup]="loginForm" *ngIf="!auth.loggedIn()">
		<div class="form-group">
		<br>
		<br>
		<label class="main-label d-block lh-1 text-black"> Username </label>
		<mat-form-field appearance="fill">
			<mat-label>Enter username</mat-label>
			<input type="text" required matInput formControlName='Email'>
			<mat-error *ngIf="loginForm.controls['Email'].invalid && loginForm.controls['Email'].errors.required">Username is <strong>required</strong></mat-error>
		</mat-form-field>  
		</div>
		<br>
		<div class="form-group">
			<label class="main-label d-block lh-1 text-black"> Password </label>
			<mat-form-field appearance="fill">
				<mat-label>Enter your password</mat-label>
				<input [type]="hide ? 'password' : 'text'" required matInput formControlName='Password'>
				<mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
				<mat-error *ngIf="loginForm.controls['Password'].invalid && loginForm.controls['Password'].errors.required">Password is <strong>required</strong></mat-error>
			  </mat-form-field>  
			</div>
	
			<div class="d-flex justify-content-between align-items-center">
				<a (click)="resetPassword()" class="d-inline-block">Forgot Password?</a>
				 </div>
	
			<button [disabled]="!loginForm.valid" type="submit" mat-flat-button>Sign In</button>
	
			<div class='has-error' *ngIf='errorMessage'>
				{{ errorMessage }}
			</div>
		</form>
	</div>
	
	
	<div *ngIf="passwordflag && !auth.loggedIn()">
		<div class="form-title">
			Forgot Your Password?
		</div>
		<form class='form-horizontal' novalidate [formGroup]='resetPasswordForm'>
			<div class="form-group">
				<br>
				<br>
				<label class="main-label d-block lh-1 text-black"> Email </label>
				<mat-form-field appearance="fill">
					<mat-label>Enter email</mat-label>
					<input type="email" required matInput formControlName='userEmail'>
					@if (resetPasswordForm.controls['userEmail'].hasError('pattern') && !resetPasswordForm.controls['userEmail'].hasError('required')) {
						<mat-error>Please enter a valid email address</mat-error>
					  }
					  @if (resetPasswordForm.controls['userEmail'].hasError('required')) {
						<mat-error>Email is <strong>required</strong></mat-error>
					  }
				</mat-form-field>  
			</div>
			<button [disabled]="!resetPasswordForm.valid" (click)="sendResetEmail()" mat-flat-button>Send Email</button>
			<div class='has-error' *ngIf='errorMessage'>
				{{ errorMessage }}
			</div>
			<div class="back-to-login-in">
			<a mat-button (click)="goToLogin()"><mat-icon>keyboard_arrow_left</mat-icon>Back to Login</a>
			</div>
		</form>
	</div>
	
	<div class="row" *ngIf="auth.loggedIn()">
		<div class="form-title">
			You are already logged in
		</div>
		<div class="col-md-12">
			<div class="alert alert-warning">Logged in user: {{auth.getUsername()}}</div>
		</div>
	</div>
	</div>
	</div>
	</div>
	<app-loading *ngIf="progressStatus"></app-loading> 
	
	
	