<div class="card">
<div class='row'>
    <div class='col-md-4'> 
        <select style="margin-top:10px;"  class="form-control" [(ngModel)]='AssignedUser' >
            <option *ngFor="let item of applicationSettings.employees" [value]="(item)">{{item}}</option>
        </select>
    </div>
    <div class='col-md-4'> 
        <input [disabled]="true" class='form-control' style="margin-top:10px;" type='date' [(ngModel)]='WorkDate' (ngModelChange)="dateChanged()" />
    </div>
</div>

    <div *ngFor="let d of days">              
        <table class="table">
            <thead>
                <tr>
                    <th scope="col" width="140px">App</th>
                    <th scope="col" width="70px"></th>
                    <th scope="col" width="120px">Type</th>
                    <th scope="col" width="300px">Name</th>
                    <th scope="col" >Description</th>
                    <th scope="col" width="120px">Starting KMS</th>
                    <th scope="col" width="120px">Ending KMS</th>
                    <th scope="col" width="50px">KMS</th>
                    
                    <th scope="col" width="40px">Bill</th>
                    <th scope="col" width="40px">Non</th>
                    <th scope="col" width="40px">Person</th>
                </tr>
            </thead>
            <tbody >
                <tr *ngFor="let l of d.DailyLog">
                    <td>
                        <a target="_blank" style='min-width: 50px;' [routerLink]='["/worktask-details", l.WorkTaskId]'>
                            {{l.App}}
                        </a>
                    </td>
                    <td>
                        <a target="_blank" style='min-width: 50px;' [routerLink]='["/worklog-details", l.WorkLogId, l.WorkTaskId]'>
                            Item
                        </a>
                    </td>
                    <td scope="col">{{l.Type}}</td>
                    <td scope="col">{{l.Name}}</td>
                    <td scope="col">{{l.Description}}</td>
                    <td scope="col">{{l.StartingKms}}</td>
                    <td scope="col">{{l.EndingKms}}</td>
                    <td scope="col">{{l.totalKMS}}</td>
                    <td scope="col">{{l.BillableHours}}</td>
                    <td scope="col">{{l.NonBillableHours}}</td>
                    <td scope="col">{{l.Person}}</td>
                </tr>
            </tbody>
            </table>
            <table width="100%">
                <td width="80%"></td>
                <td width="15%"><h2>Total:  {{d.Total}}</h2></td>
                <td width="5%"></td>
            </table>
            <!-- <div><br/><br/></div> -->
    </div>
</div>  
    


