
<div class='card animaPlaceholder'>
	<div class='card-heading'>
	<div class='row'>
		<div class='col-md-2'>
			{{pageTitle}}
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml text-right'>
			<button class='btn btn-light btn-sm' [routerLink]="['/customercontact-details/0']" style='width:auto;' [disabled]="progressStatus">Add New Customer Contact</button>
		</div>
		<div class='col-md-1 col-xs-6 div-list-sml text-right'>
		<button class='btn btn-primary btn-sm' style='width: auto;' (click)='showHideSearchClick()' [disabled]="progressStatus">{{SearchText}}</button>
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml text-right'>
			<button class='btn btn-light btn-sm' style='width: 100px' (click)='toExcel()' [disabled]="progressStatus">To Excel</button>
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml text-right'>
			<label>Rows</label>&nbsp;&nbsp;
			<select [(ngModel)]='pageSize' style='width: 100px; color: black;' (change)='pageSizeValueChanged()'>
				<option *ngFor='let item of applicationSettings.pageSizes'>{{item}}</option>
			</select>
		</div>
		<div class='col-md-1 col-xs-12 div-list-sml text-right'>
			<button class='btn btn-primary btn-sm' style='width: auto;' (click)='help()'>how-to</button>
		</div>
	</div>
	</div>

	<div class='card-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='excelFile'>
					<button class='btn btn-primary btn-sm' (click)='hideFileLink()'>hide link</button>
					<span>excel File:<a href = "javascript:void(0)"(click) = "openExcelFile()" > {{ excelFile}}</a></span>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<div class='row filters' *ngIf='showHideSearch'>
		<div class='col-md-9'>
			<div class='row'>
				<div class='col-md-4'> 
					<label class='inline-label'> Customer Name</label>
					   <input class='form-control' type='text' [formControl]='CustomerName' matInput
						  [matAutocomplete]="autoCustomerId" />
					   <mat-autocomplete autoActiveFirstOption #autoCustomerId="matAutocomplete">
					   <mat-option *ngFor = "let item of autoCustomerName" [value] = "item.CustomerName"
						(onSelectionChange) = "this.CustomerId=item.Id" > 
						{{item.CustomerName}}
					   </mat-option>
					   </mat-autocomplete>
					   <input type = "hidden" [(ngModel)]="CustomerId" />
				  </div>
			<div class='col-md-4'>
				<label class='inline-label'> Type</label>
				<input type='text' class='form-control block-element' placeholder=' Type' [(ngModel)]='Type' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Contact Date</label>
				<input type='date' class='form-control block-element' [(ngModel)]='ContactDate' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Future Reminder Date</label>
				<input type='date' class='form-control block-element' [(ngModel)]='FutureReminderDate' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Notes</label>
				<input type='text' class='form-control block-element' placeholder=' Notes' [(ngModel)]='Notes' />
			</div>
			</div>
		</div>
		<div class='col-md-3'>
			<div class='row'>
				<div class='col-md-12 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm' style='width: 110px' (click)='searchClick()' [disabled]="progressStatus">Search</button>
				</div>
				<div class='col-md-12 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm' style='width: 110px' (click)='searchAndHideClick()' [disabled]="progressStatus">Search and Hide</button>
				</div>
				<div class='col-md-12 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm' style='width: 110px' (click)='searchClearClick()' [disabled]="progressStatus">Clear-Search</button>
				</div>
			</div>
		</div>
		</div>
	</div>
</div>

<div class='table-container'>
<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
	<ng-container matColumnDef='Id'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
		<td mat-cell *matCellDef='let e'>
			<a class='btn btn-light' style='min-width: 80px;' [routerLink]="['/customercontact-details', e.Id]"  >{{e.Id}}</a>
		</td>
	</ng-container>
	<ng-container matColumnDef='CustomerName'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col6'> Customer Name</th>
		<td mat-cell *matCellDef = 'let e' class='col6'>{{e.CustomerName}}</td>
		</ng-container>
	<ng-container matColumnDef='Type'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Type</th>
		<td mat-cell *matCellDef='let e' class='col3' >{{e.Type}}</td>
	</ng-container>
	<ng-container matColumnDef='ContactDate'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> Contact Date</th>
		<td mat-cell *matCellDef='let e' class='col4' >{{e.ContactDate}}</td>
	</ng-container>
	<ng-container matColumnDef='FutureReminderDate'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'> Future Reminder Date</th>
		<td mat-cell *matCellDef='let e' class='col5' >{{e.FutureReminderDate}}</td>
	</ng-container>
	<ng-container matColumnDef='Notes'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col6'> Notes</th>
		<td mat-cell *matCellDef='let e' class='col6' >{{e.Notes}}</td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
	<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
</div>
<mat-paginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>
<div>
<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>