
<div class='panel panel-primary animaPlaceholder' *ngIf = 'employeelesson'>
	<div class='panel-heading'>
		<span *ngIf = 'employeelesson' >{{ page }} :{{ employeelesson.Id }}</span>
		<span class= 'pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class= 'panel-body'>
		<div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<form class='form-horizontal' novalidate [formGroup]='employeelessonForm'>
			<fieldset>
			<div class='panel-body'>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Lesson Name</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='LessonName' />
							<span *ngIf = "employeelessonForm.controls['LessonName'].invalid  && employeelessonForm.controls['LessonName'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Courses Id</label>
						<div class='col-md-10'>
							<input class='form-control' type='number' formControlName='CoursesId' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Lesson Id</label>
						<div class='col-md-10'>
							<input class='form-control' type='number' formControlName='LessonId' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Content Order</label>
						<div class='col-md-10'>
							<input class='form-control' type='number' formControlName='ContentOrder' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Content Name</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='ContentName' />
							<span *ngIf = "employeelessonForm.controls['ContentName'].invalid  && employeelessonForm.controls['ContentName'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Content Description</label>
						<div class='col-md-10'>
							<span [innerHTML]='ContentDescriptionHtml'></span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Content Reviewed</label>
						<div class='col-md-10'>
							<input class='form-control' type='date' formControlName='ContentReviewed' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Content Status</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='ContentStatus' />
							<span *ngIf = "employeelessonForm.controls['ContentStatus'].invalid  && employeelessonForm.controls['ContentStatus'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Content Notes</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='ContentNotes' />
							<span *ngIf = "employeelessonForm.controls['ContentNotes'].invalid  && employeelessonForm.controls['ContentNotes'].errors.maxlength" class="text-danger">
									Only 0 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Lesson User</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='LessonUser' />
							<span *ngIf = "employeelessonForm.controls['LessonUser'].invalid  && employeelessonForm.controls['LessonUser'].errors.maxlength" class="text-danger">
									Only 256 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Employee</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='Employee' />
							<span *ngIf = "employeelessonForm.controls['Employee'].invalid  && employeelessonForm.controls['Employee'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
			</div>
			</fieldset>
		</form>

			<div class='row' style='padding: 15px;'>
				<div class= 'col-md-12'>
						<span  class='pull-right remove-float-sml'>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='cancel()'
								style='width: 150px; margin-right:10px'
								[disabled]='!employeelessonForm.valid || progressStatus'>
								Cancel &nbsp;
							</button>
						</span>
					</div>
				</div>

				<div class='row'>
					<div class='col-md-12'>
						<span> Updated By: <label *ngIf='employeelesson'>{{employeelesson.updatedby}}</label></span>
						<span> Time: <label *ngIf='employeelesson'>{{employeelesson.updateddate}}</label></span>
					</div>
				</div>
	</div>
</div>
<div>
<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>