import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControlName, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';
import { Subscription } from 'rxjs/Subscription';
import { UsersearchService } from '../usersearch-service';
import { IUsersearches } from '../usersearch-model/usersearchs';
import { IUsersearch } from '../usersearch-model/usersearch';
import { IUsersearcheSearch } from '../usersearch-model/usersearcheSearch';

@Component({
	templateUrl: './usersearch-edit.component.html',
	styleUrls: ['usersearch-edit.component.css']
})

export class UsersearchEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit UserSearches';
	errorMessage: string;
	usersearcheForm: FormGroup;
	usersearche: IUsersearch;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public UsersearcheSearch: IUsersearcheSearch;
	public ListData: IUsersearches;
	public showNextRecordButton: boolean = true;
	public progressStatus=false;

	constructor(private fb: FormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private usersearcheService: UsersearchService,
		) {
	}

	ngOnInit(): void {
		this.usersearcheForm = this.fb.group({
			Id: '',
			UserId: ['',[Validators.maxLength(250)]],
			Form: ['',[Validators.maxLength(250)]],
			Item: ['',[Validators.maxLength(250)]],
			Value: ['',[Validators.maxLength(250)]],
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getUsersearche(id);
				this.progressStatus = true;
			}
		);
	}

	public getSelectedRecordSet():void{
		this.usersearcheService.getRecordSet(this.UsersearcheSearch)
			.subscribe((obj: IUsersearches) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: IUsersearches){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.usersearche.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

		this.progressStatus = false;

	}

	saveUsersearche(returnToList: boolean, advanceToNextRecord: boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

		if (this.usersearcheForm.valid) {
			const obj = Object.assign({ }, this.usersearche, this.usersearcheForm.value);
			this.usersearcheService.saveUsersearch(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean): void {
		if (returnToList) {
			this.usersearcheForm.reset();
			this.router.navigate(['/usersearches']);
		} 
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
			// when the record id is zero then it is a new record.
		else if (data !== null && this.usersearche.Id === 0) {
			this.router.navigate(['/usersearche-details', data.Id]);
		} 
		else if (this.usersearche.Id != 0){
			this.getUsersearche(this.usersearche.Id);
		}

		this.buttonPushed = false;
		this.progressStatus = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.usersearche.Id)
				record = count;
		});

		this.getUsersearche(this.ListData.data[record].Id)
	}

	getUsersearche(id: number): void {
		this.usersearcheService.getUsersearch(id)
			.subscribe((obj: IUsersearch) => this.onUsersearcheRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onUsersearcheRetrieved(usersearche: IUsersearch): void {
		if (this.usersearcheForm) {
			this.usersearcheForm.reset();
		}
		this.usersearche = usersearche;
		if (this.usersearche.Id === 0) {
			this.page = 'Add UserSearches';
		} else {
			this.page = 'Edit UserSearches';
		}

		this.usersearcheForm.patchValue({
			Id: this.usersearche.Id,
			UserId: this.usersearche.UserId,
			Form: this.usersearche.Form,
			Item: this.usersearche.Item,
			Value: this.usersearche.Value,
			CreatedDate: this.usersearche.CreatedDate,
			CreatedBy: this.usersearche.CreatedBy,
			UpdatedDate: this.usersearche.UpdatedDate,
			UpdatedBy: this.usersearche.UpdatedBy,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteUsersearche(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.usersearche.UserId}?`)) {
			this.usersearcheService.deleteUsersearch(this.usersearche.Id)
				.subscribe(() => this.onSaveComplete(true, null, false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/usersearches']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('UserSearches List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.UsersearcheSearch = this.usersearcheService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'UserId')
				this.UsersearcheSearch.UserId = i.Value;

			if (i.Item == 'Form')
				this.UsersearcheSearch.Form = i.Value;

			if (i.Item == 'Item')
				this.UsersearcheSearch.Item = i.Value;

			if (i.Item == 'Value')
				this.UsersearcheSearch.Value = i.Value;

			if (i.Item == 'orderByString')
				this.UsersearcheSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.UsersearcheSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
}
