<div class="card">
    <div class="card-heading">
        <h3>Work Task Logs Report</h3>
    </div>
    <div class="card-body">
        <div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
<div class="row">
    <div class="col-md-3">
        <label>Application</label> 
        <select  class="form-control" [(ngModel)]="application" >
            <option *ngFor="let item of applicationSettings.apps" [value]="(item)">{{item}}</option>
        </select>
    </div>
    <div class="col-md-3">
       <label> Start Date </label>
        <input type="date" class="form-control" [(ngModel)]="startDate" />
    </div>
    <div class="col-md-3">
       <label>End Date </label> 
        <input type="date" class="form-control" [(ngModel)]="endDate" />
    </div>
    <div class="col-md-3"> 
         <button class="btn btn-primary" (click)="getReport()" [disabled]="progressStatus">Show Report</button>
     </div>
</div>
        
    </div>
</div>
<div>
    <mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
    </div>