import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor() { }

  facebookUrl = 'https://www.facebook.com/EdmontonIntegrationAndRobotics/';
  linkedinUrl = 'https://www.linkedin.com/in/craig-duguid-19a60b47/';
  ngOnInit() {
  }
}
