
<div class='panel panel-primary animaPlaceholder' *ngIf = 'lesson'>
	<div class='panel-heading'>
		<span *ngIf = 'lesson' >{{ page }} :{{ lesson.Id }}</span>
		<span class= 'pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class= 'panel-body'>
		<div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<form class='form-horizontal' novalidate [formGroup]='lessonForm'>
			<fieldset>
			<div class='panel-body'>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Course</label>
						<div class='col-md-10'>
							<mat-select class="form-control"formControlName='CoursesId'>
								@for (item of coursesList; track item) {
								  <mat-option [value]="item.Id">{{item.Name}}</mat-option>
								}
							</mat-select>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Name</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='Name' />
							<span *ngIf = "lessonForm.controls['Name'].invalid  && lessonForm.controls['Name'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Description</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='Description' />
							<span *ngIf = "lessonForm.controls['Description'].invalid  && lessonForm.controls['Description'].errors.maxlength" class="text-danger">
									Only 0 characters are allowed.
							</span>
						</div>
					</div>
			</div>
			</fieldset>
		</form>

			<div class='row' style='padding: 15px;'>
				<div class= 'col-md-12'>
					<span>
						<button class='btn btn-primary btn-edit-sml'
							[disabled]='buttonPushed'
								(click)='saveLesson(false, false,false)'
									style='width: 110px; margin-right:10px'
									[disabled]='!lessonForm.valid || progressStatus||progressCoursesStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
								</button>
								<button class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveLesson(true, false,false)'
									style='width: 150px; margin-right:10px'
									[disabled]='!lessonForm.valid || progressStatus||progressCoursesStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
								</button>
								<button *ngIf='showNextRecordButton && lesson.Id ' class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveLesson(false, true,false)'
									style='width: 190px; margin-right:10px'
									[disabled]='!lessonForm.valid || progressStatus||progressCoursesStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
								</button>
								<button  class='btn btn-primary btn-edit-sml' *ngIf="!lesson.Id"  
									  [disabled]='buttonPushed'
									  (click)='saveLesson(false, false,true)'
									  style ='width: 200px; margin-right:10px'
									  [disabled]='!lessonForm.valid || progressStatus||progressCoursesStatus'>
									   <span class='glyphicon glyphicon-floppy-save'></span> Save & Add New Record &nbsp;
								  </button>
							</span>

						<span  class='pull-right remove-float-sml'>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='cancel()'
								style='width: 150px; margin-right:10px'
								[disabled]='!lessonForm.valid || progressStatus||progressCoursesStatus'>
								Cancel &nbsp;
							</button>
							<button class='btn btn-primary btn-edit-sml' *ngIf="lesson.Id" 
								[disabled]='buttonPushed'
								(click)='deleteLesson()'
								style='width: 150px; margin-right:10px'
								[disabled]='!lessonForm.valid || progressStatus||progressCoursesStatus'>
								<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
							</button>
						</span>
					</div>
				</div>

				<div class='row'>
					<div class='col-md-12'>
						<span> Updated By: <label *ngIf='lesson'>{{lesson.UpdatedBy}}</label></span>
						<span> Time: <label *ngIf='lesson'>{{lesson.UpdatedDate}}</label></span>
					</div>
				</div>
				<br>
				<br>
				<div class='row'>
					<div class='col-md-12'>
				<div *ngIf="lesson">
					<div *ngIf="lesson.Id>0">
						<content-list coursesId={{lesson.CoursesId}}  lessonsId={{lesson.Id}}></content-list>
						<br>
						<br>
					</div>

					 <div *ngIf="lesson.Id>0">
						<question-list coursesId={{lesson.CoursesId}} lessonsId={{lesson.Id}}></question-list>
					</div> 
				</div>
			</div>
		</div>
	</div>
</div>
<div>
<mat-spinner color="primary" class="spinner" *ngIf="progressStatus||progressCoursesStatus"></mat-spinner>
</div>