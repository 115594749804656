
<div class='panel panel-primary animaPlaceholder' *ngIf = 'journal'>
	<div class='panel-heading'>
		<span *ngIf = 'journal' >{{ page }} :{{ journal.Id }}</span>
		<span class= 'pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class= 'panel-body'>
		<div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<form class='form-horizontal' novalidate [formGroup]='journalForm'>
			<fieldset>
			<div class='panel-body'>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Date Note</label>
						<div class='col-md-10'>
							<input class='form-control' type='date' formControlName='DateNote' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Day Notes</label>
						<div class='col-md-10'>
							<ckeditor formControlName="DayNotes" [editor]="Editor"
								[config]="{ ckfinder: { uploadUrl: this.settings.retrieveUrl() + 'api/Journals/uploadimage' } }" data=""></ckeditor>
						</div>
					</div>
			</div>
			</fieldset>
		</form>

			<div class='row' style='padding: 15px;'>
				<div class= 'col-md-12'>
					<span>
						<button class='btn btn-primary btn-edit-sml'
							[disabled]='buttonPushed'
								(click)='saveJournal(false, false,false)'
									style='width: 110px; margin-right:10px'
									[disabled]='!journalForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
								</button>
								<button class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveJournal(true, false,false)'
									style='width: 150px; margin-right:10px'
									[disabled]='!journalForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
								</button>
								<button *ngIf='showNextRecordButton && journal.Id ' class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveJournal(false, true,false)'
									style='width: 190px; margin-right:10px'
									[disabled]='!journalForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
								</button>
								<button  class='btn btn-primary btn-edit-sml' *ngIf="!journal.Id"  
									  [disabled]='buttonPushed'
									  (click)='saveJournal(false, false,true)'
									  style ='width: 200px; margin-right:10px'
									  [disabled]='!journalForm.valid || progressStatus'>
									   <span class='glyphicon glyphicon-floppy-save'></span> Save & Add New Record &nbsp;
								  </button>
							</span>

						<span  class='pull-right remove-float-sml'>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='cancel()'
								style='width: 150px; margin-right:10px'
								[disabled]='!journalForm.valid || progressStatus'>
								Cancel &nbsp;
							</button>
							<button class='btn btn-primary btn-edit-sml' *ngIf="journal.Id" 
								[disabled]='buttonPushed'
								(click)='deleteJournal()'
								style='width: 150px; margin-right:10px'
								[disabled]='!journalForm.valid || progressStatus'>
								<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
							</button>
						</span>
					</div>
				</div>

				<div class='row'>
					<div class='col-md-12'>
						<span> Updated By: <label *ngIf='journal'>{{journal.UpdatedBy}}</label></span>
						<span> Time: <label *ngIf='journal'>{{journal.UpdatedDate}}</label></span>
					</div>
				</div>
	</div>
</div>
<div>
<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>