import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatPaginator as MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import {merge} from 'rxjs';
import { Router } from '@angular/router';

import { ApplicationSettings } from '../../shared/application-settings';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';

import { CustomercontactService } from '../customercontact-service';
import { ICustomercontactSearch } from '../customercontact-model/customercontactsearch';
import { ICustomercontacts } from '../customercontact-model/customercontacts';
import { UntypedFormControl }  from '@angular/forms';
 import { debounceTime, switchMap }  from 'rxjs/operators';
 import { CustomerService } from '../../customers/customer-service';

@Component({
	templateUrl: './customercontact-list.component.html',
	styleUrls: ['./customercontact-list.component.css']
})

export class CustomercontactListComponent implements OnInit, AfterViewInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	displayedColumns: string[] = ['Id', 'CustomerName', 'Type', 'ContactDate', 'FutureReminderDate', 'Notes'];
	isLoadingResults = true;

	public UserSearches: IUsersearch[];
	public pageSize = '10';
	public skip = 0;
	public recordTotal: number = 0;

	public showHideSearch: boolean = false;
	public SearchText: string = 'Show Filters';

	public CustomerId = '';
	public Type = '';
	public ContactDate = '';
	public FutureReminderDate = '';
	public Notes = '';

	public pageTitle = 'Customer Contacts List';
	public errorMessage = '';
	public dataSource: any;
	public excelFile;
	public progressStatus=false;
	autoCustomerName:any;
	CustomerName= new UntypedFormControl(); 

	constructor(private userSearchService: UsersearchService
		, public applicationSettings: ApplicationSettings
		 , private router: Router
		, private _appService: CustomercontactService
		, public customerService:CustomerService) {
	}

	ngOnInit(): void {
		this.progressStatus = true;
		this.skip = 0;
		let x  = +localStorage.getItem('rows');
		if(x>0) { this.pageSize = x.toString(); }
		else { this.pageSize = '10';}
		if (localStorage.getItem('showHideSearch') === 'true')
		{
			   this.showHideSearch = true;
			   this.SearchText = 'Hide Filters';
		}
		else
		{
			   this.showHideSearch = false;
			   this.SearchText = 'Show Filters';
		 }
		 this.getAllCustomers(); 
	}

	ngAfterViewInit(): void {
		this.userSearchService.getUserSearchesByForm(this.pageTitle)
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.paginator.pageIndex = 0;

		this.UserSearches.forEach(i => {
			if(i.Item=='CustomerId')
				this.CustomerId = i.Value;

			if(i.Item=='Type')
				this.Type = i.Value;

			if(i.Item=='ContactDate')
				this.ContactDate = i.Value;

			if(i.Item=='FutureReminderDate')
				this.FutureReminderDate = i.Value;

			if(i.Item=='Notes')
				this.Notes = i.Value;

			if(i.Item=='orderByString') {
				if (this.applicationSettings.getOrderByDirection(i.Value)==='asc')
					this.sort.direction = 'asc';
				else
					this.sort.direction = 'desc';

				this.sort.active = this.applicationSettings.getOrderByName(i.Value);
			}

			if (i.Item == 'Skip')
				this.skip = i.Value;

		});

		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
		merge(this.sort.sortChange, this.paginator.page)
			.subscribe(()=> this.getData());

		this.getData();
	}

	pageSizeValueChanged(): void {
		this.applicationSettings.setPageSize(this.pageSize.toString());
		this.paginator.pageIndex = 0;
		this.getData();
	}

	getData(): any {
	 this.progressStatus=true; 
	 this.dataSource = null; 
	 this.recordTotal = 0; 
		let search: ICustomercontactSearch = this._appService.getFreshSearch();

		search.CustomerId = this.CustomerId;
		search.Type = this.Type;
		search.ContactDate = this.ContactDate;
		search.FutureReminderDate = this.FutureReminderDate;
		search.Notes = this.Notes;
		search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
		search.take = parseInt(this.pageSize);
		search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

		this._appService.getRecordSet(search).subscribe((obj: ICustomercontacts) => this.onSelectedRecordSetReceived(obj),
		(error: any) => {
			this.errorMessage = <any> error;
		});
	}

	private onSelectedRecordSetReceived(obj: ICustomercontacts){
		this.dataSource = new MatTableDataSource(obj.data);
		this.recordTotal = obj.total;
		this.progressStatus = false;
	}

	searchClick(): void {
		this.skip = 0;
		this.paginator.pageIndex = 0;
		this.getData();
	}

	public searchClearClick(): void {
		this.CustomerId = '';
		this.Type = '';
		this.ContactDate = '';
		this.FutureReminderDate = '';
		this.Notes = '';
		this.skip = 0;
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.paginator.pageIndex = 0;
		this.getData();
	}

	resetError() : void {
		this.errorMessage = '';
		this.progressStatus=false;
	}
	showHideSearchClick() {
		 if (this.showHideSearch) 
		 {
			 this.showHideSearch = false; 
			localStorage.setItem('showHideSearch', 'false'); 
			this.SearchText = 'Show Filters'; 
		}
		else
		{
			 this.showHideSearch = true; 
			localStorage.setItem('showHideSearch', 'true'); 
			  this.SearchText = 'Hide Filters'; 
		}
	}
	searchAndHideClick() {
		 this.skip = 0;
		 this.paginator.pageIndex = 0;
		this.getData();
		 setTimeout(() => {
		     this.showHideSearchClick();
		 }, 500);
	 }
	public help()
	{
		   const url = this.router.serializeUrl(
		       this.router.createUrlTree([`/help-view/customercontacts`])
		);
		  window.open(url, '_blank');
	 }
	public toExcel(){
			let search: ICustomercontactSearch = this._appService.getFreshSearch();

			search.CustomerId = this.CustomerId;
			search.Type = this.Type;
			search.ContactDate = this.ContactDate;
			search.FutureReminderDate = this.FutureReminderDate;
			search.Notes = this.Notes;
			search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
			search.take = parseInt(this.pageSize);
			search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

			this._appService.toExcel(search).subscribe(
				retVal => {
					    this.excelFile = retVal;
				},
				 (error: any) => {
					 this.errorMessage = < any > error;
				});
	}
	public hideFileLink()
	{
	   this.excelFile = "";
	}
	public openExcelFile()
	{
	   window.open(`${ this.applicationSettings.retrieveUrl()}${ this.excelFile}`);
	}
	public getAllCustomers(){
		this.CustomerName.valueChanges
		 .pipe(
		 debounceTime(1000),
		 switchMap(value =>
		 this.customerService.getAllCustomers(value)
		 )
		 )
		 .subscribe(response => {
		 this.autoCustomerName = response
			   }); 
		}
}