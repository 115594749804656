import { Injectable } from '@angular/core';
import { Http, RequestOptions } from '@angular/http';


@Injectable()
export class ConfigurationService {
    public pageSizeList: pageSizeListItem[];

    constructor( public http: Http) {
        this.pageSizeList = [
            { name: '10', value: '10' },
            { name: '15', value: '15' },
            { name: '20', value: '20' },
            { name: '50', value: '50' },
            { name: '100', value: '100' }
        ];
      }
}

export interface pageSizeListItem {
  name: string,
  value: string
}
