import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { ApplicationSettings } from '../../shared/application-settings';
import { JwtHttpInterceptor } from '../../shared/jwtHttp.interceptor';

export function tokenGetter() {
	return localStorage.getItem('eiar_token');
}

@NgModule({
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: JwtHttpInterceptor, multi: true },
		AuthService,
		ApplicationSettings
	]
})
export class AuthorizationModule { }
