import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControlName } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IGreen } from '../green-model/green';
import { Subscription } from 'rxjs/Subscription';
import { GreenService } from '../green-service';
import { IGreens } from '../Green-model/Greens';
import { IGreenSearch } from '../Green-model/GreenSearch';

import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';

@Component({
	templateUrl: './green-edit.component.html',
	styleUrls: ['green-edit.component.css']
})

export class GreenEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
	public page = 'Edit Greens';
	errorMessage: string;
	greenForm: FormGroup;
	green: IGreen;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public GreenSearch: IGreenSearch;
	public ListData: IGreens;
	public showNextRecordButton: boolean = true;

	constructor(private fb: FormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private greenService: GreenService,
		) {
	}

	ngOnInit(): void {
		this.greenForm = this.fb.group({
			Id: '',
			Name: '',
			SeedSerialNumber: '',
			Description: '',
			PlantedDate: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});
		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getGreen(id);
			}
		);
	}

	public getSelectedRecordSet():void{
		this.greenService.getRecordSet(this.GreenSearch)
			.subscribe((obj: IGreens) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: IGreens){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.green.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

	}

	saveGreen(returnToList: boolean, advanceToNextRecord: boolean): void {
		this.buttonPushed = true;

		if (this.greenForm.valid) {
			const obj = Object.assign({ }, this.green, this.greenForm.value);
			this.greenService.saveGreen(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean): void {
		if (returnToList) {
			this.greenForm.reset();
			this.router.navigate(['/greens']);
		} else {
			// when the record id is zero then it is a new record.
			if (data !== null && this.green.Id === 0) {
				this.router.navigate(['/green-details', data.Id]);
			} else {
				if (advanceToNextRecord) {
					this.advanceToNextRecord();
				}
			}
		}

		this.buttonPushed = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.green.Id)
				record = count;
		});

		this.getGreen(this.ListData.data[record].Id)
	}

	getGreen(id: number): void {
		this.greenService.getGreen(id)
			.subscribe((obj: IGreen) => this.onGreenRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onGreenRetrieved(green: IGreen): void {
		if (this.greenForm) {
			this.greenForm.reset();
		}
		this.green = green;
		if (this.green.Id === 0) {
			this.page = 'Add Greens';
		} else {
			this.page = 'Edit Greens';
		}

		this.greenForm.patchValue({
			Id: this.green.Id,
			Name: this.green.Name,
			SeedSerialNumber: this.green.SeedSerialNumber,
			Description: this.green.Description,
			PlantedDate: this.green.PlantedDate,
			CreatedDate: this.green.CreatedDate,
			CreatedBy: this.green.CreatedBy,
			UpdatedDate: this.green.UpdatedDate,
			UpdatedBy: this.green.UpdatedBy,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteGreen(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.green.Name}?`)) {
			this.greenService.deleteGreen(this.green.Id)
				.subscribe(() => this.onSaveComplete(true, null, false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/greens']);
	}


	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Greens List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.GreenSearch = this.greenService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'Name')
				this.GreenSearch.Name = i.Value;

			if (i.Item == 'SeedSerialNumber')
				this.GreenSearch.SeedSerialNumber = i.Value;

			if (i.Item == 'Description')
				this.GreenSearch.Description = i.Value;

			if (i.Item == 'PlantedDate')
				this.GreenSearch.PlantedDate = i.Value;

			if (i.Item == 'orderByString')
				this.GreenSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.GreenSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}
}
