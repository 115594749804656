import { Component, OnInit, OnDestroy, ViewChildren, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControlName, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IMaterial } from '../material-model/material';
import { Subscription } from 'rxjs/Subscription';
import { MaterialService } from '../material-service';
import { IMaterials } from '../Material-model/Materials';
import { IMaterialSearch } from '../Material-model/MaterialSearch';
import { MaterialFileDto } from '../material-model/MaterialFileDto';
import { ApplicationSettings } from '../../shared/application-settings';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';

import { UploaderComponent,RemovingEventArgs } from '@syncfusion/ej2-angular-inputs';
import { FormValidator, FormValidatorModel } from '@syncfusion/ej2-inputs';
import { EmitType, detach } from '@syncfusion/ej2-base';

@Component({
	templateUrl: './material-edit.component.html',
	styleUrls: ['material-edit.component.css']
})

export class MaterialEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

@ViewChild(MatPaginator) paginator: MatPaginator;
displayedColumns: string[] =  ['Edit', 'FileName', 'FileDescription', 'SortOrder', 'IncludeInReport','Delete'];
	public page = 'Edit Materials';
	errorMessage: string;
	materialForm: UntypedFormGroup;
	material: IMaterial;
	private sub: Subscription;
	public filesForm: UntypedFormGroup
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public pageSize = '10';
	public UserSearches: IUsersearch[];
	public MaterialSearch: IMaterialSearch;
	public ListData: IMaterials;
	public showNextRecordButton: boolean = true;
	public progressStatus=true;

	public buttonName:any = 'Save File';
	public filename:any = '';
	public show:boolean = false;
	public showButtonName:any = 'Add New File';
	fileFormSubmitted: boolean = false;
	fileToEdit: MaterialFileDto;
	public dataSource: any;
	materialId: number;
	@ViewChild('inputFile') fileUp: ElementRef;
	@ViewChild('firstPaginator', { static: true}) firstPaginator: MatPaginator;
	@ViewChild('defaultupload')  public uploadObj: UploaderComponent;
	constructor(private fb: UntypedFormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, public applicationSettings: ApplicationSettings
		, private router: Router
		, private materialService: MaterialService,
		) {
	}

	
	ngOnInit(): void {
		let x  = +localStorage.getItem('rows');
		if(x>0) { this.pageSize = x.toString(); }
		else { this.pageSize = '10';}

		this.materialForm = this.fb.group({
			Id: '',
			Name: ['',[Validators.maxLength(250)]],
			Description: ['',[Validators.maxLength(4000)]],
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.filesForm = this.fb.group({
			fileUpload:['',Validators.required],
			fileDescription:[''],
			sortOrder:[''],
			includeReport:[''],
		})
		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.materialId = id;
				this.getMaterial(id);
				this.progressStatus = true;
			}
		);
	}

	public getSelectedRecordSet():void{
		this.materialService.getRecordSet(this.MaterialSearch)
			.subscribe((obj: IMaterials) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: IMaterials){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.material.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if ((this.record-obj.skip) < this.ListData.data.length){
			this.showNextRecordButton = true;
		}
		else{
			this.showNextRecordButton = false;
		}

		this.progressStatus = false;

	}

	saveMaterial(returnToList: boolean, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

		if (this.materialForm.valid) {
			const obj = Object.assign({ }, this.material, this.materialForm.value);
			this.materialService.saveMaterial(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord,addNewRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		if (returnToList) {
			this.materialForm.reset();
			this.router.navigate(['/materials']);
		} 
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
		else if(addNewRecord){
			this.router.navigate(['/material-details', 0]);
			this.materialForm.reset();
			let obj=this.materialService.initializeMaterial();
			this.onMaterialRetrieved(obj);
		}
			// when the record id is zero then it is a new record.
		else if (data !== null && this.material.Id === 0) {
			this.router.navigate(['/material-details', data.Id]);
		} 
		else if (this.material.Id != 0){
			this.getMaterial(this.material.Id);
		}

		this.buttonPushed = false;
		this.progressStatus = false;
	}

	advanceToNextRecord(): void {
		let count = 0;
		let record = 0;
		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.material.Id)
				record = count;
		});
		this.router.navigate(['/material-details', this.ListData.data[record].Id]);
	}

	getMaterial(id: number): void {
		this.materialService.getMaterial(id)
			.subscribe((obj: IMaterial) => this.onMaterialRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onMaterialRetrieved(material: IMaterial): void {
		if (this.materialForm) {
			this.materialForm.reset();
		}
		this.material = material;
		if (this.material.Id === 0) {
			this.page = 'Add Materials';
		} else {
			this.page = 'Edit Materials';
		}

		this.materialForm.patchValue({
			Id: this.material.Id,
			Name: this.material.Name,
			Description: this.material.Description,
			CreatedDate: this.material.CreatedDate,
			CreatedBy: this.material.CreatedBy,
			UpdatedDate: this.material.UpdatedDate,
			UpdatedBy: this.material.UpdatedBy,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteMaterial(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.material.Name}?`)) {
			this.materialService.deleteMaterial(this.material.Id)
				.subscribe(() => this.onSaveComplete(true, null, false,false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/materials']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Materials List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.MaterialSearch = this.materialService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'Name')
				this.MaterialSearch.Name = i.Value;

			if (i.Item == 'Description')
				this.MaterialSearch.Description = i.Value;

			if (i.Item == 'orderByString')
				this.MaterialSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.MaterialSearch.skip = i.Value;

		});
		this.MaterialSearch.take = parseInt(this.pageSize);
		this.getSelectedRecordSet();
		this.getFileMaterialData();
		
	}

	onFileSelected(event) {
		console.log('onFileSelected:');
		console.log(event);
		let inputElement: HTMLInputElement  = document.getElementById('upload') as HTMLInputElement;
        inputElement.value = event.filesData[0].name;
		if (event.filesData.length > 0) {
			console.log( event.filesData[0]);
		 this.filesForm.patchValue({
		 fileUpload: event.filesData[0].rawFile,
		 })
		 }
	}
	onSaveFile(){
		console.log('onSaveFile');

		this.fileFormSubmitted = true;

		if (this.filesForm.invalid)
		{
			console.log('onSaveFile::Form Invalid');
			return;
		}

		const fileForm = this.filesForm.value;
		const formData = new FormData();
		formData.append('RecordId', this.material.Id.toString());
		formData.append('FileDescription', fileForm.fileDescription || 0);
		formData.append('IncludeInReport', !!fileForm.includeReport ? '1' : '0');
		formData.append('SortOrder', fileForm.sortOrder || 0);
			formData.append('FileName', fileForm.fileUpload);


		if (this.fileToEdit && this.fileToEdit.Id)
		{
			formData.append('Id', this.fileToEdit.Id.toString());
			formData.append('GeneratedFileName', this.fileToEdit.GeneratedFileName);

			this.materialService.updateFileMaterial(formData).subscribe(file => {
				this.fileToEdit = null;
				this.clearForm();
				this.fileUp.nativeElement.value = '';
				this.getFileMaterialData();
			});
		}
		else
		{
			this.materialService.createFileMaterial(formData).subscribe(file => {
				this.clearForm();
				this.fileUp.nativeElement.value = '';
				this.getFileMaterialData()});
			}
		}
		editFileData(file){
			this.fileToEdit = file;
			
			this.filesForm.patchValue({
				fileUpload: this.fileToEdit.FileName,
				fileDescription: this.fileToEdit.FileDescription,
				sortOrder: this.fileToEdit.SortOrder,
				includeReport: this.fileToEdit.IncludeInReport === 1 ? true : false
			});
	
			console.log(this.fileToEdit);
			let inputElement1: HTMLInputElement  = document.getElementById('upload') as HTMLInputElement;
			inputElement1.value = this.fileToEdit.FileName;
	
			let inputElement2: HTMLInputElement  = document.getElementById('fileDescription') as HTMLInputElement;
			inputElement2.value = this.fileToEdit.FileDescription;
	
			let inputElement3: HTMLInputElement  = document.getElementById('sortOrder') as HTMLInputElement;
			inputElement3.value = this.fileToEdit.SortOrder.toString();
		}

	clearForm() {
		this.filesForm.reset();
			this.fileFormSubmitted = false;
	}

	open(row) {
		window.open(`${ this.applicationSettings.retrieveUrl()}${ row.FilePath}${ row.GeneratedFileName}`);
	}

	deleteFileData(id) {
		this.materialService.deleteFileMaterial(id).subscribe(file => this.getFileMaterialData())
	}

	//This gets the file grid
	getFileMaterialData(){
		console.log('should get the file grid');
		if (this.material)
		{
			if (this.material.Id > 0)
			{
				this.materialService.getAllFilesMaterial(this.material.Id).subscribe(response => {
					console.log('file list returned');
					console.log(response);
					this.dataSource = new MatTableDataSource(response);
					this.dataSource.paginator = this.firstPaginator;
				});
			}
		}
	}

	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
	public dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;


    public onFileRemove(args: RemovingEventArgs): void {
        args.postRawFile = false;
    }

	public browseClick() {
		document.getElementsByClassName('e-file-select-wrap')[0].querySelector('button').click(); return false;
	  }

	  public onFileSelect: EmitType<Object> = (args: any) => {
        let inputElement: HTMLInputElement  = document.getElementById('upload') as HTMLInputElement;
        inputElement.value = args.filesData[0].name;
		
    }


	Submit() {
		this.onFormSubmit();
}
public onFormSubmit(): void {
    let formObject: FormValidator = new FormValidator("#form1", this.options);
    let formStatus: Boolean = formObject.validate();
    if (formStatus) {
		
		const fileForm = this.filesForm.value;
		const formData = new FormData();
		
		fileForm.fileDescription = (<HTMLInputElement>document.getElementById("fileDescription")).value;
		fileForm.sortOrder = (<HTMLInputElement>document.getElementById("sortOrder")).value;
	

		formData.append('RecordId', this.material.Id.toString());
		formData.append('FileDescription', fileForm.fileDescription || 0);
		formData.append('IncludeInReport', !!fileForm.includeReport ? '1' : '0');
		formData.append('SortOrder', fileForm.sortOrder || 0);
		formData.append('FileName', fileForm.fileUpload);


		if (this.fileToEdit && this.fileToEdit.Id)
		{
			formData.append('Id', this.fileToEdit.Id.toString());
			formData.append('GeneratedFileName', this.fileToEdit.GeneratedFileName);

			this.materialService.updateFileMaterial(formData).subscribe(file => {
				this.fileToEdit = null;
				this.clearForm();
				this.getFileMaterialData();
				//this.getImagePart();
			});
		}
		else
		{
			this.materialService.createFileMaterial(formData).subscribe(file => {
				this.clearForm();
				this.getFileMaterialData()});
				//this.getImagePart();
			}
		
		formObject.element.reset();
        //this.Dialog?.show();
    }else{
		console.log('onSaveFile::Form Invalid');
		return;
	}
    }

	public options: object = {
		rules: {
		'fileDescription': {
			required: true
		},
		'sortOrder': {
			required: true
		},
		'upload': {
			required: true
		}
		}
	}
}
