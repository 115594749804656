import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {merge} from 'rxjs';
import { Router } from '@angular/router';

import { ApplicationSettings } from '../../../shared/application-settings';
import { IUsersearch } from '../../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../../admin/usersearches/usersearch-service';

import { UserService } from '../user-service/user.service';
import { IAspnetuserSearch } from '../aspnetuser-model/aspnetusersearch';
import { IAspnetusers } from '../aspnetuser-model/aspnetusers';
import { BrancheService } from '../../branches/branche-service';
import { FormControl } from '@angular/forms';

@Component({
	templateUrl: './aspnetuser-list.component.html',
	styleUrls: ['./aspnetuser-list.component.css']
})

export class AspnetuserListComponent implements OnInit, AfterViewInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	displayedColumns: string[] = ['Id', 'FirstName', 'LastName', 'City', 'Active', 'CompanyName', 'BranchNumber', 'Email'];

	// displayedColumns: string[] = ['Id', 'FirstName', 'LastName', 'Initials', 'Title', 'Address', 'City', 'Region', 'PostalCode', 'HomePhone', 'CellPhone', 'BirthDate', 'HireDate', 'EmergencyName', 'EmergencyPhone', 'Notes', 'Active', 'Image', 'Signature', 'CompanyId', 'CompanyName', 'Email', 'EmailConfirmed', 'PasswordHash', 'SecurityStamp', 'PhoneNumber', 'PhoneNumberConfirmed', 'TwoFactorEnabled', 'LockoutEndDateUtc', 'LockoutEnabled', 'AccessFailedCount', 'UserName', 'BranchId'];

	public UserSearches: IUsersearch[];
	public pageSize = '10';
	public skip = 0;
	public recordTotal: number = 0;

	public showHideSearch: boolean = false;
	public SearchText: string = 'Show Filters';

	public FirstName = '';
	public LastName = '';
	public Initials = '';
	public Title = '';
	public Address = '';
	public City = '';
	public Region = '';
	public PostalCode = '';
	public HomePhone = '';
	public CellPhone = '';
	public BirthDate = '';
	public HireDate = '';
	public EmergencyName = '';
	public EmergencyPhone = '';
	public Notes = '';
	public Active = false;
	public Image = '';
	public Signature = '';
	public CompanyId = '';
	public CompanyName = '';
	public Email = '';
	public EmailConfirmed = '';
	public PasswordHash = '';
	public SecurityStamp = '';
	public PhoneNumber = '';
	public PhoneNumberConfirmed = '';
	public TwoFactorEnabled = '';
	public LockoutEndDateUtc = '';
	public LockoutEnabled = '';
	public AccessFailedCount = '';
	public UserName = '';
	public BranchId = '';

	public pageTitle = 'Users List';
	public errorMessage = '';
	public dataSource: any;
	public excelFile;
	public branches;
	pageSizeLst = new FormControl();

	progressStatus = true;

	constructor(private userSearchService: UsersearchService
		, public applicationSettings: ApplicationSettings
		 , private router: Router
		, private _appService: UserService
		, public branchService: BrancheService) {
	}

	ngOnInit(): void {
		this.skip = 0;
		let x  = +localStorage.getItem('rows');
		if(x>0) { this.pageSize = x.toString(); }
		else { this.pageSize = '10';}
		this.pageSizeLst.setValue(this.pageSize);
		if (localStorage.getItem('showHideSearch') === 'true')
		{
			   this.showHideSearch = true;
			   this.SearchText = 'Hide Filters';
		}
		else
		{
			   this.showHideSearch = false;
			   this.SearchText = 'Show Filters';
		 }
		 this.branchService.getAllBranchesForDropdown().subscribe(response => {
			this.branches = response;
		});

	}

	ngAfterViewInit(): void {
		this.userSearchService.getUserSearchesByForm(this.pageTitle)
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
		}
		, (error: any) => {this.errorMessage = < any > error; this.progressStatus = false;});
	}

	setUserFilters(): void {
		this.sort.direction = 'asc';
		this.sort.active = 'Id';

		this.UserSearches.forEach(i => {
			if(i.Item=='FirstName')
				this.FirstName = i.Value;

			if(i.Item=='LastName')
				this.LastName = i.Value;

			if(i.Item=='Initials')
				this.Initials = i.Value;

			if(i.Item=='Title')
				this.Title = i.Value;

			if(i.Item=='Address')
				this.Address = i.Value;

			if(i.Item=='City')
				this.City = i.Value;

			if(i.Item=='Region')
				this.Region = i.Value;

			if(i.Item=='PostalCode')
				this.PostalCode = i.Value;

			if(i.Item=='HomePhone')
				this.HomePhone = i.Value;

			if(i.Item=='CellPhone')
				this.CellPhone = i.Value;

			if(i.Item=='BirthDate')
				this.BirthDate = i.Value;

			if(i.Item=='HireDate')
				this.HireDate = i.Value;

			if(i.Item=='EmergencyName')
				this.EmergencyName = i.Value;

			if(i.Item=='EmergencyPhone')
				this.EmergencyPhone = i.Value;

			if(i.Item=='Notes')
				this.Notes = i.Value;

			if(i.Item=='Active')
			{
				if(i.Value=='true'){
					this.Active = true;
				}
				else{
					this.Active = false;
				}		
			}
				
			if(i.Item=='Image')
				this.Image = i.Value;

			if(i.Item=='Signature')
				this.Signature = i.Value;

			if(i.Item=='CompanyId')
				this.CompanyId = i.Value;

			if(i.Item=='CompanyName')
				this.CompanyName = i.Value;

			if(i.Item=='Email')
				this.Email = i.Value;

			if(i.Item=='EmailConfirmed')
				this.EmailConfirmed = i.Value;

			if(i.Item=='PasswordHash')
				this.PasswordHash = i.Value;

			if(i.Item=='SecurityStamp')
				this.SecurityStamp = i.Value;

			if(i.Item=='PhoneNumber')
				this.PhoneNumber = i.Value;

			if(i.Item=='PhoneNumberConfirmed')
				this.PhoneNumberConfirmed = i.Value;

			if(i.Item=='TwoFactorEnabled')
				this.TwoFactorEnabled = i.Value;

			if(i.Item=='LockoutEndDateUtc')
				this.LockoutEndDateUtc = i.Value;

			if(i.Item=='LockoutEnabled')
				this.LockoutEnabled = i.Value;

			if(i.Item=='AccessFailedCount')
				this.AccessFailedCount = i.Value;

			if(i.Item=='UserName')
				this.UserName = i.Value;

			if(i.Item=='BranchId')
				this.BranchId = i.Value;

			if(i.Item=='orderByString') {
				if (this.applicationSettings.getOrderByDirection(i.Value)==='asc')
					this.sort.direction = 'asc';
				else
					this.sort.direction = 'desc';

				this.sort.active = this.applicationSettings.getOrderByName(i.Value);
			}

			if (i.Item == 'Skip')
				this.skip = i.Value;

		});

		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
		merge(this.sort.sortChange, this.paginator.page)
			.subscribe(()=> this.getData());

		this.getData();
	}

	pageSizeValueChanged(rows: string): void {
		this.applicationSettings.setPageSize(rows);
		this.pageSize=rows;
		this.pageSizeLst.setValue(this.pageSize);
		this.getData();
	}

	getData(): any {
	 this.progressStatus = true;
	 this.dataSource = null; 
	 this.recordTotal = 0; 
		let search: IAspnetuserSearch = this._appService.getFreshSearch();

		search.FirstName = this.FirstName;
		search.LastName = this.LastName;
		search.Initials = this.Initials;
		search.Title = this.Title;
		search.Address = this.Address;
		search.City = this.City;
		search.Region = this.Region;
		search.PostalCode = this.PostalCode;
		search.HomePhone = this.HomePhone;
		search.CellPhone = this.CellPhone;
		search.BirthDate = this.BirthDate;
		search.HireDate = this.HireDate;
		search.EmergencyName = this.EmergencyName;
		search.EmergencyPhone = this.EmergencyPhone;
		search.Notes = this.Notes;
		search.Active = this.Active;
		search.Image = this.Image;
		search.Signature = this.Signature;
		search.CompanyId = this.CompanyId;
		search.CompanyName = this.CompanyName;
		search.Email = this.Email;
		search.EmailConfirmed = this.EmailConfirmed;
		search.PasswordHash = this.PasswordHash;
		search.SecurityStamp = this.SecurityStamp;
		search.PhoneNumber = this.PhoneNumber;
		search.PhoneNumberConfirmed = this.PhoneNumberConfirmed;
		search.TwoFactorEnabled = this.TwoFactorEnabled;
		search.LockoutEndDateUtc = this.LockoutEndDateUtc;
		search.LockoutEnabled = this.LockoutEnabled;
		search.AccessFailedCount = this.AccessFailedCount;
		search.UserName = this.UserName;
		search.BranchId = this.BranchId;
		search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
		search.take = parseInt(this.pageSize);
		search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

		this._appService.getRecordSet(search).subscribe((obj: IAspnetusers) => this.onSelectedRecordSetReceived(obj),
		(error: any) => {
			this.errorMessage = <any> error; this.progressStatus = false;
		});
	}

	private onSelectedRecordSetReceived(obj: IAspnetusers){
		
		this.dataSource = new MatTableDataSource(obj.data);
		this.recordTotal = obj.total;
		this.progressStatus = false;
	}

	searchClick(): void {
		this.skip = 0;
		this.getData();
	}

	public searchClearClick(): void {
		this.FirstName = '';
		this.LastName = '';
		this.Initials = '';
		this.Title = '';
		this.Address = '';
		this.City = '';
		this.Region = '';
		this.PostalCode = '';
		this.HomePhone = '';
		this.CellPhone = '';
		this.BirthDate = '';
		this.HireDate = '';
		this.EmergencyName = '';
		this.EmergencyPhone = '';
		this.Notes = '';
		this.Active = false;
		this.Image = '';
		this.Signature = '';
		this.CompanyId = '';
		this.CompanyName = '';
		this.Email = '';
		this.EmailConfirmed = '';
		this.PasswordHash = '';
		this.SecurityStamp = '';
		this.PhoneNumber = '';
		this.PhoneNumberConfirmed = '';
		this.TwoFactorEnabled = '';
		this.LockoutEndDateUtc = '';
		this.LockoutEnabled = '';
		this.AccessFailedCount = '';
		this.UserName = '';
		this.BranchId = '';
		this.skip = 0;
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.getData();
	}

	resetError() : void {
		this.errorMessage = '';
	}
	showHideSearchClick() {
		 if (this.showHideSearch) 
		 {
			 this.showHideSearch = false; 
			localStorage.setItem('showHideSearch', 'false'); 
			this.SearchText = 'Show Filters'; 
		}
		else
		{
			 this.showHideSearch = true; 
			localStorage.setItem('showHideSearch', 'true'); 
			  this.SearchText = 'Hide Filters'; 
		}
	}
	searchAndHideClick() {
		 this.skip = 0;
		this.getData();
		 setTimeout(() => {
		     this.showHideSearchClick();
		 }, 500);
	 }
	public help()
	{
		   const url = this.router.serializeUrl(
			   this.router.createUrlTree([`/help-view/aspnetusers`])
		);
		  window.open(url, '_blank');
	 }
	public toExcel(){
			let search: IAspnetuserSearch = this._appService.getFreshSearch();

			search.FirstName = this.FirstName;
			search.LastName = this.LastName;
			search.Initials = this.Initials;
			search.Title = this.Title;
			search.Address = this.Address;
			search.City = this.City;
			search.Region = this.Region;
			search.PostalCode = this.PostalCode;
			search.HomePhone = this.HomePhone;
			search.CellPhone = this.CellPhone;
			search.BirthDate = this.BirthDate;
			search.HireDate = this.HireDate;
			search.EmergencyName = this.EmergencyName;
			search.EmergencyPhone = this.EmergencyPhone;
			search.Notes = this.Notes;
			search.Active = this.Active;
			search.Image = this.Image;
			search.Signature = this.Signature;
			search.CompanyId = this.CompanyId;
			search.CompanyName = this.CompanyName;
			search.Email = this.Email;
			search.EmailConfirmed = this.EmailConfirmed;
			search.PasswordHash = this.PasswordHash;
			search.SecurityStamp = this.SecurityStamp;
			search.PhoneNumber = this.PhoneNumber;
			search.PhoneNumberConfirmed = this.PhoneNumberConfirmed;
			search.TwoFactorEnabled = this.TwoFactorEnabled;
			search.LockoutEndDateUtc = this.LockoutEndDateUtc;
			search.LockoutEnabled = this.LockoutEnabled;
			search.AccessFailedCount = this.AccessFailedCount;
			search.UserName = this.UserName;
			search.BranchId = this.BranchId;
			search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
			search.take = parseInt(this.pageSize);
			search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

			this._appService.toExcel(search).subscribe(
				retVal => {
					    this.excelFile = retVal;
				},
				 (error: any) => {
					 this.errorMessage = < any > error;
				});
	}
	public hideFileLink()
	{
	   this.excelFile = "";
	}
	public openExcelFile()
	{
	   window.open(`${ this.applicationSettings.retrieveUrl()}${ this.excelFile}`);
	}
}