<div class="panel panel-primary animaPlaceholder">
	<div class="panel-heading">
		{{ pageTitle }}
		<div class='has-error' *ngIf='errorMessage'>{{ errorMessage }}</div>
	</div>

	<div class="panel-body">
		<form class="form-horizontal" novalidate (ngSubmit)="editRole()" [formGroup]="editRoleForm">
			<div class="form-group">
				<label class="col-md-1 control-label" for="Name">Name</label>
				<div class="col-md-3 form-group-item">
					<input class="form-control" id="Name" type="text" placeholder="Name (required)" formControlName="Name" />
				</div>
				<span class="text-danger" *ngIf="displayMessage.Name">
					{{displayMessage.Name}}
				</span>
			</div>
			<div class='row'>
				<div class='col-md-4'>
					<span>
						<button class='btn btn-primary btn-edit-sml'
							(click)='editRole()'
							[disabled]='!editRoleForm.valid'>
							<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
						</button>
					</span>
					<span class='pull-right remove-float-sml'>
						<button class='btn btn-primary btn-edit-sml' (click)='cancel()'>
							Cancel &nbsp;
						</button>
					</span>
				</div>
			</div>
		</form>
	</div>
</div>
<app-loading *ngIf="progressStatus"></app-loading> 
