import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IGreen} from './Green-model/Green';
import { IGreens } from './Green-model/Greens';
import { IGreenSearch } from './Green-model/GreenSearch';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';

@Injectable()
export class GreenService {
	private baseUrl;
	public pageTitle = 'Greens List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Greens';
	}

	getGreen(id: number):  Observable<IGreen> {
		if (id === 0) {
			return of(this.initializeGreen());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IGreen>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveGreen(green: IGreen): Observable<any> {
		if (green.Id === 0) {
			return this.createGreen(green);
		}
		return this.updateGreen(green);
	}

	private createGreen(green: IGreen): Observable<any> {
		green.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl, green)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateGreen(green: IGreen): Observable<IGreen> {
		return this.authHttp.put<IGreen>(this.baseUrl, green)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteGreen(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeGreen(): IGreen {
		return {
			Id: 0,
			Name: '',
			SeedSerialNumber: '',
			Description: '',
			PlantedDate: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IGreen;
	}

	public getRecordSet(search: IGreenSearch): Observable<IGreens> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('Name', search.Name, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('SeedSerialNumber', search.SeedSerialNumber, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Description', search.Description, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('PlantedDate', search.PlantedDate, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('Name', search.Name ? search.Name : '')
			.set('SeedSerialNumber', search.SeedSerialNumber ? search.SeedSerialNumber : '')
			.set('Description', search.Description ? search.Description : '')
			.set('PlantedDate', search.PlantedDate ? search.PlantedDate : '')
		};

		return this.authHttp.get<IGreens>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IGreens>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IGreenSearch { 
		let search: IGreenSearch = {
			Id: 1,
			Name: '',
			SeedSerialNumber: '',
			Description: '',
			PlantedDate: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}
}