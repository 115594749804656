import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { ITask } from './task-model/task';
import { ITasks } from './task-model/tasks';
import { ITaskSearch } from './task-model/taskSearch';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';
import { ITaskAction } from './task-model/taskAction';
import { IMachineMappingPLC } from './task-model/machineMappingPLC';

@Injectable()
export class TaskService {
	private baseUrl;
	public pageTitle = 'Task List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Tasks';
	}

	getAllTasks():  Observable<any> {
		return this.authHttp.get<ITask[]>(this.baseUrl + '/alltasks')
			.pipe(retry(1),
			catchError(this.processError));
	};

	getTask(id: number):  Observable<ITask> {
		if (id === 0) {
			return of(this.initializeTask());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<ITask>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveTask(task: ITask): Observable<any> {
		console.log(task);
		if (task.Id === 0) {
			return this.createTask(task);
		}
		return this.updateTask(task);
	}

	private createTask(task: ITask): Observable<any> {
		task.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/taskcreate', task)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateTask(task: ITask): Observable<ITask> {
		return this.authHttp.put<ITask>(this.baseUrl, task)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteTask(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeTask(): ITask {
		return {
			Id: 0,
			TaskName: '',	
			EquipmentId: 0,
			Description: ''
		} as ITask;
	}

	public getRecordSet(search: ITaskSearch): Observable<ITasks> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('TaskName', search.TaskName, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('TaskName', search.TaskName ? search.TaskName : '')
			
		};

		return this.authHttp.get<ITasks>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<ITasks>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : ITaskSearch { 
		let search: ITaskSearch = {
			Id: 1,
			TaskName: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.error.ErrorDescription}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: ITaskSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('TaskName', search.TaskName ? search.TaskName : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}


	
	GetAllTaskActionsByTask(taskId: number){
		const url=`${this.baseUrl}/GetAllActionsByTask?taskId=${taskId}`;
		return this.authHttp.get<ITaskAction[]>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	CreateTaskAction(taskTaskAction: ITaskAction): Observable<any> {
		//taskTaskAction.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/CreateAction', taskTaskAction)
			.pipe(retry(1), catchError(this.processError));
	}

	UpdateTaskAction(taskTaskAction: ITaskAction): Observable<ITaskAction> {
			return this.authHttp.put<ITaskAction>(this.baseUrl+ '/UpdateAction', taskTaskAction)
				.pipe(retry(1), catchError(this.processError));
	}

	SaveTaskAction(taskTaskAction: ITaskAction): Observable<any> {
		//console.log(taskTaskAction);
		if (taskTaskAction.Id === 0) {
			return this.CreateTaskAction(taskTaskAction);
		}
		return this.UpdateTaskAction(taskTaskAction);
	}

	UpdateActionsOrder(configuration: ITaskAction[]): Observable<any> {
		return this.authHttp.post<ITaskAction[]>(this.baseUrl + '/UpdateActionsOrder', configuration)
			.pipe(retry(1), catchError(this.processError));
	}

	DeleteTaskAction(id: number){
		const url=`${this.baseUrl}/DeleteAction?id=${id}`;
		return this.authHttp.delete<any>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	GetAllMappingsByEquipmentId(equipmentId: number){
		const url=this.settings.retrieveUrl() + `/api/MachinePLCMapping/GetByEquipmentId?equipmentid=${equipmentId}`;
		return this.authHttp.get<IMachineMappingPLC[]>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

}