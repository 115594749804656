
<div class='card animaPlaceholder'>
	<div class='card-heading'>
		<span *ngIf = 'green'>{{page}} :{{green.Id}}</span >
		<span class='pull-right'>
			{{record}} of {{recordTotal}}
		</span>
		<div class='has-error' *ngIf='errorMessage'>{{ errorMessage }}</div>
	</div>
<div class='container-fluid'>
	<div class='card-body'>
		<form class='form-horizontal' novalidate [formGroup]='greenForm'>
			<fieldset>
				<div class='row'>
<!-- Column 1 -->
<div class='col-md-6'>
<div class='row'>
<!-- Column 1-->
			<div class='row mt-2'>
			<label class='col-md-2 control-label'> Name</label>
				<div class='col-md-10'>
					<input class='form-control' type='text' formControlName='Name' />
				</div>
			</div>
			<div class='row mt-2'>
			<label class='col-md-2 control-label'> Seed Serial Number</label>
				<div class='col-md-10'>
					<input class='form-control' type='text' formControlName='SeedSerialNumber' />
				</div>
			</div>
			<div class='row mt-2'>
			<label class='col-md-2 control-label'> Description</label>
				<div class='col-md-10'>
					<input class='form-control' type='text' formControlName='Description' />
				</div>
			</div>
			<div class='row mt-2'>
			<label class='col-md-2 control-label'> Planted Date</label>
				<div class='col-md-10'>
					<input class='form-control' type='date' formControlName='PlantedDate' />
				</div>
			</div>
			</div>
<!-- End Column 1 -->
</div>
</div>
<!-- End Column 1-->
<!-- Column 2 -->
<div class='col-md-6'>
<div class='row'>
<!-- Column 2-->
<!-- End Column 2 -->
</div>
</div>
<!-- End Column 2-->
				<div class='row'>
					<div class='col-md-12'>
						<span>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='saveGreen(false, false)'
								style='width: 110px; margin-right:10px'
								[disabled]='!greenForm.valid'>
								<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
							</button>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='saveGreen(true, false)'
								style='width: 150px; margin-right:10px'
								[disabled]='!greenForm.valid'>
								<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
							</button>
							<button *ngIf='showNextRecordButton' class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='saveGreen(false, true)'
								style='width: 190px; margin-right:10px'
								[disabled]='!greenForm.valid'>
								<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
							</button>
						</span>
						<span  class='pull-right remove-float-sml'>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='cancel()'
								style='width: 150px; margin-right:10px'
								[disabled]='!greenForm.valid'>
								Cancel &nbsp;
							</button>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='deleteGreen()'
								style='width: 150px; margin-right:10px'
								[disabled]='!greenForm.valid'>
								<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
							</button>
						</span>
					</div>
				</div>
				<div class='row'>
					<div class='col-md-12'>
						<span>Updated By: <label *ngIf='green'>{{green.UpdatedBy}}</label></span>
						<span> Time: <label *ngIf='green'>{{green.UpdatedDate}}</label></span>
					</div>
				</div>
			</fieldset>
		</form>
	</div>
	</div>
</div>