import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {merge} from 'rxjs';
import { Router } from '@angular/router';

import { ApplicationSettings } from '../../../shared/application-settings';
import { IUsersearch } from '../../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../../admin/usersearches/usersearch-service';

import { EmployeelessonService } from '../employeelesson-service';
import { IEmployeelessonSearch } from '../employeelesson-model/employeelessonsearch';
import { IEmployeelessons } from '../employeelesson-model/employeelessons';

@Component({
	templateUrl: './employeelesson-list.component.html',
	styleUrls: ['./employeelesson-list.component.css']
})

export class EmployeelessonListComponent implements OnInit, AfterViewInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	displayedColumns: string[] = ['Id', 'LessonName', 'CoursesId', 'LessonId', 'ContentOrder', 'ContentName', 'ContentDescription', 'ContentReviewed', 'ContentStatus', 'ContentNotes', 'LessonUser', 'Employee', 'createddate', 'createdby', 'updateddate', 'updatedby'];
	isLoadingResults = true;

	public UserSearches: IUsersearch[];
	public pageSize = '10';
	public skip = 0;
	public recordTotal: number = 0;

	public showHideSearch: boolean = false;
	public SearchText: string = 'Show Filters';

	public LessonName = '';
	public CoursesId = '';
	public LessonId = '';
	public ContentOrder = '';
	public ContentName = '';
	public ContentDescription = '';
	public ContentReviewed = '';
	public ContentStatus = '';
	public ContentNotes = '';
	public LessonUser = '';
	public Employee = '';
	public createddate = '';
	public createdby = '';
	public updateddate = '';
	public updatedby = '';

	public pageTitle = 'EmployeeLessons List';
	public errorMessage = '';
	public dataSource: any;
	public excelFile;
	public progressStatus=false;

	constructor(private userSearchService: UsersearchService
		, public applicationSettings: ApplicationSettings
		 , private router: Router
		, private _appService: EmployeelessonService) {
	}

	ngOnInit(): void {
		this.progressStatus = true;
		this.skip = 0;
		let x  = +localStorage.getItem('rows');
		if(x>0) { this.pageSize = x.toString(); }
		else { this.pageSize = '10';}
		if (localStorage.getItem('showHideSearch') === 'true')
		{
			   this.showHideSearch = true;
			   this.SearchText = 'Hide Filters';
		}
		else
		{
			   this.showHideSearch = false;
			   this.SearchText = 'Show Filters';
		 }
	}

	ngAfterViewInit(): void {
		this.userSearchService.getUserSearchesByForm(this.pageTitle)
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.paginator.pageIndex = 0;

		this.UserSearches.forEach(i => {
			if(i.Item=='LessonName')
				this.LessonName = i.Value;

			if(i.Item=='CoursesId')
				this.CoursesId = i.Value;

			if(i.Item=='LessonId')
				this.LessonId = i.Value;

			if(i.Item=='ContentOrder')
				this.ContentOrder = i.Value;

			if(i.Item=='ContentName')
				this.ContentName = i.Value;

			if(i.Item=='ContentDescription')
				this.ContentDescription = i.Value;

			if(i.Item=='ContentReviewed')
				this.ContentReviewed = i.Value;

			if(i.Item=='ContentStatus')
				this.ContentStatus = i.Value;

			if(i.Item=='ContentNotes')
				this.ContentNotes = i.Value;

			if(i.Item=='LessonUser')
				this.LessonUser = i.Value;

			if(i.Item=='Employee')
				this.Employee = i.Value;

			if(i.Item=='createddate')
				this.createddate = i.Value;

			if(i.Item=='createdby')
				this.createdby = i.Value;

			if(i.Item=='updateddate')
				this.updateddate = i.Value;

			if(i.Item=='updatedby')
				this.updatedby = i.Value;

			if(i.Item=='orderByString') {
				if (this.applicationSettings.getOrderByDirection(i.Value)==='asc')
					this.sort.direction = 'asc';
				else
					this.sort.direction = 'desc';

				this.sort.active = this.applicationSettings.getOrderByName(i.Value);
			}

			if (i.Item == 'Skip')
				this.skip = i.Value;

		});

		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
		merge(this.sort.sortChange, this.paginator.page)
			.subscribe(()=> this.getData());

		this.getData();
	}

	pageSizeValueChanged(): void {
		this.applicationSettings.setPageSize(this.pageSize.toString());
		this.paginator.pageIndex = 0;
		this.getData();
	}

	getData(): any {
	 this.progressStatus=true; 
	 this.dataSource = null; 
	 this.recordTotal = 0; 
		let search: IEmployeelessonSearch = this._appService.getFreshSearch();

		search.LessonName = this.LessonName;
		search.CoursesId = this.CoursesId;
		search.LessonId = this.LessonId;
		search.ContentOrder = this.ContentOrder;
		search.ContentName = this.ContentName;
		search.ContentDescription = this.ContentDescription;
		search.ContentReviewed = this.ContentReviewed;
		search.ContentStatus = this.ContentStatus;
		search.ContentNotes = this.ContentNotes;
		search.LessonUser = this.LessonUser;
		search.Employee = this.Employee;
		search.createddate = this.createddate;
		search.createdby = this.createdby;
		search.updateddate = this.updateddate;
		search.updatedby = this.updatedby;
		search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
		search.take = parseInt(this.pageSize);
		search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

		this._appService.getRecordSet(search).subscribe((obj: IEmployeelessons) => this.onSelectedRecordSetReceived(obj),
		(error: any) => {
			this.errorMessage = <any> error;
		});
	}

	private onSelectedRecordSetReceived(obj: IEmployeelessons){
		this.dataSource = new MatTableDataSource(obj.data);
		this.recordTotal = obj.total;
		this.progressStatus = false;
	}

	searchClick(): void {
		this.skip = 0;
		this.paginator.pageIndex = 0;
		this.getData();
	}

	public searchClearClick(): void {
		this.LessonName = '';
		this.CoursesId = '';
		this.LessonId = '';
		this.ContentOrder = '';
		this.ContentName = '';
		this.ContentDescription = '';
		this.ContentReviewed = '';
		this.ContentStatus = '';
		this.ContentNotes = '';
		this.LessonUser = '';
		this.Employee = '';
		this.createddate = '';
		this.createdby = '';
		this.updateddate = '';
		this.updatedby = '';
		this.skip = 0;
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.paginator.pageIndex = 0;
		this.getData();
	}

	resetError() : void {
		this.errorMessage = '';
		this.progressStatus=false;
	}
	showHideSearchClick() {
		 if (this.showHideSearch) 
		 {
			 this.showHideSearch = false; 
			localStorage.setItem('showHideSearch', 'false'); 
			this.SearchText = 'Show Filters'; 
		}
		else
		{
			 this.showHideSearch = true; 
			localStorage.setItem('showHideSearch', 'true'); 
			  this.SearchText = 'Hide Filters'; 
		}
	}
	searchAndHideClick() {
		 this.skip = 0;
		 this.paginator.pageIndex = 0;
		this.getData();
		 setTimeout(() => {
		     this.showHideSearchClick();
		 }, 500);
	 }
	public help()
	{
		   const url = this.router.serializeUrl(
		       this.router.createUrlTree([`/help-view/employeelessons`])
		);
		  window.open(url, '_blank');
	 }
	public toExcel(){
			let search: IEmployeelessonSearch = this._appService.getFreshSearch();

			search.LessonName = this.LessonName;
			search.CoursesId = this.CoursesId;
			search.LessonId = this.LessonId;
			search.ContentOrder = this.ContentOrder;
			search.ContentName = this.ContentName;
			search.ContentDescription = this.ContentDescription;
			search.ContentReviewed = this.ContentReviewed;
			search.ContentStatus = this.ContentStatus;
			search.ContentNotes = this.ContentNotes;
			search.LessonUser = this.LessonUser;
			search.Employee = this.Employee;
			search.createddate = this.createddate;
			search.createdby = this.createdby;
			search.updateddate = this.updateddate;
			search.updatedby = this.updatedby;
			search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
			search.take = parseInt(this.pageSize);
			search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

			this._appService.toExcel(search).subscribe(
				retVal => {
					    this.excelFile = retVal;
				},
				 (error: any) => {
					 this.errorMessage = < any > error;
				});
	}
	public hideFileLink()
	{
	   this.excelFile = "";
	}
	public openExcelFile()
	{
	   window.open(`${ this.applicationSettings.retrieveUrl()}${ this.excelFile}`);
	}
}