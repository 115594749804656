import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IHelp} from './Help-model/Help';
import { IHelps } from './Help-model/Helps';
import { IHelpSearch } from './Help-model/HelpSearch';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';

@Injectable()
export class HelpService {
	private baseUrl;
	public pageTitle = 'Helps List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Helps';
	}

	getHelp(id: number):  Observable<IHelp> {
		if (id === 0) {
			return of(this.initializeHelp());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IHelp>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveHelp(help: IHelp): Observable<any> {
		if (help.Id === 0) {
			return this.createHelp(help);
		}
		return this.updateHelp(help);
	}

	private createHelp(help: IHelp): Observable<any> {
		help.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/helpcreate', help)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateHelp(help: IHelp): Observable<IHelp> {
		return this.authHttp.put<IHelp>(this.baseUrl, help)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteHelp(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeHelp(): IHelp {
		return {
			Id: 0,
			LinkName: '',
			Content: '',
			DeveloperContent:'',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IHelp;
	}

	public getRecordSet(search: IHelpSearch): Observable<IHelps> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('LinkName', search.LinkName, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Content', search.Content, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('DeveloperContent', search.DeveloperContent, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('LinkName', search.LinkName ? search.LinkName : '')
			.set('Content', search.Content ? search.Content : '')
			.set('DeveloperContent', search.DeveloperContent ? search.DeveloperContent : '')
		};

		return this.authHttp.get<IHelps>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IHelps>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IHelpSearch { 
		let search: IHelpSearch = {
			Id: 1,
			LinkName: '',
			Content: '',
			DeveloperContent:'',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}
	updateFileHelp(file: any){
		const url=`${this.baseUrl}/fileupdate`;
		return this.authHttp.put<IHelps>(url, file)
			.pipe(retry(1),
				catchError(this.processError))
	}
	deleteFileHelp(id: number){
		const url=`${this.baseUrl}/filedelete?id=${id}`;
		return this.authHttp.delete<any>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
	getAllFilesHelp(Id: number){
		const url=`${this.baseUrl}/files?id=${Id}`;
		return this.authHttp.get<IHelps[]>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
	createFileHelp(file: any){
		const url=`${this.baseUrl}/filecreate`;
		return this.authHttp.post<any>(url, file)
			.pipe(retry(1),
			catchError(this.processError))
	}
	public toExcel(search: IHelpSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('LinkName', search.LinkName ? search.LinkName : '')
			.set('Content', search.Content ? search.Content : '')
		    .set('DeveloperContent', search.DeveloperContent ? search.DeveloperContent : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
	getByLink(link:any){
		const url=`${this.baseUrl}/getbylink?link=${link}`;
		return this.authHttp.get<any>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
}