import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ApplicationSettings {
    baseUrl = '';
    serviceBaseUrl = '';

    // public vehicles = [
    //   { value: '' }
    //   , { value: 'Mazda' }
    //   , { value: 'Truck' }
    //   , { value: 'Magnum' }
    //  ];

    public pageSizes = [
      '10'
      ,'15'
      ,'20'
      ,'50'
      , '100'
    ];

     public vehicles: string[] = [
      '' 
      , 'Mazda'
      , 'Truck'
      , 'Magnum'
     ];

    public employees: string[] = [
      ''
      , 'Craig' 
      , 'Koralee'
      , 'James'
      , 'Annarose'
      , 'Raul'
      , 'contract' 
       
    ];
  
    public apps: string[] = [
      ''
      , 'EIAR'
      , 'Agropur'
      , 'Apollo'
      , 'BGE'
      , 'Faba'
      , 'Flexxaire'
      , 'GLM'
      , 'Green Line' 
      , 'Pulsar'
      , 'Sales'
      , 'Sabre'
      , 'Other' 
    ];

    public workStatus: string[] = [
      ''
      , 'open'
      , 'complete' 
      , 'cancelled'
      , 'in-progress'
      , 'waiting'
      , 'support' 
    ];

     public priorities = [
      { value: '' }
      , { value: '1' }
      , { value: '2' }
      , { value: '3' }
      , { value: '4' }
      , { value: '5' }
     ];

     public worktype = [
      { value: '' }
      , { value: 'Billable' }
      , { value: 'Contract' }
      , { value: 'Non-Billable' }
      , { value: 'Travel' }
     ];

    constructor(public http: HttpClient) {       
    }

    public getUrl() {
            return new Promise((resolve, reject) => {
              this.http.get('z_config.json')  // path of config.json file
                .subscribe(
                  (data: any) => {
                    this.baseUrl = data.baseUrl;
                    this.serviceBaseUrl = data.serviceBaseUrl;
                    resolve(true);
                  },
                  err => console.log(err)
                );
            });
          }

    retrieveUrl(): string {
        return this.baseUrl;
    }

      retrieveServiceUrl(): string {
        return this.serviceBaseUrl;
    }

    public getOrderByName(orderby: string): string {
      let retVal = '';
      let split = orderby.split('::');
  
      if (split.length > 1)
      {
        retVal = split[1];
      }
      return retVal;
    }

    public getOrderByDirection(orderby: string): string {
      let retVal = '';
      let split = orderby.split('::');
  
      if (split.length > 1)
      {
        retVal = split[0];
      }
      return retVal;
    }

    getDayOfWeek(date: Date): string {

      let day = '';
      switch (date.getDay()) {
        case 0:
          day = "Sunday";
          break;
        case 1:
          day = "Monday";
          break;
        case 2:
          day = "Tuesday";
          break;
        case 3:
          day = "Wednesday";
          break;
        case 4:
          day = "Thursday";
          break;
        case 5:
          day = "Friday";
          break;
        case 6:
          day = "Saturday";
      }

      return day;
    }

    public retrievePageSize(): string
    {
      let retVal = "";
      retVal = localStorage.getItem('rows');

      if(isNaN(+retVal))
        retVal = '10';

        return retVal;
    }
    public setPageSize(pageSize: string): void {
      localStorage.setItem('rows', pageSize);
    }
    public setPageSizeLarge(pageSize: string): void {
      localStorage.setItem('rowsLarge', pageSize);
    }
    
    public AddDaysReturnAsString(inDate: string, addDays: number): string {
      //Expects date in as '2021-12-25'
      //returns date as this string '2021-12-25'

      let newDate = new Date(inDate + "T00:00:00");
      newDate.setDate(newDate.getDate() + addDays);
      
      return this.returnDateAsString(newDate.toString());
    }

    public returnDateAsString(inDate: string): string {
          //returns date as this string '2021-12-25'
      let newDate = new Date(inDate);

      let day = newDate.getDate().toString();
      if (day.length==1)
              day = "0" + day;
      
      let month = (newDate.getMonth() + 1).toString();
      if(month.length==1)
              month = "0" + month;
      
      return newDate.getFullYear() + "-" + month + "-" + day;

    }
    public getPageSizes(){
      return this.pageSizes.map(Number);
    }
}