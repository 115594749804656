import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule }  from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material-module'
import { PLCServiceConfigComponent } from './plc-service-config-edit/plc-service-config-edit.component';
import { DialogsModule } from '../shared/dialogs/dialogs.module';
import { PLCServiceConfigService } from './plc-service-config-service';

@NgModule({
  declarations: [
	PLCServiceConfigComponent
  ],
  imports: [ 
	BrowserModule,  
    MatProgressSpinnerModule,
	MaterialModule,
	BrowserModule,
	FormsModule,
	ReactiveFormsModule,
	CommonModule,
	DialogsModule,
	RouterModule.forChild([
		{ path: 'plc-service-config', component: PLCServiceConfigComponent },
	]),
  ],
  providers: [
	PLCServiceConfigService
	]
})
export class PLCServiceConfigModule { }
