import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';


import { ApplicationSettings } from '../shared/application-settings';


@Injectable({
  providedIn: 'root'
})
export class ContactService {
	private baseUrl;

  constructor(
    private authHttp: HttpClient
		, private settings: ApplicationSettings
  ) {
    this.baseUrl = this.settings.retrieveUrl() + '/api/Contacts';
   }
    createContact(obj): any {
		obj.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/contactcreate', obj)
			.pipe(retry(1), catchError(this.processError));
	}
	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}
}
