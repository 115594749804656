import { Input, Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControlName } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs/Subscription';
import { TestjoinedresultService } from '../testjoinedresult-service'; 
import { ITestjoinedresult } from '../Testjoinedresult-model/Testjoinedresult';

import { UsersearchService } from '../../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../../admin/usersearches/usersearch-model/usersearch';

import { TrainingManagementService } from '../../trainingmanagement-service';

@Component({
	templateUrl: './testjoinedresult-cus-tf.component.html',
	selector: 'testjoinedresult-cus-tf'
})

export class TestJoinedResultComponent implements OnInit {
    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
    
	private _questionId: number;
	private _testId: number;
	private _init: boolean = false;
	public answerList: ITestjoinedresult[];


    @Input() set questionId(value: string) {
        this._questionId = Number(value);
		//console.log('QuestionId: ' + this._questionId.toString());
		if (this._init)
			this.getSelectedRecordSet();
		
     }

	 @Input() set testId(value: string) {
        this._testId = Number(value);
       //console.log('TestId: ' + this._testId.toString());
	 }
	 
	errorMessage: string;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public showNextRecordButton: boolean = true;

	constructor(private fb: FormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private testjoinedresultService: TestjoinedresultService
		, private trainingManagementService: TrainingManagementService
		) {
	}

	ngOnInit(): void {
		this.getSelectedRecordSet();
		this._init = true;
	}


	public getSelectedRecordSet():void{
		this.testjoinedresultService.getTestjoinedresultDataSet(this._testId, this._questionId)
			.subscribe((obj: ITestjoinedresult[]) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: ITestjoinedresult[]){
		this.answerList = obj;
	
		console.log('got the joined result record set');
		console.log(this.answerList);

	}

	public setValue(value: string, id: number, result: string) {
		console.log("Clicked: " + value + " Id: " + id.toString());
		this.trainingManagementService.saveAnswer(value, id, this._testId, result)
		.subscribe((obj: any) => console.log('answer:' + obj.toString()),
		(error: any) => {
			this.errorMessage = < any > error;
	});
		
	}
	
}