import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { ICustomer} from './Customer-model/Customer';
import { ICustomers } from './Customer-model/Customers';
import { ICustomerSearch } from './Customer-model/CustomerSearch';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';

@Injectable()
export class CustomerService {
	private baseUrl;
	public pageTitle = 'Customers List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Customers';
	}

	getCustomer(id: number):  Observable<ICustomer> {
		if (id === 0) {
			return of(this.initializeCustomer());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<ICustomer>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveCustomer(customer: ICustomer): Observable<any> {
		if (customer.Id === 0) {
			return this.createCustomer(customer);
		}
		return this.updateCustomer(customer);
	}

	private createCustomer(customer: ICustomer): Observable<any> {
		customer.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/customercreate', customer)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateCustomer(customer: ICustomer): Observable<ICustomer> {
		return this.authHttp.put<ICustomer>(this.baseUrl, customer)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteCustomer(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeCustomer(): ICustomer {
		return {
			Id: 0,
			CompanyId: 0,
			FirstName: '',
			LastName: '',
			Email: '',
			PhoneNumber: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as ICustomer;
	}

	public getRecordSet(search: ICustomerSearch): Observable<ICustomers> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('CompanyId', search.CompanyId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('SearchText', search.SearchText, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('CompanyId', search.CompanyId ? search.CompanyId : '')
			.set('SearchText', search.SearchText ? search.SearchText : '')
		};

		return this.authHttp.get<ICustomers>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<ICustomers>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : ICustomerSearch { 
		let search: ICustomerSearch = {
			Id: 1,
			CompanyId: '',
			SearchText: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.error.ErrorDescription}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: ICustomerSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('CompanyId', search.CompanyId ? search.CompanyId : '')
			.set('SearchText', search.SearchText ? search.SearchText : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
	public getAllCustomers(value: any):  Observable<any[]>{
		return this.authHttp.get<any[]>( `${this.baseUrl}/getallcustomers?value=`+value) 
		.pipe(
			retry(1),
			catchError(this.processError));
	   }
}