
<div class='panel panel-primary animaPlaceholder'>
	<div class='panel-heading'>
	<div class='row'>
		<div class='col-md-2'>
			{{pageName}}
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<button class='btn btn-default btn-sm btn-list-sml' [routerLink]="['/branchlabelprinter-details/0']" style='width:150px'>Add New Device</button>
		</div>
		<div class='col-md-1 col-xs-6 div-list-sml'>
		<button class='btn btn-primary btn-sm btn-list-sml' style='width: 80px' (click)='showHideSearchClick()'>{{SearchText}}</button>
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<button class='btn btn-default btn-sm btn-list-sml' style='width: 100px' (click)='toExcel()'>To Excel</button>
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml'>
			<label>Rows</label>&nbsp;&nbsp;
			<select [(ngModel)]='pageSize' style='width: 100px; color: black;' (change)='pageSizeValueChanged()'>
				<option *ngFor='let item of applicationSettings.pageSizes'>{{item}}</option>
			</select>
		</div>
		<div class='col-md-1 col-xs-12 div-list-sml'>
			<button class='btn btn-primary btn-sm btn-list-sml' style='width: auto;' (click)='help()'>how-to</button>
		</div>
	</div>
	</div>

	<div class='panel-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='excelFile'>
					<button class='btn btn-primary btn-sm' (click)='hideFileLink()'>hide link</button>
					<span>excel File:<a href = "javascript:void(0)"(click) = "openExcelFile()" > {{ excelFile}}</a></span>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<div class='row filters' *ngIf='showHideSearch'>
		<div class='col-md-9'>
			<div class='row'>
			<div class='col-md-4'>
				<label class='inline-label'> Name</label>
				<input type='text' class='form-control block-element' placeholder=' Name' [(ngModel)]='Name' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Device Type</label>
			    <select class="form-control" [(ngModel)]='PrinterType'>
					<option value="2x4">2x4</option>
					<option value="4x6">4x6</option>
					<option value="4x1">4x1</option>
					<option value="6x2">6x2</option>
					<option value="HighPressure">HighPressure</option>
					<option value="LowPressure">LowPressure</option>
					<option value="InvLbl">Inventory Label</option>
				</select>
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> IP</label>
				<input type='text' class='form-control block-element' placeholder=' IP' [(ngModel)]='IP' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Branch</label>
				<select class="form-control" [(ngModel)]='BranchId'>
					<option value=""> </option>
					<option *ngFor='let branch of branches;' [value]="branch.BranchNumber">
						{{branch.BranchName}} {{branch.BranchNumber}}</option>
				</select>
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> ZPL</label>
				<input type='text' class='form-control block-element' placeholder=' ZPL' [(ngModel)]='ZPL' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> PLCChannel</label>
				<input type='number' class='form-control block-element' placeholder=' PLCChannel' [(ngModel)]='PLCChannel' />
			</div>
			</div>
		</div>
		<div class='col-md-3'>
			<div class='row'>
				<div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm' style='width: 110px' (click)='searchClick()'>Search</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm' style='width: 110px' (click)='searchAndHideClick()'>Search and Hide</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right' style='margin-top: 15px;'>
					<button class='btn btn-primary btn-sm remove-width-sml' style='width: 110px' (click)='searchClearClick()'>Clear-Search</button>
				</div>
			</div>
		</div>
		</div>
	</div>
</div>

<div class='table-container'>
<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
	<ng-container matColumnDef='Id'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
		<td mat-cell *matCellDef='let e'>
			<button class='btn btn-default' style='min-width: 80px;' [routerLink]="['/branchlabelprinter-details', e.Id]">{{e.Id}}</button>
		</td>
	</ng-container>
	<ng-container matColumnDef='Name'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'> Name</th>
		<td mat-cell *matCellDef='let e'>{{e.Name}}</td>
	</ng-container>
	<ng-container matColumnDef='PrinterType'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Device Type</th>
		<td mat-cell *matCellDef='let e'>{{e.PrinterType}}</td>
	</ng-container>
	<ng-container matColumnDef='IP'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> IP</th>
		<td mat-cell *matCellDef='let e'>{{e.IP}}</td>
	</ng-container>
	<ng-container matColumnDef='BranchId'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'> Branch</th>
		<td mat-cell *matCellDef='let e'>{{e.BranchId}}</td>
	</ng-container>
	<!-- <ng-container matColumnDef='ZPL'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> ZPL</th>
		<td mat-cell *matCellDef='let e'>{{e.ZPL}}</td>
	</ng-container> -->
	<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
	<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
</div>
<mat-paginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>