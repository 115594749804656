import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ContactService } from './contact-service';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],

})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
	errorMessage: string;
  formSubmitStatus=false;

  constructor(private meta: Meta, private titleService: Title
    ,private fb: FormBuilder
    ,private contactService:ContactService) { 
    this.meta.addTag({ name: 'description', content: 'Check us out on facebook at Edmonton Robotics and Integration!'});
    this.titleService.setTitle('Contact Us');
  }

  ngOnInit() {
    this.contactForm = this.fb.group({
			Id: '',
      IpAddress:'',
			FirstName: ['',[Validators.required]],
			LastName: ['',[Validators.required]],
			Email: ['',[Validators.required, Validators.email]],
      Phone: [''],
			Company: '',
			Message: '',
			Projects: ''
		});
  }
  saveContact(){
    console.log(this.contactForm.value);
    this.contactService.createContact(this.contactForm.value).subscribe(obj=>{
           this.formSubmitStatus=true;
    } 
		  ,(error: any) => this.errorMessage = <any>error
		);
  }
	resetError() : void {
		this.errorMessage = '';
	}
}
