
<div class='card animaPlaceholder'>
	<div class='card-heading'>
		<span *ngIf = 'employeelesson'>{{employeelesson.LessonName}}</span >
		<span class='pull-right'>
			{{record}} of {{recordTotal}}
		</span>
		<div class='has-error' *ngIf='errorMessage'>{{ errorMessage }}</div>
	</div>
<div class='container-fluid'>
	<div class='card-body'>
		<form class='form-horizontal' novalidate [formGroup]='employeelessonForm'>
			<fieldset>
				<div class='row'>
<!-- Column 1 -->
<div class='col-md-12'>
<div class='row'>
<!-- Column 1-->

	<div *ngIf=employeelesson>
		<span [innerHTML]='employeelesson.ContentDescription'></span>
	</div>
</div>
<!-- End Column 1 -->
</div>
</div>
<!-- End Column 1-->
	<div class='row'>
		<div class='col-md-12'>
			<span>
				<button class='btn btn-primary' [disabled]='disablePreviousRecordButton||buttonPushed'
					(click)='saveEmployeelesson(false, false)' style='width: 110px; margin-right:10px'>
					<span class='glyphicon glyphicon-floppy-save'></span> Previous &nbsp;
				</button>
				<button class='btn btn-primary' [disabled]='buttonPushed||disableNextRecordButton'
					(click)='saveEmployeelesson(false, true)' style='width: 150px; margin-right:10px'>
					<span class='glyphicon glyphicon-floppy-save'></span> Next &nbsp;
				</button>
			</span>
			<span class='pull-right'>
				<button class='btn btn-primary' (click)='completeLesson()' style='width: 150px; margin-right:10px' 
				[disabled]='buttonPushed||!disableNextRecordButton'
				>
					<span class='glyphicon glyphicon-floppy-save'></span> Complete Lesson &nbsp;
				</button>
				<button class='btn btn-primary' (click)='cancel()' style='width: 150px; margin-right:10px' >
					<span class='glyphicon glyphicon-floppy-save'></span> Cancel &nbsp;
				</button>
			</span>
		</div>
	</div>
	</fieldset>
	</form>
	</div>
	</div>
</div>