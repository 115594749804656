import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IJournal} from './Journal-model/Journal';
import { IJournals } from './Journal-model/Journals';
import { IJournalSearch } from './Journal-model/JournalSearch';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';

@Injectable()
export class JournalService {
	private baseUrl;
	public pageTitle = 'Journals List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Journals';
	}

	getJournal(id: number):  Observable<IJournal> {
		if (id === 0) {
			return of(this.initializeJournal());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IJournal>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveJournal(journal: IJournal): Observable<any> {
		if (journal.Id === 0) {
			return this.createJournal(journal);
		}
		return this.updateJournal(journal);
	}

	private createJournal(journal: IJournal): Observable<any> {
		journal.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/journalcreate', journal)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateJournal(journal: IJournal): Observable<IJournal> {
		return this.authHttp.put<IJournal>(this.baseUrl, journal)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteJournal(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeJournal(): IJournal {
		return {
			Id: 0,
			DateNote: '',
			DayNotes: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IJournal;
	}

	public getRecordSet(search: IJournalSearch): Observable<IJournals> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('DateNote', search.DateNote, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('DateNote', search.DateNote ? search.DateNote : '')
		};

		return this.authHttp.get<IJournals>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IJournals>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IJournalSearch { 
		let search: IJournalSearch = {
			Id: 1,
			DateNote: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.error.ErrorDescription}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: IJournalSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('DateNote', search.DateNote ? search.DateNote : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}

	getJournalByDate(date: string):  Observable<IJournal> {
		const url = `${this.baseUrl}/getByDate?date=${date}`;
		return this.authHttp.get<IJournal>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
}