import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IAnswer} from './Answer-model/Answer';
import { IAnswers } from './Answer-model/Answers';
import { IAnswerSearch } from './Answer-model/AnswerSearch';

import { ApplicationSettings } from '../../shared/application-settings';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';

@Injectable()
export class AnswerService {
	private baseUrl;
	public pageTitle = 'Answers List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Answers';
	}

	getAnswer(id: number):  Observable<IAnswer> {
		if (id === 0) {
			return of(this.initializeAnswer());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IAnswer>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveAnswer(answer: IAnswer): Observable<any> {
		if (answer.Id === 0) {
			return this.createAnswer(answer);
		}
		return this.updateAnswer(answer);
	}

	private createAnswer(answer: IAnswer): Observable<any> {
		answer.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/answercreate', answer)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateAnswer(answer: IAnswer): Observable<IAnswer> {
		return this.authHttp.put<IAnswer>(this.baseUrl, answer)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteAnswer(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeAnswer(): IAnswer {
		return {
			Id: 0,
			QuestionId: 0,
			Name: '',
			Description: '',
			Result: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IAnswer;
	}

	public getRecordSet(search: IAnswerSearch): Observable<IAnswers> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('QuestionId', search.QuestionId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Name', search.Name, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Description', search.Description, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Result', search.Result, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('QuestionId', search.QuestionId ? search.QuestionId : '')
			.set('Name', search.Name ? search.Name : '')
			.set('Description', search.Description ? search.Description : '')
			.set('Result', search.Result ? search.Result : '')
		};

		return this.authHttp.get<IAnswers>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IAnswers>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IAnswerSearch { 
		let search: IAnswerSearch = {
			Id: 1,
			QuestionId: '',
			Name: '',
			Description: '',
			Result: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.error.ErrorDescription}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: IAnswerSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('QuestionId', search.QuestionId ? search.QuestionId : '')
			.set('Name', search.Name ? search.Name : '')
			.set('Description', search.Description ? search.Description : '')
			.set('Result', search.Result ? search.Result : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
}