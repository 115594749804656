import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ApplicationSettings } from '../../shared/application-settings';
import { DailylogService } from '../dailylog-service';
import { DailyLog, DailyLogArray } from '../dailylog-model';
import { DatePipe} from '@angular/common'
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-dailylog-list',
  templateUrl: './dailylog-list.component.html',
  styleUrls: ['./dailylog-list.component.css']
})
export class DailylogListComponent implements OnInit,OnChanges {
  @Input() DateParam: string;
  public dailyLogs: DailyLog[];
  public weekLogs: DailyLog[];

  public days: DailyLogArray[];
  
  public errorMessage: string;
  public AssignedUser: string;

  public currentDate: Date;
  public WorkDate: string;
  public dayOfWeek: string;
  
  public sub: Subscription;
	public inDate = '';
  public DateNote = '';

  constructor( 
      public applicationSettings: ApplicationSettings
      , public datepipe: DatePipe
      , private service: DailylogService) { 
      }

  ngOnInit() {
    this.DateNote=this.DateParam.toString()
    this.currentDate = new Date(this.DateNote);
    this.WorkDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    this.dayOfWeek = this.applicationSettings.getDayOfWeek(this.currentDate);
  }

  ngAfterViewInit() {
    this.getData();
  }

  getData() {
 
  let startDate = this.applicationSettings.AddDaysReturnAsString(this.WorkDate, -7)
  let endDate = this.applicationSettings.AddDaysReturnAsString(this.WorkDate, 0)
  let person = this.AssignedUser;
  
    this.service.getRecordSet(startDate, endDate, person).subscribe(
      (retVal: DailyLog[]) => {
        this.weekLogs=retVal;
        this.parseWeek();
        }, 
      (error: any) => { this.errorMessage = <any> error;}
    );

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['DateParam']) {
      this.DateNote=changes['DateParam'].currentValue.toString();
      this.currentDate = new Date(this.DateNote);
      this.WorkDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
      this.dayOfWeek = this.applicationSettings.getDayOfWeek(this.currentDate);
      this.getData();
    }
  }
  
  parseWeek(){
    this.days = new Array(0);

    //Set the day of week and the day of month
    this.weekLogs.forEach(e => {
        let d = new Date(e.workdate);
          e.DayOfMonth = d.getDate();
          e.DayOfWeek = this.applicationSettings.getDayOfWeek(d);
      });
     
      let dayOfMonth = 0;
      var log: DailyLog[] = new Array(0);
      var dayOfWeek: string = "";
      var total: number = 0;
      var workDate: string = "";

      this.weekLogs.forEach(e => {

        if (dayOfMonth==0||dayOfMonth == e.DayOfMonth){
          log.push(e);
          dayOfWeek = e.DayOfWeek;
        }
        else {
          this.days.push({DailyLog: log, DayOfWeek: dayOfWeek, WorkDate: workDate,Total: total});
          total = 0;
          log  = new Array(0);
          log.push(e);
        }

        dayOfWeek = e.DayOfWeek;
        dayOfMonth = e.DayOfMonth;
        total = total + e.Hours;
        workDate = this.applicationSettings.returnDateAsString(e.workdate);

      });

      this.days.push({DailyLog: log, DayOfWeek: dayOfWeek, WorkDate: workDate,Total: total});
  }
 

  dateChanged() {
      this.getData();
  }
  prevDay() {
    let d = new Date(this.WorkDate + " ")
    d.setDate(d.getDate() -1)
    this.currentDate = d;
    this.dayOfWeek = this.applicationSettings.getDayOfWeek(this.currentDate);
    this.WorkDate = this.datepipe.transform(d, 'yyyy-MM-dd');
    this.getData();
  }
  nextDay() {
    let d = new Date(this.WorkDate + " ")
    d.setDate(d.getDate() +1)
    this.currentDate = d;
    this.dayOfWeek = this.applicationSettings.getDayOfWeek(this.currentDate);
    this.WorkDate = this.datepipe.transform(d, 'yyyy-MM-dd');
    this.getData();
  }
}

