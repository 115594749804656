import { Component, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { timer } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GenericValidator } from '../../shared/generic-validator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PLCServiceConfigService } from '../plc-service-config-service';


export interface Message {
	source: string;
	content: any[];
	message: string;
	function: string;
}

@Component({
	selector: 'app-machine-hmi-config-edit',
	templateUrl: './plc-service-config-edit.component.html',
	styleUrls: ['./plc-service-config-edit.component.css']
})
export class PLCServiceConfigComponent implements OnInit {
	propertiesForm!: FormGroup;
	localLogForm!: FormGroup;
	errorMessage: string;
	displayMessage: { [key: string]: string } = {};
    buttonLatchTime: number = 1000;
	private timer: any;
	private startTime: number;
	modbusData: any;
	ip: string = '(empty)';
    port=0;
	arrCol1 = [];
	arrCol2 = [];

	public serverStatus = false;
	public deviceStatus = false;
    serverStatusSub:Subscription;
	deviceErrorSub:Subscription;
	
	serverPropertiesSub:Subscription;
	localLogSub:Subscription;

	serverProperties = '';
	localLog = '';

	processStarted=false;
	progressStatus=true;

	private timerSubscription: Subscription;

	constructor(
		private fb: FormBuilder,
		private plcService: PLCServiceConfigService,
		public snackBar: MatSnackBar
	) {
		this.plcService.connectServer();
	}


	ngOnInit(): void {

		this.propertiesForm = this.fb.group({
			PLCIP: ['', [Validators.required]],
			PLCPORT: ['', [Validators.required]],
			Log_URL_API_Prod: ['', [Validators.required]],
			LogEachSeconds: ['', [Validators.required]],
			LoggingEnabled: ['', [Validators.required]]
		});

		this.localLogForm = this.fb.group({
			LocalLog: [''],
		});


		this.serverStatusSub=this.plcService.GetServerStatusSubject.subscribe(obj => {
			this.serverStatus = obj;
		})
		this.deviceErrorSub=this.plcService.GetDeviceErrorSubject.subscribe(obj => {
			this.deviceStatus = obj;
		})
	
		this.serverPropertiesSub=this.plcService.GetServerPropertiesSubject.subscribe(obj => {
			this.serverProperties = obj;
			this.propertiesForm.patchValue({
			PLCIP: obj[3],
			Log_URL_API_Prod: obj[0],
			LogEachSeconds: obj[1],
			LoggingEnabled: obj[2]=="True"?true:false,
			PLCPORT: obj[4]
			});
			this.progressStatus = false;
		})

		this.localLogSub=this.plcService.GetlocalLogSubject.subscribe(obj => {
			this.localLog = '';
			obj.forEach(el => {
				this.localLog += el+'\n'
			});
			this.localLogForm.patchValue({
				LocalLog: this.localLog
			});
		})

		this.plcService.startReadingLocalLog();
		//this.startPeriodicLogUpdate();

	}

	startPeriodicLogUpdate(): void {
		this.updateLogText();
		this.timer = setInterval(() => {
		  this.updateLogText();
		}, 5000);
	  }
	
	  updateLogText(): void {
		this.reconnect();
		this.localLogSub=this.plcService.GetlocalLogSubject.subscribe(obj => {
			this.localLog = '';
			obj.forEach(el => {
				this.localLog += el+'\n'
			});
			this.localLogForm.patchValue({
				LocalLog: this.localLog
			});
		})
	  }

	openSnackBar(message: string, action: string, durationMiliiseconds:number) {
		this.snackBar.open(message, action, {
		  duration: durationMiliiseconds,
		});
	}

	ngAfterViewInit() {
	}

	ngOnDestroy() {
		//this.plcService.stopReadModbusValues();
		this.serverStatusSub.unsubscribe();
		this.deviceErrorSub.unsubscribe();
		this.localLogSub.unsubscribe();
		if (this.timerSubscription) {
			this.timerSubscription.unsubscribe();
		}
		if (this.timer) {
			clearInterval(this.timer);
		  }
	}

	reconnect() {
		this.plcService.connectServer();
	}
	
	resetError(): void {
		this.errorMessage = '';
	}

	updateServerPropertiesDelayed() {
		this.progressStatus = true;
		timer(3000).subscribe(() => {
		  this.updateServerProperties();
		});
	  }

	  updateServerProperties() {
		this.plcService.saveServerProperties(this.propertiesForm.value['PLCIP'],this.propertiesForm.value['Log_URL_API_Prod'],this.propertiesForm.value['LogEachSeconds'],this.propertiesForm.value['LoggingEnabled'],this.propertiesForm.value['PLCPORT']);
		this.progressStatus = false;
		this.openSnackBar('Your changes have been successfully updated','Ok',5000);
	}

}
