import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'

import { JournalListComponent } from './journal-list/journal-list.component';
import { JournalEditComponent } from './journal-edit/journal-edit.component';
import { JournalService } from './journal-service';
import { MatProgressSpinnerModule }  from '@angular/material/progress-spinner';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

@NgModule({
	declarations: [
		JournalListComponent
		, JournalEditComponent
	],
	imports: [
		MatProgressSpinnerModule,
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		CKEditorModule,
		RouterModule.forChild([
			{ path: 'journals', component: JournalListComponent },
			{ path: 'journal-details/:Id', component: JournalEditComponent },
		])
	],
	providers: [
		JournalService
	]
})
export class JournalModule { }
