import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControlName, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ICourse } from '../course-model/course';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';
import { Subscription } from 'rxjs/Subscription';
import { CourseService } from '../course-service';
import { ICourses } from '../Course-model/Courses';
import { ICourseSearch } from '../Course-model/CourseSearch';

import { IUsersearch } from '../../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../../admin/usersearches/usersearch-service';

@Component({
	templateUrl: './course-edit.component.html',
	styleUrls: ['course-edit.component.css']
})

export class CourseEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit Courses';
	errorMessage: string;
	courseForm: FormGroup;
	course: ICourse;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public CourseSearch: ICourseSearch;
	public ListData: ICourses;
	public showNextRecordButton: boolean = true;
	public progressStatus=false;

	constructor(private fb: FormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private courseService: CourseService,
		) {
	}

	ngOnInit(): void {
		this.courseForm = this.fb.group({
			Id: '',
			Name: ['',[Validators.maxLength(250)]],
			Description: ['',[Validators.maxLength(2000)]],
			Category: ['',[Validators.maxLength(250)]],
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getCourse(id);
				this.progressStatus = true;
			}
		);
	}

	public getSelectedRecordSet():void{
		this.courseService.getRecordSet(this.CourseSearch)
			.subscribe((obj: ICourses) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: ICourses){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.course.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

		this.progressStatus = false;

	}

	saveCourse(returnToList: boolean, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

		if (this.courseForm.valid) {
			const obj = Object.assign({ }, this.course, this.courseForm.value);
			this.courseService.saveCourse(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord,addNewRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		if (returnToList) {
			this.courseForm.reset();
			this.router.navigate(['/training/courses']);
		} 
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
		else if (addNewRecord)
		{
			this.router.navigate(['/training/course-details', 0]);
			this.courseForm.reset();
			let obj = this.courseService.initializeCourse();
			this.onCourseRetrieved(obj);
		}
			// when the record id is zero then it is a new record.
		else if (data !== null && this.course.Id === 0) {
			this.router.navigate(['/training/course-details', data.Id]);
		} 
		else if (this.course.Id != 0){
			this.getCourse(this.course.Id);
		}

		this.buttonPushed = false;
		this.progressStatus = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.course.Id)
				record = count;
		});

		this.getCourse(this.ListData.data[record].Id)
	}

	getCourse(id: number): void {
		this.courseService.getCourse(id)
			.subscribe((obj: ICourse) => this.onCourseRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onCourseRetrieved(course: ICourse): void {
		if (this.courseForm) {
			this.courseForm.reset();
		}
		this.course = course;
		if (this.course.Id === 0) {
			this.page = 'Add Courses';
		} else {
			this.page = 'Edit Courses';
		}

		this.courseForm.patchValue({
			Id: this.course.Id,
			Name: this.course.Name,
			Description: this.course.Description,
			Category: this.course.Category,
			CreatedDate: this.course.CreatedDate,
			CreatedBy: this.course.CreatedBy,
			UpdatedDate: this.course.UpdatedDate,
			UpdatedBy: this.course.UpdatedBy,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteCourse(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.course.Name}?`)) {
			this.courseService.deleteCourse(this.course.Id)
				.subscribe(() => this.onSaveComplete(true, null, false,false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/training/courses']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Courses List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.CourseSearch = this.courseService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'Name')
				this.CourseSearch.Name = i.Value;

			if (i.Item == 'Description')
				this.CourseSearch.Description = i.Value;

			if (i.Item == 'Category')
				this.CourseSearch.Category = i.Value;

			if (i.Item == 'orderByString')
				this.CourseSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.CourseSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
}
