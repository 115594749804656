import { Component, OnInit, OnDestroy,  ViewChildren, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControlName, Validators, FormControl  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ITask } from '../task-model/task';
import { Subscription } from 'rxjs/Subscription';
import { TaskService } from '../task-service';
import { ITasks } from '../task-model/tasks';
import { ITaskSearch } from '../task-model/TaskSearch';

import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { LookuplistService } from '../../lookuplists/lookuplist-service';

import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { MatPaginator as MatPaginator } from '@angular/material/paginator';
import { ApplicationSettings } from '../../shared/application-settings';
import { MatSnackBar } from '@angular/material/snack-bar';
import { formatDate } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../shared/dialogs/confirmation-dialog.component';
import { EquipmentService } from '../../equipments/equipment-service';
import { IEquipment } from '../../equipments/equipment-model/equipment';
import { IMachineMappingPLC } from '../task-model/machineMappingPLC';
import {
	CdkDragDrop,
	moveItemInArray,
  } from '@angular/cdk/drag-drop';
import { ITaskAction } from '../task-model/taskAction';

@Component({
	templateUrl: './task-edit.component.html',
	styleUrls: ['task-edit.component.css']
})

export class TaskEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

@ViewChild(MatPaginator) paginator: MatPaginator;
displayedColumns: string[] =  ['Edit', 'FileName', 'FileDescription', 'SortOrder', 'IncludeInReport','Delete'];
displayedColumnsTaskActions: string[] =  ['Edit','SequenceNumber', 'ActionType', 'ContactName', 'ActionDescription', 'Delete'];

	public page = 'Edit Task';
	errorMessage: string;
	taskForm: FormGroup;
	TaskActionForm: FormGroup;
	task: ITask;
	actionList: ITaskAction[];
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;

	public recordTotalTaskActions: number = 0;

	public UserSearches: IUsersearch[];
	public MachineMappingsPLC: IMachineMappingPLC[];
	public MachineMappingsPLCInput: IMachineMappingPLC[];
	public MachineMappingsPLCOutput: IMachineMappingPLC[];
	public TaskSearch: ITaskSearch;
	public ListData: ITasks;
	public showNextRecordButton: boolean = true;
	public progressStatus=false;
	public provinceList:any;
	public countryList:any;

	public dataSource: any;
	public dataSourceTaskActions: any;
	taskId: number;
	public filesForm: FormGroup

	TaskActionTypes = new FormControl([]);
	public statusList :any;
	public priorityList :any;
	public TaskActionTypesList :any;
	public pageSize = '10';

	hiddenInputs = false;

	public equipments: IEquipment[];

	DelaySecondsdisabled=false;
	ChkStatusdisabled=false;

	@ViewChild('firstPaginator', { static: true}) firstPaginator: MatPaginator;
	@ViewChild('secondPaginator', { static: true}) secondPaginator: MatPaginator;
	constructor(private fb: FormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private taskService: TaskService
		, private lookuplistService:LookuplistService
		, private applicationSettings:ApplicationSettings
		, public snackBar: MatSnackBar
		, public dialog: MatDialog
		, private equipmentService: EquipmentService
		) {
	} 

	ngOnInit(): void {
			let x  = +localStorage.getItem('rows');
			if(x>0) { this.pageSize = x.toString(); }
			else { this.pageSize = '10';}

		this.taskForm = this.fb.group({
			Id: '',
			TaskName: ['',[Validators.maxLength(250)]],
			Description: ['',[Validators.maxLength(250)]],
			EquipmentId: 0,
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.TaskActionForm = this.fb.group({
			Id: 0,
			taskId: ['', Validators.required],
			ActionType: ['', Validators.required],
			ActionDescription: ['', Validators.required],
			SequenceNumber: [0, Validators.required],
			IO_Array_Number: ['', Validators.required],
			IO_Position: ['', Validators.required],
			SetValue: [0, Validators.required],
			Delay_in_seconds: [0, Validators.required],
		})

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.taskId = id;
				this.getTask(id);
				this.progressStatus = true;
			}
		);
		this.equipmentService.getAllEquipments().subscribe((data) => {
			this.equipments = data;
		});
	}

	public getSelectedRecordSet():void{
		this.taskService.getRecordSet(this.TaskSearch)
			.subscribe((obj: ITasks) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: ITasks){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.task.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if ((this.record-obj.skip) < this.ListData.data.length)
			this.showNextRecordButton = true;
		else
			this.showNextRecordButton = false;

		this.progressStatus = false;

	}

	openSnackBar(message: string, action: string, durationMiliiseconds:number) {
		this.snackBar.open(message, action, {
		  duration: durationMiliiseconds,
		});
	}

	saveTask(returnToList: boolean, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

		if (this.taskForm.valid) {
			const obj = Object.assign({ }, this.task, this.taskForm.value);
			this.taskService.saveTask(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord,addNewRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		if (returnToList) {
			this.taskForm.reset();
			this.router.navigate(['/tasks']);
		} 
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
		else if(addNewRecord){
			this.router.navigate(['/task-details', 0]);
			this.taskForm.reset();
			let obj=this.taskService.initializeTask();
			this.onTaskRetrieved(obj);
		}
			// when the record id is zero then it is a new record.
		else if (data !== null && this.task.Id === 0) {
			this.router.navigate(['/task-details', data.Id]);
		} 
		else if (this.task.Id != 0){
			this.getTask(this.task.Id);
		}

		this.openSnackBar('Your changes have been successfully saved','Ok',5000);

		this.buttonPushed = false;
		this.progressStatus = false;
	}

	advanceToNextRecord(): void {
		let count = 0;
		let record = 0;
		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.task.Id)
				record = count;
		});
		this.router.navigate(['/task-details', this.ListData.data[record].Id]);
	}

	getTask(id: number): void {
		this.taskService.getTask(id)
			.subscribe((obj: ITask) => this.onTaskRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	GetAllMappingsByEquipmentId(EquipmentId): void {
		this.taskService.GetAllMappingsByEquipmentId(EquipmentId)
		.subscribe((data) => {
			this.MachineMappingsPLCOutput= data.filter(function(item) {
				return item.ModBusArrayType === 'OUTPUT';
			  });
			this.MachineMappingsPLCInput= data.filter(function(item) {
				return item.ModBusArrayType === 'INPUT';
			  });
			 
			console.log(this.MachineMappingsPLCInput);
			console.log(this.MachineMappingsPLCOutput);
		}
		, (error: any) => this.errorMessage = < any > error);
	}


	GetMappingList(io_type): void {
		if(io_type=='1'){
			this.hiddenInputs = false;
			this.MachineMappingsPLC = this.MachineMappingsPLCOutput;
			this.TaskActionForm.patchValue({
				Delay_in_seconds: 0,
				SetValue:0
			});
			
		}
		if(io_type=='2'){
			this.hiddenInputs = true;
			this.MachineMappingsPLC = this.MachineMappingsPLCInput;
			this.TaskActionForm.patchValue({
				Delay_in_seconds: -1,
				SetValue:1
			});
			

		}
	}

	SetActionMapping(item: IMachineMappingPLC): void {
		this.TaskActionForm.patchValue({
			IO_Array_Number: item.ArrayNumberPLC,
			IO_Position: item.VariableBinaryPosition
		});
		this.fillActionDescription();
	}

	onTaskRetrieved(task: ITask): void {
		if (this.taskForm) {
			this.taskForm.reset();
		}
		this.task = task;
		if (this.task.Id === 0) {
			this.page = 'Add Task';
		} else {
			this.page = 'Edit Task';
		}

		this.taskForm.patchValue({
			Id: this.task.Id,
			TaskName: this.task.TaskName,
			Description: this.task.Description,
			EquipmentId: this.task.EquipmentId,
			CreatedDate: this.task.CreatedDate,
			CreatedBy: this.task.CreatedBy,
			UpdatedDate: this.task.UpdatedDate,
			UpdatedBy: this.task.UpdatedBy,
		});

		if(this.task.EquipmentId>0){
			this.GetAllMappingsByEquipmentId(this.task.EquipmentId);
			
		}

		this.GetAllTaskActionsByTask();
		this.getUserSearchData();

	}
	

	ngOnDestroy(): void {
	}

	deleteTask(): void {
		this.buttonPushed = true;
			this.taskService.deleteTask(this.task.Id)
				.subscribe(() => this.onSaveComplete(true, null, false,false)
				, (error: any) => this.errorMessage = <any>error);
	}

	cancel(): void {
		this.router.navigate(['/tasks']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Task List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.TaskSearch = this.taskService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'TaskName')
				this.TaskSearch.TaskName = i.Value;

			if (i.Item == 'orderByString')
				this.TaskSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.TaskSearch.skip = i.Value;

		});
		this.TaskSearch.take = parseInt(this.pageSize);
		this.getSelectedRecordSet();
	}


	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
	
	 
	onFileSelected(event) {
		if (event.target.files.length > 0) {
		 this.filesForm.patchValue({
		 fileUpload: event.target.files [0],
		 })
		 }
	}


	GetAllTaskActionsByTask(){
		if (this.task)
		{
			if (this.task.Id > 0)
			{
				this.taskService.GetAllTaskActionsByTask(this.task.Id).subscribe(response => {
					this.actionList = response;
				}, (error: any) => this.errorMessage = < any > error);
			}
		}
	}


	SaveTaskAction(){
		this.buttonPushed = true;
		this.progressStatus = true;
		var getSequence = 1;

		if(this.actionList.length>0){
		var max_sequence = this.actionList.reduce(function(prev, current) {
			return (prev.SequenceNumber > current.SequenceNumber) ? prev : current;
		});
		getSequence = max_sequence.SequenceNumber+1;
		}
		this.TaskActionForm.patchValue({
			Id: 0,
			taskId: this.task.Id,
			SequenceNumber:getSequence
		});
		if(this.TaskActionForm.get('ActionType').value=='2'){
			this.TaskActionForm.patchValue({
				Delay_in_seconds: -1,
				SetValue:1
			});
		}
		if(this.TaskActionForm.get('ActionType').value=='1'){
			var setvalueInt = this.TaskActionForm.get('SetValue').value ? 1 : 0;
			this.TaskActionForm.patchValue({
				SetValue:setvalueInt
			});
		}
		
		

		if (this.TaskActionForm.invalid)
		{
		this.buttonPushed = false;
		this.progressStatus = false;
			return;
		}
		console.log(this.TaskActionForm);
		const obj = Object.assign({ }, this.TaskActionForm.value);
		console.log(obj);
		this.taskService.SaveTaskAction(obj).subscribe(() => {
			this.TaskActionForm.reset();
			this.MachineMappingsPLC = null;
			//this.TaskActionForm.controls.Delay_in_seconds.enable();
			//this.TaskActionForm.controls.SetValue.enable();
			this.TaskActionForm.patchValue({
				Id: 0,
				taskId: this.task.Id,
				ActionType: '',
				ActionDescription: '',
				SequenceNumber: 0,
				IO_Array_Number: '',
				IO_Position: '',
				SetValue: '',
				Delay_in_seconds: 0
			});

			this.GetAllTaskActionsByTask();
			this.buttonPushed = false;
			this.progressStatus = false;
			this.openSnackBar('Action has been successfully saved','Ok',5000);
		});
	}

	UpdateActionsOrder(){
		if(this.actionList){
		this.buttonPushed = true;
		this.progressStatus = true;
		this.taskService.UpdateActionsOrder(this.actionList).subscribe(() => {
			this.GetAllTaskActionsByTask();
			this.buttonPushed = false;
			this.progressStatus = false;
			this.openSnackBar('Actions have been successfully updated','Ok',5000);
		});
	}
	}

	UpdateTaskAction(TaskAction){
		this.TaskActionForm.patchValue({
			Id: TaskAction.Id,
			TaskId: TaskAction.taskId,
			ActionType: TaskAction.ActionType,
			ActionDescription: TaskAction.ContactName,
			SequenceNumber: TaskAction.SequenceNumber,
			IO_Array_Number: TaskAction.IO_Array_Number,
			IO_Position: TaskAction.IO_Position,
			SetValue: TaskAction.SetValue,
			Delay_in_seconds: TaskAction.Delay_in_seconds
		});
		console.log(this.TaskActionForm.value);
	}

	showDialog(type:string,id: string): void {
		this.dialog
		  .open(ConfirmationDialogComponent, {
			data: `Are you sure you want to delete this ${type.toLowerCase()}?`
		  })
		  .afterClosed()
		  .subscribe((confirmed: Boolean) => {
			if (confirmed) {
				if(type=='Action'){
				this.DeleteTaskAction(id);
				}
				
				if(type=='Task'){
					this.deleteTask();
				}
			} else {
			  return;
			}
		  });
	  }

	DeleteTaskAction(id) {
		this.buttonPushed = true;
		this.progressStatus = true;
		this.taskService.DeleteTaskAction(id).subscribe(() => {this.GetAllTaskActionsByTask();this.openSnackBar('Action has been successfully deleted','Ok',5000);});
		this.buttonPushed = false;
		this.progressStatus = false;
	}

	  drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.actionList, event.previousIndex, event.currentIndex);
	  }

	  fillActionDescription(){
		//Write value 0 to OUTPUT variable 105.0 (drawer1). Delay x seconds.
		//Read value from INPUT variable 82.0 (START).
	
		if(
			this.TaskActionForm.get('ActionType').value!=''
		&&	this.TaskActionForm.get('SetValue').value!=null
		&&	this.TaskActionForm.get('IO_Array_Number').value!=null
		&&	this.TaskActionForm.get('IO_Position').value!=null
		&&	this.TaskActionForm.get('Delay_in_seconds').value!=null
		){
		if(this.TaskActionForm.get('ActionType').value=='1'){
			this.TaskActionForm.patchValue({
				ActionDescription: `Write value ${this.TaskActionForm.get('SetValue').value ? 1 : 0} to OUTPUT variable ${this.TaskActionForm.get('IO_Array_Number').value}.${this.TaskActionForm.get('IO_Position').value}. Delay ${this.TaskActionForm.get('Delay_in_seconds').value} seconds.`
			});
		}
		if(this.TaskActionForm.get('ActionType').value=='2'){
			this.TaskActionForm.patchValue({
				ActionDescription: `Read value from INPUT variable ${this.TaskActionForm.get('IO_Array_Number').value}.${this.TaskActionForm.get('IO_Position').value}.`
			});
		}
		}
		
	  }
}
