import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControlName, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ITestresult } from '../testresult-model/testresult';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';
import { Subscription } from 'rxjs/Subscription';
import { TestresultService } from '../testresult-service';
import { ITestresults } from '../Testresult-model/Testresults';
import { ITestresultSearch } from '../Testresult-model/TestresultSearch';

import { UsersearchService } from '../../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../../admin/usersearches/usersearch-model/usersearch';

@Component({
	templateUrl: './testresult-edit.component.html',
	styleUrls: ['testresult-edit.component.css']
})

export class TestresultEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit TestResults';
	errorMessage: string;
	testresultForm: FormGroup;
	testresult: ITestresult;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public TestresultSearch: ITestresultSearch;
	public ListData: ITestresults;
	public showNextRecordButton: boolean = true;
	public progressStatus=false;

	constructor(private fb: FormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private testresultService: TestresultService,
		) {
	}

	ngOnInit(): void {
		this.testresultForm = this.fb.group({
			Id: '',
			TestId: '',
			AnswerId: '',
			Result: ['',[Validators.maxLength(250)]],
			Notes: [''],
			Answer: ['',[Validators.maxLength(250)]],
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getTestresult(id);
				this.progressStatus = true;
			}
		);
	}

	public getSelectedRecordSet():void{
		this.testresultService.getRecordSet(this.TestresultSearch)
			.subscribe((obj: ITestresults) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: ITestresults){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.testresult.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

		this.progressStatus = false;

	}

	saveTestresult(returnToList: boolean, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

		if (this.testresultForm.valid) {
			const obj = Object.assign({ }, this.testresult, this.testresultForm.value);
			this.testresultService.saveTestresult(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord,addNewRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		if (returnToList) {
			this.testresultForm.reset();
			this.router.navigate(['/training/testresults']);
		} 
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
		else if (addNewRecord)
		{
			this.router.navigate(['/testresult-details', 0]);
			this.testresultForm.reset();
			let obj = this.testresultService.initializeTestresult();
			this.onTestresultRetrieved(obj);
		}
			// when the record id is zero then it is a new record.
		else if (data !== null && this.testresult.Id === 0) {
			this.router.navigate(['/training/testresult-details', data.Id]);
		} 
		else if (this.testresult.Id != 0){
			this.getTestresult(this.testresult.Id);
		}

		this.buttonPushed = false;
		this.progressStatus = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.testresult.Id)
				record = count;
		});

		this.getTestresult(this.ListData.data[record].Id)
	}

	getTestresult(id: number): void {
		this.testresultService.getTestresult(id)
			.subscribe((obj: ITestresult) => this.onTestresultRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onTestresultRetrieved(testresult: ITestresult): void {
		if (this.testresultForm) {
			this.testresultForm.reset();
		}
		this.testresult = testresult;
		if (this.testresult.Id === 0) {
			this.page = 'Add TestResults';
		} else {
			this.page = 'Edit TestResults';
		}

		this.testresultForm.patchValue({
			Id: this.testresult.Id,
			TestId: this.testresult.TestId,
			AnswerId: this.testresult.AnswerId,
			Result: this.testresult.Result,
			Notes: this.testresult.Notes,
			Answer: this.testresult.Answer,
			CreatedDate: this.testresult.CreatedDate,
			CreatedBy: this.testresult.CreatedBy,
			UpdatedDate: this.testresult.UpdatedDate,
			UpdatedBy: this.testresult.UpdatedBy,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteTestresult(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.testresult.TestId}?`)) {
			this.testresultService.deleteTestresult(this.testresult.Id)
				.subscribe(() => this.onSaveComplete(true, null, false,false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/training/testresults']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('TestResults List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.TestresultSearch = this.testresultService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'TestId')
				this.TestresultSearch.TestId = i.Value;

			if (i.Item == 'AnswerId')
				this.TestresultSearch.AnswerId = i.Value;

			if (i.Item == 'Result')
				this.TestresultSearch.Result = i.Value;

			if (i.Item == 'Notes')
				this.TestresultSearch.Notes = i.Value;

			if (i.Item == 'Answer')
				this.TestresultSearch.Answer = i.Value;

			if (i.Item == 'orderByString')
				this.TestresultSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.TestresultSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
}
