import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControlName } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IBranchlabelprinter } from '../branchlabelprinter-model/branchlabelprinter';

import { Subscription } from 'rxjs/Subscription';
import { BranchlabelprinterService } from '../branchlabelprinter-service';
import { IBranchlabelprinters } from '../Branchlabelprinter-model/Branchlabelprinters';
import { IBranchlabelprinterSearch } from '../Branchlabelprinter-model/BranchlabelprinterSearch';

import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { BrancheService } from '../../admin/branches/branche-service';
import { ApplicationSettings } from '../../shared/application-settings';


@Component({
	templateUrl: './branchlabelprinter-edit.component.html',
	styleUrls: ['branchlabelprinter-edit.component.css']
})

export class BranchlabelprinterEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit BranchLabelPrinters';
	errorMessage: string;
	branchlabelprinterForm: UntypedFormGroup;
	branchlabelprinter: IBranchlabelprinter;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public BranchlabelprinterSearch: IBranchlabelprinterSearch;
	public ListData: IBranchlabelprinters;
	public showNextRecordButton: boolean = true;
	public branches;
	urldata:any;

	constructor(private fb: UntypedFormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private branchlabelprinterService: BranchlabelprinterService
		, public branchService: BrancheService
		, public applicationSettings: ApplicationSettings
	) {
	}

	ngOnInit(): void {
		this.branchlabelprinterForm = this.fb.group({
			Id: '',
			Name: '',
			PrinterType: '',
			IP: '',
			BranchId: '',
			ZPL: '',
			PLCChannel: '',
			Port: '',
			MacAddress:'',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
			FileName:''
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getBranchlabelprinter(id);
			}
		);
		this.branchService.getAllBranchesForDropdown().subscribe(response => {
			console.log('list of branches:');
			console.log(response);
			this.branches = response;
		});
	}

	public getSelectedRecordSet(): void {
		this.branchlabelprinterService.getRecordSet(this.BranchlabelprinterSearch)
			.subscribe((obj: IBranchlabelprinters) => this.onSelectedRecordSetReceived(obj),
				(error: any) => {
					this.errorMessage = <any>error;
				});
	}

	private onSelectedRecordSetReceived(obj: IBranchlabelprinters) {
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.branchlabelprinter.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

	}

	saveBranchlabelprinter(returnToList: boolean, advanceToNextRecord: boolean): void {
		this.buttonPushed = true;

		if (this.branchlabelprinterForm.valid) {
			const obj = Object.assign({}, this.branchlabelprinter, this.branchlabelprinterForm.value);
			this.branchlabelprinterService.saveBranchlabelprinter(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord)
					, (error: any) => this.errorMessage = <any>error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean): void {
		if (returnToList) {
			this.branchlabelprinterForm.reset();
			this.router.navigate(['/branchlabelprinters']);
		}
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
		// when the record id is zero then it is a new record.
		else if (data !== null && this.branchlabelprinter.Id === 0) {
			this.router.navigate(['/branchlabelprinter-details', data.Id]);
		}
		else if (this.branchlabelprinter.Id != 0) {
			this.getBranchlabelprinter(this.branchlabelprinter.Id);
		}

		this.buttonPushed = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.branchlabelprinter.Id)
				record = count;
		});

		this.getBranchlabelprinter(this.ListData.data[record].Id)
	}

	getBranchlabelprinter(id: number): void {
		this.branchlabelprinterService.getBranchlabelprinter(id)
			.subscribe((obj: IBranchlabelprinter) => this.onBranchlabelprinterRetrieved(obj),
				(error: any) => {
					this.errorMessage = <any>error;
					// this.spinner.stop();
				});
	}

	onBranchlabelprinterRetrieved(branchlabelprinter: IBranchlabelprinter): void {
		if (this.branchlabelprinterForm) {
			this.branchlabelprinterForm.reset();
		}
		this.branchlabelprinter = branchlabelprinter;
		if (this.branchlabelprinter.Id === 0) {
			this.page = 'Add Branch Device';
		} else {
			this.page = 'Edit Branch Device';
		}

		this.branchlabelprinterForm.patchValue({
			Id: this.branchlabelprinter.Id,
			Name: this.branchlabelprinter.Name,
			PrinterType: this.branchlabelprinter.PrinterType,
			IP: this.branchlabelprinter.IP,
			BranchId: this.branchlabelprinter.BranchId,
			ZPL: this.branchlabelprinter.ZPL,
			PLCChannel: this.branchlabelprinter.PLCChannel,
			Port: this.branchlabelprinter.Port,
			MacAddress:this.branchlabelprinter.MacAddress,
			CreatedDate: this.branchlabelprinter.CreatedDate,
			CreatedBy: this.branchlabelprinter.CreatedBy,
			UpdatedDate: this.branchlabelprinter.UpdatedDate,
			UpdatedBy: this.branchlabelprinter.UpdatedBy,
			FileName:this.branchlabelprinter.FileName
		});
		this.urldata=this.applicationSettings.retrieveUrl()+'/uploadedfiles/BranchLabelPrinters/'+this.branchlabelprinter.FileName;
		
		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteBranchlabelprinter(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.branchlabelprinter.Name}?`)) {
			this.branchlabelprinterService.deleteBranchlabelprinter(this.branchlabelprinter.Id)
				.subscribe(() => this.onSaveComplete(true, null, false)
					, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/branchlabelprinters']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('BranchLabelPrinters List')
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
			}
				, (error: any) => this.errorMessage = <any>error);
	}

	setUserFilters(): void {
		this.BranchlabelprinterSearch = this.branchlabelprinterService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'Name')
				this.BranchlabelprinterSearch.Name = i.Value;

			if (i.Item == 'PrinterType')
				this.BranchlabelprinterSearch.PrinterType = i.Value;

			if (i.Item == 'IP')
				this.BranchlabelprinterSearch.IP = i.Value;

			if (i.Item == 'BranchId')
				this.BranchlabelprinterSearch.BranchId = i.Value;

			if (i.Item == 'ZPL')
				this.BranchlabelprinterSearch.ZPL = i.Value;

			if (i.Item == 'PLCChannel')
				this.BranchlabelprinterSearch.PLCChannel = i.Value;

			if (i.Item == 'orderByString')
				this.BranchlabelprinterSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.BranchlabelprinterSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError(): void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
	onFileSelected(event: any) {

		const target = event.target as HTMLInputElement;
		if (target.files && target.files.length > 0) {
		  console.log(target.files[0].name);
	   
		const formData = new FormData();
		formData.append('fileName', target.files[0]);
		formData.append('id', this.branchlabelprinter.Id.toString());
		this.urldata="";
		this.branchlabelprinterService.addFile(formData).subscribe(
		  retVal => {
			if (retVal !== "") {
			  alert("File Uploaded");
			  console.log("File Uploaded");
			  this.branchlabelprinterForm.patchValue({
				  FileName: retVal
				});
			
			  this.urldata=this.applicationSettings.retrieveUrl()+'/uploadedfiles/BranchLabelPrinters/'+retVal;
			}
			else {
			  alert("File Not Uploaded");
			  this.branchlabelprinterForm.patchValue({
				FileName: ''
			  });
			  this.urldata="";
			}
		  }
		  ,(error: any) => this.errorMessage = <any>error
		);
	  }	
	}
}
