
<div class='panel panel-primary animaPlaceholder' *ngIf = 'branchlabelprinter'>
	<div class='panel-heading'>
		<span *ngIf = 'branchlabelprinter' >{{ page }} :{{ branchlabelprinter.Id }}</span>
		<span class= 'pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class= 'panel-body'>
		<div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<form class='form-horizontal' novalidate [formGroup]='branchlabelprinterForm'>
			<fieldset>
			<div class='panel-body'>
				<div class="row">
					<div class="col-md-12">
						<div class="col-md-6">
							<div class='form-group'>
								<label class='col-md-2 control-label'> Name</label>
								<div class='col-md-10'>
									<input class='form-control' type='text' formControlName='Name' />
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Device Type</label>
								<div class='col-md-10'>
									<select class="form-control" formControlName='PrinterType'>
										<option value="2x4">2x4</option>
										<option value="4x6">4x6</option>
										<option value="4x1">4x1</option>
										<option value="6x2">6x2</option>
										<option value="HighPressure">HighPressure</option>
										<option value="LowPressure">LowPressure</option>
										<option value="InvLbl">Inventory Label 2 5/8 by 6</option>
									</select>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> IP</label>
								<div class='col-md-10'>
									<input class='form-control' type='text' formControlName='IP' />
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Branch </label>
								<div class='col-md-10'>
									<select class="form-control" formControlName='BranchId'>
										<option value=""> </option>
										<option *ngFor='let branch of branches;' [value]="branch.BranchNumber">
											{{branch.BranchName}} {{branch.BranchNumber}}</option>
									</select>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> ZPL</label>
								<div class='col-md-10'>
									<input class='form-control' type='text' formControlName='ZPL' />
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> PLCChannel</label>
								<div class='col-md-10'>
									<input class='form-control' type='number' formControlName='PLCChannel' />
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Port</label>
								<div class='col-md-10'>
									<input class='form-control' type='number' formControlName='Port' />
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Mac Address</label>
								<div class='col-md-10'>
									<input class='form-control' type='text' formControlName='MacAddress' />
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class='form-group' *ngIf="branchlabelprinter.Id">					
								<div class='col-md-10'>
									<div *ngIf="branchlabelprinterForm.controls['FileName'].value">
										<img [src]="urldata" style="width: 500px; height:350px;" >
									</div>
								</div>
							</div>
							<div class='form-group' *ngIf="branchlabelprinter.Id">
								<label class='col-md-2 control-label'> Image</label>
								<div class='col-md-10'>
									<input (change)="onFileSelected($event)" type="file" >
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			</fieldset>
		</form>

			<div class='row' style='padding: 15px;'>
				<div class= 'col-md-12'>
					<span>
						<button class='btn btn-primary btn-edit-sml'
							[disabled]='buttonPushed'
								(click)='saveBranchlabelprinter(false, false)'
									style='width: 110px; margin-right:10px'
									[disabled]='!branchlabelprinterForm.valid'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
								</button>
								<button class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveBranchlabelprinter(true, false)'
									style='width: 150px; margin-right:10px'
									[disabled]='!branchlabelprinterForm.valid'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
								</button>
								<button *ngIf='showNextRecordButton' class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveBranchlabelprinter(false, true)'
									style='width: 190px; margin-right:10px'
									[disabled]='!branchlabelprinterForm.valid'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
								</button>
							</span>

						<span  class='pull-right remove-float-sml'>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='cancel()'
								style='width: 150px; margin-right:10px'
								[disabled]='!branchlabelprinterForm.valid'>
								Cancel &nbsp;
							</button>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='deleteBranchlabelprinter()'
								style='width: 150px; margin-right:10px'
								[disabled]='!branchlabelprinterForm.valid'>
								<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
							</button>
						</span>
					</div>
				</div>

				<div class='row'>
					<div class='col-md-12'>
						<span> Updated By: <label *ngIf='branchlabelprinter'>{{branchlabelprinter.UpdatedBy}}</label></span>
						<span> Time: <label *ngIf='branchlabelprinter'>{{branchlabelprinter.UpdatedDate}}</label></span>
					</div>
				</div>
	</div>
</div>