
<div class='panel panel-primary animaPlaceholder' *ngIf = 'testcontent'>
	<div class='panel-heading'>
		<span *ngIf = 'testcontent' >{{ page }} :{{ testcontent.Id }}</span>
		<span class= 'pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class= 'panel-body'>
		<div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<form class='form-horizontal' novalidate [formGroup]='testcontentForm'>
			<fieldset>
			<div class='panel-body'>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Content Id</label>
						<div class='col-md-10'>
							<input class='form-control' type='number' formControlName='ContentId' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Status</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='Status' />
							<span *ngIf = "testcontentForm.controls['Status'].invalid  && testcontentForm.controls['Status'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Notes</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='Notes' />
							<span *ngIf = "testcontentForm.controls['Notes'].invalid  && testcontentForm.controls['Notes'].errors.maxlength" class="text-danger">
									Only 0 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Reviewed Date</label>
						<div class='col-md-10'>
							<input class='form-control' type='date' formControlName='ReviewedDate' />
						</div>
					</div>
			</div>
			</fieldset>
		</form>

			<div class='row' style='padding: 15px;'>
				<div class= 'col-md-12'>
					<span>
						<button class='btn btn-primary btn-edit-sml'
							[disabled]='buttonPushed'
								(click)='saveTestcontent(false, false,false)'
									style='width: 110px; margin-right:10px'
									[disabled]='!testcontentForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
								</button>
								<button class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveTestcontent(true, false,false)'
									style='width: 150px; margin-right:10px'
									[disabled]='!testcontentForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
								</button>
								<button *ngIf='showNextRecordButton && testcontent.Id ' class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveTestcontent(false, true,false)'
									style='width: 190px; margin-right:10px'
									[disabled]='!testcontentForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
								</button>
								<button  class='btn btn-primary btn-edit-sml' *ngIf="!testcontent.Id"  
									  [disabled]='buttonPushed'
									  (click)='saveTestcontent(false, false,true)'
									  style ='width: 200px; margin-right:10px'
									  [disabled]='!testcontentForm.valid || progressStatus'>
									   <span class='glyphicon glyphicon-floppy-save'></span> Save & Add New Record &nbsp;
								  </button>
							</span>

						<span  class='pull-right remove-float-sml'>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='cancel()'
								style='width: 150px; margin-right:10px'
								[disabled]='!testcontentForm.valid || progressStatus'>
								Cancel &nbsp;
							</button>
							<button class='btn btn-primary btn-edit-sml' *ngIf="testcontent.Id" 
								[disabled]='buttonPushed'
								(click)='deleteTestcontent()'
								style='width: 150px; margin-right:10px'
								[disabled]='!testcontentForm.valid || progressStatus'>
								<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
							</button>
						</span>
					</div>
				</div>

				<div class='row'>
					<div class='col-md-12'>
						<span> Updated By: <label *ngIf='testcontent'>{{testcontent.UpdatedBy}}</label></span>
						<span> Time: <label *ngIf='testcontent'>{{testcontent.UpdatedDate}}</label></span>
					</div>
				</div>
	</div>
</div>
<div>
<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>