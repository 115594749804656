import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ApplicationSettings } from '../application-settings';

@Injectable()
export class HeaderVersionService {
	private baseUrl;

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/version';
	}
	getByLink(){
		const url=`${this.baseUrl}/getdateversion`;
		return this.authHttp.get<any>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
    processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}
}