import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'

import { CustomerListComponent } from './customer-list/customer-list.component';
import { CustomerEditComponent } from './customer-edit/customer-edit.component';
import { CustomerService } from './customer-service';
import { MatProgressSpinnerModule as MatProgressSpinnerModule }  from '@angular/material/progress-spinner';
import { DialogsModule } from '../shared/dialogs/dialogs.module';

@NgModule({
	declarations: [
		CustomerListComponent
		, CustomerEditComponent
	],
	imports: [
		MatProgressSpinnerModule,
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		DialogsModule,
		RouterModule.forChild([
			{ path: 'customers', component: CustomerListComponent },
			{ path: 'customer-details/:Id/:Id2', component: CustomerEditComponent },
		])
	],
	exports:[
		CustomerListComponent
	],
	providers: [
		CustomerService
	]
})
export class CustomerModule { }
