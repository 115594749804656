import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IQuestion} from './Question-model/Question';
import { IQuestions } from './Question-model/Questions';
import { IQuestionSearch } from './Question-model/QuestionSearch';

import { ApplicationSettings } from '../../shared/application-settings';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';

@Injectable()
export class QuestionService {
	private baseUrl;
	public pageTitle = 'Questions List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Questions';
	}

	getQuestion(id: number):  Observable<IQuestion> {
		if (id === 0) {
			return of(this.initializeQuestion());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IQuestion>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveQuestion(question: IQuestion): Observable<any> {
		if (question.Id === 0) {
			return this.createQuestion(question);
		}
		return this.updateQuestion(question);
	}

	private createQuestion(question: IQuestion): Observable<any> {
		question.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/questioncreate', question)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateQuestion(question: IQuestion): Observable<IQuestion> {
		return this.authHttp.put<IQuestion>(this.baseUrl, question)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteQuestion(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeQuestion(): IQuestion {
		return {
			Id: 0,
			LessonId: 0,
			Name: '',
			Description: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IQuestion;
	}

	public getRecordSet(search: IQuestionSearch): Observable<IQuestions> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('LessonId', search.LessonId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Name', search.Name, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Description', search.Description, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('LessonId', search.LessonId ? search.LessonId : '')
			.set('Name', search.Name ? search.Name : '')
			.set('Description', search.Description ? search.Description : '')
		};

		return this.authHttp.get<IQuestions>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IQuestions>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IQuestionSearch { 
		let search: IQuestionSearch = {
			Id: 1,
			LessonId: '',
			Name: '',
			Description: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.error.ErrorDescription}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: IQuestionSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('LessonId', search.LessonId ? search.LessonId : '')
			.set('Name', search.Name ? search.Name : '')
			.set('Description', search.Description ? search.Description : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}

	getAll():  Observable<IQuestion[]> {
		const url = `${this.baseUrl}/getall`;
		return this.authHttp.get<IQuestion[]>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
}