import { Component, OnInit, OnDestroy, ViewChildren, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControlName, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IHelp } from '../help-model/help';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';
import { Subscription } from 'rxjs/Subscription';
import { HelpService } from '../help-service';
import { IHelps } from '../Help-model/Helps';
import { IHelpSearch } from '../Help-model/HelpSearch';
import { HelpFileDto } from '../help-model/HelpFileDto';
import { ApplicationSettings } from '../../shared/application-settings';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';

@Component({
	templateUrl: './help-edit.component.html',
	styleUrls: ['help-edit.component.css']
})

export class HelpEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	@ViewChild(MatPaginator) paginator: MatPaginator;
	displayedColumns: string[] = ['Edit','Copy', 'FileName', 'FileDescription', 'SortOrder', 'IncludeInReport', 'Delete'];
	public page = 'Edit Helps';
	errorMessage: string;
	helpForm: FormGroup;
	help: IHelp;
	private sub: Subscription;
	public filesForm: FormGroup
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public pageSize = '10';
	
	public UserSearches: IUsersearch[];
	public HelpSearch: IHelpSearch;
	public ListData: IHelps;
	public showNextRecordButton: boolean = true;
	public progressStatus = false;

	public buttonName: any = 'Save File';
	public filename: any = '';
	public show: boolean = false;
	public showButtonName: any = 'Add New File';
	fileFormSubmitted: boolean = false;
	fileToEdit: HelpFileDto;
	public dataSource: any;
	public fileList: any;
	helpViewData: any;
	helpDeveloperData: any;
	dataContent: any;
	dataDeveloper: any;
	helpId: number;

	@ViewChild('inputFile') fileUp: ElementRef;
	@ViewChild('firstPaginator', { static: true }) firstPaginator: MatPaginator;
	constructor(private fb: FormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, public applicationSettings: ApplicationSettings
		, private router: Router
		, private helpService: HelpService,
	) {
	}

	ngOnInit(): void {
		this.helpForm = this.fb.group({
			Id: '',
			LinkName: ['', [Validators.maxLength(255)]],
			Content: ['', [Validators.maxLength(4000)]],
			DeveloperContent: ['', [Validators.maxLength(4000)]],
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.filesForm = this.fb.group({
			fileUpload: ['', Validators.required],
			fileDescription: [''],
			sortOrder: [''],
			includeReport: [''],
		})
		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.helpId = id;
				this.getHelp(id);
				this.progressStatus = true;
			}
		);
	}

	public getSelectedRecordSet(): void {
		this.helpService.getRecordSet(this.HelpSearch)
			.subscribe((obj: IHelps) => this.onSelectedRecordSetReceived(obj),
				(error: any) => {
					this.errorMessage = <any>error;
				});
	}

	private onSelectedRecordSetReceived(obj: IHelps) {
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.help.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

		this.progressStatus = false;

	}

	saveHelp(returnToList: boolean, advanceToNextRecord: boolean): void {
		this.buttonPushed = true;

		if (this.helpForm.valid) {
			const obj = Object.assign({}, this.help, this.helpForm.value);
			this.dataContent = obj.Content;
			this.dataDeveloper = obj.DeveloperContent;
			this.helpService.saveHelp(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord)
					, (error: any) => this.errorMessage = <any>error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean): void {
		if (returnToList) {
			this.helpForm.reset();
			this.router.navigate(['/helps']);
		}
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
		// when the record id is zero then it is a new record.
		else if (data !== null && this.help.Id === 0) {
			this.router.navigate(['/help-details', data.Id]);
		}
		else if (this.help.Id != 0) {
			this.getHelp(this.help.Id);
		}

		this.buttonPushed = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.help.Id)
				record = count;
		});

		this.getHelp(this.ListData.data[record].Id)
	}

	getHelp(id: number): void {
		this.helpService.getHelp(id)
			.subscribe((obj: IHelp) => this.onHelpRetrieved(obj),
				(error: any) => {
					this.errorMessage = <any>error;
					// this.spinner.stop();
				});
	}

	onHelpRetrieved(help: IHelp): void {
		if (this.helpForm) {
			this.helpForm.reset();
		}
		this.help = help;
		if (this.help.Id === 0) {
			this.page = 'Add Helps';
		} else {
			this.page = 'Edit Helps';
		}

		this.helpForm.patchValue({
			Id: this.help.Id,
			LinkName: this.help.LinkName,
			Content: this.help.Content,
			DeveloperContent: this.help.DeveloperContent,
			CreatedDate: this.help.CreatedDate,
			CreatedBy: this.help.CreatedBy,
			UpdatedDate: this.help.UpdatedDate,
			UpdatedBy: this.help.UpdatedBy,
		});
		this.dataContent = this.help.Content;
		this.dataDeveloper = this.help.DeveloperContent;
		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteHelp(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.help.LinkName}?`)) {
			this.helpService.deleteHelp(this.help.Id)
				.subscribe(() => this.onSaveComplete(true, null, false)
					, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/helps']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Helps List')
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
			}
				, (error: any) => this.errorMessage = <any>error);
	}

	setUserFilters(): void {
		this.HelpSearch = this.helpService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'LinkName')
				this.HelpSearch.LinkName = i.Value;

			if (i.Item == 'Content')
				this.HelpSearch.Content = i.Value;
			if (i.Item == 'DeveloperContent')
				this.HelpSearch.DeveloperContent = i.Value;

			if (i.Item == 'orderByString')
				this.HelpSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.HelpSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
		this.getFileHelpData();
	}

	onFileSelected(event) {
		console.log('onFileSelected:');
		if (event.target.files.length > 0) {
			this.filesForm.patchValue({
				fileUpload: event.target.files[0],
			})
		}
	}
	onSaveFile() {
		console.log('onSaveFile');

		this.fileFormSubmitted = true;

		if (this.filesForm.invalid) {
			console.log('onSaveFile::Form Invalid');
			return;
		}

		const fileForm = this.filesForm.value;
		const formData = new FormData();
		formData.append('RecordId', this.help.Id.toString());
		formData.append('FileDescription', fileForm.fileDescription || 0);
		formData.append('IncludeInReport', !!fileForm.includeReport ? '1' : '0');
		formData.append('SortOrder', fileForm.sortOrder || 0);
		formData.append('FileName', fileForm.fileUpload);


		if (this.fileToEdit && this.fileToEdit.Id) {
			formData.append('Id', this.fileToEdit.Id.toString());
			formData.append('GeneratedFileName', this.fileToEdit.GeneratedFileName);

			this.helpService.updateFileHelp(formData).subscribe(file => {
				this.fileToEdit = null;
				this.clearForm();
				this.fileUp.nativeElement.value = '';
				this.getFileHelpData();
			});
		}
		else {
			this.helpService.createFileHelp(formData).subscribe(file => {
				this.clearForm();
				this.fileUp.nativeElement.value = '';
				this.getFileHelpData()
			});
		}
	}
	editFileData(file) {
		this.fileToEdit = file;
		this.filesForm.patchValue({
			fileUpload: this.fileToEdit.FileName,
			fileDescription: this.fileToEdit.FileDescription,
			sortOrder: this.fileToEdit.SortOrder,
			includeReport: this.fileToEdit.IncludeInReport === 1 ? true : false
		});
	}

	clearForm() {
		this.filesForm.reset();
		this.fileFormSubmitted = false;
	}

	open(row) {
		window.open(`${this.applicationSettings.retrieveUrl()}${row.FilePath}${row.GeneratedFileName}`);
	}

	deleteFileData(id) {
		this.helpService.deleteFileHelp(id).subscribe(file => this.getFileHelpData())
	}

	//This gets the file grid
	getFileHelpData() {
		console.log('should get the file grid');
		if (this.help) {
			if (this.help.Id > 0) {
				this.helpService.getAllFilesHelp(this.help.Id).subscribe(response => {
					console.log('file list returned');
					console.log(response);
					this.dataSource = new MatTableDataSource(response);
					this.dataSource.paginator = this.firstPaginator;
					this.recordTotal = response.length;
					this.fileList = response;
					console.log(this.fileList);
				});
			}
		}
	}

	resetError(): void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
	tabClick(tab) {
		if (tab.tab.textLabel === "Help View") {
			this.helpViewData = this.dataContent;
			if (this.helpViewData != null) {
				this.helpViewData = this.replaceFileTag(this.helpViewData);
			}
			this.helpDeveloperData = this.dataDeveloper;
			if (this.helpDeveloperData != null) {
				this.helpDeveloperData = this.replaceFileTag(this.helpDeveloperData);
			}
		}
	}
	replaceFileTag(cdata) {
		var contentdata = cdata;
		var datalist = contentdata.split('>>>');
		datalist.forEach(e => {
			if (e.indexOf("<<<") != -1) {
				var d1 = e.split('<<<')[1];
				var d2 = d1.split(',');
				var d3 = d2[0];
				let index = this.fileList.findIndex(x => x.FileName === d3);
				if (index != -1) {
					contentdata = contentdata.replace("<<<" + d1 + ">>>", "<img src='" + this.applicationSettings.retrieveUrl() + this.fileList[index].FilePath + this.fileList[index].GeneratedFileName + "'  width='" + d2[1] + "' height='" + d2[2] + "'/>");
				}
			}
		})
		return contentdata;
	}
	copy(row) {
		return `<img src='${this.applicationSettings.retrieveUrl()}${row.FilePath}${row.GeneratedFileName}' width="700px" height="500px" >`;
	 }
}
