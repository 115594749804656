import { Component, Inject, OnInit } from "@angular/core"; 
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
@Component({selector: 'confirmation-dialog',
templateUrl: './confirmation-dialog.component.html'
 })   
export class ConfirmationDialogComponent  implements OnInit {

  constructor(
      public dialog: MatDialogRef<ConfirmationDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public message: string) { }
  
      closeDialog(): void {
        this.dialog.close(false);
      }
      confirm(): void {
        this.dialog.close(true);
      }
  
    ngOnInit() {
    }
  
  }