
<div class='card animaPlaceholder'>
	<div class='card-heading'>
		<span *ngIf = 'worktask'>{{page}} :{{worktask.Id}}</span >
		<span class='pull-right'>
			{{record}} of {{recordTotal}}
		</span>
		<div class='has-error' *ngIf='errorMessage'>{{ errorMessage }}</div>
	</div>
<div class='container-fluid'>
	<div class='card-body'>
		<form class='form-horizontal' novalidate [formGroup]='worktaskForm'>
			<fieldset>
				<div class='row' style='padding: 15px;'>
					<div class='col-md-12'>
						<span>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='saveWorktask(false, false)'
								style='width: 110px; margin-right:10px'
								[disabled]='!worktaskForm.valid'>
								<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
							</button>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='saveWorktask(true, false)'
								style='width: 150px; margin-right:10px'
								[disabled]='!worktaskForm.valid'>
								<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
							</button>
							<button *ngIf='showNextRecordButton' class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='saveWorktask(false, true)'
								style='width: 190px; margin-right:10px'
								[disabled]='!worktaskForm.valid'>
								<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
							</button>
						</span>
						<span  class='pull-right remove-float-sml'>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='cancel()'
								style='width: 150px; margin-right:10px'
								[disabled]='!worktaskForm.valid'>
								Cancel &nbsp;
							</button>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='deleteWorktask()'
								style='width: 150px; margin-right:10px'
								[disabled]='!worktaskForm.valid'>
								<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
							</button>
						</span>
					</div>
				</div>
				<div class='row'>

<!-- Column 1 -->
<div class='col-md-6'>
<div class='row'>
<!-- Column 1-->


			<div class='row mt-2'>
			<label class='col-md-2 control-label'> Name</label>
				<div class='col-md-10'>
					<input class='form-control' type='text' formControlName='Name' />
				</div>
			</div>
	<div class='row mt-2'>
	<label class='col-md-2 control-label'> Branch Id</label>
		<div class='col-md-4'>
			<input class='form-control' type='number' formControlName='BranchId' />
		</div>

		<label class='col-md-2 control-label'> Status</label>
			<div class='col-md-3'>
				<select  class="form-control"  formControlName='Status' >
					<option *ngFor="let item of applicationSettings.workStatus" [value]="(item)">{{item}}</option>
				</select>
			</div>
		</div>
		<div class='row mt-2'>
			<label class='col-md-2 control-label'> Estimated Hours</label>
				<div class='col-md-4'>
					<input class='form-control' type='number' formControlName='EstimatedHours' />
				</div>
			
			<label class='col-md-2 control-label'> Priority</label>
				<div class='col-md-3'>
					<input class='form-control' type='number' formControlName='Priority' />
				</div>
			</div>
			

			<div class='row mt-2'>
			<label class='col-md-2 control-label'> Completed Date</label>
				<div class='col-md-4'>
					<input class='form-control' type='date' formControlName='CompletedDate' />
				</div>
			
			<label class='col-md-2 control-label'> App</label>
				<div class='col-md-3'>
					<select  class="form-control"  formControlName='Application' >
						<option *ngFor="let item of applicationSettings.apps" [value]="(item)">{{item}}</option>
					</select>
				</div>
			</div>
			<div class='row mt-2'>
				<label class='col-md-2 control-label'> Description</label>
					<div class='col-md-10'>
						<input class='form-control' type='text' formControlName='Description' />
					</div>
			</div>
			<div class='row mt-2'>
				<label class='col-md-2 control-label'> Assigned User</label>
					<div class='col-md-4'>
						<select  class="form-control"  formControlName='AssignedUser' >
							<option *ngFor="let item of applicationSettings.employees" [value]="(item)">{{item}}</option>
						</select>
					</div>
			<label class='col-md-2 control-label'> Type1</label>
				<div class='col-md-3'>
					<input class='form-control' type='text' formControlName='Type1' />
				</div>
			</div>
			<div class='row mt-2'>
			<label class='col-md-2 control-label'> Type2</label>
				<div class='col-md-4'>
					<input class='form-control' type='text' formControlName='Type2' />
				</div>
			
			<label class='col-md-2 control-label'> Type3</label>
				<div class='col-md-3'>
					<input class='form-control' type='text' formControlName='Type3' />
				</div>
			</div>
			<div class='row mt-2'>
			<label class='col-md-2 control-label'> Notes</label>
				<div class='col-md-10'>
					<input class='form-control' type='text' formControlName='Notes' />
				</div>
			</div>
			<div class='row mt-2'>
			<label class='col-md-2 control-label'> Installed Date</label>
				<div class='col-md-10'>
					<input class='form-control' type='date' formControlName='InstalledDate' />
				</div>
			</div>
			
			<div class='row mt-2'>
			<label class='col-md-2 control-label'>nextaction</label>
				<div class='col-md-10'>
					<input class='form-control' type='text' formControlName='nextaction' />
				</div>
			</div>
			<div class='row mt-2'>
			<label class='col-md-2 control-label'> Dev Notes</label>
				<div class='col-md-10'>
					<input class='form-control' type='text' formControlName='DevNotes' />
				</div>
			</div>

			</div>


<!-- End Column 1 -->
</div>

<!-- End Column 1-->
<!-- Column 2 -->
<div class='col-md-6'>
<div class='row'>
<!-- Column 2-->

<div class='col-md-2'>
 <b>Work Log List</b>
</div>
<div class='col-md-10'>
	<div *ngIf='worktask && worktask.Id'>
		<button class='btn btn-light btn-sm' [routerLink]="['/worklog-details', 0, worktask.Id]" style='width:160px;margin: 10px;'>
			Add New Work Log</button>
	
		<div class='has-error' *ngIf='errorMessage'>{{ errorMessage }}</div>
	</div>
</div>

<div class='table-container'>
	<table mat-table [dataSource]='dataSourceIncompletedWork' class='mat-elevation-z8' matSort >
	<ng-container matColumnDef='Id'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
		<td mat-cell *matCellDef='let e'>
		<a class='btn btn-light' style='min-width: 80px;' [routerLink]="['/worklog-details', e.Id, worktask.Id]"  >{{e.Id}}</a>
		</td>
	</ng-container>
	<ng-container matColumnDef='Description' >
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'>Description </th>
		<td mat-cell *matCellDef='let e'> {{e.Description}} </td>
	</ng-container>
	<ng-container matColumnDef='WorkDate'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header disableClear class='col3'>WorkDate</th>
		<td mat-cell *matCellDef='let e'> {{e.WorkDate}} </td>
	</ng-container>
	<ng-container matColumnDef='Hours'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header disableClear class='col4'>Hours</th>
		<td mat-cell *matCellDef='let e'> {{e.Hours}} </td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
	<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
</div>  
<!-- <mat-paginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator> -->

</div>
</div>
<!-- End Column 2-->

</div>

				<div *ngIf='worktask && worktask.Id' class='row'>
					<div class='col-md-12'>
					
					</div>
				</div>

				<div class='row'>
					<div class='col-md-12'>
						<span>Updated By: <label *ngIf='worktask'>{{worktask.UpdatedBy}}</label></span>
						<span> Time: <label *ngIf='worktask'>{{worktask.UpdatedDate}}</label></span>
					</div>
				</div>
			</fieldset>
		</form>
	</div>
	</div>
</div>