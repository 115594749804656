
<div class='card animaPlaceholder' *ngIf = 'customercontact'>
	<div class='card-heading'>
		<span *ngIf = 'customercontact' >{{ page }} :{{ customercontact.Id }}</span>
		<span class= 'pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class= 'card-body'>
		<div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<form class='form-horizontal' novalidate [formGroup]='customercontactForm'>
					<fieldset>
					<div class='card-body'>
						<div class='row mt-2'> 
							<label class='col-md-2 control-label'> Customer Name</label>
							<div class='col-md-10'>
							   <input class='form-control' type='text' formControlName='CustomerName' matInput
								  [matAutocomplete]="autoCustomerId" />
							   <mat-autocomplete autoActiveFirstOption #autoCustomerId="matAutocomplete">
							   <mat-option *ngFor = "let item of autoCustomerName" [value] = "item.CustomerName"
								(onSelectionChange) = "this.customercontactForm.controls['CustomerId'].setValue(item.Id)" > 
								{{item.CustomerName}}
							   </mat-option>
							   </mat-autocomplete>
							   <input type = "hidden" formControlName="CustomerId" />
							   </div>
						  </div>
							<div class='row mt-2'>
								<label class='col-md-2 control-label'> Type</label>
								<div class='col-md-10 d-flex'>
									<button class="btn btn-primary btn-sm" (click)="openLookUpList('Type')">A</button>
									<select class="form-control" formControlName='Type'>
										<option *ngFor='let data of typeList;' [value]="data.Value">
											{{data.Value}}</option>
									</select>
									<span *ngIf = "customercontactForm.controls['Type'].invalid  && customercontactForm.controls['Type'].errors.maxlength" class="text-danger">
											Only 250 characters are allowed.
									</span>
								</div>
							</div>
							<div class='row mt-2'>
								<label class='col-md-2 control-label'> Contact Date</label>
								<div class='col-md-10'>
									<input class='form-control' type='date' formControlName='ContactDate' />
								</div>
							</div>
							<div class='row mt-2'>
								<label class='col-md-2 control-label'> Future Reminder Date</label>
								<div class='col-md-10'>
									<input class='form-control' type='date' formControlName='FutureReminderDate' />
								</div>
							</div>
							<div class='row mt-2'>
								<label class='col-md-2 control-label'> Notes</label>
								<div class='col-md-10'>
									<input class='form-control' type='text' formControlName='Notes' />
									<span *ngIf = "customercontactForm.controls['Notes'].invalid  && customercontactForm.controls['Notes'].errors.maxlength" class="text-danger">
											Only 4000 characters are allowed.
									</span>
								</div>
							</div>
					</div>
					</fieldset>
				</form>
			</div>
			<div class="col-md-6">
				<div *ngIf = "customercontactId" >
					<div class='card-body'>
				   <form [formGroup]='filesForm' novalidate
				   style = "display: flex;flex-direction: column;row-gap: 10px; ">
				   <div>
				   <div class="col-md-2">
					   <label class='control-label'>File Upload:</label>
					</div>
					<div class="col-md-4">
					   <input class="form -control" type="file" #inputFile
					(change)="onFileSelected($event)">
					   <div *ngIf = "fileToEdit && fileToEdit.Id" >
					   {{fileToEdit.FileName}}
					</div>
					<div *ngIf = "fileFormSubmitted && filesForm.controls.fileUpload.errors?.required" >
					<span style="color:red"> Required </span></div>
					</div>
					</div>
					<div>
					<div class="col-md-2">
					<label class=''> File Description </label>
					</div>
					<div class="col-md-4">
					<input class="form-control" type ="text" formControlName = 'fileDescription' >
					</div>
					</div>
					<div>
					<div class="col-md-2">
					<label class='control-label'> Sort Order </label>
					 </div>
					<div class="col-md-4">
					<input class="form-control" type = "number" formControlName = 'sortOrder' >
					</div>
					</div>
					<div class= 'row mt-2' style ="display:flex;align-items: center;" >
					<label class='col-md-2 control-label' > Include in Report </label>
					<div class="col-md-1 text-left">
					 <input style = "width:34px;height: 34px;" class= "form-check-input" type = "checkbox"
					formControlName = 'includeReport' >
					</div>
					</div>
					<div class="col-md-4 col-12 row mt-2">
					<button class="btn btn-primary form-control center-center"
					style ="width:auto" type = "button"(click)= "onSaveFile()" > Save
					File </button>
					</div>
					</form>
					</div>
					<div>
						<div class='table-container'>
					 <table mat-table [dataSource] = "dataSource" class= 'mat-elevation-z8 table-wdt' matSort >
					  <ng-container matColumnDef="Edit">
						<th mat-header-cell *matHeaderCellDef class='col1' > EDIT </th>
						<td mat-cell *matCellDef ="let element"> <button class= 'btn btn-light'
						style= 'min-width: 50px;' (click) ="editFileData(element)" > <span style="color: rgb(4, 50, 255);"
						class="glyphicon glyphicon-edit"> EDIT </span ></button></td>
					 </ng-container>
					 <ng-container matColumnDef="FileName" >
					 <th mat-header-cell *matHeaderCellDef class='col2'> File Name </th>
					 <td mat-cell *matCellDef = "let element" >
					   <a href ="javascript:void(0)"
						(click) = "open(element)" > {{element.FileName}}
					   </a>
					 </td>
					</ng-container>
					 <ng-container matColumnDef="FileDescription" >
					 <th mat-header-cell *matHeaderCellDef class= 'col4' > Description </th>
					 <td mat-cell *matCellDef = "let element" > {{ element.FileDescription}} </td>
					 </ng-container>
					 <ng-container matColumnDef ="SortOrder">
					 <th mat-header-cell *matHeaderCellDef class= 'col5' > Sort Order </th>
					 <td mat-cell *matCellDef="let element" > {{ element.SortOrder}} </td>
					 </ng-container>
					  <ng-container matColumnDef="IncludeInReport">
					  <th mat-header-cell *matHeaderCellDef class= 'col6' > Include In Report</th>
					  <td mat-cell *matCellDef ="let element" > {{element.IncludeInReport === 1 ? 'True' :
					  'False'}}
					  </td>
					  </ng-container>
					 <ng-container matColumnDef="Delete" >
					 <th mat-header-cell *matHeaderCellDef class= 'col7' > Delete </th>
					 <td mat-cell *matCellDef ="let element" >
					 <button class='btn btn-light btn-xs'
					 style ='min-width: 50px;background: none;border: none;'
					 (click) = "deleteFileData(element.Id)" >
					 <span class= "glyphicon glyphicon-trash text-success text-dlt" ></span>
					 </button>
					 </td>
					 </ng-container>
					<tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
					<tr mat-row *matRowDef ="let row; columns: displayedColumns;" ></tr>
					</table>
					<mat-paginator #firstPaginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>
				</div>	
				</div>
					</div>
			</div>
		</div>
			<div class='row' style='padding: 15px;'>
				<div class= 'col-md-12'>
					<span>
						<button class='btn btn-primary btn-edit-sml'
							[disabled]='buttonPushed'
								(click)='saveCustomercontact(false, false)'
									style='width: 110px; margin-right:10px'
									[disabled]='!customercontactForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
								</button>
								<button class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveCustomercontact(true, false)'
									style='width: 150px; margin-right:10px'
									[disabled]='!customercontactForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
								</button>
								<button *ngIf='showNextRecordButton' class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveCustomercontact(false, true)'
									style='width: 190px; margin-right:10px'
									[disabled]='!customercontactForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
								</button>
							</span>

						<span  class='pull-right remove-float-sml'>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='cancel()'
								style='width: 150px; margin-right:10px'
								[disabled]='!customercontactForm.valid || progressStatus'>
								Cancel &nbsp;
							</button>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='deleteCustomercontact()'
								style='width: 150px; margin-right:10px'
								[disabled]='!customercontactForm.valid || progressStatus'>
								<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
							</button>
						</span>
					</div>
				</div>

				<div class='row'>
					<div class='col-md-12'>
						<span> Updated By: <label *ngIf='customercontact'>{{customercontact.UpdatedBy}}</label></span>
						<span> Time: <label *ngIf='customercontact'>{{customercontact.UpdatedDate}}</label></span>
					</div>
				</div>
	</div>
</div>
<div>
<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>