import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { ILookuplist} from './Lookuplist-model/Lookuplist';
import { ILookuplists } from './Lookuplist-model/Lookuplists';
import { ILookuplistSearch } from './Lookuplist-model/LookuplistSearch';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';

@Injectable()
export class LookuplistService {
	private baseUrl;
	public pageTitle = 'Lookup Lists';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Lookuplists';
	}

	getLookuplist(id: number):  Observable<ILookuplist> {
		if (id === 0) {
			return of(this.initializeLookuplist());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<ILookuplist>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveLookuplist(lookuplist: ILookuplist): Observable<any> {
		if (lookuplist.Id === 0) {
			return this.createLookuplist(lookuplist);
		}
		return this.updateLookuplist(lookuplist);
	}

	private createLookuplist(lookuplist: ILookuplist): Observable<any> {
		lookuplist.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/lookuplistcreate', lookuplist)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateLookuplist(lookuplist: ILookuplist): Observable<ILookuplist> {
		return this.authHttp.put<ILookuplist>(this.baseUrl, lookuplist)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteLookuplist(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeLookuplist(): ILookuplist {
		return {
			Id: 0,
			Form: '',
			Field: '',
			Value: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as ILookuplist;
	}

	public getRecordSet(search: ILookuplistSearch): Observable<ILookuplists> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('Form', search.Form, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Field', search.Field, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Value', search.Value, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('Form', search.Form ? search.Form : '')
			.set('Field', search.Field ? search.Field : '')
			.set('Value', search.Value ? search.Value : '')
		};

		return this.authHttp.get<ILookuplists>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<ILookuplists>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : ILookuplistSearch { 
		let search: ILookuplistSearch = {
			Id: 1,
			Form: '',
			Field: '',
			Value: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: ILookuplistSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('Form', search.Form ? search.Form : '')
			.set('Field', search.Field ? search.Field : '')
			.set('Value', search.Value ? search.Value : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
	getLookupListByForm(form,field):  Observable<ILookuplist[]> {
		return this.authHttp
		.get<ILookuplist[]>( `${this.baseUrl}/getlookuplistbyform?form=${form}&field=${field}`)
		.pipe(retry(1), catchError(this.processError));

	}
	
}