import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {merge} from 'rxjs';
import { Router } from '@angular/router';

import { ApplicationSettings } from '../../../shared/application-settings';
import { IUsersearch } from '../../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../../admin/usersearches/usersearch-service';

import { EmployeeanswerService } from '../employeeanswer-service';
import { IEmployeeanswerSearch } from '../employeeanswer-model/employeeanswersearch';
import { IEmployeeanswers } from '../employeeanswer-model/employeeanswers';

@Component({
	templateUrl: './employeeanswer-list.component.html',
	styleUrls: ['./employeeanswer-list.component.css']
})

export class EmployeeanswerListComponent implements OnInit, AfterViewInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	displayedColumns: string[] = ['Id', 'QuestionId', 'LessonName', 'LessonId', 'ContentName', 'ContentDescription', 'ContentReviewed', 'ContentStatus', 'ContentNotes', 'LessonUser', 'Employee'];
	isLoadingResults = true;

	public UserSearches: IUsersearch[];
	public pageSize = '10';
	public skip = 0;
	public recordTotal: number = 0;

	public showHideSearch: boolean = false;
	public SearchText: string = 'Show Filters';

	public QuestionId = '';
	public LessonName = '';
	public LessonId = '';
	public ContentName = '';
	public ContentDescription = '';
	public ContentReviewed = '';
	public ContentStatus = '';
	public ContentNotes = '';
	public LessonUser = '';
	public Employee = '';

	public pageTitle = 'EmployeeAnswers List';
	public errorMessage = '';
	public dataSource: any;
	public excelFile;
	public progressStatus=false;

	constructor(private userSearchService: UsersearchService
		, public applicationSettings: ApplicationSettings
		 , private router: Router
		, private _appService: EmployeeanswerService) {
	}

	ngOnInit(): void {
		this.progressStatus = true;
		this.skip = 0;
		let x  = +localStorage.getItem('rows');
		if(x>0) { this.pageSize = x.toString(); }
		else { this.pageSize = '10';}
		if (localStorage.getItem('showHideSearch') === 'true')
		{
			   this.showHideSearch = true;
			   this.SearchText = 'Hide Filters';
		}
		else
		{
			   this.showHideSearch = false;
			   this.SearchText = 'Show Filters';
		 }
	}

	ngAfterViewInit(): void {
		this.userSearchService.getUserSearchesByForm(this.pageTitle)
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.paginator.pageIndex = 0;

		this.UserSearches.forEach(i => {
			if(i.Item=='QuestionId')
				this.QuestionId = i.Value;

			if(i.Item=='LessonName')
				this.LessonName = i.Value;

			if(i.Item=='LessonId')
				this.LessonId = i.Value;

			if(i.Item=='ContentName')
				this.ContentName = i.Value;

			if(i.Item=='ContentDescription')
				this.ContentDescription = i.Value;

			if(i.Item=='ContentReviewed')
				this.ContentReviewed = i.Value;

			if(i.Item=='ContentStatus')
				this.ContentStatus = i.Value;

			if(i.Item=='ContentNotes')
				this.ContentNotes = i.Value;

			if(i.Item=='LessonUser')
				this.LessonUser = i.Value;

			if(i.Item=='Employee')
				this.Employee = i.Value;

			if(i.Item=='orderByString') {
				if (this.applicationSettings.getOrderByDirection(i.Value)==='asc')
					this.sort.direction = 'asc';
				else
					this.sort.direction = 'desc';

				this.sort.active = this.applicationSettings.getOrderByName(i.Value);
			}

			if (i.Item == 'Skip')
				this.skip = i.Value;

		});

		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
		merge(this.sort.sortChange, this.paginator.page)
			.subscribe(()=> this.getData());

		this.getData();
	}

	pageSizeValueChanged(): void {
		this.applicationSettings.setPageSize(this.pageSize.toString());
		this.paginator.pageIndex = 0;
		this.getData();
	}

	getData(): any {
	 this.progressStatus=true; 
	 this.dataSource = null; 
	 this.recordTotal = 0; 
		let search: IEmployeeanswerSearch = this._appService.getFreshSearch();

		search.QuestionId = this.QuestionId;
		search.LessonName = this.LessonName;
		search.LessonId = this.LessonId;
		search.ContentName = this.ContentName;
		search.ContentDescription = this.ContentDescription;
		search.ContentReviewed = this.ContentReviewed;
		search.ContentStatus = this.ContentStatus;
		search.ContentNotes = this.ContentNotes;
		search.LessonUser = this.LessonUser;
		search.Employee = this.Employee;
		search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
		search.take = parseInt(this.pageSize);
		search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

		this._appService.getRecordSet(search).subscribe((obj: IEmployeeanswers) => this.onSelectedRecordSetReceived(obj),
		(error: any) => {
			this.errorMessage = <any> error;
		});
	}

	private onSelectedRecordSetReceived(obj: IEmployeeanswers){
		this.dataSource = new MatTableDataSource(obj.data);
		this.recordTotal = obj.total;
		this.progressStatus = false;
	}

	searchClick(): void {
		this.skip = 0;
		this.paginator.pageIndex = 0;
		this.getData();
	}

	public searchClearClick(): void {
		this.QuestionId = '';
		this.LessonName = '';
		this.LessonId = '';
		this.ContentName = '';
		this.ContentDescription = '';
		this.ContentReviewed = '';
		this.ContentStatus = '';
		this.ContentNotes = '';
		this.LessonUser = '';
		this.Employee = '';
		this.skip = 0;
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.paginator.pageIndex = 0;
		this.getData();
	}

	resetError() : void {
		this.errorMessage = '';
		this.progressStatus=false;
	}
	showHideSearchClick() {
		 if (this.showHideSearch) 
		 {
			 this.showHideSearch = false; 
			localStorage.setItem('showHideSearch', 'false'); 
			this.SearchText = 'Show Filters'; 
		}
		else
		{
			 this.showHideSearch = true; 
			localStorage.setItem('showHideSearch', 'true'); 
			  this.SearchText = 'Hide Filters'; 
		}
	}
	searchAndHideClick() {
		 this.skip = 0;
		 this.paginator.pageIndex = 0;
		this.getData();
		 setTimeout(() => {
		     this.showHideSearchClick();
		 }, 500);
	 }
	public help()
	{
		   const url = this.router.serializeUrl(
		       this.router.createUrlTree([`/help-view/employeeanswers`])
		);
		  window.open(url, '_blank');
	 }
	public toExcel(){
			let search: IEmployeeanswerSearch = this._appService.getFreshSearch();

			search.QuestionId = this.QuestionId;
			search.LessonName = this.LessonName;
			search.LessonId = this.LessonId;
			search.ContentName = this.ContentName;
			search.ContentDescription = this.ContentDescription;
			search.ContentReviewed = this.ContentReviewed;
			search.ContentStatus = this.ContentStatus;
			search.ContentNotes = this.ContentNotes;
			search.LessonUser = this.LessonUser;
			search.Employee = this.Employee;
			search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
			search.take = parseInt(this.pageSize);
			search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

			this._appService.toExcel(search).subscribe(
				retVal => {
					    this.excelFile = retVal;
				},
				 (error: any) => {
					 this.errorMessage = < any > error;
				});
	}
	public hideFileLink()
	{
	   this.excelFile = "";
	}
	public openExcelFile()
	{
	   window.open(`${ this.applicationSettings.retrieveUrl()}${ this.excelFile}`);
	}
}