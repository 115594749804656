import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ApplicationSettings } from '../shared/application-settings';

import { retry, catchError, map } from 'rxjs/operators';
import { Observable, throwError, of } from 'rxjs';


import { IWorklog} from './Worklog-model/Worklog';
import { IWorklogs } from './Worklog-model/Worklogs';
import { IWorklogSearch } from './Worklog-model/WorklogSearch';

@Injectable()
export class WorklogService {
	private baseUrl;

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Worklogs';
	}

	getWorklog(id: number):  Observable<IWorklog> {
		if (id === 0) {
			return of(this.initializeWorklog());
		}

		const url = `${this.baseUrl}/${id}`;
		return this.authHttp.get<IWorklog>(url)
		.pipe(
			retry(1),
			catchError(this.processError));
	}

	saveWorklog(worklog: IWorklog): Observable < any > {
		if (worklog.Id === 0) {
			return this.createWorklog(worklog);
		}
		return this.updateWorklog(worklog);
	}

	private createWorklog(worklog: IWorklog): Observable <any> {
		worklog.Id = undefined;
		return this.authHttp.post(this.baseUrl, worklog)
		.pipe(
			retry(1),
			catchError(this.processError));
	}

	private updateWorklog(worklog: IWorklog): Observable <IWorklog> {
		return this.authHttp.put<IWorklog>(this.baseUrl, worklog)
		.pipe(
			retry(1),
			catchError(this.processError));
	}

	deleteWorklog(id: number): Observable < Response > {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
		.pipe(
			retry(1),
			catchError(this.processError));
	}

	initializeWorklog(): IWorklog {
		return {
			Id: 0,
			WorkTaskId: 0,
			StartTimeHour: 0,
			EndTimeHour: 0,
			StartTimeMinute: 0,
			EndTimeMinute: 0,
			Hours: 0,
			Type: '',
			Billable: '',
			Description: '',
			StartingKms: 0,
			EndingKms: 0,
			Notes: '',
			WorkDate: '',
			Person: '',
			Vehicle: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IWorklog;
	}

	public getRecordSet(search: IWorklogSearch): Observable<IWorklogs> {

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('WorkTaskId', search.WorkTaskId ? search.WorkTaskId : '')
			.set('completed', '')
		};

		return this.authHttp
			.get<any>(this.baseUrl + '/grid', options)
			.pipe(
				retry(1),
				catchError(this.processError),
				map(response => (<IWorklogs>{
					data: response.data,
					total: response.total,
					skip: search.skip
					  })));
	}

	public getFreshSearch() : IWorklogSearch { 
		let search: IWorklogSearch = {
			Id: 1,
			WorkTaskId: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
		 message = err.error.message;
		} else {
		 message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.log(message);
		return throwError(message);
	 }
}
