import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {merge} from 'rxjs';
import { Router } from '@angular/router';

import { ApplicationSettings } from '../../../shared/application-settings';
import { IUsersearch } from '../../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../../admin/usersearches/usersearch-service';

import { TestService } from '../test-service';
import { ITestSearch } from '../test-model/testsearch';
import { ITests } from '../test-model/tests';
import { LessonService } from '../../lessons/lesson-service';

@Component({
	templateUrl: './test-list.component.html',
	styleUrls: ['./test-list.component.css']
})

export class TestListComponent implements OnInit, AfterViewInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	displayedColumns: string[] = ['Id', 'LessonName', 'Employee', 'ScoreNumerator', 'ScoreDenominator', 'Notes', 'LessonStartDate', 'LessonEndDate', 'QuizStartDate', 'QuizEndDate'];
	isLoadingResults = true;

	public UserSearches: IUsersearch[];
	public pageSize = '10';
	public skip = 0;
	public recordTotal: number = 0;

	public showHideSearch: boolean = false;
	public SearchText: string = 'Show Filters';

	public LessonsId = '';
	public Employee = '';
	public ScoreNumerator = '';
	public ScoreDenominator = '';
	public Notes = '';
	public LessonStartDate = '';
	public LessonEndDate = '';
	public QuizStartDate = '';
	public QuizEndDate = '';

	public pageTitle = 'Tests List';
	public errorMessage = '';
	public dataSource: any;
	public excelFile;
	public progressStatus=false;
	public lessonsList;
	public progressLessonsStatus=false;

	constructor(private userSearchService: UsersearchService
		, public applicationSettings: ApplicationSettings
		 , private router: Router
		, private _appService: TestService
		, private lessonService: LessonService) {
	}

	ngOnInit(): void {
		this.progressStatus = true;
		this.skip = 0;
		let x  = +localStorage.getItem('rows');
		if(x>0) { this.pageSize = x.toString(); }
		else { this.pageSize = '10';}
		if (localStorage.getItem('showHideSearch') === 'true')
		{
			   this.showHideSearch = true;
			   this.SearchText = 'Hide Filters';
		}
		else
		{
			   this.showHideSearch = false;
			   this.SearchText = 'Show Filters';
		 }
		 this.getLessons();
	}

	ngAfterViewInit(): void {
		this.userSearchService.getUserSearchesByForm(this.pageTitle)
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.paginator.pageIndex = 0;

		this.UserSearches.forEach(i => {
			if(i.Item=='LessonsId')
				this.LessonsId = i.Value;

			if(i.Item=='Employee')
				this.Employee = i.Value;

			if(i.Item=='ScoreNumerator')
				this.ScoreNumerator = i.Value;

			if(i.Item=='ScoreDenominator')
				this.ScoreDenominator = i.Value;

			if(i.Item=='Notes')
				this.Notes = i.Value;

			if(i.Item=='LessonStartDate')
				this.LessonStartDate = i.Value;

			if(i.Item=='LessonEndDate')
				this.LessonEndDate = i.Value;

			if(i.Item=='QuizStartDate')
				this.QuizStartDate = i.Value;

			if(i.Item=='QuizEndDate')
				this.QuizEndDate = i.Value;

			if(i.Item=='orderByString') {
				if (this.applicationSettings.getOrderByDirection(i.Value)==='asc')
					this.sort.direction = 'asc';
				else
					this.sort.direction = 'desc';

				this.sort.active = this.applicationSettings.getOrderByName(i.Value);
			}

			if (i.Item == 'Skip')
				this.skip = i.Value;

		});

		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
		merge(this.sort.sortChange, this.paginator.page)
			.subscribe(()=> this.getData());

		this.getData();
	}

	pageSizeValueChanged(): void {
		this.applicationSettings.setPageSize(this.pageSize.toString());
		this.paginator.pageIndex = 0;
		this.getData();
	}

	getData(): any {
	 this.progressStatus=true; 
	 this.dataSource = null; 
	 this.recordTotal = 0; 
		let search: ITestSearch = this._appService.getFreshSearch();

		search.LessonsId = this.LessonsId;
		search.Employee = this.Employee;
		search.ScoreNumerator = this.ScoreNumerator;
		search.ScoreDenominator = this.ScoreDenominator;
		search.Notes = this.Notes;
		search.LessonStartDate = this.LessonStartDate;
		search.LessonEndDate = this.LessonEndDate;
		search.QuizStartDate = this.QuizStartDate;
		search.QuizEndDate = this.QuizEndDate;
		search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
		search.take = parseInt(this.pageSize);
		search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

		this._appService.getRecordSet(search).subscribe((obj: ITests) => this.onSelectedRecordSetReceived(obj),
		(error: any) => {
			this.errorMessage = <any> error;
		});
	}

	private onSelectedRecordSetReceived(obj: ITests){
		this.dataSource = new MatTableDataSource(obj.data);
		this.recordTotal = obj.total;
		this.progressStatus = false;
	}

	searchClick(): void {
		this.skip = 0;
		this.paginator.pageIndex = 0;
		this.getData();
	}

	public searchClearClick(): void {
		this.LessonsId = '';
		this.Employee = '';
		this.ScoreNumerator = '';
		this.ScoreDenominator = '';
		this.Notes = '';
		this.LessonStartDate = '';
		this.LessonEndDate = '';
		this.QuizStartDate = '';
		this.QuizEndDate = '';
		this.skip = 0;
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.paginator.pageIndex = 0;
		this.getData();
	}

	resetError() : void {
		this.errorMessage = '';
		this.progressStatus=false;
	}
	showHideSearchClick() {
		 if (this.showHideSearch) 
		 {
			 this.showHideSearch = false; 
			localStorage.setItem('showHideSearch', 'false'); 
			this.SearchText = 'Show Filters'; 
		}
		else
		{
			 this.showHideSearch = true; 
			localStorage.setItem('showHideSearch', 'true'); 
			  this.SearchText = 'Hide Filters'; 
		}
	}
	searchAndHideClick() {
		 this.skip = 0;
		 this.paginator.pageIndex = 0;
		this.getData();
		 setTimeout(() => {
		     this.showHideSearchClick();
		 }, 500);
	 }
	public help()
	{
		   const url = this.router.serializeUrl(
		       this.router.createUrlTree([`/help-view/tests`])
		);
		  window.open(url, '_blank');
	 }
	public toExcel(){
			let search: ITestSearch = this._appService.getFreshSearch();

			search.LessonsId = this.LessonsId;
			search.Employee = this.Employee;
			search.ScoreNumerator = this.ScoreNumerator;
			search.ScoreDenominator = this.ScoreDenominator;
			search.Notes = this.Notes;
			search.LessonStartDate = this.LessonStartDate;
			search.LessonEndDate = this.LessonEndDate;
			search.QuizStartDate = this.QuizStartDate;
			search.QuizEndDate = this.QuizEndDate;
			search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
			search.take = parseInt(this.pageSize);
			search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

			this._appService.toExcel(search).subscribe(
				retVal => {
					    this.excelFile = retVal;
				},
				 (error: any) => {
					 this.errorMessage = < any > error;
				});
	}
	public hideFileLink()
	{
	   this.excelFile = "";
	}
	public openExcelFile()
	{
	   window.open(`${ this.applicationSettings.retrieveUrl()}${ this.excelFile}`);
	}

	getLessons() {
		this.lessonService.getAll().subscribe(response => {
			this.lessonsList = response;
			this.progressLessonsStatus = false;
		},(error: any) => {
			this.progressLessonsStatus = true;
			this.errorMessage = <any> error;
		});
	}
}