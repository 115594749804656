import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IContent} from './Content-model/Content';
import { IContents } from './Content-model/Contents';
import { IContentSearch } from './Content-model/ContentSearch';

import { ApplicationSettings } from '../../shared/application-settings';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';

@Injectable()
export class ContentService {
	private baseUrl;
	public pageTitle = 'Contents List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Contents';
	}

	getContent(id: number):  Observable<IContent> {
		if (id === 0) {
			return of(this.initializeContent());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IContent>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveContent(content: IContent): Observable<any> {
		if (content.Id === 0) {
			return this.createContent(content);
		}
		return this.updateContent(content);
	}

	private createContent(content: IContent): Observable<any> {
		content.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/contentcreate', content)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateContent(content: IContent): Observable<IContent> {
		return this.authHttp.put<IContent>(this.baseUrl, content)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteContent(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeContent(): IContent {
		return {
			Id: 0,
			LessonsId: 0,
			Order: 0,
			Name: '',
			Description: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IContent;
	}

	public getRecordSet(search: IContentSearch): Observable<IContents> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('LessonsId', search.LessonsId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Order', search.Order, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Name', search.Name, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Description', search.Description, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('LessonsId', search.LessonsId ? search.LessonsId : '')
			.set('Order', search.Order ? search.Order : '')
			.set('Name', search.Name ? search.Name : '')
			.set('Description', search.Description ? search.Description : '')
		};

		return this.authHttp.get<IContents>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IContents>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IContentSearch { 
		let search: IContentSearch = {
			Id: 1,
			LessonsId: '',
			Order: '',
			Name: '',
			Description: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.error.ErrorDescription}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: IContentSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('LessonsId', search.LessonsId ? search.LessonsId : '')
			.set('Order', search.Order ? search.Order : '')
			.set('Name', search.Name ? search.Name : '')
			.set('Description', search.Description ? search.Description : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
}