import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IJob} from './Job-model/Job';
import { IJobs } from './Job-model/Jobs';
import { IJobSearch } from './Job-model/JobSearch';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';
import { IJobConfig } from './job-model/jobConfig';

@Injectable()
export class JobService {
	private baseUrl;
	public pageTitle = 'Jobs List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Jobs';
	}

	getJob(id: number):  Observable<IJob> {
		if (id === 0) {
			return of(this.initializeJob());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IJob>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveJob(job: IJob): Observable<any> {
		if (job.Id === 0) {
			return this.createJob(job);
		}
		return this.updateJob(job);
	}

	private createJob(job: IJob): Observable<any> {
		job.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/jobcreate', job)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateJob(job: IJob): Observable<IJob> {
		return this.authHttp.put<IJob>(this.baseUrl, job)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteJob(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	getAllJobs():  Observable<any> {
		return this.authHttp.get<IJob[]>(this.baseUrl)
			.pipe(retry(1),
			catchError(this.processError));
	};

	getJobsBySchedule(scheduleId: any):  Observable<any> {
		return this.authHttp.get<IJob[]>(this.baseUrl + '/byschedule?scheduleId=' + scheduleId)
			.pipe(retry(1),
			catchError(this.processError));
	};

	initializeJob(): IJob {
		return {
			Id: 0,
			ScheduleId: 0,
			PartId: 0,
			JobNumber: '',
			Description: '',
			Quantity: 1,
			DueDate: '',
			IsCompleted: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IJob;
	}

	public getRecordSet(search: IJobSearch): Observable<IJobs> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('ScheduleId', search.ScheduleId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('CompanyId', search.CompanyId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('PartId', search.PartId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('JobNumber', search.JobNumber, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Description', search.Description, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Quantity', search.Quantity, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('DueDate', search.DueDate, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('IsCompleted', search.IsCompleted, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('ScheduleId', search.ScheduleId ? search.ScheduleId : '')
			.set('CompanyId', search.CompanyId ? search.CompanyId : '')
			.set('PartId', search.PartId ? search.PartId : '')
			.set('JobNumber', search.JobNumber ? search.JobNumber : '')
			.set('Description', search.Description ? search.Description : '')
			.set('Quantity', search.Quantity ? search.Quantity : '')
			.set('DueDate', search.DueDate ? search.DueDate : '')
			.set('IsCompleted', search.IsCompleted ? search.IsCompleted : 'false')
		};

		return this.authHttp.get<IJobs>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IJobs>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IJobSearch { 
		let search: IJobSearch = {
			Id: 1,
			CompanyId:'',
			ScheduleId: '',
			PartId: '',
			JobNumber: '',
			Description: '',
			Quantity: '',
			DueDate: '',
			IsCompleted: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.error.ErrorDescription}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: IJobSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('ScheduleId', search.ScheduleId ? search.ScheduleId : '')
			.set('CompanyId', search.CompanyId ? search.CompanyId : '')
			.set('PartId', search.PartId ? search.PartId : '')
			.set('JobNumber', search.JobNumber ? search.JobNumber : '')
			.set('Description', search.Description ? search.Description : '')
			.set('Quantity', search.Quantity ? search.Quantity : '')
			.set('DueDate', search.DueDate ? search.DueDate : '')
			.set('IsCompleted', search.IsCompleted ? search.IsCompleted : 'false')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}


	saveJobConfig(job: IJobConfig[]): Observable<any> {
			return this.createJobConfig(job);
	}

	private createJobConfig(configuration: IJobConfig[]): Observable<any> {
		return this.authHttp.post<IJobConfig[]>(this.baseUrl + '/jobconfigcreate', configuration)
			.pipe(retry(1), catchError(this.processError));
	}

	updateJobConfig(job: IJobConfig): Observable<any> {
		return this.authHttp.put<IJobConfig>(this.baseUrl+ '/jobconfigupdate', job)
			.pipe(retry(1), catchError(this.processError));
	}

}