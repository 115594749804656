import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControlName } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IBranche } from '../branche-model/branche';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';
import { Subscription } from 'rxjs/Subscription';
import { BrancheService } from '../branche-service';
import { IBranches } from '../Branche-model/Branches';
import { IBrancheSearch } from '../Branche-model/BrancheSearch';
import { UsersearchService } from '../../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../../admin/usersearches/usersearch-model/usersearch';
import { ApplicationSettings } from '../../../shared/application-settings';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from '../../../shared/dialogs/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
	templateUrl: './branche-edit.component.html',
	styleUrls: ['branche-edit.component.css']
})

export class BrancheEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit Branches';
	errorMessage: string;
	brancheForm: FormGroup;
	branche: IBranche;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public BrancheSearch: IBrancheSearch;
	public ListData: IBranches;
	public showNextRecordButton: boolean = true;
	urldatalogo:any;
	urldataisologo:any;

	progressStatus = true;
	public pageSize = '10';

	constructor(private fb: FormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private brancheService: BrancheService
		, private settings:ApplicationSettings
		, public snackBar: MatSnackBar
		, public dialog: MatDialog
		) {
	}

	ngOnInit(): void {

		let x  = +localStorage.getItem('rows');
			if(x>0) { this.pageSize = x.toString(); }
			else { this.pageSize = '10';}

		this.brancheForm = this.fb.group({
			Id: '',
			BranchName: '',
			BusinessNumber: '',
			TollFreeNumber: '',
			AddressLine: '',
			AddressLine2: '',
			PostalCode: '',
			City: '',
			Province: '',
			Country: '',
			Notes: '',
			BranchNumber: '',
			fax: '',
			UPC: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
			Logo:'',
			IsoLogo:''
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getBranche(id);
				this.progressStatus = true;
			}
		);
	}

	public getSelectedRecordSet():void{
		this.brancheService.getRecordSet(this.BrancheSearch)
			.subscribe((obj: IBranches) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {this.errorMessage = < any > error;this.progressStatus = false;	});
	}

	private onSelectedRecordSetReceived(obj: IBranches){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.branche.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if ((this.record-obj.skip) < this.ListData.data.length){
			this.showNextRecordButton = true;
		}
		else{
			this.showNextRecordButton = false;
		}

		this.progressStatus = false;

	}

	saveBranche(returnToList: boolean, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

			if (this.brancheForm.valid) {
			const obj = Object.assign({ }, this.branche, this.brancheForm.value);
			this.brancheService.saveBranche(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord,addNewRecord)
				, (error: any) => {this.errorMessage = < any > error;this.progressStatus = false;});
			}else{
				this.buttonPushed = false;
				this.progressStatus = false;
			}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		if (returnToList) {
			this.brancheForm.reset();
			this.router.navigate(['/branches']);
		} 
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
		else if(addNewRecord){
			this.router.navigate(['/branche-details', 0]);
			this.brancheForm.reset();
			let obj=this.brancheService.initializeBranche();
			this.onBrancheRetrieved(obj);
		}
			// when the record id is zero then it is a new record.
		else if (data !== null && this.branche.Id === 0) {
			this.router.navigate(['/branche-details', data.Id]);
		} 
		else if (this.branche.Id != 0){
			this.getBranche(this.branche.Id);
		}

		this.openSnackBar('Your changes have been successfully saved','Ok',5000);

		this.buttonPushed = false;
		this.progressStatus = false;


	}

	advanceToNextRecord(): void {
		let count = 0;
		let record = 0;
		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.branche.Id)
				record = count;
		});
		this.router.navigate(['/branche-details', this.ListData.data[record].Id]);
	}

	getBranche(id: number): void {
		this.brancheService.getBranche(id)
			.subscribe((obj: IBranche) => this.onBrancheRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onBrancheRetrieved(branche: IBranche): void {
		if (this.brancheForm) {
			this.brancheForm.reset();
		}
		this.branche = branche;
		if (this.branche.Id === 0) {
			this.page = 'Add Branches';
		} else {
			this.page = 'Edit Branches';
		}

		this.brancheForm.patchValue({
			Id: this.branche.Id,
			BranchName: this.branche.BranchName,
			BusinessNumber: this.branche.BusinessNumber,
			TollFreeNumber: this.branche.TollFreeNumber,
			AddressLine: this.branche.AddressLine,
			AddressLine2: this.branche.AddressLine2,
			PostalCode: this.branche.PostalCode,
			City: this.branche.City,
			Province: this.branche.Province,
			Country: this.branche.Country,
			Notes: this.branche.Notes,
			BranchNumber: this.branche.BranchNumber,
			fax: this.branche.fax,
			UPC: this.branche.UPC,
			CreatedDate: this.branche.CreatedDate,
			CreatedBy: this.branche.CreatedBy,
			UpdatedDate: this.branche.UpdatedDate,
			UpdatedBy: this.branche.UpdatedBy,
			Logo:this.branche.Logo,
			IsoLogo:this.branche.IsoLogo
		});
		this.urldatalogo=this.settings.retrieveUrl()+'/Files/Logo/'+this.branche.Logo;
		this.urldataisologo=this.settings.retrieveUrl()+'/Files/IsoLogo/'+this.branche.IsoLogo;
		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteBranche(): void {
			this.brancheService.deleteBranche(this.branche.Id)
				.subscribe(() => this.onSaveComplete(true, null, false,false)
				, (error: any) => this.errorMessage = <any>error);
	}

	cancel(): void {
		this.router.navigate(['/branches']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Branches List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		,(error: any) => {this.errorMessage = < any > error;this.progressStatus = false;	});
	}

	setUserFilters(): void {
		this.BrancheSearch = this.brancheService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'BranchName')
				this.BrancheSearch.BranchName = i.Value;

			if (i.Item == 'BusinessNumber')
				this.BrancheSearch.BusinessNumber = i.Value;

			if (i.Item == 'TollFreeNumber')
				this.BrancheSearch.TollFreeNumber = i.Value;

			if (i.Item == 'AddressLine')
				this.BrancheSearch.AddressLine = i.Value;

			if (i.Item == 'AddressLine2')
				this.BrancheSearch.AddressLine2 = i.Value;

			if (i.Item == 'PostalCode')
				this.BrancheSearch.PostalCode = i.Value;

			if (i.Item == 'City')
				this.BrancheSearch.City = i.Value;

			if (i.Item == 'Province')
				this.BrancheSearch.Province = i.Value;

			if (i.Item == 'Country')
				this.BrancheSearch.Country = i.Value;

			if (i.Item == 'Notes')
				this.BrancheSearch.Notes = i.Value;

			if (i.Item == 'BranchNumber')
				this.BrancheSearch.BranchNumber = i.Value;

			if (i.Item == 'fax')
				this.BrancheSearch.fax = i.Value;

			if (i.Item == 'UPC')
				this.BrancheSearch.UPC = i.Value;

			if (i.Item == 'orderByString')
				this.BrancheSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.BrancheSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
	onFileSelected(event: any,location) {
		const target = event.target as HTMLInputElement;
		if (target.files && target.files.length > 0) {
		  console.log(target.files[0].name);
	   
		const formData = new FormData();
		formData.append('fileName', target.files[0]);
		formData.append('Id', this.brancheForm.value.Id.toString());
		formData.append('Location', location);
		
		this.brancheService.addFile(formData).subscribe(
		  retVal => {
			if (retVal !== "") {
			  alert("File Uploaded");
			  console.log("File Uploaded");
			  if(location=="Logo"){
				this.urldatalogo="";
				this.brancheForm.patchValue({
					Logo: retVal
				  });				
				this.urldatalogo=this.settings.retrieveUrl()+'/Files/'+location+'/'+retVal;
			  }
			 else if(location=="IsoLogo"){
				this.urldataisologo="";
				this.brancheForm.patchValue({
					IsoLogo: retVal
				  });				
				this.urldataisologo=this.settings.retrieveUrl()+'/Files/'+location+'/'+retVal;
			 }
			}
			else {
				if(location=="Logo"){
					this.brancheForm.patchValue({
						Logo: ''
					  });
					  this.urldatalogo="";
				}
				else if(location=="Signature"){
					this.brancheForm.patchValue({
						IsoLogo: ''
					  });
					  this.urldataisologo="";
				}
			  alert("File Not Uploaded");
			 
			}
		  }
		  ,(error: any) => this.errorMessage = <any>error
		);
	  }	
	}

	openSnackBar(message: string, action: string, durationMiliiseconds:number) {
		this.snackBar.open(message, action, {
		  duration: durationMiliiseconds,
		});
	}

	showDialog(): void {
		this.buttonPushed=true;
		this.dialog
		  .open(ConfirmationDialogComponent, {
			data: `Are you sure you want to delete this Branch?`
		  })
		  .afterClosed()
		  .subscribe((confirmed: Boolean) => {
			if (confirmed) {
				this.deleteBranche();
			} else {
			  this.buttonPushed=false;
			  return;
			}
		  });
	  }
}
