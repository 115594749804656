import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControlName, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ILesson } from '../lesson-model/lesson';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';
import { Subscription } from 'rxjs/Subscription';
import { LessonService } from '../lesson-service';
import { ILessons } from '../Lesson-model/Lessons';
import { ILessonSearch } from '../Lesson-model/LessonSearch';

import { UsersearchService } from '../../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../../admin/usersearches/usersearch-model/usersearch';
import { CourseService } from '../../courses/course-service';

@Component({
	templateUrl: './lesson-edit.component.html',
	styleUrls: ['lesson-edit.component.css']
})

export class LessonEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit Lessons';
	errorMessage: string;
	lessonForm: FormGroup;
	lesson: ILesson;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public LessonSearch: ILessonSearch;
	public ListData: ILessons;
	public showNextRecordButton: boolean = true;
	public progressStatus=false;
	public progressCoursesStatus=false;
	public coursesList;
	public courseId: number;

	constructor(private fb: FormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private lessonService: LessonService
		, private courseService: CourseService
		) {
	}

	ngOnInit(): void {
		this.lessonForm = this.fb.group({
			Id: '',
			CoursesId: '',
			Name: ['',[Validators.maxLength(250)]],
			Description: ['',[Validators.maxLength(1000)]],
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});
		this.getCourses();
		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.courseId = +params['courseId'];
				this.getLesson(id);
				this.progressStatus = true;
			}
		);
	}

	public getSelectedRecordSet():void{
		this.lessonService.getRecordSet(this.LessonSearch)
			.subscribe((obj: ILessons) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: ILessons){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.lesson.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

		this.progressStatus = false;

	}

	saveLesson(returnToList: boolean, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

		if (this.lessonForm.valid) {
			const obj = Object.assign({ }, this.lesson, this.lessonForm.value);
			this.lessonService.saveLesson(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord,addNewRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		if (returnToList) {
			this.lessonForm.reset();
			// this.router.navigate(['/training/lessons']);
			this.router.navigate(['/training/course-details/' + this.courseId.toString()]);
		} else {
			// when the record id is zero then it is a new record.
			if (data !== null && this.lesson.Id === 0) {
				this.router.navigate(['/training/lesson-details', data.Id, this.courseId]);
			} else {
				if (advanceToNextRecord) {
					this.advanceToNextRecord();
				}
			}
		}

		this.buttonPushed = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.lesson.Id)
				record = count;
		});

		this.getLesson(this.ListData.data[record].Id)
	}

	getLesson(id: number): void {
		this.lessonService.getLesson(id)
			.subscribe((obj: ILesson) => this.onLessonRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onLessonRetrieved(lesson: ILesson): void {
		if (this.lessonForm) {
			this.lessonForm.reset();
		}
		this.lesson = lesson;
		if (this.lesson.Id === 0) {
			this.page = 'Add Lessons';
			this.lesson.CoursesId = this.courseId;
		} else {
			this.page = 'Edit Lessons';
		}

		this.lessonForm.patchValue({
			Id: this.lesson.Id,
			CoursesId: this.lesson.CoursesId,
			Name: this.lesson.Name,
			Description: this.lesson.Description,
			CreatedDate: this.lesson.CreatedDate,
			CreatedBy: this.lesson.CreatedBy,
			UpdatedDate: this.lesson.UpdatedDate,
			UpdatedBy: this.lesson.UpdatedBy,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteLesson(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.lesson.CoursesId}?`)) {
			this.lessonService.deleteLesson(this.lesson.Id)
				.subscribe(() => this.onSaveComplete(true, null, false,false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		//this.router.navigate(['/training/lessons']);
		this.router.navigate(['/training/course-details/' + this.courseId.toString()]);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Lessons List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.LessonSearch = this.lessonService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'CoursesId')
				this.LessonSearch.CoursesId = i.Value;

			if (i.Item == 'Name')
				this.LessonSearch.Name = i.Value;

			if (i.Item == 'Description')
				this.LessonSearch.Description = i.Value;

			if (i.Item == 'orderByString')
				this.LessonSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.LessonSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}

	getCourses() {
		this.courseService.getAll().subscribe(response => {
			this.coursesList = response;
			this.progressCoursesStatus = false;
		},(error: any) => {
			this.progressCoursesStatus = true;
			this.errorMessage = <any> error;
		});
	}

}
