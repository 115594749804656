import { Component, OnInit } from '@angular/core';
import { EquipmentService } from '../../equipments/equipment-service';

import { AuthService } from '../../admin/auth/auth.service';
import { Machine16PLCService } from '../Machine16-PLC-service';
import { PartService } from '../../parts/part-service';
import { LoggingService } from '../logging-service';
import { Subscription } from 'rxjs';
import { JobService } from '../../jobs/job-service';
import { IJob } from '../../jobs/Job-model/Job';
import { IPart } from '../../parts/Part-model/Part';
import { IJobConfig } from '../../jobs/job-model/jobConfig';

import { timer } from 'rxjs';
import { take, delay } from 'rxjs/operators';

export interface Message {
	source: string;
	content: any[];
	message: string;
	function: string;
}

@Component({
	selector: 'app-machine16',
	templateUrl: './machine16.component.html',
	styleUrls: ['./machine16.component.css']
})
export class Machine16Component implements OnInit {

	errorMessage: string;
    buttonLatchTime: number = 1000;
	localGLHoseFlag = false;
	private timer: any;
	private startTime: number;
	modbusData: any;
	ip: string = '(empty)';
    port=0;
	arrCol1 = [];
	arrCol2 = [];

	imgGreenURL: string = './assets/green_button.png';
	imgYellowURL: string = './assets/yellow_button.png';
	imgGrayURL: string = './assets/gray_button.png';
	imgGrayDownURL: string = './assets/gray_button_down.png'
	imgRedURL: string = './assets/red_button.png';
	imgBlueURL: string = './assets/blue_button.png';

	imgLastPartNowOnURL: string = './assets/lastpartnow_on_button.png';
	imgLastPartNowOffURL: string = './assets/lastpartnow_off_button.png';

	imgLastPartAfterOnURL: string = './assets/lastpartafter_on_button.png';
	imgLastPartAfterOffURL: string = './assets/lastpartafter_off_button.png';

	imgAbort: string = this.imgBlueURL;
	imgReset: string = this.imgRedURL;
	imgStart: string = this.imgGreenURL;
	imgHome: string = this.imgYellowURL;
	imgHold: string = this.imgGrayURL;
	
	//imgLastPartNow : string = this.imgLastPartNowOffURL;
	//imgLastPartAfter : string = this.imgLastPartAfterOffURL;
	
	public AbortbuttonPushed = false;
	public ResetbuttonPushed = false;
	public StartbuttonPushed = false;
	public HomebuttonPushed = false;
	public HoldbuttonPushed = false;

	public templateButtonPressed=false;

	on_indicator: string= './assets/onimg.png'
	off_indicator : string= './assets/offimg.png'

	status_empty_img: string= './assets/template_empty.png'
	status_blank_img : string= './assets/template_blank.png'
	status_in_progress_img: string= './assets/template_in_progress.png'
	status_finished_img : string= './assets/template_finished.png'

	public serverStatus = false;
	public deviceStatus = false;
	hearbeatStatus: any;

	public Status1: string = '(please wait)';
	public Status2: string = '(please wait)';
	public Status3: string = '(please wait)';
	public Status4: string = '(please wait)';

	public currentPart: string = '(pls wait)'
	public nextPart: string = '(pls wait)'

	public LastPartAmount: number = 0;
	public LastPartCountDown: number = 0;
	public LastPartNow: string = 'off';
	public LastPartAfter: string = 'off';

	public LastPartNowRobot: string = '';
	public LastPartAfterRobot: string = '';

	public RobotFault: string = "(please wait)";
	public TPEnabled: string = "(please wait)";
	public CycleStart: string = "(please wait)";
	public StepEnabled: string = "(please wait)";
	public JobsList: IJob[];
	public PartsList: IPart[];
	public SelectedPart: IPart;
	selectedJobId = 0;

	macAddress = '';

    macAddressSub:Subscription;
	toCNCSub:Subscription;
	fromCNCSub:Subscription;
    serverStatusSub:Subscription;
	deviceErrorSub:Subscription;
	cellStatusSub:Subscription;
	cncStatusLogSub:Subscription;
	partsSub:Subscription;
	equipmentid=0;

	partsTemplate: IJobConfig[];
	TemplateData = [];
	displayedColumns: string[];
	processStarted=false;

	private timerSubscription: Subscription;

	constructor(
		private equipmentService: EquipmentService
		, private auth: AuthService
		, private plcService: Machine16PLCService
		, private loggingService: LoggingService
		, private jobService:JobService
		, private partService: PartService
	) {
		this.plcService.connectServer();
	}


	ngOnInit(): void {

		this.localGLHoseFlag = true;
		this.toCNCSub=this.plcService.GetToCNCSubject.subscribe(obj => {
			this.arrCol1 = obj;
		});
		this.fromCNCSub=this.plcService.GetFromCNCSubject.subscribe(obj => {
			this.arrCol2 = obj;
		});
		this.serverStatusSub=this.plcService.GetServerStatusSubject.subscribe(obj => {
			this.serverStatus = obj;
		})
		this.deviceErrorSub=this.plcService.GetDeviceErrorSubject.subscribe(obj => {
			this.deviceStatus = obj;
		})
		this.cellStatusSub=this.plcService.GetCellStatusSubject.subscribe(obj => {
			this.Status1 = obj[0];
			this.Status2 = obj[1];
			this.Status3 = obj[2];
			this.Status4 = obj[3];

			if (this.LastPartNowRobot == 'on' && obj[4] == 'off') {
				//Togle off the last part now
				this.LastPartNow = 'off'
			}
			if (this.LastPartAfterRobot == 'on' && obj[5] == 'off') {
				//Togle off the last part now
				this.LastPartAfter = 'off'
				this.LastPartAmount = 0;
				this.plcService.genericValueMessage(96, this.LastPartAmount);
			}
			this.RobotFault = obj[6];
			this.TPEnabled = obj[7];
			this.CycleStart = obj[8];
			this.StepEnabled = obj[9];

		})
		this.cncStatusLogSub=this.plcService.GetCncStatusLogSubject.subscribe(obj => {
			this.loggingService.createCncstatuslog(obj,this.equipmentid).
				subscribe(resp => {
				}, (error: any) => this.errorMessage = <any>error);
		})
		this.partsSub=this.plcService.GetPartsSubject.subscribe(obj => {
			//this.currentPart = obj[0];
			// this.nextPart = obj[1];
			this.LastPartCountDown = obj[2];
			localStorage.setItem('currentPart', this.currentPart);
			// localStorage.setItem('nextPart', this.nextPart);
		})
		 this.macAddressSub=this.plcService.GetMacAddressSubject.subscribe(obj => {
			//console.log(obj);
			this.macAddress = obj;
			this.getConnectionInfo();
		})
	

		this.displayHearbeat();
		this.getJobsList();
		this.getPartsList();
	}

	ngAfterViewInit() {
	}

	ngOnDestroy() {
		this.plcService.stopReadModbusValues();
		this.macAddressSub.unsubscribe();
		this.macAddressSub.unsubscribe();
		this.toCNCSub.unsubscribe();
		this.fromCNCSub.unsubscribe();
		this.serverStatusSub.unsubscribe();
		this.deviceErrorSub.unsubscribe();
		this.cellStatusSub.unsubscribe();
		this.cncStatusLogSub.unsubscribe();
		this.partsSub.unsubscribe();
		if (this.timerSubscription) {
			this.timerSubscription.unsubscribe();
		}
	}

	setNextPart(target: any) {
		const value = (target as HTMLSelectElement).value;
		localStorage.setItem('nextPart', value);
		//console.log('setNextPart:' + value);
		this.plcService.genericValueMessage(95, value);
	}

	getConnectionInfo() {
		let branchid = this.auth.getBranchId();
		this.equipmentService.getEquipmentsData('Robot Cell 16', branchid, 'Robot Cell',this.macAddress).subscribe(
			resp => {
				if (resp.length > 0) {
					if (resp[0].IP != '') {
						this.equipmentid=resp[0].Id;
						this.plcService.ip = resp[0].IP;
						this.ip = resp[0].IP;

						if (resp[0].Port > 1){
							this.plcService.port = resp[0].Port;
							this.port=resp[0].Port;
						}
							
						this.plcService.startReadModbusValues();
					}
					else {
						alert("IP not available for configured machine in equipments");
					}
				}

				else {
					alert("This PC is not configured in the equipment table to run this CNC.");
				}
			})
	}

	sendPulse(val, name) {
		console.log("send pulse: val:"+val+' name: '+name);
		this.plcService.genericSetPulseMessage(val);
		this.createGuiLog(name);
		if(name=='Abort'){
			if (this.timerSubscription) {
				this.timerSubscription.unsubscribe();
				this.processStarted = false;
			  }
			this.AbortbuttonPushed = true;
			setTimeout(() => {
				this.AbortbuttonPushed = false;
			  }, this.buttonLatchTime);
		}

		if(name=='Start'){
			this.StartbuttonPushed = true;
			this.processStarted = true;
			
			this.processObjectsWithDelay();
			setTimeout(() => {
				this.StartbuttonPushed = false;
				
			  }, this.buttonLatchTime);
		}

		if(name=='Reset'){
			this.ResetbuttonPushed = true;
			if (this.timerSubscription) {
				this.timerSubscription.unsubscribe();
				this.processStarted = false;
				this.createTemplate();
			  }
			setTimeout(() => {
				this.ResetbuttonPushed = false;
			  }, this.buttonLatchTime);
		}

		if(name=='Home'){
			this.HomebuttonPushed = true;
			setTimeout(() => {
				this.HomebuttonPushed = false;
			  }, this.buttonLatchTime);
		}

		if(name=='Hold'){
			this.HoldbuttonPushed = true;
			setTimeout(() => {
				this.HoldbuttonPushed = false;
			  }, this.buttonLatchTime);
		}
	}
	reconnect() {
		this.plcService.connectServer();
	}
	displayHearbeat() {

		this.timer = setInterval(() => {
			if (this.hearbeatStatus == 1) {
				this.hearbeatStatus = 0;
			}
			else {
				this.hearbeatStatus = 1;
			}
		}, 1000);
	}
	resetError(): void {
		this.errorMessage = '';
	}
	lastPartCount(value) {
		this.LastPartAmount = this.LastPartAmount + Number(value);
		if (this.LastPartAmount < 0) {
			this.LastPartAmount = 0;
		}
		this.plcService.genericValueMessage(96, this.LastPartAmount);
	}
	toggleButton(value) {

		if (value == 'LastPartNow') {
			if (this.LastPartNow == 'off') {
				this.LastPartNow = 'on';
				this.plcService.genericValueMessage('84', '1')
				this.plcService.genericValueMessage('86', '0')
				//this.plcService.genericSetPulseMessage('84')
				this.LastPartAfter = 'off'
			}
			else {
				this.LastPartNow = 'off'
				this.plcService.genericValueMessage('84', '0')
			}
			this.createGuiLog(value);
		}
		if (value == 'LastPartAfter' && this.LastPartNow == 'off') {
			if (this.LastPartAfter == 'off') {
				this.LastPartAfter = 'on';
				this.plcService.genericValueMessage('86', '1')
			}
			else {
				this.LastPartAfter = 'off'
				this.plcService.genericValueMessage('86', '0')
				this.LastPartAmount = 0;
				this.plcService.genericValueMessage(96, this.LastPartAmount);

			}
			this.createGuiLog(value);
		}

	}

	getJobsList() {
		this.jobService.getAllJobs().subscribe((data) => {
			this.JobsList = data;
		});
	}
	getPartsList() {
	this.partService.getAllParts().subscribe((data) => {
		this.PartsList = data;
		console.log(this.PartsList);
	});
	}

	selectJob(target: any) {
		this.processStarted = false;
		
		let count = 0;
		let record = 0;
		if(this.PartsList.length>0){
		this.PartsList.forEach(el => {count++;if (el.Id == target.PartId){
			record = count;}
		});
		this.SelectedPart = this.PartsList[record-1];
		this.currentPart = this.SelectedPart.PartNumber;
		this.selectedJobId = target.Id;
		this.createTemplate();
		}
	}

	createTemplate(){
		var seq = 0;
		this.partsTemplate = [];
		this.TemplateData = [];
		this.displayedColumns = [];
		for (let i = 1; i <= this.SelectedPart.Rows; i++) {
			let row = {};
		
			for (let j = 1; j <= this.SelectedPart.Columns; j++) {
			seq++;
			let item = {
				Id:0,
				JobId: this.selectedJobId,
				RowNum:i,
				ColNum:j,
				SequenceNumber: seq, 
				Status: "E",
			} as IJobConfig
			row[`column${j}`] = item;
			this.partsTemplate.push(item);
			}
			
			this.TemplateData.push(row);

		}
		for (let j = 1; j <= this.SelectedPart.Columns; j++) {
			this.displayedColumns.push(`column${j}`);
		}
		

	}

	changeStatus(currentValue:string, sequence:number){
		if(!this.processStarted){
		this.templateButtonPressed =true;
			if(currentValue!='F' && currentValue!='IP'){
				//console.log('pressed');
				let count = 0;
				let record = 0;
				if(this.partsTemplate.length>0){
					this.partsTemplate.forEach(el => {count++;if (el.SequenceNumber == sequence){
					record = count;}
				});

				if(currentValue=='E'){
					this.partsTemplate[record-1].Status = 'B';
				}
				if(currentValue=='B'){
					this.partsTemplate[record-1].Status = 'E';
				}
				}
				this.templateButtonPressed =false;
			}else{
				this.templateButtonPressed =false;
			}
		}
	}

	createGuiLog(value) {
		let obj = {
			"CurrentPartNumber": "",
			"ButtonPressed": "",
			"Machine": ""
		}
		obj.CurrentPartNumber = this.currentPart;
		obj.ButtonPressed = value;
		obj.Machine = "Machine 16";
		this.loggingService.createGuilog(obj).
			subscribe(resp => {

			}, (error: any) => this.errorMessage = <any>error);
	}

	updateJobConfig(jobConfigItem,index) {
		console.log('updated request sent');
		this.jobService.updateJobConfig(jobConfigItem).
			subscribe((error: any) => this.errorMessage = <any>error);
	}

	processObjectsWithDelay(): void {
		if(this.partsTemplate)
		this.jobService.saveJobConfig(this.partsTemplate).
			subscribe(resp => {
				this.partsTemplate = resp;
				//console.log(this.TemplateData);
				let index = 0;
				const delayTime = 5000; // Delay in milliseconds
				const countElements = (this.partsTemplate.reduce((acc, cur) => cur.Status === 'B' ? ++acc : acc, 0))+1;
				
				this.timerSubscription = timer(0, delayTime).pipe(
				  take(countElements)
				).subscribe(() => {
				 if(index < countElements-1){
				  var itemIndex = this.partsTemplate.findIndex(function(obj) {return obj.Status === 'B';});
				  this.partsTemplate[itemIndex].Status = 'IP';
				  this.partsTemplate[itemIndex].StartTime = this.getCurrentDate();
				  //this.updateJobConfig(this.partsTemplate[itemIndex],itemIndex);
				  this.TemplateData[this.partsTemplate[itemIndex].RowNum-1][`column${this.partsTemplate[itemIndex].ColNum}`].Status=this.partsTemplate[itemIndex].Status;
				  } 
				  if(index>0 && index < countElements)
				 {
				  var itemIndex = this.partsTemplate.findIndex(function(obj) {return obj.Status === 'IP';});
				  this.partsTemplate[itemIndex].Status = 'F';
				  this.partsTemplate[itemIndex].FinishTime = this.getCurrentDate();
				  this.TemplateData[this.partsTemplate[itemIndex].RowNum-1][`column${this.partsTemplate[itemIndex].ColNum}`].Status=this.partsTemplate[itemIndex].Status;
				  this.updateJobConfig(this.partsTemplate[itemIndex],itemIndex);
					}
					if(index == countElements-1)
						{
							console.log('All complete');
							console.log(this.TemplateData);
						}
				  index++;
				});

			}, (error: any) => this.errorMessage = <any>error);
	  }

	  getCurrentDate(){
		var currentDate = new Date();
		// Get the components of the date and time
		var year = currentDate.getFullYear();
		var month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
		var day = String(currentDate.getDate()).padStart(2, '0');
		var hours = String(currentDate.getHours()).padStart(2, '0');
		var minutes = String(currentDate.getMinutes()).padStart(2, '0');
		var seconds = String(currentDate.getSeconds()).padStart(2, '0');
		var milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0');

		// Concatenate the components to form the formatted date string
		var formattedDateTime = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds + '.' + milliseconds;

		return formattedDateTime;
	  }

}
