
<div class='panel panel-primary animaPlaceholder'>
	<div class='panel-heading'>
	<div class='row'>
		<div class='col-md-2'>
			{{pageTitle}}
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml text-right'>
			<button class='btn btn-default btn-sm' [routerLink]="['/training/employeeanswer-details/0']" style='width:auto;' [disabled]="progressStatus">Add New EmployeeAnswer</button>
		</div>
		<div class='col-md-1 col-xs-6 div-list-sml text-right'>
		<button class='btn btn-primary btn-sm' style='width: auto;' (click)='showHideSearchClick()' [disabled]="progressStatus">{{SearchText}}</button>
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml text-right'>
			<button class='btn btn-default btn-sm' style='width: 100px' (click)='toExcel()' [disabled]="progressStatus">To Excel</button>
		</div>
		<div class='col-md-2 col-xs-6 div-list-sml text-right'>
			<label>Rows</label>&nbsp;&nbsp;
			<select [(ngModel)]='pageSize' style='width: 100px; color: black;' (change)='pageSizeValueChanged()'>
				<option *ngFor='let item of applicationSettings.pageSizes'>{{item}}</option>
			</select>
		</div>
		<div class='col-md-1 col-xs-12 div-list-sml text-right'>
			<button class='btn btn-primary btn-sm' style='width: auto;' (click)='help()'>how-to</button>
		</div>
	</div>
	</div>

	<div class='panel-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='excelFile'>
					<button class='btn btn-primary btn-sm' (click)='hideFileLink()'>hide link</button>
					<span>excel File:<a href = "javascript:void(0)"(click) = "openExcelFile()" > {{ excelFile}}</a></span>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<div class='row filters' *ngIf='showHideSearch'>
		<div class='col-md-9'>
			<div class='row'>
			<div class='col-md-4'>
				<label class='inline-label'> Question Id</label>
				<input type='text' class='form-control block-element' placeholder=' Question Id' [(ngModel)]='QuestionId' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Lesson Name</label>
				<input type='text' class='form-control block-element' placeholder=' Lesson Name' [(ngModel)]='LessonName' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Lesson Id</label>
				<input type='text' class='form-control block-element' placeholder=' Lesson Id' [(ngModel)]='LessonId' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Content Name</label>
				<input type='text' class='form-control block-element' placeholder=' Content Name' [(ngModel)]='ContentName' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Content Description</label>
				<input type='text' class='form-control block-element' placeholder=' Content Description' [(ngModel)]='ContentDescription' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Content Reviewed</label>
				<input type='date' class='form-control block-element' [(ngModel)]='ContentReviewed' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Content Status</label>
				<input type='text' class='form-control block-element' placeholder=' Content Status' [(ngModel)]='ContentStatus' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Content Notes</label>
				<input type='text' class='form-control block-element' placeholder=' Content Notes' [(ngModel)]='ContentNotes' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Lesson User</label>
				<input type='text' class='form-control block-element' placeholder=' Lesson User' [(ngModel)]='LessonUser' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Employee</label>
				<input type='text' class='form-control block-element' placeholder=' Employee' [(ngModel)]='Employee' />
			</div>
			</div>
		</div>
		<div class='col-md-3'>
			<div class='row'>
				<div class='col-md-12 col-xs-4 text-right' >
					<button class='btn btn-primary btn-sm btn-search' (click)='searchClick()' [disabled]="progressStatus">Search</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right' >
					<button class='btn btn-primary btn-sm btn-search' (click)='searchAndHideClick()' [disabled]="progressStatus">Search and Hide</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right' >
					<button class='btn btn-primary btn-sm btn-search' (click)='searchClearClick()' [disabled]="progressStatus">Clear-Search</button>
				</div>
			</div>
		</div>
		</div>
	</div>
</div>

<div class='table-container'>
<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
	<ng-container matColumnDef='Id'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
		<td mat-cell *matCellDef='let e'>
			<a class='btn btn-default' style='min-width: 80px;' [routerLink]="['/training/employeeanswer-details', e.Id]"  >{{e.Id}}</a>
		</td>
	</ng-container>
	<ng-container matColumnDef='QuestionId'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'> Question Id</th>
		<td mat-cell *matCellDef='let e' class='col2' >{{e.QuestionId}}</td>
	</ng-container>
	<ng-container matColumnDef='LessonName'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Lesson Name</th>
		<td mat-cell *matCellDef='let e' class='col3' >{{e.LessonName}}</td>
	</ng-container>
	<ng-container matColumnDef='LessonId'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> Lesson Id</th>
		<td mat-cell *matCellDef='let e' class='col4' >{{e.LessonId}}</td>
	</ng-container>
	<ng-container matColumnDef='ContentName'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'> Content Name</th>
		<td mat-cell *matCellDef='let e' class='col5' >{{e.ContentName}}</td>
	</ng-container>
	<ng-container matColumnDef='ContentDescription'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col6'> Content Description</th>
		<td mat-cell *matCellDef='let e' class='col6' >{{e.ContentDescription}}</td>
	</ng-container>
	<ng-container matColumnDef='ContentReviewed'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col7'> Content Reviewed</th>
		<td mat-cell *matCellDef='let e' class='col7' >{{e.ContentReviewed}}</td>
	</ng-container>
	<ng-container matColumnDef='ContentStatus'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col8'> Content Status</th>
		<td mat-cell *matCellDef='let e' class='col8' >{{e.ContentStatus}}</td>
	</ng-container>
	<ng-container matColumnDef='ContentNotes'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col9'> Content Notes</th>
		<td mat-cell *matCellDef='let e' class='col9' >{{e.ContentNotes}}</td>
	</ng-container>
	<ng-container matColumnDef='LessonUser'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col10'> Lesson User</th>
		<td mat-cell *matCellDef='let e' class='col10' >{{e.LessonUser}}</td>
	</ng-container>
	<ng-container matColumnDef='Employee'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col11'> Employee</th>
		<td mat-cell *matCellDef='let e' class='col11' >{{e.Employee}}</td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
	<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
</div>
<mat-paginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>
<div>
<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>