import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge } from 'rxjs';
import { Router } from '@angular/router';

import { ApplicationSettings } from '../../shared/application-settings';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';

import { HelpService } from '../help-service';
import { IHelpSearch } from '../help-model/helpsearch';
import { IHelps } from '../help-model/helps';

@Component({
	templateUrl: './help-list.component.html',
	styleUrls: ['./help-list.component.css']
})

export class HelpListComponent implements OnInit, AfterViewInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	displayedColumns: string[] = ['Id', 'LinkName', 'Content','DeveloperContent'];
	isLoadingResults = true;

	public UserSearches: IUsersearch[];
	public pageSize = '10';
	public skip = 0;
	public recordTotal: number = 0;

	public showHideSearch: boolean = false;
	public SearchText: string = 'Show Filters';

	public LinkName = '';
	public Content = '';
	public DeveloperContent = '';
	public pageTitle = 'Helps List';
	public errorMessage = '';
	public dataSource: any;
	public excelFile;
	public progressStatus = false;

	constructor(private userSearchService: UsersearchService
		, public applicationSettings: ApplicationSettings
		, private router: Router
		, private _appService: HelpService) {
	}

	ngOnInit(): void {
		this.progressStatus = true;
		this.skip = 0;
		let x = +localStorage.getItem('rows');
		if (x > 0) { this.pageSize = x.toString(); }
		else { this.pageSize = '10'; }
		if (localStorage.getItem('showHideSearch') === 'true') {
			this.showHideSearch = true;
			this.SearchText = 'Hide Filters';
		}
		else {
			this.showHideSearch = false;
			this.SearchText = 'Show Filters';
		}
	}

	ngAfterViewInit(): void {
		this.userSearchService.getUserSearchesByForm(this.pageTitle)
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
			}
				, (error: any) => this.errorMessage = <any>error);
	}

	setUserFilters(): void {
		this.sort.direction = 'asc';
		this.sort.active = 'Id';

		this.UserSearches.forEach(i => {
			if (i.Item == 'LinkName')
				this.LinkName = i.Value;

			if (i.Item == 'Content')
				this.Content = i.Value;
			if (i.Item == 'DeveloperContent')
				this.DeveloperContent = i.Value;
			if (i.Item == 'orderByString') {
				if (this.applicationSettings.getOrderByDirection(i.Value) === 'asc')
					this.sort.direction = 'asc';
				else
					this.sort.direction = 'desc';

				this.sort.active = this.applicationSettings.getOrderByName(i.Value);
			}

			if (i.Item == 'Skip')
				this.skip = i.Value;

		});

		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
		merge(this.sort.sortChange, this.paginator.page)
			.subscribe(() => this.getData());

		this.getData();
	}

	pageSizeValueChanged(): void {
		this.applicationSettings.setPageSize(this.pageSize.toString());
		this.getData();
	}

	getData(): any {
		this.dataSource = null;
		this.recordTotal = 0;
		let search: IHelpSearch = this._appService.getFreshSearch();

		search.LinkName = this.LinkName;
		search.Content = this.Content;
		search.DeveloperContent=this.DeveloperContent;
		search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
		search.take = parseInt(this.pageSize);
		search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

		this._appService.getRecordSet(search).subscribe((obj: IHelps) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = <any>error;
			});
	}

	private onSelectedRecordSetReceived(obj: IHelps) {
		this.dataSource = new MatTableDataSource(obj.data);
		this.recordTotal = obj.total;
		this.progressStatus = false;
	}

	searchClick(): void {
		this.skip = 0;
		this.getData();
	}

	public searchClearClick(): void {
		this.LinkName = '';
		this.Content = '';
		this.DeveloperContent='';
		this.skip = 0;
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.getData();
	}

	resetError(): void {
		this.errorMessage = '';
	}
	showHideSearchClick() {
		if (this.showHideSearch) {
			this.showHideSearch = false;
			localStorage.setItem('showHideSearch', 'false');
			this.SearchText = 'Show Filters';
		}
		else {
			this.showHideSearch = true;
			localStorage.setItem('showHideSearch', 'true');
			this.SearchText = 'Hide Filters';
		}
	}
	searchAndHideClick() {
		this.skip = 0;
		this.getData();
		setTimeout(() => {
			this.showHideSearchClick();
		}, 500);
	}
	public help() {
		const url = this.router.serializeUrl(
			this.router.createUrlTree([`/help-help`])
		);
		window.open(url, '_blank');
	}
	public toExcel() {
		let search: IHelpSearch = this._appService.getFreshSearch();

		search.LinkName = this.LinkName;
		search.Content = this.Content;
		search.DeveloperContent = this.DeveloperContent;
		search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
		search.take = parseInt(this.pageSize);
		search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

		this._appService.toExcel(search).subscribe(
			retVal => {
				this.excelFile = retVal;
			},
			(error: any) => {
				this.errorMessage = <any>error;
			});
	}
	public hideFileLink() {
		this.excelFile = "";
	}
	public openExcelFile() {
		window.open(`${this.applicationSettings.retrieveUrl()}${this.excelFile}`);
	}
}