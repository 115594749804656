import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApplicationSettings } from '../../shared/application-settings';

import { Observable, throwError, of } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { ITestjoinedresult} from './Testjoinedresult-model/Testjoinedresult';
import { ITestjoinedresults } from './Testjoinedresult-model/Testjoinedresults';
import { ITestjoinedresultSearch } from './Testjoinedresult-model/TestjoinedresultSearch';

@Injectable()
export class TestjoinedresultService {
	private baseUrl;

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Testjoinedresults';
	}

	getTestjoinedresultDataSet(TestId: number, QuestionId: number):  Observable<ITestjoinedresult[]> {

		const url = `${this.baseUrl}/dataset?TestId=${TestId}&QuestionId=${QuestionId}`;
		return this.authHttp.get<ITestjoinedresult[]>(url)
		.pipe(
			retry(1),
			catchError(this.processError));
	}

	getTestjoinedresult(id: number):  Observable<ITestjoinedresult> {
		if (id === 0) {
			return of(this.initializeTestjoinedresult());
		}

		const url = `${this.baseUrl}/${id}`;
		return this.authHttp.get<ITestjoinedresult>(url)
		.pipe(
			retry(1),
			catchError(this.processError));
	}

	saveTestjoinedresult(testjoinedresult: ITestjoinedresult): Observable < any > {
		if (testjoinedresult.Id === 0) {
			return this.createTestjoinedresult(testjoinedresult);
		}
		return this.updateTestjoinedresult(testjoinedresult);
	}

	private createTestjoinedresult(testjoinedresult: ITestjoinedresult): Observable <any> {
		testjoinedresult.Id = undefined;
		return this.authHttp.post(this.baseUrl, testjoinedresult)
		.pipe(
			retry(1),
			catchError(this.processError));
	}

	private updateTestjoinedresult(testjoinedresult: ITestjoinedresult): Observable <ITestjoinedresult> {
		return this.authHttp.put<ITestjoinedresult>(this.baseUrl, testjoinedresult)
		.pipe(
			retry(1),
			catchError(this.processError));
	}

	deleteTestjoinedresult(id: number): Observable <any> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete(url)
		.pipe(
			retry(1),
			catchError(this.processError));
	}

	processError(err) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
		 message = err.error.message;
		} else {
		 message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.log(message);
		return throwError(message);
	 }

	initializeTestjoinedresult(): ITestjoinedresult {
		return {
			Id: 0,
			QuestionId: 0,
			Name: '',
			Description: '',
			Result: '',
			TestResult: '',
			Notes: '',
			Answer: '',
			TestId: 0,
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as ITestjoinedresult;
	}

	public getRecordSet(search: ITestjoinedresultSearch): Observable<ITestjoinedresults> {

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('QuestionId', search.QuestionId ? search.QuestionId : '')
			.set('Name', search.Name ? search.Name : '')
			.set('Description', search.Description ? search.Description : '')
			.set('Result', search.Result ? search.Result : '')
			.set('TestResult', search.TestResult ? search.TestResult : '')
			.set('Notes', search.Notes ? search.Notes : '')
			.set('Answer', search.Answer ? search.Answer : '')
			.set('TestId', search.TestId ? search.TestId : '')
		};

		return this.authHttp
			.get<any>(this.baseUrl + '/grid', options)
			.pipe(map(response => (<ITestjoinedresults>{
				data: response.data,
				total: response.total,
				skip: search.skip
		 })));
	}

	public getFreshSearch() : ITestjoinedresultSearch { 
		let search: ITestjoinedresultSearch = {
			Id: 1,
			QuestionId: '',
			Name: '',
			Description: '',
			Result: '',
			TestResult: '',
			Notes: '',
			Answer: '',
			TestId: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};
}
