import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';

@Injectable()
export class LoggingService {
	private baseUrl;
	public pageTitle = 'Logging List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Logging';
	}


	createGuilog(guilog): Observable<any> {
		guilog.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/createguilog', guilog)
			.pipe(retry(1), catchError(this.processError));
	}
	createCncstatuslog(cncstatuslog,equipmentid): Observable<any> {
		cncstatuslog.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/createcncstatuslog?equipmentid='+equipmentid, cncstatuslog)
			.pipe(retry(1), catchError(this.processError));
	}
	getcncstatusreport(selecteddate,machinename): Observable<any> {
		
		return this.authHttp.get<any>(this.baseUrl + '/getcncstatusreport?selecteddate='+selecteddate+'&machinename='+machinename)
			.pipe(retry(1), catchError(this.processError));
	}
	getcncdashboarddata(): Observable<any> {
		
		return this.authHttp.get<any>(this.baseUrl + '/getcncdashboarddata')
			.pipe(retry(1), catchError(this.processError));
	}
	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}

}