<div class='panel panel-primary animaPlaceholder'>
	<div class='panel-heading'>
		<div class='row'>
			<div class='col-lg-8 col-md-6 col-xs-12 options-bar'>
				{{pageTitle}}
			</div>
			<div class='col-lg-2 col-md-3 col-xs-6 options-bar'>
				<button class='btn btn-default btn-sm center-block btn-full' mat-fab extended color="primary" (click)='showHideSearchClick()'><mat-icon *ngIf="showHideSearch">speaker_notes_off</mat-icon><mat-icon *ngIf="!showHideSearch">speaker_notes</mat-icon>{{SearchText}}
			</button>
			</div>
			<div class='col-lg-2 col-md-3 col-xs-6 options-bar'>
				<button class='btn btn-default btn-sm center-block btn-full' mat-fab extended color="primary" (click)='toExcel()'><mat-icon>cloud_download</mat-icon>To Excel</button>
			</div>
		</div>
	</div>
	<div class='panel-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='excelFile'>
					<button class='btn btn-primary btn-sm' (click)='hideFileLink()'>hide link</button>
					<span>excel File:<a href = "javascript:void(0)"(click) = "openExcelFile()" > {{ excelFile}}</a></span>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<div class='row filters' *ngIf='showHideSearch'>
		<div class='col-md-9'>
			<div class='row'>
			<div class='col-md-4 form-group-item'>
				<label class='inline-label'> Title</label>
				<input type='text' class='form-control block-element' placeholder=' Title' [(ngModel)]='Title' />
			</div>
			<div class='col-md-4 form-group-item'>
				<label class='inline-label'> Category</label>
				<input type='text' class='form-control block-element' placeholder=' Category' [(ngModel)]='Category' />
			</div>
			<div class='col-md-4 form-group-item'>
				<label class='inline-label'> File Name</label>
				<input type='text' class='form-control block-element' placeholder=' File Name' [(ngModel)]='FileName' />
			</div>
			<div class='col-md-4 form-group-item'>
				<label class='inline-label'> Notes</label>
				<input type='text' class='form-control block-element' placeholder=' Notes' [(ngModel)]='Notes' />
			</div>
			<div class='col-md-4 form-group-item'>
				<label class='inline-label'> Vimeo Id</label>
				<input type='text' class='form-control block-element' placeholder=' Vimeo Id' [(ngModel)]='VimeoId' />
			</div>
			</div>
		</div>
		<div class='col-md-3'>
			<div class='row'>
				<div class='col-md-12 col-xs-4 text-right'>
					<button class='btn btn-primary btn-sm btn-search' (click)='searchClick()' [disabled]="progressStatus">Search</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right'>
					<button class='btn btn-primary btn-sm btn-search' (click)='searchAndHideClick()' [disabled]="progressStatus">Search and Hide</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right'>
					<button class='btn btn-primary btn-sm btn-search' (click)='searchClearClick()' [disabled]="progressStatus">Clear-Search</button>
				</div>
			</div>
		</div>
		</div>
	</div>
</div>

<div class='table-container'>
<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
	<ng-container matColumnDef='Id'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
		<td mat-cell *matCellDef='let e'>
			<button [routerLink]="['/training-video-details', e.Id]" mat-icon-button matTooltip="View Training Video"><mat-icon>visibility</mat-icon></button>
		</td>
	</ng-container>
	<ng-container matColumnDef='Title'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'> Title</th>
		<td mat-cell *matCellDef='let e' class='col2' >{{e.Title}}</td>
	</ng-container>
	<ng-container matColumnDef='Category'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Category</th>
		<td mat-cell *matCellDef='let e' class='col3' >{{e.Category}}</td>
	</ng-container>
	<ng-container matColumnDef='FileName'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> File Name</th>
		<td mat-cell *matCellDef='let e' class='col4' >{{e.FileName}}</td>
	</ng-container>
	<ng-container matColumnDef='Notes'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'> Notes</th>
		<td mat-cell *matCellDef='let e' class='col5' >{{e.Notes}}</td>
	</ng-container>
	<ng-container matColumnDef='VimeoId'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col6'> Vimeo Id</th>
		<td mat-cell *matCellDef='let e' class='col6' >{{e.VimeoId}}</td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
	<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
</div>
<mat-paginator (page)="pageSizeValueChanged($event.pageSize.toString())" [length]='recordTotal' [pageSize]='pageSize'  [pageSizeOptions]="applicationSettings.getPageSizes()"></mat-paginator>
<div>
<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>