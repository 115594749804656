import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { UsersearchService } from './usersearch-service';
import { UsersearchListComponent } from './usersearch-list/usersearch-list.component';
import { UsersearchEditComponent } from './usersearch-edit/usersearch-edit.component';
import { MaterialModule } from '../../material-module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
	declarations: [
		UsersearchListComponent
		,UsersearchEditComponent
	],
	imports: [
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		MatProgressSpinnerModule,
	    RouterModule.forChild([
			{ path: 'usersearches', component: UsersearchListComponent },
			{ path: 'usersearche-details/:Id', component: UsersearchEditComponent },
		 ])
	],
	providers: [
		UsersearchService
	]
})
export class UsersearchModule { }
