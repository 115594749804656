
<div class='panel panel-primary animaPlaceholder'>
	<div class='panel-heading'>
	<div class='row'>
		<div class='col-md-4'>
			{{pageTitle}}
		</div>
		<div class='col-lg-2 col-md-2 col-xs-4 options-bar'>
			<button class='btn btn-default btn-sm center-block btn-full'
			 [routerLink]="['/landlocation-company-details/0',inCompanyId]" 
			 [disabled]="progressStatus">Add Land</button>
		</div>
		<div class='col-lg-2 col-md-2 col-xs-4 options-bar'>
		<button class='btn btn-default btn-sm center-block btn-full' (click)='showHideSearchClick()' [disabled]="progressStatus">{{SearchText}}</button>
		</div>
		<div class='col-lg-2 col-md-2 col-xs-4 options-bar'>
			<button class='btn btn-default btn-sm center-block btn-full' (click)='toExcel()' [disabled]="progressStatus">To Excel</button>
		</div>
		<div class='col-lg-1 col-md-1 col-xs-4 options-bar'>
			<label>Rows</label>&nbsp;&nbsp;
			<select [(ngModel)]='pageSize' (change)='pageSizeValueChanged()'>
				<option *ngFor='let item of applicationSettings.pageSizes'>{{item}}</option>
			</select>
		</div>
		<div class='col-lg-1 col-md-1 col-xs-4 options-bar'>
			<button class='btn btn-default btn-sm center-block btn-full' (click)='help()'>how-to</button>
		</div>
	</div>
	</div>

	<div class='panel-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='excelFile'>
					<button class='btn btn-primary btn-sm' (click)='hideFileLink()'>hide link</button>
					<span>excel File:<a href = "javascript:void(0)"(click) = "openExcelFile()" > {{ excelFile}}</a></span>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<div class='row filters' *ngIf='showHideSearch'>
			<div class='col-md-9'>
				<div class='row'>
				<div class='col-md-4'>
					<label class='inline-label'> Legal Land Description</label>
					<input type='text' class='form-control block-element' placeholder=' Legal Land Description' [(ngModel)]='LegalLandDescription' />
				</div>
				<div class='col-md-4'>
					<label class='inline-label'> Acres</label>
					<input type='text' class='form-control block-element' placeholder=' Acres' [(ngModel)]='Acres' />
				</div>
				<div class='col-md-4'>
					<label class='inline-label'> Soil Type</label>
					<input type='text' class='form-control block-element' placeholder=' Soil Type' [(ngModel)]='SoilType' />
				</div>
				<div class='col-md-4'>
					<label class='inline-label'> Notes</label>
					<input type='text' class='form-control block-element' placeholder=' Notes' [(ngModel)]='Notes' />
				</div>
				</div>
			</div>
		<div class='col-md-3'>
			<div class='row'>
				<div class='col-md-12 col-xs-4 text-right'>
					<button class='btn btn-primary btn-sm btn-search' (click)='searchClick()' [disabled]="progressStatus">Search</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right'>
					<button class='btn btn-primary btn-sm btn-search' (click)='searchAndHideClick()' [disabled]="progressStatus">Search and Hide</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right'>
					<button class='btn btn-primary btn-sm btn-search' (click)='searchClearClick()' [disabled]="progressStatus">Clear-Search</button>
				</div>
			</div>
		</div>
		</div>
	</div>
</div>

<div class='table-container'>
<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
	<ng-container matColumnDef='Id'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
		<td mat-cell *matCellDef='let e'>
			<button (click)="editLocation(e.Id,inCompanyId)" mat-mini-fab color="default">
				<mat-icon>edit</mat-icon>
			</button>
		</td>
	</ng-container>
	<ng-container matColumnDef='CompanyId'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'> Company Id</th>
		<td mat-cell *matCellDef='let e' class='col2' >{{e.CompanyId}}</td>
	</ng-container>
	<ng-container matColumnDef='LegalLandDescription'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Legal Land Description</th>
		<td mat-cell *matCellDef='let e' class='col3' >{{e.LegalLandDescription}}</td>
	</ng-container>
	<ng-container matColumnDef='Acres'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> Acres</th>
		<td mat-cell *matCellDef='let e' class='col4' >{{e.Acres}}</td>
	</ng-container>
	<ng-container matColumnDef='SoilType'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'> Soil Type</th>
		<td mat-cell *matCellDef='let e' class='col5' >{{e.SoilType}}</td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
	<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
</div>
<mat-paginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>
<div>

	<br>
	<h2>Land Locations</h2>
	<google-map height="400px" width="100%" [options]="options">
		 @for (location of locationList; track location; let index = $index) {
		   <map-advanced-marker
			 #markerElem="mapAdvancedMarker"
			 [position]="{ lat: location.Latitude, lng: location.Longitude }"
			 [title]="'Land Description: ' +location.LegalLandDescription"
			 (mapClick)="onMarkerClick(markerElem)"
		   />
		   }
		 <map-info-window #infoWindow></map-info-window>
	   </google-map>

<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>