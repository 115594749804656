import { Component, OnInit, OnDestroy, ViewChildren, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControlName, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ICustomercontact } from '../customercontact-model/customercontact';
import { Subscription } from 'rxjs/Subscription';
import { CustomercontactService } from '../customercontact-service';
import { ICustomercontacts } from '../Customercontact-model/Customercontacts';
import { ICustomercontactSearch } from '../Customercontact-model/CustomercontactSearch';
import { CustomercontactFileDto } from '../customercontact-model/CustomercontactFileDto';
import { ApplicationSettings } from '../../shared/application-settings';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import { MatPaginator as MatPaginator } from '@angular/material/paginator';

import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { LookuplistService } from '../../lookuplists/lookuplist-service';
import { CustomerService } from '../../customers/customer-service';
import { debounceTime, switchMap }  from 'rxjs/operators';

@Component({
	templateUrl: './customercontact-edit.component.html',
	styleUrls: ['customercontact-edit.component.css']
})

export class CustomercontactEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

@ViewChild(MatPaginator) paginator: MatPaginator;
displayedColumns: string[] =  ['Edit', 'FileName', 'FileDescription', 'SortOrder', 'IncludeInReport','Delete'];
	public page = 'Edit Customer Contacts';
	errorMessage: string;
	customercontactForm: FormGroup;
	customercontact: ICustomercontact;
	private sub: Subscription;
	public filesForm: FormGroup
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public CustomercontactSearch: ICustomercontactSearch;
	public ListData: ICustomercontacts;
	public showNextRecordButton: boolean = true;
	public progressStatus=false;

	public buttonName:any = 'Save File';
	public filename:any = '';
	public show:boolean = false;
	public showButtonName:any = 'Add New File';
	fileFormSubmitted: boolean = false;
	fileToEdit: CustomercontactFileDto;
	public dataSource: any;
	customercontactId: number;
	public typeList:any;
	autoCustomerName:any;

	@ViewChild('inputFile') fileUp: ElementRef;
	@ViewChild('firstPaginator', { static: true}) firstPaginator: MatPaginator;
	constructor(private fb: FormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, public applicationSettings: ApplicationSettings
		, private router: Router
		, private customercontactService: CustomercontactService
		, private lookuplistService:LookuplistService
		, public customerService:CustomerService
		) {
	}

	ngOnInit(): void {
		this.customercontactForm = this.fb.group({
			Id: '',
			CustomerId: '',
			Type: ['',[Validators.maxLength(250)]],
			ContactDate: '',
			FutureReminderDate: '',
			Notes: ['',[Validators.maxLength(4000)]],
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
			CustomerName:'', 
		});

		this.filesForm = this.fb.group({
			fileUpload:['',Validators.required],
			fileDescription:[''],
			sortOrder:[''],
			includeReport:[''],
		})
		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.customercontactId = id;
				this.getCustomercontact(id);
				this.progressStatus = true;
			}
		);
		this.getType();
		this.getAllCustomers();
	}

	public getSelectedRecordSet():void{
		this.customercontactService.getRecordSet(this.CustomercontactSearch)
			.subscribe((obj: ICustomercontacts) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: ICustomercontacts){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		console.log(obj);
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.customercontact.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

		this.progressStatus = false;

	}

	saveCustomercontact(returnToList: boolean, advanceToNextRecord: boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

		if (this.customercontactForm.valid) {
			const obj = Object.assign({ }, this.customercontact, this.customercontactForm.value);
			this.customercontactService.saveCustomercontact(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean): void {
		if (returnToList) {
			this.customercontactForm.reset();
			this.router.navigate(['/customercontacts']);
		} 
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
			// when the record id is zero then it is a new record.
		else if (data !== null && this.customercontact.Id === 0) {
			this.router.navigate(['/customercontact-details', data.Id]);
		} 
		else if (this.customercontact.Id != 0){
			this.getCustomercontact(this.customercontact.Id);
		}

		this.buttonPushed = false;
		this.progressStatus = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.customercontact.Id)
				record = count;
		});

		this.getCustomercontact(this.ListData.data[record].Id)
	}

	getCustomercontact(id: number): void {
		this.customercontactService.getCustomercontact(id)
			.subscribe((obj: ICustomercontact) => this.onCustomercontactRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onCustomercontactRetrieved(customercontact: ICustomercontact): void {
		if (this.customercontactForm) {
			this.customercontactForm.reset();
		}
		this.customercontact = customercontact;
		if (this.customercontact.Id === 0) {
			this.page = 'Add Customer Contacts';
		} else {
			this.page = 'Edit Customer Contacts';
		}

		this.customercontactForm.patchValue({
			Id: this.customercontact.Id,
			CustomerId: this.customercontact.CustomerId,
			Type: this.customercontact.Type,
			ContactDate: this.customercontact.ContactDate,
			FutureReminderDate: this.customercontact.FutureReminderDate,
			Notes: this.customercontact.Notes,
			CreatedDate: this.customercontact.CreatedDate,
			CreatedBy: this.customercontact.CreatedBy,
			UpdatedDate: this.customercontact.UpdatedDate,
			UpdatedBy: this.customercontact.UpdatedBy,
			CustomerName:this.customercontact.CustomerName, 
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteCustomercontact(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.customercontact.CustomerId}?`)) {
			this.customercontactService.deleteCustomercontact(this.customercontact.Id)
				.subscribe(() => this.onSaveComplete(true, null, false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/customercontacts']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Customer Contacts List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.CustomercontactSearch = this.customercontactService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'CustomerId')
				this.CustomercontactSearch.CustomerId = i.Value;

			if (i.Item == 'Type')
				this.CustomercontactSearch.Type = i.Value;

			if (i.Item == 'ContactDate')
				this.CustomercontactSearch.ContactDate = i.Value;

			if (i.Item == 'FutureReminderDate')
				this.CustomercontactSearch.FutureReminderDate = i.Value;

			if (i.Item == 'Notes')
				this.CustomercontactSearch.Notes = i.Value;

			if (i.Item == 'orderByString')
				this.CustomercontactSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.CustomercontactSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
		this.getFileCustomercontactData();
	}

	onFileSelected(event) {
		console.log('onFileSelected:');
		if (event.target.files.length > 0) {
		 this.filesForm.patchValue({
		 fileUpload: event.target.files [0],
		 })
		 }
	}
	onSaveFile(){
		console.log('onSaveFile');

		this.fileFormSubmitted = true;

		if (this.filesForm.invalid)
		{
			console.log('onSaveFile::Form Invalid');
			return;
		}

		const fileForm = this.filesForm.value;
		const formData = new FormData();
		formData.append('RecordId', this.customercontact.Id.toString());
		formData.append('FileDescription', fileForm.fileDescription || 0);
		formData.append('IncludeInReport', !!fileForm.includeReport ? '1' : '0');
		formData.append('SortOrder', fileForm.sortOrder || 0);
			formData.append('FileName', fileForm.fileUpload);


		if (this.fileToEdit && this.fileToEdit.Id)
		{
			formData.append('Id', this.fileToEdit.Id.toString());
			formData.append('GeneratedFileName', this.fileToEdit.GeneratedFileName);

			this.customercontactService.updateFileCustomercontact(formData).subscribe(file => {
				this.fileToEdit = null;
				this.clearForm();
				this.fileUp.nativeElement.value = '';
				this.getFileCustomercontactData();
			});
		}
		else
		{
			this.customercontactService.createFileCustomercontact(formData).subscribe(file => {
				this.clearForm();
				this.fileUp.nativeElement.value = '';
				this.getFileCustomercontactData()});
			}
		}
	editFileData(file){
		this.fileToEdit = file;
		this.filesForm.patchValue({
			fileUpload: this.fileToEdit.FileName,
			fileDescription: this.fileToEdit.FileDescription,
			sortOrder: this.fileToEdit.SortOrder,
			includeReport: this.fileToEdit.IncludeInReport === 1 ? true : false
		});
	}

	clearForm() {
		this.filesForm.reset();
			this.fileFormSubmitted = false;
	}

	open(row) {
		window.open(`${ this.applicationSettings.retrieveUrl()}${ row.FilePath}${ row.GeneratedFileName}`);
	}

	deleteFileData(id) {
		this.customercontactService.deleteFileCustomercontact(id).subscribe(file => this.getFileCustomercontactData())
	}

	//This gets the file grid
	getFileCustomercontactData(){
		console.log('should get the file grid');
		if (this.customercontact)
		{
			if (this.customercontact.Id > 0)
			{
				this.customercontactService.getAllFilesCustomercontact(this.customercontact.Id).subscribe(response => {
					console.log('file list returned');
					console.log(response);
					this.dataSource = new MatTableDataSource(response);
					this.dataSource.paginator = this.firstPaginator;
				});
			}
		}
	}

	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
	
	getType() {
		this.lookuplistService.getLookupListByForm('Customercontacts', 'Type').subscribe(response => {
			this.typeList = response;
		}	
		, (error: any) => this.errorMessage = < any > error);
	}
	public openLookUpList(value)
	{
		   const url = this.router.serializeUrl(
		       this.router.createUrlTree([`/lookuplist-details`,0,'Customercontacts',value])
		);
		  window.open(url,'_blank');
	 }
	 public getAllCustomers(){
		this.customercontactForm.controls.CustomerName.valueChanges
		 .pipe(
		 debounceTime(1000),
		 switchMap(value =>
		 this.customerService.getAllCustomers(value)
		 )
		 )
		 .subscribe(response => {
		 this.autoCustomerName = response
			   }); 
		}
}
