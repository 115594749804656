import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IMaterial} from './Material-model/Material';
import { IMaterials } from './Material-model/Materials';
import { IMaterialSearch } from './Material-model/MaterialSearch';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';

@Injectable()
export class MaterialService {
	private baseUrl;
	public pageTitle = 'Materials List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Materials';
	}

	getMaterial(id: number):  Observable<IMaterial> {
		if (id === 0) {
			return of(this.initializeMaterial());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IMaterial>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveMaterial(material: IMaterial): Observable<any> {
		if (material.Id === 0) {
			return this.createMaterial(material);
		}
		return this.updateMaterial(material);
	}

	private createMaterial(material: IMaterial): Observable<any> {
		material.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/materialcreate', material)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateMaterial(material: IMaterial): Observable<IMaterial> {
		return this.authHttp.put<IMaterial>(this.baseUrl, material)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteMaterial(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeMaterial(): IMaterial {
		return {
			Id: 0,
			Name: '',
			Description: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IMaterial;
	}

	public getRecordSet(search: IMaterialSearch): Observable<IMaterials> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('Name', search.Name, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Description', search.Description, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('Name', search.Name ? search.Name : '')
			.set('Description', search.Description ? search.Description : '')
		};

		return this.authHttp.get<IMaterials>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IMaterials>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IMaterialSearch { 
		let search: IMaterialSearch = {
			Id: 1,
			Name: '',
			Description: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.error(message);
		return throwError(message);
	}
	updateFileMaterial(file: any){
		const url=`${this.baseUrl}/fileupdate`;
		return this.authHttp.put<IMaterials>(url, file)
			.pipe(retry(1),
				catchError(this.processError))
	}
	deleteFileMaterial(id: number){
		const url=`${this.baseUrl}/filedelete?id=${id}`;
		return this.authHttp.delete<any>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
	getAllFilesMaterial(Id: number){
		const url=`${this.baseUrl}/files?id=${Id}`;
		return this.authHttp.get<IMaterials[]>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
	createFileMaterial(file: any){
		const url=`${this.baseUrl}/filecreate`;
		return this.authHttp.post<any>(url, file)
			.pipe(retry(1),
			catchError(this.processError))
	}
	public toExcel(search: IMaterialSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('Name', search.Name ? search.Name : '')
			.set('Description', search.Description ? search.Description : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
	getAllMaterials():  Observable<any> {
		const url = `${this.baseUrl}/getallmaterials`;

		return this.authHttp.get<any>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
}