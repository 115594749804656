<div class="panel">
    <!-- <div class="panel-heading"></div> -->
    <div class="panel-body">
        <div class='row'>
            <div class='col-md-8'>
                <div class='has-error' class='text-danger' *ngIf='errorMessage'>
                    <button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
                    {{ errorMessage }}
                </div>
            </div>
        </div>
        <div class='row'>
                <div class="col-md-4" *ngIf="localGLHoseFlag">
                    <div *ngIf="serverStatus && deviceStatus">
                        <label style="color: green;font-size: 18px;">Local Communications Working</label>
                    </div>
                    <div *ngIf="!serverStatus">
                        <label style="color: red;font-size: 18px;">Start Local Communications</label>
                        
                    </div>
                    <div *ngIf="serverStatus && !deviceStatus">
                        <label style="color: red;font-size: 18px;">Device Communications Not Working: {{ip}}</label>
                    </div>
                </div>
                <div class="col-md-2" *ngIf="!serverStatus">
                    <button class="btn btn-primary btn-sm" (click)="reconnect()">Reconnect</button>
                </div>
                <div class="col-md-2" >
                    <h2>ROBOT CELL 16</h2> 
                </div>
               
                <div class="col-md-2">
                    <div *ngIf="hearbeatStatus==1 && serverStatus==true && deviceStatus==true">
                        <img src="./assets/heartbeatimg1.jpg" width="80" height="50" />
                    </div>
                    <div *ngIf="hearbeatStatus==0 && serverStatus==true && deviceStatus==true">
                        <img src="./assets/heartbeatimg2.jpg" width="80" height="50" />
                    </div>
                    <div *ngIf="serverStatus==false || deviceStatus==false">
                        <img src="./assets/heartbeatimg3.jpg" width="80" height="50" />
                    </div>
                </div>
                <div class="col-md-2" >
                   
                </div>
        </div>
        <div class='row'>
            <mat-tab-group class="custom-tab-group mat-tab-labels">
            <mat-tab label="Home">
                <div class="custom-tab-content">
                <div class="row">
                    <div class="col-md-4">
                        <h2>MAIN SCREEN</h2>
                        <div>
                            <button [disabled]='AbortbuttonPushed' style="background-color: white;" class="btn"(mousedown)="sendPulse('80','Abort')">Abort<br /><img style="pointer-events: none;"
                                    src={{imgAbort}} /></button>
                            <button [disabled]='ResetbuttonPushed' style="background-color: white;" class="btn"  (mousedown)="sendPulse('81','Reset')">Reset<br /><img style="pointer-events: none;"
                                    src={{imgReset}} /></button>
                            <button [disabled]='StartbuttonPushed' style="background-color: white;" class="btn"  (mousedown)="sendPulse('82','Start')">Start<br /><img style="pointer-events: none;"
                                    src={{imgStart}} /></button>
                            <br />
                            <button [disabled]='HomebuttonPushed' style="background-color: white;" class="btn"  (mousedown)="sendPulse('83','Home')">Home<br /><img style="pointer-events: none;"
                                    src={{imgHome}} /></button>
                            
                            <!-- <button style="background-color: white;" class="btn" (click)="toggleButton('LastPartNow')">Last Part Now<br />
                                <img *ngIf="LastPartNow=='off'" src={{imgGrayURL}}  style="pointer-events: none;"/>
                                <img *ngIf="LastPartNow=='on'" src={{imgGrayDownURL}}  style="pointer-events: none;"/>
                            </button> -->
                               
                            <button [disabled]='HoldbuttonPushed' style="background-color: white;" class="btn"  (mousedown)="sendPulse('85','Hold')">Hold<br /><img style="pointer-events: none;"
                                    src={{imgHold}} /></button>
                            <br />
                            <!-- <button style="background-color: white;" class="btn" (click)="toggleButton('LastPartAfter')">Last Part After<br />
                                <img *ngIf="LastPartAfter=='off'" src={{imgGrayURL}}  style="pointer-events: none;"/>
                                <img *ngIf="LastPartAfter=='on'" src={{imgGrayDownURL}}  style="pointer-events: none;"/>
                            </button> -->
                        </div>
                    </div>
                    <div class="col-md-3">
                        <h2>Robot Status</h2>
                        <div class="row">
                            <div class="col-md-6">
                                <label> Cycle Start:</label>
                            </div>
                            <div class="col-md-6">
                                <label *ngIf="CycleStart=='off'">not running</label>
                                <label *ngIf="CycleStart=='on'" style="color: blue;"><b>RUNNING</b></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label> Robot Status:</label>
                            </div>
                            <div class="col-md-6">
                                <label *ngIf="RobotFault=='off'" style="color: blue;"><b>Ready</b></label>
                                <label *ngIf="RobotFault=='on'" style="color: red;"><b>FAULT</b></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label> Teach Pendent:</label>
                            </div>
                            <div class="col-md-6">
                                <label *ngIf="TPEnabled=='off'" style="color: blue;"><b>TP AUTO MODE</b></label>
                                <label *ngIf="TPEnabled=='on'" style="color: red;"><b>TP ENABLED</b></label>
                               
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label> Step Mode:</label>
                            </div>
                            <div class="col-md-6">
                                <label *ngIf="StepEnabled=='off'" style="color: blue;"><b>RUN MODE</b></label>
                                <label *ngIf="StepEnabled=='on'" style="color: red;"><b>PAUSE MODE</b></label>
                               
                            </div>
                        </div>
                        <h2>Job Selection</h2>
                        <div class="row">   
                            <div class="col-md-4  label-machine">
                                <label> Jobs available</label>
                            </div>
                                <div class="col-md-8">
                                    <mat-select placeholder="Select Job" class="form-control" (selectionChange)='selectJob($event.source.value)'>
                                        @for (item of JobsList; track item) {
                                            <mat-option [value]="item">{{item.Description}}</mat-option>
                                        }
                                    </mat-select>
                                </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 label-machine">
                                <label> Current Part Number</label>
                            </div>
                            <div class="col-md-6 label-machine">
                                <label> {{currentPart}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-5">
                        <h2>Part Template Area</h2>
                       
                        <div class="row">
                            <div class="col-md-3">
                        <div class="col-md-12 form-group">
                            <div class="col-md-12"> Area 1</div>
                            <div *ngIf="Status1=='Un-loaded'" class="col-md-12">
                                <button (click)="sendPulse('87','LoadDrawer1')" class="btn"
                                    [ngStyle]="{'background-color':'#ffff66'}">Finished</button>
                            </div>
                            <div *ngIf="Status1=='Loaded'" class="col-md-12">
                                <button (click)="sendPulse('91','UnLoadDrawer1')" class="btn"
                                    [ngStyle]="{'background-color':'#8EF216'}">Loaded</button>
                            </div> 
                            <div *ngIf="Status1!=='Un-loaded'&&Status1!=='Loaded'" class="col-md-12">
                                <button class="btn"
                                    [ngStyle]="{'background-color':'#ececf9'}">(please wait)</button>
                            </div> 
                            <!-- <div class="col-md-6"><b>{{Status1}}</b></div> -->
                        </div>
                        <div class="col-md-12 form-group">
                            <div class="col-md-12">Area 2</div>
                            <div *ngIf="Status2=='Un-loaded'" class="col-md-12"><button (click)="sendPulse('88','LoadDrawer2')" class="btn"
                                    [ngStyle]="{'background-color':'#ffff66'}">Finished</button>
                            </div>
                              <div *ngIf="Status2=='Loaded'" class="col-md-12">
                                <button (click)="sendPulse('92','UnLoadDrawer2')" class="btn"
                                    [ngStyle]="{'background-color':'#8EF216'}">Loaded</button>
                            </div>
                            <div *ngIf="Status2!=='Un-loaded'&&Status2!=='Loaded'" class="col-md-12">
                                <button class="btn"
                                    [ngStyle]="{'background-color':'#ececf9'}">(please wait)</button>
                            </div> 
                            <!-- <div class="col-md-6"><b>{{Status2}}</b></div> -->
                        </div>
                        <div class="col-md-12 form-group">
                            <div class="col-md-12">Area 3</div>
                            <div *ngIf="Status3=='Un-loaded'" class="col-md-12"><button (click)="sendPulse('89','LoadDrawer3')" class="btn"
                                    [ngStyle]="{'background-color':'#ffff66'}">Finished</button>
                            </div>
                              <div *ngIf="Status3=='Loaded'" class="col-md-12">
                                <button (click)="sendPulse('93','UnLoadDrawer3')" class="btn"
                                    [ngStyle]="{'background-color':'#8EF216'}">Loaded</button>
                            </div>
                            <div *ngIf="Status3!=='Un-loaded'&&Status3!=='Loaded'" class="col-md-12">
                                <button class="btn"
                                    [ngStyle]="{'background-color':'#ececf9'}">(please wait)</button>
                            </div> 
                            <!-- <div class="col-md-6"><b>{{Status3}}</b></div> -->
                        </div>
                        <div class="col-md-12 form-group">
                            <div class="col-md-12">Area 4</div>
                            <div *ngIf="Status4=='Un-loaded'" class="col-md-12"><button (click)="sendPulse('90','LoadDrawer4')" class="btn"
                                    [ngStyle]="{'background-color':'#ffff66'}">Finished</button>
                            </div>
                              <div *ngIf="Status4=='Loaded'" class="col-md-12">
                                <button (click)="sendPulse('94','UnLoadDrawer4')" class="btn"
                                    [ngStyle]="{'background-color':'#8EF216'}">Loaded</button>
                            </div>
                            <div *ngIf="Status4!=='Un-loaded'&&Status4!=='Loaded'" class="col-md-12">
                                <button class="btn"
                                    [ngStyle]="{'background-color':'#ececf9'}">(please wait)</button>
                            </div> 
                            <!-- <div class="col-md-6"><b>{{Status4}}</b></div> -->
                        </div>
                    </div>

                    <div class="col-md-9">
                        <div *ngIf="SelectedPart" class="row">
                                <table mat-table [dataSource]='TemplateData'>
        
                                    @for (item of displayedColumns; track item) {
                                        <ng-container [matColumnDef]='item'>
                                            <td mat-cell *matCellDef='let e' class='col_template' >
                                                <button style="background-color: white;" class="btn" (click)="changeStatus(e[item].Status,e[item].SequenceNumber)" [disabled]='templateButtonPressed'>
                                                <img [src]="(e[item].Status=='E')?(status_empty_img):((e[item].Status=='IP')?status_in_progress_img:(((e[item].Status=='F')?status_finished_img:status_blank_img)))" />
                                                </button>
                                            </td>
                                        </ng-container>
                                    }
                                    <tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
                                </table>
                        </div>
                    </div>

                </div>
            </div>

                </div>

            </div>

            </mat-tab>
            <mat-tab label="CNC Status" style="width: 500px; height: 500px;" class="divmachine">
                <div class="custom-tab-content">
                <div class="row">
                    <div class="col-md-6">
                        <h2>To CNC</h2>
                    </div>
                    <div class="col-md-6">
                        <h2>From CNC</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <div>
                            <ng-container *ngIf="arrCol1[0]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol1[0]!=1"><img src={{off_indicator}} /></ng-container> Main
                            Complete
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol1[1]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol1[1]!=1"><img src={{off_indicator}} /></ng-container> Auto
                            Start CMD
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol1[2]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol1[2]!=1"><img src={{off_indicator}} /></ng-container> Non
                            Interference
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol1[3]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol1[3]!=1"><img src={{off_indicator}} /></ng-container> Unclamp
                            Main
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol1[4]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol1[4]!=1"><img src={{off_indicator}} /></ng-container> Clamp
                            Main
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol1[5]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol1[5]!=1"><img src={{off_indicator}} /></ng-container> Blow
                            Main
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol1[6]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol1[6]!=1"><img src={{off_indicator}} /></ng-container> Auto
                            Power Cut Off
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol1[7]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol1[7]!=1"><img src={{off_indicator}} /></ng-container> Unclamp
                            Sub
                        </div>
                      
                    </div>
                        <div class="col-md-3">
                        <div>
                            <ng-container *ngIf="arrCol1[8]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol1[8]!=1"><img src={{off_indicator}} /></ng-container> Clamp
                            Sub
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol1[9]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol1[9]!=1"><img src={{off_indicator}} /></ng-container> Blow
                            Sub
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol1[10]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol1[10]!=1"><img src={{off_indicator}} /></ng-container> Open
                            Door
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol1[11]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol1[11]!=1"><img src={{off_indicator}} /></ng-container> Close
                            Door
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol1[12]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol1[12]!=1"><img src={{off_indicator}} /></ng-container> Robot
                            Alarm
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol1[13]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol1[13]!=1"><img src={{off_indicator}} /></ng-container> No
                            Material
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol1[14]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol1[14]!=1"><img src={{off_indicator}} /></ng-container> Fin
                            Work Area
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol1[15]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol1[15]!=1"><img src={{off_indicator}} /></ng-container> Lock
                            Front Cover Cmd
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div>
                            <ng-container *ngIf="arrCol2[0]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol2[0]!=1"><img src={{off_indicator}} /></ng-container> Spindle
                            Rotating
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol2[1]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol2[1]!=1"><img src={{off_indicator}} /></ng-container> Robot
                            Valid
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol2[2]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol2[2]!=1"><img src={{off_indicator}} /></ng-container>
                            Load/Unload Ok
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol2[3]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol2[3]!=1"><img src={{off_indicator}} /></ng-container> Chuck 1
                            Unclamp Cptl
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol2[4]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol2[4]!=1"><img src={{off_indicator}} /></ng-container> Chuck 1
                            Clamp Cplt
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol2[5]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol2[5]!=1"><img src={{off_indicator}} /></ng-container> Work
                            Holding Dtn 1
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol2[6]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol2[6]!=1"><img src={{off_indicator}} /></ng-container> Auto
                            Pwr Shutoff Enable
                        </div>
                   
                        <div>
                            <ng-container *ngIf="arrCol2[7]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol2[7]!=1"><img src={{off_indicator}} /></ng-container> Chk2
                            Unclamp Sub
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol2[8]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol2[8]!=1"><img src={{off_indicator}} /></ng-container> Chk2
                            CI/Tstk In Cplt
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div>
                            <ng-container *ngIf="arrCol2[9]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol2[9]!=1"><img src={{off_indicator}} /></ng-container> Work
                            Hold Dtn 2
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol2[10]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol2[10]!=1"><img src={{off_indicator}} /></ng-container> Unload
                            Spindle (M63)
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol2[11]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol2[11]!=1"><img src={{off_indicator}} /></ng-container> ---
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol2[12]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol2[12]!=1"><img src={{off_indicator}} /></ng-container>
                            Machine Alarm
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol2[13]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol2[13]!=1"><img src={{off_indicator}} /></ng-container>
                            Service Main
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol2[14]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol2[14]!=1"><img src={{off_indicator}} /></ng-container>
                            Service Sub
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol2[15]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol2[15]!=1"><img src={{off_indicator}} /></ng-container> Front
                            Cover CI Cplt
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol2[16]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol2[16]!=1"><img src={{off_indicator}} /></ng-container> M64
                            (bit5)
                        </div>
                        <div>
                            <ng-container *ngIf="arrCol2[17]==1"><img src={{on_indicator}} /></ng-container>
                            <ng-container *ngIf="arrCol2[17]!=1"><img src={{off_indicator}} /></ng-container> M63
                            (bit6)
                        </div>
                    </div>
                </div>   
            </div>            
            </mat-tab>
        </mat-tab-group>
        <div>
			<h3>MAC: {{macAddress}}    IP: {{ip}}   Port: {{port}} </h3> 
		</div>
    </div>
    </div>
</div>