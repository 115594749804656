import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControlName } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IEmployeelesson } from '../employeelesson-model/employeelesson';

import { Subscription } from 'rxjs/Subscription';
import { EmployeelessonService } from '../employeelesson-service';
import { TrainingManagementService } from '../../trainingmanagement-service';
import { IEmployeelessons } from '../Employeelesson-model/Employeelessons';
import { IEmployeelessonSearch } from '../Employeelesson-model/EmployeelessonSearch';

import { UsersearchService } from '../../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../../admin/usersearches/usersearch-model/usersearch';


@Component({
	templateUrl: './employeelesson-custom.component.html',
	styleUrls: ['employeelesson-custom.component.css']
})

export class EmployeelessonCustomComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
	public page = 'Edit EmployeeLessons';
	errorMessage: string;
	employeelessonForm: FormGroup;
	employeelesson: IEmployeelesson;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public EmployeelessonSearch: IEmployeelessonSearch;
	public ListData: IEmployeelessons;
	public disableNextRecordButton: boolean = true;
	public disablePreviousRecordButton = true;

	public LessonId: number;

	constructor(private fb: FormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private employeelessonService: EmployeelessonService
		, private trainingManagementService: TrainingManagementService
		) {
	}

	ngOnInit(): void {
		this.employeelessonForm = this.fb.group({
			LessonName: '',
			CoursesId: '',
			LessonId: '',
			ContentOrder: '',
			ContentName: '',
			ContentDescription: '',
			ContentReviewed: '',
			ContentStatus: '',
			ContentNotes: '',
			LessonUser: '',
			Employee: '',
			createddate: '',
			createdby: '',
			updateddate: '',
			updatedby: '',
		});
		//A lesson id is entered.  We want to get the list of lessons and then get the first one.
		this.sub = this.route.params.subscribe(
			params => {
				this.LessonId = +params['Id'];
				this.record = +params['CurrentPage'];
				//this.getEmployeelesson(this.LessonId);
				this.setUserFilters();
			}
		);
	}

	public getSelectedRecordSet():void{
		this.trainingManagementService.startLesson(this.EmployeelessonSearch)
			.subscribe(() => console.log('lesson started'),
			(error: any) => {
				this.errorMessage = < any > error;
		});

		this.employeelessonService.getRecordSet(this.EmployeelessonSearch)
			.subscribe((obj: IEmployeelessons) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: IEmployeelessons){
		this.ListData = obj;
		this.recordTotal = obj.total;

		this.getEmployeelesson(this.ListData.data[this.record].Id);


	
	}

	private showHideButtons(){

		let count = 0;
		let record = 0;
		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.employeelesson.Id)
				record = count;
		});

		this.record = +this.ListData.skip + record;

		if (this.record ==this.ListData.total)
			this.disableNextRecordButton = true;
		else
			this.disableNextRecordButton = false;

		//Hide Previous Button here
		console.log('current record: ' + this.record);
		if (this.record==1)
			this.disablePreviousRecordButton = true;
		else
			this.disablePreviousRecordButton = false;

		this.buttonPushed = false;

	}

	saveEmployeelesson(returnToList: boolean, advanceToNextRecord: boolean): void {
		this.buttonPushed = true;

		if(advanceToNextRecord)
			this.advanceToNextRecord();
		else
			this.advanceToPreviousRecord();

	}

	advanceToNextRecord(): void {
		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.employeelesson.Id)
				record = count;
		});

		this.getEmployeelesson(this.ListData.data[record].Id)
	}

	advanceToPreviousRecord(): void {
		
		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.employeelesson.Id)
				record = count;
		});

		record = record -2;
	console.log('record: ' + record);
		console.log(this.ListData.data[record].Id);
		this.getEmployeelesson(this.ListData.data[record].Id)
	}

	getEmployeelesson(id: number): void {
		this.employeelessonService.getEmployeelesson(id)
			.subscribe((obj: IEmployeelesson) => this.onEmployeelessonRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onEmployeelessonRetrieved(employeelesson: IEmployeelesson): void {
		if (this.employeelessonForm) {
			this.employeelessonForm.reset();
		}
		this.employeelesson = employeelesson;
		if (this.employeelesson.Id === 0) {
			this.page = 'Add EmployeeLessons';
		} else {
			this.page = 'Edit EmployeeLessons';
		}

		this.employeelessonForm.patchValue({
			Id: this.employeelesson.Id,
			LessonName: this.employeelesson.LessonName,
			CoursesId: this.employeelesson.CoursesId,
			LessonId: this.employeelesson.LessonId,
			ContentOrder: this.employeelesson.ContentOrder,
			ContentName: this.employeelesson.ContentName,
			ContentDescription: this.employeelesson.ContentDescription,
			ContentReviewed: this.employeelesson.ContentReviewed,
			ContentStatus: this.employeelesson.ContentStatus,
			ContentNotes: this.employeelesson.ContentNotes,
			LessonUser: this.employeelesson.LessonUser,
			Employee: this.employeelesson.Employee,
			createddate: this.employeelesson.createddate,
			createdby: this.employeelesson.createdby,
			updateddate: this.employeelesson.updateddate,
			updatedby: this.employeelesson.updatedby,
		});

		this.showHideButtons();
		
	}

	
	ngOnDestroy(): void {
	}

	cancel(): void {
		this.router.navigate(['/training/employeecourses']);
	}


	setUserFilters(): void {
		this.EmployeelessonSearch = this.employeelessonService.getFreshSearch();
		this.EmployeelessonSearch.LessonId = this.LessonId.toString();
		this.EmployeelessonSearch.orderByString = 'asc::ContentOrder::';
		
		this.getSelectedRecordSet();
	}


	completeLesson(): void {
		this.trainingManagementService.completeLesson(this.EmployeelessonSearch)
			.subscribe(() => console.log('lesson completed'),
			(error: any) => {
				this.errorMessage = < any > error;
		});

		this.cancel();
	}

}
