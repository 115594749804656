

<div class="card">
<div class='panel panel-primary animaPlaceholder'>
	<div class='panel-heading'>
		<div class='row'>
		<div class='col-md-3'>
			{{pageTitle}}
		</div>
		<div class='col-lg-3 col-md-3 col-xs-4 options-bar'>
			<button class='btn btn-default btn-sm center-block btn-full' [routerLink]="['/worktask-details/0']">Add New Work Task</button>
		</div>
		<div class='col-lg-2 col-md-2 col-xs-4 options-bar'>
			<button class='btn btn-default btn-sm center-block btn-full' (click)='searchClick()'>Search</button>
		</div>
		<div class='col-lg-2 col-md-2 col-xs-4 options-bar'>
			<button class='btn btn-default btn-sm center-block btn-full' (click)='searchClearClick()'>Clear-Search</button>
		</div>
		<div class='col-lg-2 col-md-2 col-xs-4 options-bar'>
			<label>Rows</label>&nbsp;&nbsp;
			<select [(ngModel)]='pageSize' (change)='pageSizeValueChanged()'>
				<option *ngFor='let item of applicationSettings.pageSizes'>{{item}}</option>
			</select>
		</div>
	</div>
	</div>

	<div class='card-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		
		<div class='row filters'>
			<div class='col-md-2'>
				<label class='inline-label'>Name</label>
				<input type='text' class='form-control block-element' placeholder='Name' [(ngModel)]='Name' />
			</div>
			<div class='col-md-2'>
				<label class='inline-label'>Priority</label>
				<input type='number' class='form-control block-element' placeholder='Priority' [(ngModel)]='Priority' />
			</div>
			<div class='col-md-2'>
				<label class='inline-label'>Application</label>
				<select  class='form-control' [(ngModel)]='Application' >
					<option *ngFor="let item of applicationSettings.apps" [value]="(item)">{{item}}</option>
				</select>
			</div>
			<div class='col-md-3'>
				<label class='inline-label'>Assigned User</label>
				<select  class='form-control' [(ngModel)]='AssignedUser' >
					<option *ngFor="let item of applicationSettings.employees" [value]="(item)">{{item}}</option>
				</select>
			</div>
			<div class='col-md-2'>
				<label class='inline-label'>Status</label>
				<select  class='form-control' [(ngModel)]='Status' >
					<option *ngFor="let item of applicationSettings.workStatus" [value]="(item)">{{item}}</option>
				</select>
			</div>
		</div>
	</div>
</div>

<div class='table-container'>
	<table mat-table [dataSource]='dataSource' class='mat-elevation-z8' matSort >
	<ng-container matColumnDef='Id'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
		<td mat-cell *matCellDef='let e'>
		<a class='btn btn-light' style='min-width: 80px;' [routerLink]="['/worktask-details', e.Id]"  >{{e.Id}}</a>
		</td>
	</ng-container>
	<ng-container matColumnDef='Name' >
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'>Name </th>
		<td mat-cell *matCellDef='let e'> 
			<a class='btn btn-light' style='min-width: 80px;' target="_blank" [routerLink]="['/worktask-details', e.Id]"  >{{e.Name}} </a>
		</td>
	</ng-container>
	<ng-container matColumnDef='nextaction'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header disableClear class='col3'>Next Action</th>
		<td mat-cell *matCellDef='let e'> {{e.nextaction}} </td>
	</ng-container>
	<ng-container matColumnDef='Priority'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header disableClear class='col4'>Priority</th>
		<td mat-cell *matCellDef='let e'> {{e.Priority}} </td>
	</ng-container>
	<ng-container matColumnDef='EstimatedHours'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header disableClear class='col5'>Est.</th>
		<td mat-cell *matCellDef='let e'> {{e.EstimatedHours}} </td>
	</ng-container>
	<ng-container matColumnDef='Application'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header disableClear class='col6'>App</th>
		<td mat-cell *matCellDef='let e'> {{e.Application}} </td>
	</ng-container>
	<ng-container matColumnDef='Status'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header disableClear class='col7'>Status</th>
		<td mat-cell *matCellDef='let e'> {{e.Status}} </td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
	<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
</div>  
<mat-paginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>
</div>
