
<div class='panel panel-primary animaPlaceholder' *ngIf = 'employeecourse'>
	<div class='panel-heading'>
		<span *ngIf = 'employeecourse' >{{ page }} :{{ employeecourse.Id }}</span>
		<span class= 'pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class= 'panel-body'>
		<div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<form class='form-horizontal' novalidate [formGroup]='employeecourseForm'>
			<fieldset>
			<div class='panel-body'>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Course Name</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='CourseName' />
							<span *ngIf = "employeecourseForm.controls['CourseName'].invalid  && employeecourseForm.controls['CourseName'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Lesson Id</label>
						<div class='col-md-10'>
							<input class='form-control' type='number' formControlName='LessonId' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Lesson Name</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='LessonName' />
							<span *ngIf = "employeecourseForm.controls['LessonName'].invalid  && employeecourseForm.controls['LessonName'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Lesson Status</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='LessonStatus' />
							<span *ngIf = "employeecourseForm.controls['LessonStatus'].invalid  && employeecourseForm.controls['LessonStatus'].errors.maxlength" class="text-danger">
									Only 1 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Quiz Status</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='QuizStatus' />
							<span *ngIf = "employeecourseForm.controls['QuizStatus'].invalid  && employeecourseForm.controls['QuizStatus'].errors.maxlength" class="text-danger">
									Only 1 characters are allowed.
							</span>
						</div>
					</div>
			</div>
			</fieldset>
		</form>

			<div class='row' style='padding: 15px;'>
				<div class= 'col-md-12'>
					<span>
						<button class='btn btn-primary btn-edit-sml'
							[disabled]='buttonPushed'
								(click)='saveEmployeecourse(false, false,false)'
									style='width: 110px; margin-right:10px'
									[disabled]='!employeecourseForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
								</button>
								<button class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveEmployeecourse(true, false,false)'
									style='width: 150px; margin-right:10px'
									[disabled]='!employeecourseForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
								</button>
								<button *ngIf='showNextRecordButton && employeecourse.Id ' class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveEmployeecourse(false, true,false)'
									style='width: 190px; margin-right:10px'
									[disabled]='!employeecourseForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
								</button>
								<button  class='btn btn-primary btn-edit-sml' *ngIf="!employeecourse.Id"  
									  [disabled]='buttonPushed'
									  (click)='saveEmployeecourse(false, false,true)'
									  style ='width: 200px; margin-right:10px'
									  [disabled]='!employeecourseForm.valid || progressStatus'>
									   <span class='glyphicon glyphicon-floppy-save'></span> Save & Add New Record &nbsp;
								  </button>
							</span>

						<span  class='pull-right remove-float-sml'>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='cancel()'
								style='width: 150px; margin-right:10px'
								[disabled]='!employeecourseForm.valid || progressStatus'>
								Cancel &nbsp;
							</button>
							<button class='btn btn-primary btn-edit-sml' *ngIf="employeecourse.Id" 
								[disabled]='buttonPushed'
								(click)='deleteEmployeecourse()'
								style='width: 150px; margin-right:10px'
								[disabled]='!employeecourseForm.valid || progressStatus'>
								<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
							</button>
						</span>
					</div>
				</div>

				<div class='row'>
					<div class='col-md-12'>
						<span> Updated By: <label *ngIf='employeecourse'>{{employeecourse.UpdatedBy}}</label></span>
						<span> Time: <label *ngIf='employeecourse'>{{employeecourse.UpdatedDate}}</label></span>
					</div>
				</div>
	</div>
</div>
<div>
<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>