import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControlName, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ILookuplist } from '../lookuplist-model/lookuplist';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';
import { Subscription } from 'rxjs/Subscription';
import { LookuplistService } from '../lookuplist-service';
import { ILookuplists } from '../Lookuplist-model/Lookuplists';
import { ILookuplistSearch } from '../Lookuplist-model/LookuplistSearch';

import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from '../../shared/dialogs/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
	templateUrl: './lookuplist-edit.component.html',
	styleUrls: ['lookuplist-edit.component.css']
})

export class LookuplistEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit Lookup List';
	errorMessage: string;
	lookuplistForm: FormGroup;
	lookuplist: ILookuplist;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public LookuplistSearch: ILookuplistSearch;
	public ListData: ILookuplists;
	public showNextRecordButton: boolean = true;

	progressStatus = true;
	public pageSize = '10';

	constructor(private fb: FormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private lookuplistService: LookuplistService
		, public snackBar: MatSnackBar
		, public dialog: MatDialog
		) {	}

	ngOnInit(): void {


		let x  = +localStorage.getItem('rows');
			if(x>0) { this.pageSize = x.toString(); }
			else { this.pageSize = '10';}

		this.lookuplistForm = this.fb.group({
			Id: '',
			Form: '',
			Field: '',
			Value: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getLookuplist(id);
				if(params['Form']&&params['Field']){
				this.lookuplistForm.patchValue({
					Form: params['Form'],
					Field: params['Field'],
				});
			}

			}
		);

	}

	public getSelectedRecordSet():void{
		this.lookuplistService.getRecordSet(this.LookuplistSearch)
			.subscribe((obj: ILookuplists) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {this.errorMessage = < any > error;this.progressStatus = false;	}
			);
	}

	private onSelectedRecordSetReceived(obj: ILookuplists){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.lookuplist.Id)
				record = count;
		});
		this.record = +obj.skip + record;
		if ((this.record-obj.skip) < this.ListData.data.length){
			this.showNextRecordButton = true;
		}
		else{
			this.showNextRecordButton = false;
		}

		this.progressStatus = false;
	}

	saveLookuplist(returnToList: boolean, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

		if (this.lookuplistForm.valid) {
			const obj = Object.assign({ }, this.lookuplist, this.lookuplistForm.value);
			this.lookuplistService.saveLookuplist(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord,addNewRecord)
				, (error: any) => {this.errorMessage = < any > error;this.progressStatus = false;});
		}else{
			this.buttonPushed = false;
			this.progressStatus = false;
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		if (returnToList) {
			this.lookuplistForm.reset();
			this.router.navigate(['/lookuplists']);
		} 
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
		else if(addNewRecord){
			this.router.navigate(['/lookuplist-details', 0]);
			this.lookuplistForm.reset();
			let obj=this.lookuplistService.initializeLookuplist();
			this.onLookuplistRetrieved(obj);
		}
			// when the record id is zero then it is a new record.
		else if (data !== null && this.lookuplist.Id === 0) {
			this.router.navigate(['/lookuplist-details', data.Id]);
		} 
		else if (this.lookuplist.Id != 0){
			this.getLookuplist(this.lookuplist.Id);
		}

		this.openSnackBar('Your changes have been successfully saved','Ok',5000);

		this.buttonPushed = false;
		this.progressStatus = false;
		
	}

	advanceToNextRecord(): void {
		let count = 0;
		let record = 0;
		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.lookuplist.Id)
				record = count;
		});
		this.router.navigate(['/lookuplist-details', this.ListData.data[record].Id]);
	}

	getLookuplist(id: number): void {
		this.lookuplistService.getLookuplist(id)
			.subscribe((obj: ILookuplist) => this.onLookuplistRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		this.progressStatus = false;
		});
	}

	onLookuplistRetrieved(lookuplist: ILookuplist): void {
		if (this.lookuplistForm) {
			this.lookuplistForm.reset();
		}
		this.lookuplist = lookuplist;
		if (this.lookuplist.Id === 0) {
			this.page = 'Add Lookup List';
		} else {
			this.page = 'Edit Lookup List';
		}

		this.lookuplistForm.patchValue({
			Id: this.lookuplist.Id,
			Form: this.lookuplist.Form,
			Field: this.lookuplist.Field,
			Value: this.lookuplist.Value,
			CreatedDate: this.lookuplist.CreatedDate,
			CreatedBy: this.lookuplist.CreatedBy,
			UpdatedDate: this.lookuplist.UpdatedDate,
			UpdatedBy: this.lookuplist.UpdatedBy,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteLookuplist(): void {
			this.lookuplistService.deleteLookuplist(this.lookuplist.Id)
				.subscribe(() => this.onSaveComplete(true, null, false,false)
				, (error: any) => {this.errorMessage = < any > error;this.buttonPushed = false;});
	}

	cancel(): void {
		this.router.navigate(['/lookuplists']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Lookup Lists')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => {this.errorMessage = < any > error;this.progressStatus = false;});
	}

	setUserFilters(): void {
		this.LookuplistSearch = this.lookuplistService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'Form')
				this.LookuplistSearch.Form = i.Value;

			if (i.Item == 'Field')
				this.LookuplistSearch.Field = i.Value;

			if (i.Item == 'Value')
				this.LookuplistSearch.Value = i.Value;

			if (i.Item == 'orderByString')
				this.LookuplistSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.LookuplistSearch.skip = i.Value;

		});
		this.LookuplistSearch.take = parseInt(this.pageSize);
		this.getSelectedRecordSet();
	}

	openSnackBar(message: string, action: string, durationMiliiseconds:number) {
		this.snackBar.open(message, action, {
		  duration: durationMiliiseconds,
		});
	}

	showDialog(): void {
		this.buttonPushed=true;
		this.dialog
		  .open(ConfirmationDialogComponent, {
			data: `Are you sure you want to delete this Lookup list?`
		  })
		  .afterClosed()
		  .subscribe((confirmed: Boolean) => {
			if (confirmed) {
				this.deleteLookuplist();
			} else {
			  this.buttonPushed=false;
			  return;
			}
		  });
	  }

	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
}
