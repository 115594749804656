import { NgModule } from '@angular/core';
import { LoadingComponent } from './loading.component';
import { MaterialModule } from '../../material-module';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';

@NgModule({
	declarations: [LoadingComponent,ConfirmationDialogComponent
	],
	imports: [
		MaterialModule
	],
	providers: [
	],
	exports:[LoadingComponent]
})
export class DialogsModule { }
