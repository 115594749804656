import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApplicationSettings } from '../shared/application-settings';

import { map } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { DailyLog } from './dailylog-model';


@Injectable()
export class DailylogService {
	private baseUrl;
    public iDailyLogs: DailyLog[];

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/dailylogs';
	}
  
    public getRecordSet(startDate: string, endDate: string, person: string): Observable<DailyLog[]> {
    	const options = {
		params: new HttpParams()
            .set('person', person)
            .set('startDate', startDate)
            .set('endDate', endDate)
		};

		return this.authHttp
			.get<any>(this.baseUrl, options)
			.pipe(
				map(response => (<DailyLog[]>response))
			);
	}

	processError(err) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
		 message = err.error.message;
		} else {
		 message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.log(message);
		return throwError(message);
	 }
}
