import { Component, ViewChild, OnInit, AfterViewInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatPaginator as MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
import {merge} from 'rxjs';
import { Router } from '@angular/router';

import { ApplicationSettings } from '../../shared/application-settings';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';

import { CustomerService } from '../customer-service';
import { ICustomerSearch } from '../customer-model/customersearch';
import { ICustomers } from '../customer-model/customers';
import { Subscription } from 'rxjs/Subscription';
import { ActivatedRoute, } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from '../../shared/dialogs/confirmation-dialog.component';
import { ICustomer } from '../Customer-model/Customer';


@Component({
	selector: 'customer-list-component',
	templateUrl: './customer-list.component.html',
	styleUrls: ['./customer-list.component.css']
})

export class CustomerListComponent implements OnInit, AfterViewInit,OnChanges {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	@Input() CompanyIdParam: number;

	displayedColumns: string[] = ['Id','Title', 'FirstName', 'LastName', 'Email', 'PhoneNumber','Delete'];
	isLoadingResults = true;

	public UserSearches: IUsersearch[];
	public pageSize = '10';
	public skip = 0;
	public recordTotal: number = 0;

	public showHideSearch: boolean = false;
	public SearchTextLbl: string = 'Show Filters';
	
	public CompanyId = '';
	public SearchText = '';
	customerForm: FormGroup;

	public pageTitle = 'Customers List';
	public errorMessage = '';
	public dataSource: any;
	public excelFile;
	public progressStatus=false;
	public sub: Subscription;
	public inCompanyId = '';
	customer: ICustomer;
	pageSizeLst = new FormControl();
	constructor(private userSearchService: UsersearchService
		, public applicationSettings: ApplicationSettings
		 , private router: Router
		, private _appService: CustomerService
		, private route: ActivatedRoute
		, public snackBar: MatSnackBar
		, public dialog: MatDialog
		, private fb: FormBuilder
	) {
			this.sub = this.route.params.subscribe(
				params => {
					this.inCompanyId = params['Id'];
					this.CompanyId=this.inCompanyId;
				}
			);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['CompanyIdParam']) {
		  this.CompanyId=changes['CompanyIdParam'].currentValue.toString();
		  this.userSearchService.getUserSearchesByForm(this.pageTitle)
		  .subscribe((data) => {
			  this.UserSearches = data;
			  this.setUserFilters();
	  }
	  , (error: any) => this.errorMessage = < any > error);
		}
	  }

	ngOnInit(): void {
		this.progressStatus = true;

		this.customerForm = this.fb.group({
			Id: '',
			CompanyId: '',
			FirstName: ['',[Validators.maxLength(250)]],
			LastName: ['',[Validators.maxLength(250)]],
			Email: ['',[Validators.maxLength(250)]],
			PhoneNumber: ['',[Validators.maxLength(250)]],
			Title: ['',[Validators.maxLength(100)]],
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.skip = 0;
		let x  = +localStorage.getItem('rows');
		if(x>0) { this.pageSize = x.toString();}
		else { this.pageSize = '10';}
		this.pageSizeLst.setValue(this.pageSize);
		if (localStorage.getItem('showHideSearch') === 'true')
		{
			   this.showHideSearch = true;
			   this.SearchTextLbl = 'Hide Filters';
		}
		else
		{
			   this.showHideSearch = false;
			   this.SearchTextLbl = 'Show Filters';
		 }
	}

	ngAfterViewInit(): void {
		this.userSearchService.getUserSearchesByForm(this.pageTitle)
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
		this.customer = this._appService.initializeCustomer();
	}

	setUserFilters(): void {
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.paginator.pageIndex = 0;

		this.UserSearches.forEach(i => {
			// if(i.Item=='CompanyId')
			// 	this.CompanyId = i.Value;

			if(i.Item=='SearchText')
				this.SearchText = i.Value;

			if(i.Item=='orderByString') {
				if (this.applicationSettings.getOrderByDirection(i.Value)==='asc')
					this.sort.direction = 'asc';
				else
					this.sort.direction = 'desc';

				this.sort.active = this.applicationSettings.getOrderByName(i.Value);
			}

			if (i.Item == 'Skip')
				this.skip = i.Value;

		});

		this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
		merge(this.sort.sortChange, this.paginator.page)
			.subscribe(()=> this.getData());

		this.getData();
	}

	pageSizeValueChanged(rows: string): void {
		this.applicationSettings.setPageSize(rows);
		this.pageSize=rows;
		this.pageSizeLst.setValue(this.pageSize);
		this.paginator.pageIndex = 0;
		this.getData();
	}

	getData(): any {
	 this.progressStatus=true; 
	 this.dataSource = null; 
	 this.recordTotal = 0; 
		let search: ICustomerSearch = this._appService.getFreshSearch();

		search.CompanyId = this.CompanyId;
		search.SearchText = this.SearchText;
		search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
		search.take = parseInt(this.pageSize);
		search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

		this._appService.getRecordSet(search).subscribe((obj: ICustomers) => this.onSelectedRecordSetReceived(obj),
		(error: any) => {
			this.errorMessage = <any> error;
		});
	}

	private onSelectedRecordSetReceived(obj: ICustomers){
		this.dataSource = new MatTableDataSource(obj.data);
		this.recordTotal = obj.total;
		this.progressStatus = false;
	}

	searchClick(): void {
		this.skip = 0;
		this.paginator.pageIndex = 0;
		this.getData();
	}

	public searchClearClick(): void {
		this.SearchText = '';
		this.skip = 0;
		this.sort.direction = 'asc';
		this.sort.active = 'Id';
		this.paginator.pageIndex = 0;
		this.getData();
	}

	resetError() : void {
		this.errorMessage = '';
		this.progressStatus=false;
	}
	showHideSearchClick() {
		 if (this.showHideSearch) 
		 {
			 this.showHideSearch = false; 
			localStorage.setItem('showHideSearch', 'false'); 
			this.SearchTextLbl = 'Show Filters'; 
		}
		else
		{
			 this.showHideSearch = true; 
			localStorage.setItem('showHideSearch', 'true'); 
			  this.SearchTextLbl = 'Hide Filters'; 
		}
	}
	searchAndHideClick() {
		 this.skip = 0;
		 this.paginator.pageIndex = 0;
		this.getData();
		 setTimeout(() => {
		     this.showHideSearchClick();
		 }, 500);
	 }
	public help()
	{
		   const url = this.router.serializeUrl(
		       this.router.createUrlTree([`/help-view/customers`])
		);
		  window.open(url, '_blank');
	 }
	public toExcel(){
			let search: ICustomerSearch = this._appService.getFreshSearch();

			search.CompanyId = this.CompanyId;
			search.SearchText = this.SearchText;
			search.skip = this.paginator.pageIndex * parseInt(this.pageSize);
			search.take = parseInt(this.pageSize);
			search.orderByString = this.sort.direction + '::' + this.sort.active + '::';

			this._appService.toExcel(search).subscribe(
				retVal => {
					    this.excelFile = retVal;
				},
				 (error: any) => {
					 this.errorMessage = < any > error;
				});
	}
	public hideFileLink()
	{
	   this.excelFile = "";
	}
	public openExcelFile()
	{
	   window.open(`${ this.applicationSettings.retrieveUrl()}${ this.excelFile}`);
	}

	editCustomer(id:string, companyid:string): void {
		this.router.navigate(['/customer-details/'+id+'/'+companyid]);
	}

	deleteCustomer(id): void {
		this.progressStatus = true;
			this._appService.deleteCustomer(id)
				.subscribe(() => this.getData()
				, (error: any) => this.errorMessage = <any>error);
	}

	showDialog(type:string,id: number): void {
		this.dialog
		  .open(ConfirmationDialogComponent, {
			data: `Are you sure you want to delete this ${type.toLowerCase()}?`
		  })
		  .afterClosed()
		  .subscribe((confirmed: Boolean) => {
			if (confirmed) {
				if(type=='Customer'){
				this.deleteCustomer(id);
				}
			} else {
			  return;
			}
		  });
	  }

	  SaveCustomer(){
		this.progressStatus = true;

		if (this.customerForm.valid) {
			let obj = Object.assign({ }, this.customer, this.customerForm.value);
			obj.CompanyId=this.inCompanyId;
			obj.Id=0;
			console.log(obj);
			this._appService.saveCustomer(obj)
				.subscribe((data) => {
					this.getData();
					this.customer = this._appService.initializeCustomer();
				}
				, (error: any) => this.errorMessage = < any > error);
		}
	  }

	  clearSearch(){
		this.SearchText='';
		this.searchClick();
	  }
}