import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControlName, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IJournal } from '../journal-model/journal';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';
import { Subscription } from 'rxjs/Subscription';
import { JournalService } from '../journal-service';
import { IJournals } from '../Journal-model/Journals';
import { IJournalSearch } from '../Journal-model/JournalSearch';

import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ApplicationSettings } from '../../shared/application-settings';

@Component({
	templateUrl: './journal-edit.component.html',
	styleUrls: ['journal-edit.component.css']
})

export class JournalEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit Journals';
	errorMessage: string;
	journalForm: FormGroup;
	journal: IJournal;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public JournalSearch: IJournalSearch;
	public ListData: IJournals;
	public showNextRecordButton: boolean = true;
	public progressStatus=false;

	public Editor: any = ClassicEditor;

	constructor(private fb: FormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private journalService: JournalService
		, public settings: ApplicationSettings
		) {
	}

	ngOnInit(): void {
		this.journalForm = this.fb.group({
			Id: '',
			DateNote: '',
			DayNotes: ['',[Validators.maxLength(4000)]],
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getJournal(id);
				this.progressStatus = true;
			}
		);
	}

	public getSelectedRecordSet():void{
		this.journalService.getRecordSet(this.JournalSearch)
			.subscribe((obj: IJournals) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: IJournals){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.journal.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

		this.progressStatus = false;

	}

	saveJournal(returnToList: boolean, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

		if (this.journalForm.valid) {
			const obj = Object.assign({ }, this.journal, this.journalForm.value);
			this.journalService.saveJournal(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord,addNewRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		if (returnToList) {
			this.journalForm.reset();
			this.router.navigate(['/journals']);
		} 
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
		else if (addNewRecord)
		{
			this.router.navigate(['/journal-details', 0]);
			this.journalForm.reset();
			let obj = this.journalService.initializeJournal();
			this.onJournalRetrieved(obj);
		}
			// when the record id is zero then it is a new record.
		else if (data !== null && this.journal.Id === 0) {
			this.router.navigate(['/journal-details', data.Id]);
		} 
		else if (this.journal.Id != 0){
			this.getJournal(this.journal.Id);
		}

		this.buttonPushed = false;
		this.progressStatus = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.journal.Id)
				record = count;
		});

		this.getJournal(this.ListData.data[record].Id)
	}

	getJournal(id: number): void {
		this.journalService.getJournal(id)
			.subscribe((obj: IJournal) => this.onJournalRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onJournalRetrieved(journal: IJournal): void {
		if (this.journalForm) {
			this.journalForm.reset();
		}
		this.journal = journal;
		if (this.journal.Id === 0) {
			this.page = 'Add Journals';
		} else {
			this.page = 'Edit Journals';
		}

		this.journalForm.patchValue({
			Id: this.journal.Id,
			DateNote: this.journal.DateNote,
			DayNotes: this.journal.DayNotes,
			CreatedDate: this.journal.CreatedDate,
			CreatedBy: this.journal.CreatedBy,
			UpdatedDate: this.journal.UpdatedDate,
			UpdatedBy: this.journal.UpdatedBy,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteJournal(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.journal.DateNote}?`)) {
			this.journalService.deleteJournal(this.journal.Id)
				.subscribe(() => this.onSaveComplete(true, null, false,false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/journals']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Journals List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.JournalSearch = this.journalService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'DateNote')
				this.JournalSearch.DateNote = i.Value;

			if (i.Item == 'orderByString')
				this.JournalSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.JournalSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
}
