
<div class='panel panel-primary animaPlaceholder' *ngIf = 'question'>
	<div class='panel-heading'>
		<span *ngIf = 'question' >{{ page }} :{{ question.Id }}</span>
		<span class= 'pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class= 'panel-body'>
		<div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<form class='form-horizontal' novalidate [formGroup]='questionForm'>
			<fieldset>
			<div class='panel-body'>
				<div class='form-group'>
					<label class='col-md-2 control-label'> Lesson</label>
					<div class='col-md-10'>
						<mat-select class="form-control"formControlName='LessonId'>
							@for (item of lessonsList; track item) {
							  <mat-option [value]="item.Id">{{item.Name}}</mat-option>
							}
						</mat-select>
					</div>
				</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Name</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='Name' />
							<span *ngIf = "questionForm.controls['Name'].invalid  && questionForm.controls['Name'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Description</label>
						<div class='col-md-10'>
							<ckeditor formControlName="Description" [editor]="Editor"
							[config]="{ ckfinder: { uploadUrl: this.settings.retrieveUrl() + 'api/Questions/uploadimage' } }" data=""></ckeditor>
						</div>
					</div>
			</div>
			</fieldset>
		</form>

			<div class='row' style='padding: 15px;'>
				<div class= 'col-md-12'>
					<span>
						<button class='btn btn-primary btn-edit-sml'
							[disabled]='buttonPushed'
								(click)='saveQuestion(false, false,false)'
									style='width: 110px; margin-right:10px'
									[disabled]='!questionForm.valid || progressStatus||progressLessonsStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
								</button>
								<button class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveQuestion(true, false,false)'
									style='width: 150px; margin-right:10px'
									[disabled]='!questionForm.valid || progressStatus||progressLessonsStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
								</button>
								<button *ngIf='showNextRecordButton && question.Id ' class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveQuestion(false, true,false)'
									style='width: 190px; margin-right:10px'
									[disabled]='!questionForm.valid || progressStatus||progressLessonsStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
								</button>
								<button  class='btn btn-primary btn-edit-sml' *ngIf="!question.Id"  
									  [disabled]='buttonPushed'
									  (click)='saveQuestion(false, false,true)'
									  style ='width: 200px; margin-right:10px'
									  [disabled]='!questionForm.valid || progressStatus||progressLessonsStatus'>
									   <span class='glyphicon glyphicon-floppy-save'></span> Save & Add New Record &nbsp;
								  </button>
							</span>

						<span  class='pull-right remove-float-sml'>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='cancel()'
								style='width: 150px; margin-right:10px'
								[disabled]='!questionForm.valid || progressStatus||progressLessonsStatus'>
								Cancel &nbsp;
							</button>
							<button class='btn btn-primary btn-edit-sml' *ngIf="question.Id" 
								[disabled]='buttonPushed'
								(click)='deleteQuestion()'
								style='width: 150px; margin-right:10px'
								[disabled]='!questionForm.valid || progressStatus||progressLessonsStatus'>
								<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
							</button>
						</span>
					</div>
				</div>

				<div class='row'>
					<div class='col-md-12'>
						<span> Updated By: <label *ngIf='question'>{{question.UpdatedBy}}</label></span>
						<span> Time: <label *ngIf='question'>{{question.UpdatedDate}}</label></span>
					</div>
				</div>

				<div class='row'>
					<div class='col-md-12'>
				<div *ngIf="question">
					<div *ngIf="question.Id>0">
						<answer-list coursesId={{courseId}}  lessonsId={{lessonId}} questionsId={{question.Id}}></answer-list>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div>
<mat-spinner color="primary" class="spinner" *ngIf="progressStatus||progressLessonsStatus"></mat-spinner>
</div>