
<div class='panel panel-primary animaPlaceholder' *ngIf = 'employeequestion'>
	<div class='panel-heading'>
		<span *ngIf = 'employeequestion' >{{ page }} :{{ employeequestion.Id }}</span>
		<span class= 'pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class= 'panel-body'>
		<div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<form class='form-horizontal' novalidate [formGroup]='employeequestionForm'>
			<fieldset>
			<div class='panel-body'>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Lesson Name</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='LessonName' />
							<span *ngIf = "employeequestionForm.controls['LessonName'].invalid  && employeequestionForm.controls['LessonName'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Courses Id</label>
						<div class='col-md-10'>
							<input class='form-control' type='number' formControlName='CoursesId' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Lesson Id</label>
						<div class='col-md-10'>
							<input class='form-control' type='number' formControlName='LessonId' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Content Name</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='ContentName' />
							<span *ngIf = "employeequestionForm.controls['ContentName'].invalid  && employeequestionForm.controls['ContentName'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Content Description</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='ContentDescription' />
							<span *ngIf = "employeequestionForm.controls['ContentDescription'].invalid  && employeequestionForm.controls['ContentDescription'].errors.maxlength" class="text-danger">
									Only 0 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Content Reviewed</label>
						<div class='col-md-10'>
							<input class='form-control' type='date' formControlName='ContentReviewed' />
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Content Status</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='ContentStatus' />
							<span *ngIf = "employeequestionForm.controls['ContentStatus'].invalid  && employeequestionForm.controls['ContentStatus'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Content Notes</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='ContentNotes' />
							<span *ngIf = "employeequestionForm.controls['ContentNotes'].invalid  && employeequestionForm.controls['ContentNotes'].errors.maxlength" class="text-danger">
									Only 0 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Lesson User</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='LessonUser' />
							<span *ngIf = "employeequestionForm.controls['LessonUser'].invalid  && employeequestionForm.controls['LessonUser'].errors.maxlength" class="text-danger">
									Only 256 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Employee</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='Employee' />
							<span *ngIf = "employeequestionForm.controls['Employee'].invalid  && employeequestionForm.controls['Employee'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Test Id</label>
						<div class='col-md-10'>
							<input class='form-control' type='number' formControlName='TestId' />
						</div>
					</div>
			</div>
			</fieldset>
		</form>

			<div class='row' style='padding: 15px;'>
				<div class= 'col-md-12'>
					<span>
						<button class='btn btn-primary btn-edit-sml'
							[disabled]='buttonPushed'
								(click)='saveEmployeequestion(false, false,false)'
									style='width: 110px; margin-right:10px'
									[disabled]='!employeequestionForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
								</button>
								<button class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveEmployeequestion(true, false,false)'
									style='width: 150px; margin-right:10px'
									[disabled]='!employeequestionForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
								</button>
								<button *ngIf='showNextRecordButton && employeequestion.Id ' class='btn btn-primary btn-edit-sml'
									[disabled]='buttonPushed'
									(click)='saveEmployeequestion(false, true,false)'
									style='width: 190px; margin-right:10px'
									[disabled]='!employeequestionForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
								</button>
								<button  class='btn btn-primary btn-edit-sml' *ngIf="!employeequestion.Id"  
									  [disabled]='buttonPushed'
									  (click)='saveEmployeequestion(false, false,true)'
									  style ='width: 200px; margin-right:10px'
									  [disabled]='!employeequestionForm.valid || progressStatus'>
									   <span class='glyphicon glyphicon-floppy-save'></span> Save & Add New Record &nbsp;
								  </button>
							</span>

						<span  class='pull-right remove-float-sml'>
							<button class='btn btn-primary btn-edit-sml'
								[disabled]='buttonPushed'
								(click)='cancel()'
								style='width: 150px; margin-right:10px'
								[disabled]='!employeequestionForm.valid || progressStatus'>
								Cancel &nbsp;
							</button>
							<button class='btn btn-primary btn-edit-sml' *ngIf="employeequestion.Id" 
								[disabled]='buttonPushed'
								(click)='deleteEmployeequestion()'
								style='width: 150px; margin-right:10px'
								[disabled]='!employeequestionForm.valid || progressStatus'>
								<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
							</button>
						</span>
					</div>
				</div>

				<div class='row'>
					<div class='col-md-12'>
						<span> Updated By: <label *ngIf='employeequestion'>{{employeequestion.UpdatedBy}}</label></span>
						<span> Time: <label *ngIf='employeequestion'>{{employeequestion.UpdatedDate}}</label></span>
					</div>
				</div>
	</div>
</div>
<div>
<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>