import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

//import { WorklogListComponent } from './worklog-list/worklog-list.component';
//import { WorklogGridService } from './worklog-list/worklog-list.service';
import { WorklogEditComponent } from './worklog-edit/worklog-edit.component';
import { WorklogService } from './worklog-service';
//import { WorklogFilesComponent } from './worklog-edit/worklog-files/worklog-files.component';
//import { WorklogFileEditComponent } from './worklog-edit/worklog-files/worklog-file-edit/worklog-file-edit.component';

@NgModule({
	declarations: [
		//WorklogListComponent,
		 WorklogEditComponent
		//, WorklogFilesComponent
		//, WorklogFileEditComponent
	],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		RouterModule.forChild([
			//{ path: 'worklogs', component: WorklogListComponent },
			{ path: 'worklog-details/:Id/:Id2', component: WorklogEditComponent },
		])
	],
	exports:[
		//WorklogListComponent
	],
	providers: [
		//WorklogGridService
	 WorklogService
	]
})
export class WorklogModule { }
