import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApplicationSettings } from '../shared/application-settings';

import { Observable, throwError, of } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { IEmployeelesson} from './Employeelessons/Employeelesson-model/Employeelesson';
import { IEmployeelessonSearch } from './Employeelessons/Employeelesson-model/EmployeelessonSearch';

import { IEmployeequestions } from './Employeequestions/Employeequestion-model/Employeequestions';
import { IEmployeequestionSearch } from './Employeequestions/Employeequestion-model/EmployeequestionSearch';

@Injectable()
export class TrainingManagementService {
	private trainingUrl;

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings) {
		this.trainingUrl = this.settings.retrieveUrl() + '/api/trainingmanagement';
	}

	startLesson(search: IEmployeelessonSearch): Observable <any> {
		const url = `${this.trainingUrl}/startlesson?LessonId=${search.LessonId}`;
		return this.authHttp.get<IEmployeelesson>(url)
		.pipe(
			retry(1),
			catchError(this.processError));
	}

	completeLesson(search: IEmployeelessonSearch): Observable <any> {
		const url = `${this.trainingUrl}/completelesson?LessonId=${search.LessonId}`;
		return this.authHttp.get<IEmployeelesson>(url)
		.pipe(
			retry(1),
			catchError(this.processError));
	}

	startQuiz(search: IEmployeequestionSearch): Observable <any> {
		const url = `${this.trainingUrl}/startquiz?LessonId=${search.LessonId}`;
		return this.authHttp.get<IEmployeequestions>(url)
		.pipe(
			retry(1),
			catchError(this.processError));
	}

	completeQuiz(search: IEmployeequestionSearch): Observable <any> {
		const url = `${this.trainingUrl}/completequiz?LessonId=${search.LessonId}`;
		return this.authHttp.get<IEmployeequestions>(url)
		.pipe(
			retry(1),
			catchError(this.processError));
	}

	saveAnswer(value: string, id: number, testid: number, result: string): Observable <any> {
		const url = `${this.trainingUrl}/saveanswer?value=${value}&id=${id}&testid=${testid}&result=${result}`;
		return this.authHttp.get<any>(url)
		.pipe(
			retry(1),
			catchError(this.processError));
	}

	processError(err) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
		 message = err.error.message;
		} else {
		 message = `Error Code: ${err.status}\nMessage: ${err.message}`;
		}
		console.log(message);
		return throwError(message);
	 }
}