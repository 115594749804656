import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'

import { JobListComponent } from './job-list/job-list.component';
import { JobEditComponent } from './job-edit/job-edit.component';
import { JobService } from './job-service';
import { MatProgressSpinnerModule }  from '@angular/material/progress-spinner';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
	declarations: [
		JobListComponent
		, JobEditComponent
	],
	imports: [
		MatProgressSpinnerModule,
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		NgSelectModule,
		RouterModule.forChild([
			{ path: 'jobs', component: JobListComponent },
			{ path: 'job-details/:Id', component: JobEditComponent }
		])
	],
	providers: [
		JobService
	]
})
export class JobModule { }
