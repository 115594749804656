import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControlName, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IEmployeequestion } from '../employeequestion-model/employeequestion';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';
import { Subscription } from 'rxjs/Subscription';
import { EmployeequestionService } from '../employeequestion-service';
import { IEmployeequestions } from '../Employeequestion-model/Employeequestions';
import { IEmployeequestionSearch } from '../Employeequestion-model/EmployeequestionSearch';

import { UsersearchService } from '../../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../../admin/usersearches/usersearch-model/usersearch';

@Component({
	templateUrl: './employeequestion-edit.component.html',
	styleUrls: ['employeequestion-edit.component.css']
})

export class EmployeequestionEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit EmployeeQuestions';
	errorMessage: string;
	employeequestionForm: FormGroup;
	employeequestion: IEmployeequestion;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public EmployeequestionSearch: IEmployeequestionSearch;
	public ListData: IEmployeequestions;
	public showNextRecordButton: boolean = true;
	public progressStatus=false;

	constructor(private fb: FormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private employeequestionService: EmployeequestionService,
		) {
	}

	ngOnInit(): void {
		this.employeequestionForm = this.fb.group({
			Id: '',
			LessonName: ['',[Validators.maxLength(250)]],
			CoursesId: '',
			LessonId: '',
			ContentName: ['',[Validators.maxLength(250)]],
			ContentDescription: ['',[Validators.maxLength(0)]],
			ContentReviewed: '',
			ContentStatus: ['',[Validators.maxLength(250)]],
			ContentNotes: ['',[Validators.maxLength(0)]],
			LessonUser: ['',[Validators.maxLength(256)]],
			Employee: ['',[Validators.maxLength(250)]],
			TestId: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getEmployeequestion(id);
				this.progressStatus = true;
			}
		);
	}

	public getSelectedRecordSet():void{
		this.employeequestionService.getRecordSet(this.EmployeequestionSearch)
			.subscribe((obj: IEmployeequestions) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: IEmployeequestions){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.employeequestion.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

		this.progressStatus = false;

	}

	saveEmployeequestion(returnToList: boolean, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

		if (this.employeequestionForm.valid) {
			const obj = Object.assign({ }, this.employeequestion, this.employeequestionForm.value);
			this.employeequestionService.saveEmployeequestion(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord,addNewRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		if (returnToList) {
			this.employeequestionForm.reset();
			this.router.navigate(['/training/employeequestions']);
		} 
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
		else if (addNewRecord)
		{
			this.router.navigate(['/employeequestion-details', 0]);
			this.employeequestionForm.reset();
			let obj = this.employeequestionService.initializeEmployeequestion();
			this.onEmployeequestionRetrieved(obj);
		}
			// when the record id is zero then it is a new record.
		else if (data !== null && this.employeequestion.Id === 0) {
			this.router.navigate(['/training/employeequestion-details', data.Id]);
		} 
		else if (this.employeequestion.Id != 0){
			this.getEmployeequestion(this.employeequestion.Id);
		}

		this.buttonPushed = false;
		this.progressStatus = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.employeequestion.Id)
				record = count;
		});

		this.getEmployeequestion(this.ListData.data[record].Id)
	}

	getEmployeequestion(id: number): void {
		this.employeequestionService.getEmployeequestion(id)
			.subscribe((obj: IEmployeequestion) => this.onEmployeequestionRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onEmployeequestionRetrieved(employeequestion: IEmployeequestion): void {
		if (this.employeequestionForm) {
			this.employeequestionForm.reset();
		}
		this.employeequestion = employeequestion;
		if (this.employeequestion.Id === 0) {
			this.page = 'Add EmployeeQuestions';
		} else {
			this.page = 'Edit EmployeeQuestions';
		}

		this.employeequestionForm.patchValue({
			Id: this.employeequestion.Id,
			LessonName: this.employeequestion.LessonName,
			CoursesId: this.employeequestion.CoursesId,
			LessonId: this.employeequestion.LessonId,
			ContentName: this.employeequestion.ContentName,
			ContentDescription: this.employeequestion.ContentDescription,
			ContentReviewed: this.employeequestion.ContentReviewed,
			ContentStatus: this.employeequestion.ContentStatus,
			ContentNotes: this.employeequestion.ContentNotes,
			LessonUser: this.employeequestion.LessonUser,
			Employee: this.employeequestion.Employee,
			TestId: this.employeequestion.TestId,
			CreatedDate: this.employeequestion.CreatedDate,
			CreatedBy: this.employeequestion.CreatedBy,
			UpdatedDate: this.employeequestion.UpdatedDate,
			UpdatedBy: this.employeequestion.UpdatedBy,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteEmployeequestion(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.employeequestion.LessonName}?`)) {
			this.employeequestionService.deleteEmployeequestion(this.employeequestion.Id)
				.subscribe(() => this.onSaveComplete(true, null, false,false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/training/employeequestions']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('EmployeeQuestions List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.EmployeequestionSearch = this.employeequestionService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'LessonName')
				this.EmployeequestionSearch.LessonName = i.Value;

			if (i.Item == 'CoursesId')
				this.EmployeequestionSearch.CoursesId = i.Value;

			if (i.Item == 'LessonId')
				this.EmployeequestionSearch.LessonId = i.Value;

			if (i.Item == 'ContentName')
				this.EmployeequestionSearch.ContentName = i.Value;

			if (i.Item == 'ContentDescription')
				this.EmployeequestionSearch.ContentDescription = i.Value;

			if (i.Item == 'ContentReviewed')
				this.EmployeequestionSearch.ContentReviewed = i.Value;

			if (i.Item == 'ContentStatus')
				this.EmployeequestionSearch.ContentStatus = i.Value;

			if (i.Item == 'ContentNotes')
				this.EmployeequestionSearch.ContentNotes = i.Value;

			if (i.Item == 'LessonUser')
				this.EmployeequestionSearch.LessonUser = i.Value;

			if (i.Item == 'Employee')
				this.EmployeequestionSearch.Employee = i.Value;

			if (i.Item == 'TestId')
				this.EmployeequestionSearch.TestId = i.Value;

			if (i.Item == 'orderByString')
				this.EmployeequestionSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.EmployeequestionSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
}
