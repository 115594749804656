import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module';
import { MaterialListComponent } from './material-list/material-list.component';
import { MaterialEditComponent } from './material-edit/material-edit.component';
import { MaterialHelpComponent } from './material-help/material-help.component';
import { MaterialService } from './material-service';
import { MatProgressSpinnerModule }  from '@angular/material/progress-spinner';
import { UploaderAllModule } from '@syncfusion/ej2-angular-inputs';
import { DialogsModule } from '../shared/dialogs/dialogs.module';

@NgModule({
	declarations: [
		MaterialListComponent
		, MaterialEditComponent
		, MaterialHelpComponent
	],
	imports: [
		MatProgressSpinnerModule,
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		UploaderAllModule,
		DialogsModule,
		RouterModule.forChild([
			{ path: 'materials', component: MaterialListComponent },
			{ path: 'material-details/:Id', component: MaterialEditComponent },
			{ path: 'material-help', component: MaterialHelpComponent },
		]),
	],
	providers: [
		MaterialService
	]
})
export class MaterialflexModule { }
