import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Player from '@vimeo/player';
import { Subscription } from 'rxjs';
import { IVideo } from '../video-model/video';
import { VideoService } from '../video-service';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { IVideoSearch } from '../Video-model/VideoSearch';
import { IVideos } from '../Video-model/Videos';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';


@Component({
	selector: 'app-training-video-edit',
	templateUrl: './training-video-edit.component.html',
	styleUrls: ['./training-video-edit.component.css']
})

export class TrainingVideoEditComponent implements OnInit, OnDestroy {
	public page = 'Video Viewer';
	private sub: Subscription;

	player: any;
	errorMessage: string;
	video: IVideo;
	videoPlayed = false;
	public progressStatus=false;


	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public VideoSearch: IVideoSearch;
	public ListData: IVideos;
	public showNextRecordButton: boolean = true;


	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private videoService: VideoService
		, private userSearchService: UsersearchService) { }

	ngOnInit(): void {
		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getVideo(id);
				this.progressStatus = true;
			}
		);
	}

	ngOnDestroy() {
		this.sub.unsubscribe();
	}

	createVideoLog(): void {
		this.videoService.createVideoLog(this.video.Id)
			.subscribe(_ => {});
	}

	getVideo(id: number): void {
		this.videoService.getVideo(id)
			.subscribe(video => {
				this.video = video;
				this.setupPlayer(video.VimeoId);
				this.progressStatus=false;
				this.getUserSearchData();
			}, error => this.errorMessage = <any>error);
	}

	setupPlayer(vimeoId) {
		const options = {
			id: vimeoId,
			//width: 640,
			loop: true
		};

		
		setTimeout(() => {
			this.player = new Player('vimeo-viewer-admin', options);
			this.player.on('play', () => {
				if (!this.videoPlayed) {
					this.videoPlayed = true;
					this.createVideoLog();
				}
			});
		}, 1000);

		
	}

	cancel(): void {
		this.router.navigate(['/training-videos']);
	}
	public getSelectedRecordSet():void{
		this.videoService.getRecordSet(this.VideoSearch,'Training Videos List')
			.subscribe((obj: IVideos) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: IVideos){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.video.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

		this.progressStatus = false;

	}

advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.video.Id)
				record = count;
		});

		this.getVideo(this.ListData.data[record].Id)
	}




public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Training Videos List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.VideoSearch = this.videoService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'Title')
				this.VideoSearch.Title = i.Value;

			if (i.Item == 'Category')
				this.VideoSearch.Category = i.Value;

			if (i.Item == 'FileName')
				this.VideoSearch.FileName = i.Value;

			if (i.Item == 'Notes')
				this.VideoSearch.Notes = i.Value;

			if (i.Item == 'VimeoId')
				this.VideoSearch.VimeoId = i.Value;

			if (i.Item == 'orderByString')
				this.VideoSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.VideoSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}
	
	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
}
