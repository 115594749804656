import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { WorktaskListComponent } from './worktask-list/worktask-list.component';
import { WorktaskEditComponent } from './worktask-edit/worktask-edit.component';
import { WorktaskService } from './worktask-service';
//import { WorktaskFilesComponent } from './worktask-edit/worktask-files/worktask-files.component';
//import { WorktaskFileEditComponent } from './worktask-edit/worktask-files/worktask-file-edit/worktask-file-edit.component';
import { WorklogModule } from '../worklogs/worklog-module';
import { MaterialModule } from '../material-module';
import { WorktasklogsreportComponent } from './worktasklogsreport/worktasklogsreport.component';
import { MatProgressSpinnerModule as MatProgressSpinnerModule }  from '@angular/material/progress-spinner';

@NgModule({
	declarations: [
		WorktaskListComponent
		, WorktaskEditComponent
		//, WorktaskFilesComponent
		//, WorktaskFileEditComponent
		, WorktasklogsreportComponent
	],
	imports: [
		MatProgressSpinnerModule,
		MaterialModule,
		WorklogModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		RouterModule.forChild([
			{ path: 'worktasks', component: WorktaskListComponent },
			{ path: 'worktask-details/:Id', component: WorktaskEditComponent },
			{ path: 'worktasklogsreport', component: WorktasklogsreportComponent },
		])
	],
	providers: [
		WorktaskService
	]
})
export class WorktaskModule { }
