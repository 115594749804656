<section class="footer text-center">
    <div class="container">
      <div class="row">
      <div class="col-md-12 col-12">
        <div class="ftlogo">
        <img src="../assets//footer-logo.png">
        </div>
        <nav>
        <ul class="nav ft-nav">
            <li>
                <a [routerLink]="['welcome']">Home</a>
              </li>
              <li>
                <a [routerLink]="['services']">Services</a>
              </li>
              <li>
                <a [routerLink]="['about-us']">About Us</a>
              </li>
              <li>
                <a [routerLink]="['platforms']">Platforms</a>
              </li>
              <li>
                <a [routerLink]="['fanuc']">FANUC</a>
              </li>
              <li>
                <a [routerLink]="['contact']">Contact</a>
              </li>
        </ul>
        
        </nav>
        <div class="cprite">
        <p>Copyright 2018 - Edmonton Integration and Robotics</p>
        <a class="btn btn-primary" style="background-color: #3b5998;margin: 0.25rem !important; width: 50px;" [href]="facebookUrl" target="_blank" role="button"
        ><i class="fa fa-facebook fa-2x"></i
      ></a>

      <a class="btn btn-primary" style="background-color: #0082ca; margin: 0.25rem !important; width: 50px" [href]="linkedinUrl" target="_blank"  role="button"
      ><i class="fa fa-linkedin fa-2x"></i
    ></a>
        </div>

     
      </div>
    
      </div>
    </div>
    
    </section>