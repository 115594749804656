import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VideoService } from '../video-service';
import { ElementRef, ViewChild } from '@angular/core';

/**
 * Component responsible for uploading the videos to Vimeo.com
 */
@Component({
	selector: 'app-vimeo',
	templateUrl: './vimeo.component.html',
	styleUrls: ['./vimeo.component.css']
})
export class VimeoComponent {

	fileToUpload: File | null = null;

	public progressStatus = false;

	/** Event emmitter to signal the parent component that the upload has finished */
	@Output() uploadFinished: EventEmitter<number> = new EventEmitter();

	/** Video filename */
	@Input() fileName: string;

	/** Video description */
	@Input() description: string;

	/** Alert message display state */
	uploadInProgress = false;
	@ViewChild('inputFile') fileUp: ElementRef;

	constructor(private http: HttpClient
		, private videoService: VideoService) { }

	onFileSelected(target: EventTarget): void {
		console.log('onFileSelected');
		console.log((target as HTMLInputElement).files);
		const files = (target as HTMLInputElement).files;
		if (files.length > 0) {
			this.fileToUpload = files.item(0);
			this.progressStatus = true;
			const formData = new FormData();

			formData.append('File', this.fileToUpload);
			formData.append('FileName', this.fileName);
			formData.append('Description', this.description);

			console.log(formData);
			this.videoService.vimeoUpload(formData).subscribe(obj => {

				console.log(obj);
				if (obj.status == true) {
					this.uploadFinished.emit(parseInt(obj.videoid));
				}
				else {
					alert(obj.message);
				}
				this.fileUp.nativeElement.value = '';
				this.fileToUpload = null;
				this.progressStatus = false;
			}, error => {
				console.error('Error:', error);
				// Handle any errors that occur
			});

		}
	}

}
