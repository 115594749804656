<div class='card animaPlaceholder' *ngIf='task'>
	<div class='card-heading fixed-bar'>
		<div class='row'>
			<div [class]="(task.Id==0 || !showNextRecordButton?'col-lg-4 ':'col-lg-2 ')+'col-md-11 col-xs-12'">
				<span *ngIf='task'>{{ page }} :{{ task.Id }}</span>
			</div>
			<div class='col-lg-2 col-md-3 col-xs-6 options-bar'>
				<button class='btn btn-default btn-sm center-block btn-full' mat-fab extended color="primary" [disabled]='buttonPushed'
				(click)='saveTask(false, false,false)'
				[disabled]='!taskForm.valid || progressStatus'>
				<mat-icon>save</mat-icon>Save
				</button>
			</div>
			<div class='col-lg-2 col-md-3 col-xs-6 options-bar'>
				<button class='btn btn-default btn-sm center-block btn-full' mat-fab extended color="primary" [disabled]='buttonPushed'
				(click)='saveTask(true, false,false)'
				[disabled]='!taskForm.valid || progressStatus'>
				<mat-icon>replay</mat-icon>Save & Return
				</button>
			</div>
			<div *ngIf='showNextRecordButton && task.Id' class='col-lg-2 col-md-3 col-xs-6 options-bar'>
				<button class='btn btn-default btn-sm center-block btn-full' mat-fab extended color="primary" 
				[disabled]='buttonPushed'
				(click)='saveTask(false, true,false)'
				[disabled]='!taskForm.valid || progressStatus'>
				<mat-icon>forward</mat-icon>Save & Next
				</button>	
			</div>
			<div *ngIf="!task.Id" class='col-lg-2 col-md-3 col-xs-6 options-bar'>
				<button  class='btn btn-default btn-sm center-block btn-full' mat-fab extended color="primary" [disabled]='buttonPushed'
				(click)='saveTask(false, false,true)'
				[disabled]='!taskForm.valid || progressStatus'>
				<mat-icon>forward</mat-icon>Save & Add New
				</button>	
			</div>
			<div *ngIf='task.Id!=0' class='col-lg-2 col-md-3 col-xs-6 options-bar'>
				<button class='btn btn-default btn-sm center-block btn-full' mat-fab extended color="primary" [disabled]='buttonPushed' (click)="showDialog('Task',0)"
				 [disabled]='!taskForm.valid || progressStatus'>
				 <mat-icon>delete_forever</mat-icon>Delete
			</button>
		</div>
		<div class='col-lg-2 col-md-3 col-xs-6 options-bar'>
			<button class='btn btn-default btn-sm center-block btn-full' mat-fab extended color="primary" [disabled]='buttonPushed' (click)='cancel()'
			 [disabled]='!taskForm.valid || progressStatus'>
			 <mat-icon>cancel</mat-icon>Cancel
		</button>
	</div>
	</div>
</div>

	<div class='card animaPlaceholder' *ngIf='task'>

	<div class='card-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<mat-tab-group>
			<mat-tab style="color: #101bb3;" label = "Task">
		<div class="row">
			<div class="col-md-12">
				<form class='form-horizontal' novalidate [formGroup]='taskForm'>
					<fieldset>
						<div class='card-body'>
							<div class="row">
							<div class='form-group col-md-6 form-group-item'>
								<label class='col-md-4 control-label'> Task Name</label>
								<div class='col-md-12'>
									<input class='form-control' type='text' formControlName='TaskName' />
									<span
										*ngIf="taskForm.controls['TaskName'].invalid  && taskForm.controls['TaskName'].errors.maxlength"
										class="text-danger">
										Only 250 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group col-md-6 form-group-item'>
								<label class='col-md-2 control-label'> Equipment</label>
								<div class='col-md-12'>
									<ng-select
										formControlName='EquipmentId'
										(change)='GetAllMappingsByEquipmentId($event)'
										placeholder="Select Equipment...">
										<ng-option *ngFor="let equipment of equipments" [value]="equipment.Id">
											{{equipment.Name}}
										</ng-option>
									</ng-select>
									<span *ngIf = "taskForm.controls['EquipmentId'].invalid  && taskForm.controls['EquipmentId'].errors.min" class="text-danger">
											Must select a material.
									</span>
								</div>
							</div>
							
							<div class='form-group col-md-12 form-group-item'>
								<label class='col-md-4 control-label'> Description</label>
								<div class='col-md-12'>
									<input class='form-control' type='text' formControlName='Description' />
									<span
										*ngIf="taskForm.controls['Description'].invalid  && taskForm.controls['Description'].errors.maxlength"
										class="text-danger">
										Only 1000 characters are allowed.
									</span>
								</div>
							</div>
					
						</div>
					</div>
					</fieldset>
				</form>
			</div>
		</div>


	</mat-tab>
	<mat-tab label="Actions">

		 <div *ngIf="taskId" class='card-body'>
			<form class='form-horizontal' novalidate [formGroup]='TaskActionForm'>
				<div class='row'>
					<div class='col-lg-7 col-md-12 col-xs-12 options-bar'>
						Actions details
					</div>
		
					<div class='col-lg-3 col-md-6 col-xs-6 options-bar'>
					<label class='col-md-6 control-label'> ActionType</label>
					<div class='col-md-10 d-flex'>		
						<mat-select class="form-control" [formControlName]="'ActionType'" (selectionChange)='GetMappingList($event.source.value)'>
							<mat-option value="1">Write OUTPUT Variable</mat-option>
							<mat-option value="2">Read INPUT Variable</mat-option>
						</mat-select>
					</div>
					</div>

					<div class='col-lg-5 col-md-4 col-xs-4 options-bar'>
						<label class='col-md-6 control-label'> PLC Value</label>
						<div class='col-md-10'>
							<mat-select class="form-control" (selectionChange)='SetActionMapping($event.source.value)'>
								@for (item of MachineMappingsPLC; track item.Id) {
								  <mat-option [value]="item">{{item.ModBusArrayDescription+' ('+item.ArrayNumberPLC+'.'+item.VariableBinaryPosition+') '+item.VariableDescription}}</mat-option>
								}
							</mat-select>
						</div>
					</div>

					<div [hidden]="hiddenInputs" class='col-lg-2 col-md-4 col-xs-4 options-bar'>
						<label class='col-md-6 control-label'> Delay(seconds)</label>
						<div class='col-md-10'>
						<input (change)="fillActionDescription()" class='form-control' type='number' formControlName='Delay_in_seconds' />
						</div>
					</div>
					
					<div [hidden]="hiddenInputs" class='col-lg-2 col-md-4 col-xs-4 options-bar'>
						<label class='col-md-6 control-label'> Set Status</label>
						<div class='col-md-10'>
							<mat-checkbox (change)="fillActionDescription()" formControlName='SetValue' color='primary'>On/Off</mat-checkbox>
						</div>
					</div>

					<div class='col-lg-10 col-md-4 col-xs-4 options-bar'>
						<label class='col-md-6 control-label'> Action Description</label>
						<div class='col-md-10'>
						<input class='form-control' type='text' formControlName='ActionDescription' />
						</div>
					</div>


					<div class='col-lg-2 col-md-6 col-xs-4 options-bar' style="padding-top: 30px;">
						<button class='btn btn-primary btn-sm center-block' style="width:100%;"  
						[disabled]='buttonPushed'(click)="SaveTaskAction()"
						 [disabled]='progressStatus'>Add</button>
					</div>
				</div>
			</form>
<br>

<div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
  @for (action of actionList; track action) {
    <div class="example-box" cdkDrag>
      <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
	  <button (click)="UpdateTaskAction(action)" mat-mini-fab color="default">
		<mat-icon>edit</mat-icon>
	 </button>
	 <label class='col-md-10'>{{action.SequenceNumber +'- ' +action.ActionDescription}}</label>
     
	  <button (click)="showDialog('Action',action.Id)" mat-mini-fab color="warn">
		<mat-icon>delete</mat-icon>
	</button>
    </div>
  }
</div>
<div class='col-lg-2 col-md-6 col-xs-4 options-bar' style="padding-top: 30px;">
	<button class='btn btn-primary btn-sm center-block' style="width:100%;"  
	[disabled]='buttonPushed'(click)="UpdateActionsOrder()"
	 [disabled]='progressStatus'>Update Order</button>
</div>
</div>
	 </mat-tab>
	 </mat-tab-group>
	

		<div class='row'>
			<div class='col-md-12'>
				<span> Updated By: <label *ngIf='task'>{{task.UpdatedBy}}</label></span>
				<span> Time: <label *ngIf='task'>{{task.UpdatedDate}}</label></span>
			</div>
		</div>
	</div>
</div>
<div>
	<app-loading *ngIf="progressStatus"></app-loading> 
</div>