import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControlName, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IEquipment } from '../equipment-model/equipment';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';
import { Subscription } from 'rxjs/Subscription';
import { EquipmentService } from '../equipment-service';
import { IEquipments } from '../equipment-model/equipments';
import { IEquipmentSearch } from '../equipment-model/equipmentsearch';

import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { BrancheService } from '../../admin/branches/branche-service';
import { ApplicationSettings } from '../../shared/application-settings';

@Component({
	templateUrl: './equipment-edit.component.html',
	styleUrls: ['equipment-edit.component.css']
})

export class EquipmentEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit Equipments';
	errorMessage: string;
	equipmentForm: UntypedFormGroup;
	equipment: IEquipment;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public EquipmentSearch: IEquipmentSearch;
	public ListData: IEquipments;
	public showNextRecordButton: boolean = true;
	public branches;
	urldata:any;
	Color = '';

	constructor(private fb: UntypedFormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private equipmentService: EquipmentService
		, public branchService: BrancheService
		, public applicationSettings: ApplicationSettings
	) {
	}

	ngOnInit(): void {
		this.equipmentForm = this.fb.group({
			Id: '',
			Name: '',
			NickName:'',
			WorkCenterId:'',
			Color:'',
			PrinterType: '',
			IP: '',
			BranchId: '',
			ZPL: '',
			PLCChannel: '',
			Port: '',
			MacAddress:'',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
			FileName:'',
			Email:['',[Validators.email]],
			SendAlert:'',
			UnlimitedJobs:'',
			LightsOut:'',
			Ignore:'',
		});

		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.getEquipment(id);
			}
		);
		this.branchService.getAllBranchesForDropdown().subscribe(response => {
			console.log('list of branches:');
			console.log(response);
			this.branches = response;
		});
	}

	public getSelectedRecordSet(): void {
		this.equipmentService.getRecordSet(this.EquipmentSearch)
			.subscribe((obj: IEquipments) => this.onSelectedRecordSetReceived(obj),
				(error: any) => {
					this.errorMessage = <any>error;
				});
	}

	private onSelectedRecordSetReceived(obj: IEquipments) {
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.equipment.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

	}

	saveEquipment(returnToList: boolean, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		this.buttonPushed = true;
		this.equipmentForm.patchValue({ Color:this.Color });
		if (this.equipmentForm.valid) {
			const obj = Object.assign({}, this.equipment, this.equipmentForm.value);
			
			this.equipmentService.saveEquipment(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord,addNewRecord)
					, (error: any) => this.errorMessage = <any>error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		if (returnToList) {
			this.equipmentForm.reset();
			this.router.navigate(['/equipments']);
		}
		else if (advanceToNextRecord) {
			this.advanceToNextRecord();
		}
		else if(addNewRecord){
			this.router.navigate(['/equipment-details', 0]);
			this.equipmentForm.reset();
			let obj=this.equipmentService.initializeEquipment();
			this.onEquipmentRetrieved(obj);
		}
		// when the record id is zero then it is a new record.
		else if (data !== null && this.equipment.Id === 0) {
			this.router.navigate(['/equipment-details', data.Id]);
		}
		else if (this.equipment.Id != 0) {
			this.getEquipment(this.equipment.Id);
		}

		this.buttonPushed = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.equipment.Id)
				record = count;
		});

		this.getEquipment(this.ListData.data[record].Id)
	}

	getEquipment(id: number): void {
		this.equipmentService.getEquipment(id)
			.subscribe((obj: IEquipment) => this.onEquipmentRetrieved(obj),
				(error: any) => {
					this.errorMessage = <any>error;
					// this.spinner.stop();
				});
	}

	onEquipmentRetrieved(equipment: IEquipment): void {
		if (this.equipmentForm) {
			this.equipmentForm.reset();
		}
		this.equipment = equipment;
		if (this.equipment.Id === 0) {
			this.page = 'Add Equipment';
		} else {
			this.page = 'Edit Equipment';
		}

		this.equipmentForm.patchValue({
			Id: this.equipment.Id,
			Name: this.equipment.Name,
			NickName: this.equipment.NickName,
			WorkCenterId: this.equipment.WorkCenterId,
			//Color: this.equipment.Color,
			PrinterType: this.equipment.PrinterType,
			IP: this.equipment.IP,
			BranchId: this.equipment.BranchId,
			ZPL: this.equipment.ZPL,
			PLCChannel: this.equipment.PLCChannel,
			Port: this.equipment.Port,
			MacAddress:this.equipment.MacAddress,
			CreatedDate: this.equipment.CreatedDate,
			CreatedBy: this.equipment.CreatedBy,
			UpdatedDate: this.equipment.UpdatedDate,
			UpdatedBy: this.equipment.UpdatedBy,
			FileName:this.equipment.FileName,
			Email:this.equipment.Email,
			SendAlert:this.equipment.SendAlert,
			UnlimitedJobs:this.equipment.UnlimitedJobs,
			LightsOut:this.equipment.LightsOut,
			Ignore:this.equipment.Ignore
		});
		this.Color = this.equipment.Color;
		this.urldata=this.applicationSettings.retrieveUrl()+'/uploadedfiles/Equipments/'+this.equipment.FileName;
		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteEquipment(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.equipment.Name}?`)) {
			this.equipmentService.deleteEquipment(this.equipment.Id)
				.subscribe(() => this.onSaveComplete(true, null, false,false)
					, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/equipments']);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Equipments List')
			.subscribe((data) => {
				this.UserSearches = data;
				this.setUserFilters();
			}
				, (error: any) => this.errorMessage = <any>error);
	}

	setUserFilters(): void {
		this.EquipmentSearch = this.equipmentService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'Name')
				this.EquipmentSearch.Name = i.Value;

			if (i.Item == 'PrinterType')
				this.EquipmentSearch.PrinterType = i.Value;

			if (i.Item == 'IP')
				this.EquipmentSearch.IP = i.Value;

			if (i.Item == 'BranchId')
				this.EquipmentSearch.BranchId = i.Value;

			if (i.Item == 'ZPL')
				this.EquipmentSearch.ZPL = i.Value;

			if (i.Item == 'PLCChannel')
				this.EquipmentSearch.PLCChannel = i.Value;

			if (i.Item == 'orderByString')
				this.EquipmentSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.EquipmentSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError(): void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}
	onFileSelected(event: any) {

		const target = event.target as HTMLInputElement;
		if (target.files && target.files.length > 0) {
		  console.log(target.files[0].name);
	   
		const formData = new FormData();
		formData.append('fileName', target.files[0]);
		formData.append('id', this.equipment.Id.toString());
		this.urldata="";
		this.equipmentService.addFile(formData).subscribe(
		  retVal => {
			if (retVal !== "") {
			  alert("File Uploaded");
			  console.log("File Uploaded");
			  this.equipmentForm.patchValue({
				  FileName: retVal
				});
			
			  this.urldata=this.applicationSettings.retrieveUrl()+'/uploadedfiles/Equipments/'+retVal;
			}
			else {
			  alert("File Not Uploaded");
			  this.equipmentForm.patchValue({
				FileName: ''
			  });
			  this.urldata="";
			}
		  }
		  ,(error: any) => this.errorMessage = <any>error
		);
	  }	
	}

	public onChangeColor(color: string): void {
		this.Color = color;
	  }
}
