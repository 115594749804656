import { AfterViewInit, Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/debounceTime';
import { Subscription } from 'rxjs/Subscription';
import { GenericValidator } from '../../../shared/generic-validator';
import { UserChangePassword } from '../user-model/user-change-password';
import { UserService } from '../user-service/user.service';
// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs/Rx';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'user-change-password',
	templateUrl: './user-change-password.component.html'
})
export class UserChangePasswordComponent implements OnInit, AfterViewInit {
	@ViewChildren(FormControlName, { read: ElementRef })
	formInputElements: ElementRef[];

	changePasswordForm: FormGroup;
	pageTitle = 'Change Password';
	errorMessage = '';
	user: UserChangePassword;
	private sub: Subscription;

	displayMessage: { [key: string]: string } = {};
	private validationMessages: { [key: string]: { [key: string]: string } };
	private genericValidator: GenericValidator;

	constructor(
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private userService: UserService
	) {
		this.validationMessages = {
			OldPassword: {
				required: 'Old Password is required.'
			},
			NewPassword: {
				required: 'New Password is required.'
			},
			ConfirmPassword: {
				required: 'Confirm Password is required.'
			}
		};
	}

	ngOnInit(): void {
		this.changePasswordForm = this.fb.group({
			OldPassword: ['', [Validators.required]],
			NewPassword: ['', [Validators.required]],
			ConfirmPassword: ['', [Validators.required]]
		});

		this.genericValidator = new GenericValidator(this.validationMessages);
	}

	ngAfterViewInit(): void {
		// Watch for the blur event from any input element on the form.
		const controlBlurs: Observable<any>[] = this.formInputElements.map(
			(formControl: ElementRef) =>
				Observable.fromEvent(formControl.nativeElement, 'blur')
		);

		// Merge the blur event observable with the valueChanges observable
		Observable.merge(this.changePasswordForm.valueChanges, ...controlBlurs)
			.debounceTime(800)
			.subscribe(value => {
				this.displayMessage = this.genericValidator.processMessages(
					this.changePasswordForm
				);
			});
	}

	changePassword(): void {
		if (this.changePasswordForm.dirty && this.changePasswordForm.valid) {
			const changedPassword = Object.assign(
				{},
				this.user,
				this.changePasswordForm.value
			);
			this.userService
				.changePassword(changedPassword)
				.subscribe(
					() => this.onSaveComplete(),
					(error: any) => (this.errorMessage = <any>error)
				);
		} else if (!this.changePasswordForm.dirty) {
			this.onSaveComplete();
		}
	}

	onSaveComplete(): void {
		this.changePasswordForm.reset();
		this.router.navigate(['/welcome']);
	}
}
