import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IPart} from './Part-model/Part';
import { IParts } from './Part-model/Parts';
import { IPartSearch } from './Part-model/PartSearch';

import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';
import { PartFileDto } from './part-model/partFileDto';

@Injectable()
export class PartService {
	private baseUrl;
	public pageTitle = 'Parts List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Parts';
	}

	getPart(id: number):  Observable<IPart> {
		if (id === 0) {
			return of(this.initializePart());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IPart>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	savePart(part: IPart): Observable<any> {
		if (part.Id === 0) {
			return this.createPart(part);
		}
		return this.updatePart(part);
	}

	private createPart(part: IPart): Observable<any> {
		part.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/partcreate', part)
			.pipe(retry(1), catchError(this.processError));
	}

	private updatePart(part: IPart): Observable<IPart> {
		return this.authHttp.put<IPart>(this.baseUrl, part)
			.pipe(retry(1), catchError(this.processError));
	}

	deletePart(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializePart(): IPart {
		return {
			Id: 0,
			MaterialId: 0,
			EquipmentId: 0,
			PartNumber: '',
			Description: '',
			LightsOut: false,
			InterventionQuantity: 1,
			InterventionRunTime: 15,
			PartShape: '',
			Radius: 0,
			Length: 0,
			Width: 0,
			Height: 0,

			FirstPartX: 0,
			FirstPartY: 0,
			SpacingX: 0,
			SpacingY: 0,
			Rows: 0,
			Columns: 0,

			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IPart;
	}

	public getRecordSet(search: IPartSearch): Observable<IParts> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('MaterialId', search.MaterialId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('EquipmentId', search.EquipmentId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('PartNumber', search.PartNumber, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Description', search.Description, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('MaterialId', search.MaterialId ? search.MaterialId : '')
			.set('EquipmentId', search.EquipmentId ? search.EquipmentId : '')
			.set('PartNumber', search.PartNumber ? search.PartNumber : '')
			.set('Description', search.Description ? search.Description : '')
		};

		return this.authHttp.get<IParts>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IParts>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IPartSearch { 
		let search: IPartSearch = {
			Id: 1,
			MaterialId: '',
			EquipmentId: '',
			PartNumber: '',
			Description: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	getAllParts():  Observable<IPart[]> {
		console.log(this.baseUrl);
		return this.authHttp.get<IPart[]>(`${this.baseUrl}/allparts`)
			.pipe(retry(1),
			catchError(this.processError));
	}
	
	getAllPartsData(value: any):  Observable<any[]> {
		return this.authHttp.get<any[]>( `${this.baseUrl}/getallparts?value=`+value)
		.pipe(
			retry(1),
			catchError(this.processError));
	}
	getPartsList():  Observable<any[]> {
		return this.authHttp.get<any[]>( `${this.baseUrl}/getpartslist`)
		.pipe(
			retry(1),
			catchError(this.processError));
	}

	getPartsByEquipment(equipment):  Observable<any[]> {
		return this.authHttp.get<any[]>( `${this.baseUrl}/getpartsbyequipment?equipment=`+equipment)
		.pipe(
			retry(1),
			catchError(this.processError));
	}

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.error.ErrorDescription}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: IPartSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('MaterialId', search.MaterialId ? search.MaterialId : '')
			.set('EquipmentId', search.EquipmentId ? search.EquipmentId : '')
			.set('PartNumber', search.PartNumber ? search.PartNumber : '')
			.set('Description', search.Description ? search.Description : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}

	updateFilePart(file: any){
		const url=`${this.baseUrl}/fileupdate`;
		return this.authHttp.put<IPart>(url, file)
			.pipe(retry(1),
				catchError(this.processError))
	}
	deleteFilePart(id: number){
		const url=`${this.baseUrl}/filedelete?id=${id}`;
		return this.authHttp.delete<any>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
	getAllFilesPart(Id: number){
		const url=`${this.baseUrl}/files?id=${Id}`;
		return this.authHttp.get<IPart[]>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
	createFilePart(file: any){
		const url=`${this.baseUrl}/filecreate`;
		return this.authHttp.post<any>(url, file)
			.pipe(retry(1),
			catchError(this.processError))
	}
	getMainImagePart(Id: number){
		const url=`${this.baseUrl}/main-image?id=${Id}`;
		return this.authHttp.get<PartFileDto>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}
}