import { Component, OnInit } from '@angular/core';
import { ApplicationSettings } from '../shared/application-settings';
import { DatePipe} from '@angular/common'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IJournal } from '../journals/Journal-model/Journal';
import { JournalService } from '../journals/journal-service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MatSnackBar } from '@angular/material/snack-bar';



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  
  journalForm: FormGroup;
	journal: IJournal;
  public errorMessage: string;
  public AssignedUser: string;

  public currentDate: Date;
  public WorkDate: string;
  public dayOfWeek: string;
  
  public progressStatus=false;
  public buttonPushed = false;

	public Editor: any = ClassicEditor;

  constructor( 
    public settings: ApplicationSettings
      , public datepipe: DatePipe
      , private fb: FormBuilder
      , private journalService: JournalService
      , public snackBar: MatSnackBar
    ) { 

      }

  ngOnInit() {
    this.currentDate = new Date();
    this.WorkDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    this.dayOfWeek = this.settings.getDayOfWeek(this.currentDate);

    this.journalForm = this.fb.group({
			Id: '',
			DateNote: '',
			DayNotes: ['',[Validators.maxLength(4000)]],
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});

    this.getData();
		this.progressStatus = true;

  }


  ngAfterViewInit() {
    
  }


  getData() {
  this.progressStatus = true;
  this.journalForm.reset();
  let currentDate = this.settings.AddDaysReturnAsString(this.WorkDate, 0)
  this.getJournalByDate(currentDate);
  }

  getJournalByDate(date: string): void {
		this.journalService.getJournalByDate(date)
			.subscribe((obj: IJournal) => this.onJournalRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		this.progressStatus = false;
		});
	}

  openSnackBar(message: string, action: string, durationMiliiseconds:number) {
		this.snackBar.open(message, action, {
		  duration: durationMiliiseconds,
		});
	}

  onJournalRetrieved(journal: IJournal): void {
		if (this.journalForm) {
			this.journalForm.reset();
		}
		this.journal = journal;
		
		this.journalForm.patchValue({
			Id: this.journal.Id,
			DateNote: this.journal.DateNote,
			DayNotes: this.journal.DayNotes,
			CreatedDate: this.journal.CreatedDate,
			CreatedBy: this.journal.CreatedBy,
			UpdatedDate: this.journal.UpdatedDate,
			UpdatedBy: this.journal.UpdatedBy,
		});
    this.progressStatus = false;
	}
 
  saveJournal(): void {
		this.progressStatus = true;
    this.buttonPushed = true;
		if (this.journalForm.valid) {
			const obj = Object.assign({ }, this.journal, this.journalForm.value);
			this.journalService.saveJournal(obj)
				.subscribe((data) => {this.buttonPushed = false;this.progressStatus = false;this.openSnackBar('Your changes have been successfully saved','Ok',5000);}
				, (error: any) => this.errorMessage = < any > error);
		}
	}

  dateChanged() {
      this.getData();
  }
  prevDay() {
    let d = new Date(this.WorkDate + " ")
    d.setDate(d.getDate() -1)
    this.currentDate = d;
    this.dayOfWeek = this.settings.getDayOfWeek(this.currentDate);
    this.WorkDate = this.datepipe.transform(d, 'yyyy-MM-dd');
    this.getData();
  }
  nextDay() {
    let d = new Date(this.WorkDate + " ")
    d.setDate(d.getDate() +1)
    this.currentDate = d;
    this.dayOfWeek = this.settings.getDayOfWeek(this.currentDate);
    this.WorkDate = this.datepipe.transform(d, 'yyyy-MM-dd');
    this.getData();
  }
}

