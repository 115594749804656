import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IEmployeecourse} from './Employeecourse-model/Employeecourse';
import { IEmployeecourses } from './Employeecourse-model/Employeecourses';
import { IEmployeecourseSearch } from './Employeecourse-model/EmployeecourseSearch';

import { ApplicationSettings } from '../../shared/application-settings';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';

@Injectable()
export class EmployeecourseService {
	private baseUrl;
	public pageTitle = 'EmployeeCourses List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Employeecourses';
	}

	getEmployeecourse(id: number):  Observable<IEmployeecourse> {
		if (id === 0) {
			return of(this.initializeEmployeecourse());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IEmployeecourse>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveEmployeecourse(employeecourse: IEmployeecourse): Observable<any> {
		if (employeecourse.Id === 0) {
			return this.createEmployeecourse(employeecourse);
		}
		return this.updateEmployeecourse(employeecourse);
	}

	private createEmployeecourse(employeecourse: IEmployeecourse): Observable<any> {
		employeecourse.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/employeecoursecreate', employeecourse)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateEmployeecourse(employeecourse: IEmployeecourse): Observable<IEmployeecourse> {
		return this.authHttp.put<IEmployeecourse>(this.baseUrl, employeecourse)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteEmployeecourse(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeEmployeecourse(): IEmployeecourse {
		return {
			Id: 0,
			CourseName: '',
			LessonId: 0,
			LessonName: '',
			LessonStatus: '',
			QuizStatus: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IEmployeecourse;
	}

	public getRecordSet(search: IEmployeecourseSearch): Observable<IEmployeecourses> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('CourseName', search.CourseName, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('LessonId', search.LessonId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('LessonName', search.LessonName, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('LessonStatus', search.LessonStatus, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('QuizStatus', search.QuizStatus, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('CourseName', search.CourseName ? search.CourseName : '')
			.set('LessonId', search.LessonId ? search.LessonId : '')
			.set('LessonName', search.LessonName ? search.LessonName : '')
			.set('LessonStatus', search.LessonStatus ? search.LessonStatus : '')
			.set('QuizStatus', search.QuizStatus ? search.QuizStatus : '')
		};

		return this.authHttp.get<IEmployeecourses>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IEmployeecourses>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IEmployeecourseSearch { 
		let search: IEmployeecourseSearch = {
			Id: 1,
			CourseName: '',
			LessonId: '',
			LessonName: '',
			LessonStatus: '',
			QuizStatus: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.error.ErrorDescription}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: IEmployeecourseSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('CourseName', search.CourseName ? search.CourseName : '')
			.set('LessonId', search.LessonId ? search.LessonId : '')
			.set('LessonName', search.LessonName ? search.LessonName : '')
			.set('LessonStatus', search.LessonStatus ? search.LessonStatus : '')
			.set('QuizStatus', search.QuizStatus ? search.QuizStatus : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}

	getEmployeecourseCert(id: number):  Observable<string> {
		if (id === 0) {
			return of('');
		}

		const url = `${this.baseUrl}/cert?id=${id}`;
		return this.authHttp.get<string>(url)
		.pipe(
			retry(1),
			catchError(this.processError));
	}
}