
<div class='card animaPlaceholder'>
	<div class='card-heading'>
	<div class='row'>
		<div class='col-md-4'>
			{{pageTitle}}
		</div>
		<div class='col-md-2 col-6 div-list-sml text-right'>
			<label>Rows</label>&nbsp;&nbsp;
			<select [(ngModel)]='pageSize' style='width: 100px; color: black;' onchange='pageSizeValueChanged()'>
				<option *ngFor='let item of applicationSettings.pageSizes'>{{item}}</option>
			</select>
		</div>
		<div class='col-md-2 col-6 div-list-sml text-right'>
			<button class='btn btn-light btn-sm' [routerLink]="['/green-details/0']" style='width:160px'>Add New Green</button>
		</div>
		<div class='col-md-2 col-6 div-list-sml text-right'>
			<button class='btn btn-primary btn-sm' style='width: 80px' (click)='searchClick()'>Search</button>
		</div>
		<div class='col-md-2 col-6 div-list-sml text-right'>
			<button class='btn btn-primary btn-sm' style='width: 100px' (click)='searchClearClick()'>Clear-Search</button>
		</div>
	</div>
	</div>

	<div class='card-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<div class='row filters'>
			<div class='col-md-2'>
				<label class='inline-label'>Name</label>
				<input type='text' class='form-control block-element' placeholder='Name' [(ngModel)]='Name' />
			</div>
			<div class='col-md-2'>
				<label class='inline-label'>SeedSerialNumber</label>
				<input type='text' class='form-control block-element' placeholder='SeedSerialNumber' [(ngModel)]='SeedSerialNumber' />
			</div>
			<div class='col-md-2'>
				<label class='inline-label'>Description</label>
				<input type='text' class='form-control block-element' placeholder='Description' [(ngModel)]='Description' />
			</div>
			<div class='col-md-2'>
				<label class='inline-label'>PlantedDate</label>
				<input type='text' class='form-control block-element' placeholder='PlantedDate' [(ngModel)]='PlantedDate' />
			</div>
		</div>
	</div>
</div>

<div class='table-container'>
<table mat-table [dataSource]='dataSource' class='mat-elevation-z8' matSort >
	<ng-container matColumnDef='Id'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
		<td mat-cell *matCellDef='let e'>
			<button class='btn btn-light btn-xs' style='min-width: 50px;' [routerLink]="['/green-details', e.Id]">select</button>
		</td>
	</ng-container>
	<ng-container matColumnDef='Name'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'>Name</th>
		<td mat-cell *matCellDef='let e'>{{e.Name}}</td>
	</ng-container>
	<ng-container matColumnDef='SeedSerialNumber'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'>SeedSerialNumber</th>
		<td mat-cell *matCellDef='let e'>{{e.SeedSerialNumber}}</td>
	</ng-container>
	<ng-container matColumnDef='Description'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'>Description</th>
		<td mat-cell *matCellDef='let e'>{{e.Description}}</td>
	</ng-container>
	<ng-container matColumnDef='PlantedDate'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'>PlantedDate</th>
		<td mat-cell *matCellDef='let e'>{{e.PlantedDate}}</td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
	<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
</div>
<mat-paginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>