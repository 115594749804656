import { Component, OnInit } from '@angular/core';
import { Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {
  constructor(private meta: Meta, private titleService: Title) {
    this.meta.addTag({ name: 'description', content: 'We are an innovative company offering the following: System Integration, Custom Software, FANUC Robotics, Custom Fabrication, Automation, PLC Programming.'});
    this.titleService.setTitle('EIAR');
   }

  ngOnInit() {
  }
}
