import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material-module'
import { BrancheListComponent } from './branche-list/branche-list.component';
import { BrancheEditComponent } from './branche-edit/branche-edit.component';
import { BrancheService } from './branche-service';
import { DialogsModule } from '../../shared/dialogs/dialogs.module';

@NgModule({
	declarations: [
		BrancheListComponent
		, BrancheEditComponent
	],
	imports: [
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		DialogsModule,
		RouterModule.forChild([
			{ path: 'branches', component: BrancheListComponent },
			{ path: 'branche-details/:Id', component: BrancheEditComponent }
		])
	],
	providers: [
		BrancheService
	]
})
export class BrancheModule { }
