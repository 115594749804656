
<div class='panel panel-primary animaPlaceholder' *ngIf = 'usersearche'>
	<div class='panel-heading'>
		<span *ngIf = 'usersearche' >{{ page }} :{{ usersearche.Id }}</span>
		<span class= 'pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class= 'panel-body'>
		<div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<form class='form-horizontal' novalidate [formGroup]='usersearcheForm'>
			<fieldset>
			<div class='panel-body'>
					<div class='form-group'>
						<label class='col-md-2 control-label'> User Id</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='UserId' />
							<span *ngIf = "usersearcheForm.controls['UserId'].invalid  && usersearcheForm.controls['UserId'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Form</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='Form' />
							<span *ngIf = "usersearcheForm.controls['Form'].invalid  && usersearcheForm.controls['Form'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Item</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='Item' />
							<span *ngIf = "usersearcheForm.controls['Item'].invalid  && usersearcheForm.controls['Item'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
					<div class='form-group'>
						<label class='col-md-2 control-label'> Value</label>
						<div class='col-md-10'>
							<input class='form-control' type='text' formControlName='Value' />
							<span *ngIf = "usersearcheForm.controls['Value'].invalid  && usersearcheForm.controls['Value'].errors.maxlength" class="text-danger">
									Only 250 characters are allowed.
							</span>
						</div>
					</div>
			</div>
			</fieldset>
		</form>

			<div class='row' style='padding: 15px;'>
				<div class= 'col-md-12'>
					<span>
						<button class='btn btn-primary'
							[disabled]='buttonPushed'
								(click)='saveUsersearche(false, false)'
									style='width: 110px; margin-right:10px'
									[disabled]='!usersearcheForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
								</button>
								<button class='btn btn-primary'
									[disabled]='buttonPushed'
									(click)='saveUsersearche(true, false)'
									style='width: 150px; margin-right:10px'
									[disabled]='!usersearcheForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
								</button>
								<button *ngIf='showNextRecordButton' class='btn btn-primary'
									[disabled]='buttonPushed'
									(click)='saveUsersearche(false, true)'
									style='width: 190px; margin-right:10px'
									[disabled]='!usersearcheForm.valid || progressStatus'>
									<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
								</button>
							</span>

						<span  class='pull-right'>
							<button class='btn btn-primary'
								[disabled]='buttonPushed'
								(click)='cancel()'
								style='width: 150px; margin-right:10px'
								[disabled]='!usersearcheForm.valid || progressStatus'>
								Cancel &nbsp;
							</button>
							<button class='btn btn-primary'
								[disabled]='buttonPushed'
								(click)='deleteUsersearche()'
								style='width: 150px; margin-right:10px'
								[disabled]='!usersearcheForm.valid || progressStatus'>
								<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
							</button>
						</span>
					</div>
				</div>

				<div class='row'>
					<div class='col-md-12'>
						<span> Updated By: <label *ngIf='usersearche'>{{usersearche.UpdatedBy}}</label></span>
						<span> Time: <label *ngIf='usersearche'>{{usersearche.UpdatedDate}}</label></span>
					</div>
				</div>
	</div>
</div>
<div>
<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>