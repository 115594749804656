<div class="container">
	<div class="row">
      <section class="about">
		<div class="col-md-12" align="center">
			<h1>PLATFORMS</h1>
		</div>
		<div class="col-md-12" align="center">
			<I>Languages, Frameworks and Tools</I>
		</div>
		
		<div class="col-md-12">
			<br />
			<br />
			<br />
    </div>
    </section>  
	</div>
	<div class="row">
		<div class="col-md-1" align="center">
		</div>
		<div class="col-md-5" align="left">
			<p>My company has integrated with many platforms and systems, but when 
        it comes to development we stick with Microsoft products. Our preferred 
        development languages are Booble Angular, C# and MSSQL. Our 
        production applications contain one or more of the following technologies: Google Angular, 
        ASP.NET Web Forms, ASP.NET MVC, Windows Services, Windows Forms. We try to 
        stick with windows controls and tools as much as possible. The one feature-rich third 
        party tool set we use extensively is the Telerik control suite.
      </p>
      <p>
        We have accumulated many reusable components and interfaces. For example, my base, out of the box system
        comes with a cloud based internet application for internal employees only, which also allows you
        to post training videos and monitor usage.  We have a private 
        video channel on Vimeo which allows you to post videos through a web page, and have 
        them served to your employees.
      </p>
      <img class='content-edmonton-pic' src="../assets/roboguide.png" alt="FANUC Robo Guide" />
		</div>
		<div class="col-md-5" align="left">
        <div class="content-services-part2">
            <ul>
              <li>Google Angular (Google Responsive Design)</li>
              <li>Microsoft .NET</li>
              <li>ASP.NET</li>
              <li>C#</li>
              <li>VB.NET</li>
              <li>Microsoft Web Services</li>
              <li>Microsoft Reporting Services</li>
              <li>HTML</li>
              <li>JavaScript</li>
              <li>Microsoft Windows Forms</li>
              <li>Cognex Vision Pro</li>
              <li>Cognex Vision Pro Development Kit</li>
              <li>Fanuc Robotics Software Development Kit</li>
              <li>Fanuc Robo Guide</li>
              <li>Siemens Simatic Step 7</li>
              <li>Schneider Unity Pro</li>
              <li>Schneider Vijeo Designer</li>
              <li>Rockwell Studio 5000</li>
            </ul>
          </div>
      
		</div>
		<div class="col-md-1" align="center">
		</div>
	</div>
</div>
