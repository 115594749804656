import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'

import { CompanyListComponent } from './company-list/company-list.component';
import { CompanyEditComponent } from './company-edit/company-edit.component';
import { CompanyService } from './company-service';
import { MatProgressSpinnerModule as MatProgressSpinnerModule }  from '@angular/material/progress-spinner';
import { CustomerModule } from '../customers/customer-module';
import { DialogsModule } from '../shared/dialogs/dialogs.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { LandLocationCompanyListComponent } from '../companylandlocations/landlocation-list/landlocation-company-list.component';
import { CompanylandlocationService } from '../companylandlocations/companylandlocation-service';
import { CompanytraitService } from '../companytraits/companytrait-service';

@NgModule({
	declarations: [
		CompanyListComponent
		,CompanyEditComponent
		,LandLocationCompanyListComponent
	],
	imports: [
		CustomerModule,
		MatProgressSpinnerModule,
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		DialogsModule,
		GoogleMapsModule,
		RouterModule.forChild([
			{ path: 'companies', component: CompanyListComponent },
			{ path: 'company-details/:Id', component: CompanyEditComponent },
			{ path: 'company-details/:Id/:TabIndex', component: CompanyEditComponent },
		])
	],
	providers: [
		CompanyService,CompanylandlocationService,CompanytraitService
	],
	exports:
	[LandLocationCompanyListComponent]
})
export class CompanyModule { }
