
<div class='card card-primary animaPlaceholder'>
	<div class='card-heading'>
	<div class='row'>
		<div class='col-lg-6 col-md-7 col-xs-12 options-bar'>
			{{pageTitle}}
		</div>
		<div class='col-lg-2 col-md-1 col-xs-6 options-bar'>
			<button class='btn btn-default btn-sm center-block btn-full' [routerLink]="['/task-details/0']" [disabled]="progressStatusTaskList">New Task</button>
		</div>
		<div class='col-lg-1 col-md-1 col-xs-6 options-bar'>
		<button class='btn btn-default btn-sm center-block btn-full'(click)='showHideSearchClick()' [disabled]="progressStatusTaskList">{{SearchText}}</button>
		</div>
		<div class='col-lg-1 col-md-1 col-xs-6 options-bar'>
			<button class='btn btn-default btn-sm center-block btn-full' (click)='toExcel()' [disabled]="progressStatusTaskList">To Excel</button>
		</div>
	</div>
	</div>

	<div class='card-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='excelFile'>
					<button class='btn btn-primary btn-sm' (click)='hideFileLink()'>hide link</button>
					<span>excel File:<a href = "javascript:void(0)"(click) = "openExcelFile()" > {{ excelFile}}</a></span>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<div class='row filters' *ngIf='showHideSearch'>
		<div class='col-md-9'>
			<div class='row'>
			<div class='col-md-4 form-group-item'>
				<label class='inline-label'> Task Name</label>
				<input type='text' class='form-control block-element' placeholder=' Task Name' [(ngModel)]='TaskName' />
			</div>
			</div>
		</div>
		<div class='col-md-3'>
			<div class='row'>
				<div class='col-md-12 text-right'>
					<button class='btn btn-primary btn-sm btn-search' (click)='searchClick()' [disabled]="progressStatus">Search</button>
				</div>
				<div class='col-md-12 text-right'>
					<button class='btn btn-primary btn-sm btn-search' (click)='searchAndHideClick()' [disabled]="progressStatus">Search and Hide</button>
				</div>
				<div class='col-md-12 text-right'>
					<button class='btn btn-primary btn-sm btn-search' (click)='searchClearClick()' [disabled]="progressStatus">Clear-Search</button>
				</div>
			</div>
		</div>
		</div>
	</div>
</div>

<div class='table-container'>
	<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
	<ng-container matColumnDef='Id'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-actions'>#</th>
		<td mat-cell *matCellDef='let e'>
			<a class='btn btn-light' style='min-width: 80px;' [routerLink]="['/task-details', e.Id]"  >{{e.Id}}</a>
		</td>
	</ng-container>
	<ng-container matColumnDef='TaskName'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Task Name</th>
		<td mat-cell *matCellDef='let e' class='col2' >{{e.TaskName}}</td>
	</ng-container>
	<ng-container matColumnDef='Description'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Description</th>
		<td mat-cell *matCellDef='let e' class='col-100' >{{e.Description}}</td>
	</ng-container>
	
	<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
	<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
</div>
<mat-paginator (page)="pageSizeValueChanged($event.pageSize.toString())" [length]='recordTotal' [pageSize]='pageSize'  [pageSizeOptions]="applicationSettings.getPageSizes()"></mat-paginator>
<div>
<app-loading *ngIf="progressStatusTaskList"></app-loading> 
</div>


