<div class="panel panel-primary animaPlaceholder">
	<div class="panel-heading">
		{{ pageTitle }}
		<div class='has-error' *ngIf='errorMessage'>{{ errorMessage }}</div>
	</div>
	<div class="panel-body">
		<div class='has-error' *ngIf='errorMessage'>{{ errorMessage }}</div>
		<form class="form-horizontal" novalidate [formGroup]="createUserForm">
			<div>
				Fields marked with * are mandatory
			</div>
			<div class="bottomMargin">
				<span class="text-danger" *ngIf="displayMessage.FirstName">
					{{displayMessage.FirstName}} &nbsp;
				</span>
				<span class="text-danger" *ngIf="displayMessage.LastName">
					{{displayMessage.LastName}} &nbsp;
				</span>
				<span class="text-danger" *ngIf="displayMessage.Email">
					{{displayMessage.Email}} &nbsp;
				</span>
				<span class="text-danger" *ngIf="displayMessage.Password">
					{{displayMessage.Password}} &nbsp;
				</span>
				<span class="text-danger" *ngIf="displayMessage.ConfirmPassword">
					{{displayMessage.ConfirmPassword}}
				</span>
			</div>
			<div class="form-group">
				<label class="col-md-1 control-label required" for="Username"><span class="text-danger">*</span>Username</label>
				<div class="col-md-4 form-group-item">
					<input class="form-control" id="Username" type="text" placeholder="Username (required)" formControlName="Username" />
				</div>

				<label class="col-md-1 control-label" for="Active">Active</label>
				<div class="col-md-1">
					<input id="Active" type="checkbox" formControlName="Active" />
				</div>
			</div>
			<div class="form-group">
				<label class="col-md-1 control-label required" for="FirstName"><span class="text-danger">*</span>First name</label>
				<div class="col-md-4 form-group-item">
					<input class="form-control" id="FirstName" type="text" placeholder="First Name (required)" formControlName="FirstName" />
				</div>

				<label class="col-md-1 control-label" for="Initials">Initials</label>
				<div class="col-md-4 form-group-item">
					<input class="form-control" id="Initials" type="text" placeholder="Initials" formControlName="Initials" maxlength="2" />
				</div>
			</div>
			<div class="form-group">
				<label class="col-md-1 control-label required" for="LastName"><span class="text-danger">*</span>Last name</label>
				<div class="col-md-4 form-group-item">
					<input class="form-control" id="LastName" type="text" placeholder="Last Name (required)" formControlName="LastName" />
				</div>
				<label class="col-md-1 control-label" for="Title">Title</label>
				<div class="col-md-4 form-group-item">
					<input class="form-control" id="Title" type="text" placeholder="Title" formControlName="Title" maxlength="50" />
				</div>
			</div>
			<div class="form-group">
				<label class="col-md-1 control-label required" for="Email"><span class="text-danger">*</span>Email</label>
				<div class="col-md-4 form-group-item">
					<input class="form-control" id="Email" type="text" placeholder="Email (required)" formControlName="Email" />
				</div>
				<label class="col-md-1 control-label" for="Address">Address</label>
				<div class="col-md-4 form-group-item">
					<input class="form-control" id="Address" type="text" placeholder="Address" formControlName="Address" maxlength="50" />
				</div>
			</div>
			<div class="form-group">
				<label class="col-md-1 control-label required" for="Password"><span class="text-danger">*</span>Password</label>
				<div class="col-md-4 form-group-item">
					<input class="form-control" id="Password" type="password" placeholder="Password (required)" formControlName="Password" />
				</div>
				<label class="col-md-1 control-label required" for="ConfirmPassword"><span class="text-danger">*</span>Confirm Password</label>
				<div class="col-md-4 form-group-item">
					<input class="form-control" id="ConfirmPassword" type="password" placeholder="Confirm Password (required)" formControlName="ConfirmPassword"
					/>
				</div>
			</div>
			<div class="form-group">
				<label class="col-md-1 control-label" for="City">City</label>
				<div class="col-md-4 form-group-item">
					<input class="form-control" id="City" type="text" placeholder="City" formControlName="City" maxlength="50" />
				</div>
				<label class="col-md-1 control-label" for="Region">Region</label>
				<div class="col-md-4 form-group-item">
					<input class="form-control" id="Region" type="text" placeholder="Region" formControlName="Region" maxlength="50" />
				</div>
			</div>
			<div class="form-group">
				<label class="col-md-1 control-label" for="Notes">Notes</label>
				<div class="col-md-4 form-group-item">
					<input class="form-control" id="Notes" type="text" placeholder="Notes" formControlName="Notes" />
				</div>

				<label class="col-md-1 control-label" for="PostalCode">Postal Code</label>
				<div class="col-md-4 form-group-item">
					<input class="form-control" id="PostalCode" type="text" placeholder="Postal Code" formControlName="PostalCode" maxlength="10"
					/>
				</div>
			</div>
			<div class="form-group">
				<label class="col-md-1 control-label" for="BirthDate">Birth Date</label>
				<div class="col-md-4 form-group-item">
					<input class="form-control" id="BirthDate" type="date" formControlName="BirthDate" />
				</div>

				<label class="col-md-1 control-label" for="HomePhone">Home Phone</label>
				<div class="col-md-4 form-group-item">
					<input class="form-control" id="HomePhone" type="text" placeholder="Home Phone" formControlName="HomePhone" maxlength="22"
					/>
				</div>
			</div>
			<div class="form-group">
				<label class="col-md-1 control-label" for="HireDate">Hire Date</label>
				<div class="col-md-4 form-group-item">
					<input class="form-control" id="HireDate" type="date" formControlName="HireDate" />
				</div>

				<label class="col-md-1 control-label" for="CellPhone">Cell Phone</label>
				<div class="col-md-4 form-group-item">
					<input class="form-control" id="CellPhone" type="text" placeholder="Cell Phone" formControlName="CellPhone" maxlength="22"
					/>
				</div>
			</div>
			<div class="form-group">
				<label class="col-md-1 control-label" for="EmergencyName">Emergency Name</label>
				<div class="col-md-4 form-group-item">
					<input class="form-control" id="EmergencyName" type="text" placeholder="Emergency Name" formControlName="EmergencyName" maxlength="50"
					/>
				</div>

				<label class="col-md-1 control-label" for="EmergencyPhone">Emergency Phone</label>
				<div class="col-md-4 form-group-item">
					<input class="form-control" id="EmergencyPhone" type="text" placeholder="Emergency Phone" formControlName="EmergencyPhone"
					    maxlength="22" />
				</div>
			</div>		
		</form>

		<div class='row' style='padding: 15px;'>
			<div class='col-md-12'>
				<span>
					<button class='btn btn-primary btn-edit-sml'
						(click)='saveUser()' style='width: 110px; margin-right:10px'
						[disabled]='!createUserForm.valid'>
						<span class='glyphicon glyphicon-floppy-save'></span> Save&nbsp;
					</button>
				</span>

				<span class='pull-right remove-float-sml'>
					<button class='btn btn-primary btn-edit-sml' (click)='cancel()'
						style='width: 150px; margin-right:10px'>
						Cancel &nbsp;
					</button>
				</span>
			</div>
		</div>
	</div>
</div>
<app-loading *ngIf="progressStatus"></app-loading> 
