import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'
import { HelpListComponent } from './help-list/help-list.component';
import { HelpEditComponent } from './help-edit/help-edit.component';
import { HelpService } from './help-service';
import { MatProgressSpinnerModule }  from '@angular/material/progress-spinner';
import { HelpViewComponent } from './help-view/help-view.component';
import { ClipboardModule } from '@angular/cdk/clipboard';

@NgModule({
	declarations: [
		HelpListComponent
		, HelpEditComponent
		, HelpViewComponent
	],
	imports: [
		ClipboardModule,
		MatProgressSpinnerModule,
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		RouterModule.forChild([
			{ path: 'helps', component: HelpListComponent },
			{ path: 'help-details/:Id', component: HelpEditComponent },
			{ path: 'help-view/:pagename', component: HelpViewComponent },
		])
	],
	providers: [
		HelpService
	]
})
export class HelpModule { }
