import { Component, OnInit, OnDestroy, ViewChildren, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControlName } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IWorktask } from '../worktask-model/worktask';

import { MatPaginator as MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource as MatTableDataSource } from '@angular/material/table';
// import { fromEvent, merge } from 'rxjs';
// import { debounceTime } from 'rxjs/operators';


import { Subscription } from 'rxjs/Subscription';
import { WorktaskService } from '../worktask-service';
import { IWorktasks } from '../Worktask-model/Worktasks';
import { IWorktaskSearch } from '../Worktask-model/WorktaskSearch';

import { UsersearchService } from '../../admin/usersearches/usersearch-service';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { ApplicationSettings } from '../../shared/application-settings';

import { IWorklogs } from '../../worklogs/worklog-model/worklogs';
import { IWorklogSearch } from '../../worklogs/worklog-model/worklogSearch';
import { WorklogService } from '../../worklogs/worklog-service';

@Component({
	templateUrl: './worktask-edit.component.html',
	styleUrls: ['worktask-edit.component.css']
})

export class WorktaskEditComponent implements OnInit, OnDestroy {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	displayedColumns: string[] = ['Id','Description', 'WorkDate', 'Hours'];
	isLoadingResults = true;
	public dataSourceIncompletedWork: any;

	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
	public page = 'Edit Work Tasks';
	errorMessage: string;
	worktaskForm: FormGroup;
	worktask: IWorktask;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public WorktaskSearch: IWorktaskSearch;
	public ListData: IWorktasks;
	public showNextRecordButton: boolean = true;
	private workTaskId: number = 0;

	constructor(private fb: FormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private worktaskService: WorktaskService
		, private workLogService: WorklogService
		, public applicationSettings: ApplicationSettings
		) {
	}

	ngOnInit(): void {
		this.worktaskForm = this.fb.group({
			Id: '',
			Name: '',
			BranchId: '',
			Description: '',
			EstimatedHours: '',
			Priority: '',
			CompletedDate: '',
			Application: '',
			Type1: '',
			Type2: '',
			Type3: '',
			Notes: '',
			InstalledDate: '',
			AssignedUser: '',
			nextaction: '',
			DevNotes: '',
			Status: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});
		this.sub = this.route.params.subscribe(
			params => {
				this.workTaskId = +params['Id'];
				this.getWorktask(this.workTaskId);
				this.getIncompletedData()
			}
		);
	}

	public getSelectedRecordSet():void{
		this.worktaskService.getRecordSet(this.WorktaskSearch)
			.subscribe((obj: IWorktasks) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: IWorktasks){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.worktask.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

	}

	saveWorktask(returnToList: boolean, advanceToNextRecord: boolean): void {
		this.buttonPushed = true;

		if (this.worktaskForm.valid) {
			const obj = Object.assign({ }, this.worktask, this.worktaskForm.value);
			
			this.worktaskService.saveWorktask(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean): void {
		if (returnToList) {
			this.worktaskForm.reset();
			this.router.navigate(['/worktasks']);
		} else {
			// when the record id is zero then it is a new record.
			if (data !== null && this.worktask.Id === 0) {
				this.router.navigate(['/worktask-details', data.Id]);
			} else {
				if (advanceToNextRecord) {
					this.advanceToNextRecord();
				}
			}
		}

		this.buttonPushed = false;
	}

	advanceToNextRecord(): void {
		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.worktask.Id)
				record = count;
		});

		this.getWorktask(this.ListData.data[record].Id)
	}

	getWorktask(id: number): void {
		this.worktaskService.getWorktask(id)
			.subscribe((obj: IWorktask) => this.onWorktaskRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onWorktaskRetrieved(worktask: IWorktask): void {
		if (this.worktaskForm) {
			this.worktaskForm.reset();
		}
		this.worktask = worktask;
		if (this.worktask.Id === 0) {
			this.page = 'Add Work Tasks';
		} else {
			this.page = 'Edit Work Tasks';
		}

		this.worktaskForm.patchValue({
			Id: this.worktask.Id,
			Name: this.worktask.Name,
			BranchId: this.worktask.BranchId,
			Description: this.worktask.Description,
			EstimatedHours: this.worktask.EstimatedHours,
			Priority: this.worktask.Priority,
			CompletedDate: this.worktask.CompletedDate,
			Application: this.worktask.Application,
			Type1: this.worktask.Type1,
			Type2: this.worktask.Type2,
			Type3: this.worktask.Type3,
			Notes: this.worktask.Notes,
			InstalledDate: this.worktask.InstalledDate,
			AssignedUser: this.worktask.AssignedUser,
			nextaction: this.worktask.nextaction,
			DevNotes: this.worktask.DevNotes,
			Status: this.worktask.Status,
			CreatedDate: this.worktask.CreatedDate,
			CreatedBy: this.worktask.CreatedBy,
			UpdatedDate: this.worktask.UpdatedDate,
			UpdatedBy: this.worktask.UpdatedBy,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteWorktask(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.worktask.Name}?`)) {
			this.worktaskService.deleteWorktask(this.worktask.Id)
				.subscribe(() => this.onSaveComplete(true, null, false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		this.router.navigate(['/worktasks']);
	}


	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Work Tasks List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.WorktaskSearch = this.worktaskService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'Name')
				this.WorktaskSearch.Name = i.Value;

			if (i.Item == 'Priority')
				this.WorktaskSearch.Priority = i.Value;

			if (i.Item == 'Application')
				this.WorktaskSearch.Application = i.Value;

			if (i.Item == 'AssignedUser')
				this.WorktaskSearch.AssignedUser = i.Value;

			if (i.Item == 'Status')
				this.WorktaskSearch.Status = i.Value;

			if (i.Item == 'orderByString')
				this.WorktaskSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.WorktaskSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}

	getIncompletedData(): any {
		let search: IWorklogSearch = this.workLogService.getFreshSearch();

		search.WorkTaskId = this.workTaskId.toString();
		search.skip = 0;
		search.take = 1000;
		search.orderByString =  'desc::WorkDate::';

		this.workLogService.getRecordSet(search).subscribe((obj: IWorklogs) => this.onWorkTasksReceived(obj),
		(error: any) => {
			this.errorMessage = <any> error;
		});
	}

	private onWorkTasksReceived(obj: IWorklogs){
		this.dataSourceIncompletedWork = new MatTableDataSource(obj.data);
		 this.recordTotal = obj.total;
	}
}
