<div class='panel panel-primary animaPlaceholder'>
	<div class='panel-heading'>
		<div class='row'>
			<div class='col-lg-6 col-md-6 col-xs-12 options-bar'>
				{{pageTitle}}
			</div>
			<div class='col-lg-2 col-md-2 col-xs-4 options-bar'>
				<button class='btn btn-default btn-sm center-block btn-full' mat-fab extended color="primary" [routerLink]="['/create-user']"><mat-icon>add</mat-icon>Add User</button>
			</div>
			<div class='col-lg-2 col-md-2 col-xs-4 options-bar'>
				<button class='btn btn-default btn-sm center-block btn-full' mat-fab extended color="primary" (click)='showHideSearchClick()'><mat-icon *ngIf="showHideSearch">speaker_notes_off</mat-icon><mat-icon *ngIf="!showHideSearch">speaker_notes</mat-icon>{{SearchText}}
			</button>
			</div>
			<div class='col-lg-2 col-md-2 col-xs-4 options-bar'>
				<button class='btn btn-default btn-sm center-block btn-full' mat-fab extended color="primary" (click)='toExcel()'><mat-icon>cloud_download</mat-icon>To Excel</button>
			</div>
		</div>
	</div>

	<div class='panel-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='excelFile'>
					<button class='btn btn-primary btn-sm' (click)='hideFileLink()'>hide link</button>
					<span>excel File:<a href = "javascript:void(0)"(click) = "openExcelFile()" > {{ excelFile}}</a></span>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<div class='row filters' *ngIf='showHideSearch'>
		<div class='col-md-9'>
			<div class='row'>
			<div class='col-md-4 form-group-item'>
				<label class='inline-label'> First Name</label>
				<input type='text' class='form-control block-element' placeholder=' First Name' [(ngModel)]='FirstName' />
			</div>
			<div class='col-md-4 form-group-item'>
				<label class='inline-label'> Last Name</label>
				<input type='text' class='form-control block-element' placeholder=' Last Name' [(ngModel)]='LastName' />
			</div>
			<!-- <div class='col-md-4'>
				<label class='inline-label'> Initials</label>
				<input type='text' class='form-control block-element' placeholder=' Initials' [(ngModel)]='Initials' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Title</label>
				<input type='text' class='form-control block-element' placeholder=' Title' [(ngModel)]='Title' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Address</label>
				<input type='text' class='form-control block-element' placeholder=' Address' [(ngModel)]='Address' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> City</label>
				<input type='text' class='form-control block-element' placeholder=' City' [(ngModel)]='City' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Region</label>
				<input type='text' class='form-control block-element' placeholder=' Region' [(ngModel)]='Region' />
			</div> -->
			<!-- <div class='col-md-4'>
				<label class='inline-label'> Postal Code</label>
				<input type='text' class='form-control block-element' placeholder=' Postal Code' [(ngModel)]='PostalCode' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Home Phone</label>
				<input type='text' class='form-control block-element' placeholder=' Home Phone' [(ngModel)]='HomePhone' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Cell Phone</label>
				<input type='text' class='form-control block-element' placeholder=' Cell Phone' [(ngModel)]='CellPhone' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Birth Date</label>
				<input type='text' class='form-control block-element' placeholder=' Birth Date' [(ngModel)]='BirthDate' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Hire Date</label>
				<input type='text' class='form-control block-element' placeholder=' Hire Date' [(ngModel)]='HireDate' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Emergency Name</label>
				<input type='text' class='form-control block-element' placeholder=' Emergency Name' [(ngModel)]='EmergencyName' />
			</div> -->
			<!-- <div class='col-md-4'>
				<label class='inline-label'> Emergency Phone</label>
				<input type='text' class='form-control block-element' placeholder=' Emergency Phone' [(ngModel)]='EmergencyPhone' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Notes</label>
				<input type='text' class='form-control block-element' placeholder=' Notes' [(ngModel)]='Notes' />
			</div> -->
			
			<!-- <div class='col-md-4'>
				<label class='inline-label'> Image</label>
				<input type='text' class='form-control block-element' placeholder=' Image' [(ngModel)]='Image' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Signature</label>
				<input type='text' class='form-control block-element' placeholder=' Signature' [(ngModel)]='Signature' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Company Id</label>
				<input type='text' class='form-control block-element' placeholder=' Company Id' [(ngModel)]='CompanyId' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Company Name</label>
				<input type='text' class='form-control block-element' placeholder=' Company Name' [(ngModel)]='CompanyName' />
			</div> -->
			<div class='col-md-4 form-group-item'>
				<label class='inline-label'> Email</label>
				<input type='text' class='form-control block-element' placeholder=' Email' [(ngModel)]='Email' />
			</div>
			<!-- <div class='col-md-4'>
				<label class='inline-label'> Email Confirmed</label>
				<input type='text' class='form-control block-element' placeholder=' Email Confirmed' [(ngModel)]='EmailConfirmed' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Password Hash</label>
				<input type='text' class='form-control block-element' placeholder=' Password Hash' [(ngModel)]='PasswordHash' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Security Stamp</label>
				<input type='text' class='form-control block-element' placeholder=' Security Stamp' [(ngModel)]='SecurityStamp' />
			</div> -->
			<!-- <div class='col-md-4'>
				<label class='inline-label'> Phone Number</label>
				<input type='text' class='form-control block-element' placeholder=' Phone Number' [(ngModel)]='PhoneNumber' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Phone Number Confirmed</label>
				<input type='text' class='form-control block-element' placeholder=' Phone Number Confirmed' [(ngModel)]='PhoneNumberConfirmed' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Two Factor Enabled</label>
				<input type='text' class='form-control block-element' placeholder=' Two Factor Enabled' [(ngModel)]='TwoFactorEnabled' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Lockout End Date Utc</label>
				<input type='text' class='form-control block-element' placeholder=' Lockout End Date Utc' [(ngModel)]='LockoutEndDateUtc' />
			</div> -->
			<!-- <div class='col-md-4'>
				<label class='inline-label'> Lockout Enabled</label>
				<input type='text' class='form-control block-element' placeholder=' Lockout Enabled' [(ngModel)]='LockoutEnabled' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Access Failed Count</label>
				<input type='text' class='form-control block-element' placeholder=' Access Failed Count' [(ngModel)]='AccessFailedCount' />
			</div> -->
			<div class='col-md-4 form-group-item'>
				<label class='inline-label'> User Name</label>
				<input type='text' class='form-control block-element' placeholder=' User Name' [(ngModel)]='UserName' />
			</div>
			<div class='col-md-4 form-group-item'>
				<label class='inline-label'> Branch </label>
                <select [(ngModel)]='BranchId' class='form-control block-element'>
					<option value="" disabled selected hidden>Select Branch</option>
					<option value= ""> </option>
					<option *ngFor='let branch of branches' [value]="branch.Id">{{branch.BranchName}} {{branch.BranchNumber}}
					</option>
				</select>		
			</div>
			<div class='col-md-4 form-group-item search-checkbox-area'>
				<input type='checkbox' [(ngModel)]='Active'/>&nbsp;
					<label> Active Users</label>
			</div>
			</div>
		</div>
		<div class='col-md-3'>
			<div class='row'>
				<div class='col-md-12 col-xs-4 text-right'>
					<button class='btn btn-primary btn-sm btn-search' (click)='searchClick()'>Search</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right'>
					<button class='btn btn-primary btn-sm btn-search' (click)='searchAndHideClick()'>Search and Hide</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right'>
					<button class='btn btn-primary btn-sm btn-search' (click)='searchClearClick()'>Clear-Search</button>
				</div>
			</div>
		</div>
		</div>
	</div>
</div>

<div class='table-container'>
	<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
		<ng-container matColumnDef='Id'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-actions'>Action</th>
			<td class="td-center" mat-cell *matCellDef='let e'>
				<button [routerLink]="['/edit-user', e.Id]" mat-icon-button matTooltip="Edit this user's info"><mat-icon>edit</mat-icon></button>
			</td>
		</ng-container>
		<ng-container matColumnDef='FirstName'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> First Name</th>
			<td mat-cell *matCellDef='let e'>{{e.FirstName}}</td>
		</ng-container>
		<ng-container matColumnDef='LastName'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Last Name</th>
			<td mat-cell *matCellDef='let e'>{{e.LastName}}</td>
		</ng-container>
		<ng-container matColumnDef='Initials'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Initials</th>
			<td mat-cell *matCellDef='let e'>{{e.Initials}}</td>
		</ng-container>
		<ng-container matColumnDef='Title'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Title</th>
			<td mat-cell *matCellDef='let e'>{{e.Title}}</td>
		</ng-container>
		<ng-container matColumnDef='Address'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Address</th>
			<td mat-cell *matCellDef='let e'>{{e.Address}}</td>
		</ng-container>
		<ng-container matColumnDef='City'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> City</th>
			<td mat-cell *matCellDef='let e'>{{e.City}}</td>
		</ng-container>
		<ng-container matColumnDef='Region'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Region</th>
			<td mat-cell *matCellDef='let e'>{{e.Region}}</td>
		</ng-container>
		<ng-container matColumnDef='PostalCode'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Postal Code</th>
			<td mat-cell *matCellDef='let e'>{{e.PostalCode}}</td>
		</ng-container>
		<ng-container matColumnDef='HomePhone'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Home Phone</th>
			<td mat-cell *matCellDef='let e'>{{e.HomePhone}}</td>
		</ng-container>
		<ng-container matColumnDef='CellPhone'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Cell Phone</th>
			<td mat-cell *matCellDef='let e'>{{e.CellPhone}}</td>
		</ng-container>
		<ng-container matColumnDef='BirthDate'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Birth Date</th>
			<td mat-cell *matCellDef='let e'>{{e.BirthDate}}</td>
		</ng-container>
		<ng-container matColumnDef='HireDate'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Hire Date</th>
			<td mat-cell *matCellDef='let e'>{{e.HireDate}}</td>
		</ng-container>
		<ng-container matColumnDef='EmergencyName'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Emergency Name</th>
			<td mat-cell *matCellDef='let e'>{{e.EmergencyName}}</td>
		</ng-container>
		<ng-container matColumnDef='EmergencyPhone'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Emergency Phone</th>
			<td mat-cell *matCellDef='let e'>{{e.EmergencyPhone}}</td>
		</ng-container>
		<ng-container matColumnDef='Notes'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Notes</th>
			<td mat-cell *matCellDef='let e'>{{e.Notes}}</td>
		</ng-container>
		<ng-container matColumnDef='Active'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Active</th>
			<td mat-cell *matCellDef='let e'>{{e.Active}}</td>
		</ng-container>
		<ng-container matColumnDef='Image'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Image</th>
			<td mat-cell *matCellDef='let e'>{{e.Image}}</td>
		</ng-container>
		<ng-container matColumnDef='Signature'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Signature</th>
			<td mat-cell *matCellDef='let e'>{{e.Signature}}</td>
		</ng-container>
		<ng-container matColumnDef='CompanyId'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Company Id</th>
			<td mat-cell *matCellDef='let e'>{{e.CompanyId}}</td>
		</ng-container>
		<ng-container matColumnDef='CompanyName'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Company Name</th>
			<td mat-cell *matCellDef='let e'>{{e.CompanyName}}</td>
		</ng-container>
		<ng-container matColumnDef='Email'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Email</th>
			<td mat-cell *matCellDef='let e'>{{e.Email}}</td>
		</ng-container>
		<ng-container matColumnDef='EmailConfirmed'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Email Confirmed</th>
			<td mat-cell *matCellDef='let e'>{{e.EmailConfirmed}}</td>
		</ng-container>
		<ng-container matColumnDef='PasswordHash'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Password Hash</th>
			<td mat-cell *matCellDef='let e'>{{e.PasswordHash}}</td>
		</ng-container>
		<ng-container matColumnDef='SecurityStamp'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Security Stamp</th>
			<td mat-cell *matCellDef='let e'>{{e.SecurityStamp}}</td>
		</ng-container>
		<ng-container matColumnDef='PhoneNumber'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Phone Number</th>
			<td mat-cell *matCellDef='let e'>{{e.PhoneNumber}}</td>
		</ng-container>
		<ng-container matColumnDef='PhoneNumberConfirmed'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Phone Number Confirmed</th>
			<td mat-cell *matCellDef='let e'>{{e.PhoneNumberConfirmed}}</td>
		</ng-container> 
		<ng-container matColumnDef='TwoFactorEnabled'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Two Factor Enabled</th>
			<td mat-cell *matCellDef='let e'>{{e.TwoFactorEnabled}}</td>
		</ng-container>
		<ng-container matColumnDef='LockoutEndDateUtc'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Lockout End Date Utc</th>
			<td mat-cell *matCellDef='let e'>{{e.LockoutEndDateUtc}}</td>
		</ng-container>
		<ng-container matColumnDef='LockoutEnabled'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Lockout Enabled</th>
			<td mat-cell *matCellDef='let e'>{{e.LockoutEnabled}}</td>
		</ng-container>
		<ng-container matColumnDef='AccessFailedCount'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> Access Failed Count</th>
			<td mat-cell *matCellDef='let e'>{{e.AccessFailedCount}}</td>
		</ng-container>
		<ng-container matColumnDef='UserName'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> User Name</th>
			<td mat-cell *matCellDef='let e'>{{e.UserName}}</td>
		</ng-container>
		<ng-container matColumnDef='BranchNumber'>
			<th mat-header-cell *matHeaderCellDef mat-sort-header class='col-100'> BranchNumber</th>
			<td mat-cell *matCellDef='let e'>{{e.BranchNumber}}</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
		<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
	</table>
</div>
<mat-paginator (page)="pageSizeValueChanged($event.pageSize.toString())" [length]='recordTotal' [pageSize]='pageSize'  [pageSizeOptions]="applicationSettings.getPageSizes()"></mat-paginator>

<app-loading *ngIf="progressStatus"></app-loading> 