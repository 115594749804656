import { Injectable } from '@angular/core';
import { Observer } from 'rxjs';
import { Observable, Subject } from 'rxjs-compat';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { catchError, map, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

const CHAT_URL = "ws://localhost:5001/chathub";

export interface Message {
	source: string;
	content: any[];
	message: string;
	function: string;
}

@Injectable({
	providedIn: 'root'
})
export class Machine16PLCService {

	trnsfrData = {
		source: '',
		content: [],
		message: '',
		function: ''
	};

	public serverStatus = false;
	private subject: AnonymousSubject<MessageEvent>;
	public messages: Subject<Message>;
	private serviceId: string;
    private machine="Machine 16";
	private isClientRegistered = false;

	ip = "";
	port = "";
	modbusData: any;
	private timer: any;
	servTempArr = [];
	cncStatusLogCnt = 0;
	clientName: any;

	GetToCNCSubject : Subject<any> = new Subject<any>() ;
	GetFromCNCSubject : Subject<any> = new Subject<any>() ;
	
	GetServerStatusSubject: Subject<any> = new Subject<any>() ;
	GetDeviceErrorSubject : Subject<any> = new Subject<any>() ;

	GetCellStatusSubject : Subject<any> = new Subject<any>() ;
	GetPartsSubject  : Subject<any> = new Subject<any>() ;
	GetCncStatusLogSubject : Subject<any> = new Subject<any>() ;
	GetMacAddressSubject : Subject<any> = new Subject<any>() ;

	constructor() { 
		this.serviceId = uuidv4();
		console.log('Service ID:', this.serviceId);
	}

	private create(url): AnonymousSubject<MessageEvent> {
		let ws = new WebSocket(url);
		ws.onopen = openevent => {
			console.log("Successfully connected: " + url);
			this.serverStatus = true;
			this.GetServerStatusSubject.next(this.serverStatus);
			this.trnsfrData = {
				source: this.clientName,
				content: [],
				message: 'register client',
				function: 'registerAngularClient'
			};

			this.trnsfrData.content.push(this.ip);
			this.trnsfrData.content.push(this.port);

			this.messages.next(this.trnsfrData);
			this.trnsfrData = {
				source: this.clientName,
				content: [],
				message: 'Get Mac Address',
				function: 'getMacAddress'
			};
			this.messages.next(this.trnsfrData);
		}

		let observable = new Observable((obs: Observer<MessageEvent>) => {
			ws.onmessage = obs.next.bind(obs);
			ws.onerror = obs.error.bind(obs);
			ws.onclose = obs.complete.bind(obs);
			return ws.close.bind(ws);
		});
		let observer = {
			error: null,
			complete: null,
			next: (data: Object) => {  //sending messge to websocket
				//console.log('Message sent to websocket: ', data);
				if (ws.readyState === WebSocket.OPEN) {
					ws.send(JSON.stringify(data));
				}
				else {
					this.serverStatus = false;
					this.GetServerStatusSubject.next(this.serverStatus);
				}
			}
		};
		return new AnonymousSubject<MessageEvent>(observer, observable);
	}

	public connect(url): AnonymousSubject<MessageEvent> {
		this.subject = this.create(url);
		console.log(this.subject)
		return this.subject;
	}

	connectServer() {
		this.isClientRegistered = false;
		this.clientName=this.machine + " client " + this.serviceId;
		
		this.messages = <Subject<Message>>this.connect(CHAT_URL).pipe(
			map(
				(response: MessageEvent): Message => {
					let data = JSON.parse(response.data)
					return data;
				}
			),
			catchError(error => { throw error }),
			tap({
				error: error => console.log('Error in WebSocket:', error),
				complete: () => {
					this.serverStatus = false;
				}
			})
		);
		
		this.messages.subscribe(msg => {
			if (msg.function == "receiveFromModbus") {
				console.log("responsefromlocalglhose");
				this.modbusData = msg.content[0];
			}
			else if (msg.function == "receiveFromLargePLC") {

				this.cncStatusLogCnt = this.cncStatusLogCnt + 1;// for cncstatus log cnt
				let arrData = [];
				let parts = [];

				arrData = JSON.parse(msg.content[0]);
				this.SetCellStatus(arrData[4]);

				let arrCol1 = [];
				let arrCol2 = [];

				let col1 = arrData[14];//bits from #14
				arrCol1 = this.getBinaryNum(col1);//first column array 1 to 16
				let col2 = arrData[10];//bits from #10
				arrCol2 = this.getBinaryNum(col2);//second column array 1 to 16
				let col3 = arrData[11];//bits from #11
				let arr3 = this.getBinaryNum(col3);//second column array 5,6
				//console.log(arrData[11]);
				arrCol2[16] = arr3[5];
				arrCol2[17] = arr3[6];
				this.GetToCNCSubject.next(arrCol1);
				this.GetFromCNCSubject.next(arrCol2);

				parts.push(arrData[5]);
				parts.push(arrData[6]);
				parts.push(arrData[7]);

				this.GetPartsSubject.next(parts);

				this.GetDeviceErrorSubject.next(true);

				if (this.cncStatusLogCnt > 10) {//insert cncstatuslog after 10 count
					let obj = {
						"Machine": "",
						"Robotword1": 0,
						"Robotword2": 0,
						"Cncword1": 0,
						"Cncword2": 0,
						"Cncword3": 0
					}
					obj.Machine = this.machine;
					obj.Robotword1 = arrData[4];
					obj.Robotword2 = 0;
					obj.Cncword1 = col1;
					obj.Cncword2 = col2;
					obj.Cncword3 = col3;
					this.GetCncStatusLogSubject.next(obj);
					this.cncStatusLogCnt = 0;
				}

			}
			else if (msg.function == "receiveMacAddress") {
				console.log(msg);
				var macaddress = (msg.content[0]);
				this.GetMacAddressSubject.next(macaddress);
			}
			else if (msg.function == "errorMessage") {
				console.error(msg.message);
				this.GetDeviceErrorSubject.next(false);

			}
			else {
				console.log("Response from websocket: " + msg.message);
			}
		});
	}

	SetCellStatus(inData: number) {

		var retVal = [];
		var binArray = this.getBinaryNum(inData);

		//console.log('SetCellStatus');
		//console.log(inData);
		//console.log(binArray);

		retVal.push(this.GetDrawerStatus(binArray[0], binArray[1])); //drawer 1
		retVal.push(this.GetDrawerStatus(binArray[2], binArray[3])); //drawer 2
		retVal.push(this.GetDrawerStatus(binArray[4], binArray[5])); //drawer 3
		retVal.push(this.GetDrawerStatus(binArray[6], binArray[7])); //drawer 4

		retVal.push(this.GetBinaryStatus(binArray[8])); //Last Part Now
		retVal.push(this.GetBinaryStatus(binArray[9])); //Last Part After

		retVal.push(this.GetBinaryStatus(binArray[10]));  //Robot Fault
		retVal.push(this.GetBinaryStatus(binArray[11]));  //TP Enabled
		retVal.push(this.GetBinaryStatus(binArray[12]));  //Cycle Start
		retVal.push(this.GetBinaryStatus(binArray[15]));  //Step Mode

		//console.log("SetCellStatus");
		//console.log('Service ID:', this.serviceId);

		this.GetCellStatusSubject.next(retVal);
	}
	GetBinaryStatus(bit): string {
		if (bit == 0)
			return 'off';
		else
			return 'on';
	}

	GetDrawerStatus(bit1, bit2): string {
		if (bit1 == 0 && bit2 == 0)
			return 'Loaded';

		if (bit1 == 1 && bit2 == 0)
			return 'Un-loaded';

		if (bit1 == 0 && bit2 == 1)
			return 'Complete';

		return 'error';
	}

	stopReadModbusValues(): void {
		clearInterval(this.timer);
	}

	startReadModbusValues() {
		this.timer = setInterval(() => {
			//console.log('Reading local service');
			this.getModbusData();
		}, 1000);
	}
	getModbusData() {
		this.trnsfrData = {
			source: this.clientName,
			content: [],
			message: 'Reading From LargePLC',
			function: 'readFromLargePLC'
		};
		this.trnsfrData.content.push(this.ip);
		this.trnsfrData.content.push(this.port);
		this.trnsfrData.content.push(!this.isClientRegistered);
		this.messages.next(this.trnsfrData);
		this.isClientRegistered = true;
	}

	genericSetPulseMessage(Address) {
		this.sendMessage(Address, '1');
		setTimeout(() => {
			//console.log('wiating: ' + Address + 'v:' + Value);
			this.genericResetMessage(Address);
		}, 1000)
	}
	
	
	
	genericValueMessage(Address, value) {
		this.sendMessage(Address, value);
	}

	private sendMessage(Address, value){
		this.trnsfrData = {
			source: this.clientName,
			content: [],
			message: 'Write Reset Data',
			function: 'writeResetData'
		};
		this.trnsfrData.content.push(value);//value
		this.trnsfrData.content.push(Address);//address
		this.trnsfrData.content.push(this.ip);
		this.trnsfrData.content.push(this.port);
		this.messages.next(this.trnsfrData);

	
	}
	
	genericResetMessage(Address) {
		this.trnsfrData = {
			source: this.clientName,
			content: [],
			message: 'Write Reset Data',
			function: 'writeResetData'
		};
		this.trnsfrData.content.push('0');//value
		this.trnsfrData.content.push(Address);//address
		this.trnsfrData.content.push(this.ip);
		this.trnsfrData.content.push(this.port);
		this.messages.next(this.trnsfrData);
	}
	

	getBinaryNum(numdata) {
		//console.log(numdata);
		var binData = (numdata).toString(2);//integer converted to binary string 
		let binArr = [];
		for (let i = binData.length - 1; i >= 0; i--) {//converting binary string to binary array from right to left
			binArr.push(binData[i]);
		}

		while (binArr.length < 16) {
			binArr.push(0);
		}
		return binArr;
	}
}
