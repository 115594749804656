
<div class='panel panel-primary animaPlaceholder'>
	<div class='panel-heading'>
	<div class='row'>
		<div class='col-md-7'>
			{{pageTitle}}
		</div>
		<div class='col-lg-1 col-md-1 col-xs-4 options-bar'>
			<label>Rows</label>&nbsp;&nbsp;
			<select [(ngModel)]='pageSize' (change)='pageSizeValueChanged()'>
				<option *ngFor='let item of applicationSettings.pageSizes'>{{item}}</option>
			</select>
		</div>
		<div class='col-lg-2 col-md-2 col-xs-4 options-bar'>
			<button class='btn btn-default btn-sm center-block btn-full' [routerLink]="['/training/lesson-details',0, coursesId ]" [disabled]="progressStatus||progressCoursesStatus">Add New Lesson</button>
		</div>
		
		<div class='col-lg-1 col-md-1 col-xs-4 options-bar'>
			<button class='btn btn-default btn-sm center-block btn-full' (click)='searchClick()' [disabled]="progressStatus">Search</button>
		</div>
		<div class='col-lg-1 col-md-1 col-xs-4 options-bar'>
			<button class='btn btn-default btn-sm center-block btn-full' (click)='searchClearClick()' [disabled]="progressStatus">Clear-Search</button>
		</div>
	</div>
	</div>

	<div class='panel-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='excelFile'>
					<button class='btn btn-primary btn-sm' (click)='hideFileLink()'>hide link</button>
					<span>excel File:<a href = "javascript:void(0)"(click) = "openExcelFile()" > {{ excelFile}}</a></span>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<div class='row filters' *ngIf='showHideSearch'>
		<div class='col-md-12'>
			<div class='row'>
			<!--<div class='col-md-4'>
				<label class='inline-label'> Courses</label>
				<mat-select class="form-control" [(ngModel)]='CoursesId'>
					<mat-option [value]="">All courses</mat-option>
					@for (item of coursesList; track item) {
					  <mat-option [value]="item.Id">{{item.Name}}</mat-option>
					}
				</mat-select>
			</div>-->
			<div class='col-md-4'>
				<label class='inline-label'> Name</label>
				<input type='text' class='form-control block-element' placeholder=' Name' [(ngModel)]='Name' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Description</label>
				<input type='text' class='form-control block-element' placeholder=' Description' [(ngModel)]='Description' />
			</div>
			</div>
		</div>
		</div>
	</div>
</div>

<div class='table-container'>
<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
	<ng-container matColumnDef='Id'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
		<td mat-cell *matCellDef='let e'>
			<a class='btn btn-default' style='min-width: 80px;' [routerLink]="['/training/lesson-details',e.Id, coursesId ]"  >{{e.Id}}</a>
		</td>
	</ng-container>
	<ng-container matColumnDef='CourseName'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'> Course Name</th>
		<td mat-cell *matCellDef='let e' class='col2' >{{e.CourseName}}</td>
	</ng-container>
	<ng-container matColumnDef='Name'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Name</th>
		<td mat-cell *matCellDef='let e' class='col3' >{{e.Name}}</td>
	</ng-container>
	<ng-container matColumnDef='Description'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> Description</th>
		<td mat-cell *matCellDef='let e' class='col4' >{{e.Description}}</td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
	<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
</div>
<mat-paginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>
<div>
<mat-spinner color="primary" class="spinner" *ngIf="progressStatus||progressCoursesStatus"></mat-spinner>
</div>