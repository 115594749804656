import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { ITestcontent} from './Testcontent-model/Testcontent';
import { ITestcontents } from './Testcontent-model/Testcontents';
import { ITestcontentSearch } from './Testcontent-model/TestcontentSearch';

import { ApplicationSettings } from '../../shared/application-settings';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';

@Injectable()
export class TestcontentService {
	private baseUrl;
	public pageTitle = 'TestContents List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Testcontents';
	}

	getTestcontent(id: number):  Observable<ITestcontent> {
		if (id === 0) {
			return of(this.initializeTestcontent());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<ITestcontent>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveTestcontent(testcontent: ITestcontent): Observable<any> {
		if (testcontent.Id === 0) {
			return this.createTestcontent(testcontent);
		}
		return this.updateTestcontent(testcontent);
	}

	private createTestcontent(testcontent: ITestcontent): Observable<any> {
		testcontent.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/testcontentcreate', testcontent)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateTestcontent(testcontent: ITestcontent): Observable<ITestcontent> {
		return this.authHttp.put<ITestcontent>(this.baseUrl, testcontent)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteTestcontent(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeTestcontent(): ITestcontent {
		return {
			Id: 0,
			ContentId: 0,
			Status: '',
			Notes: '',
			ReviewedDate: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as ITestcontent;
	}

	public getRecordSet(search: ITestcontentSearch): Observable<ITestcontents> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('ContentId', search.ContentId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Status', search.Status, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Notes', search.Notes, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('ReviewedDate', search.ReviewedDate, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('ContentId', search.ContentId ? search.ContentId : '')
			.set('Status', search.Status ? search.Status : '')
			.set('Notes', search.Notes ? search.Notes : '')
			.set('ReviewedDate', search.ReviewedDate ? search.ReviewedDate : '')
		};

		return this.authHttp.get<ITestcontents>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<ITestcontents>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : ITestcontentSearch { 
		let search: ITestcontentSearch = {
			Id: 1,
			ContentId: '',
			Status: '',
			Notes: '',
			ReviewedDate: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.error.ErrorDescription}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: ITestcontentSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('ContentId', search.ContentId ? search.ContentId : '')
			.set('Status', search.Status ? search.Status : '')
			.set('Notes', search.Notes ? search.Notes : '')
			.set('ReviewedDate', search.ReviewedDate ? search.ReviewedDate : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
}