<section class="about">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-12 ">
        <div class="col-sm-12 col-xs-12 col-12 text-center">
          <h1>Contact Us</h1>
          <h2>We would love to listen to your ideas and opportunities.</h2>
        </div>
      </div>
    </div>
  </div>
</section>

<section>
  <div class="card" *ngIf="!formSubmitStatus" class="text-center">
    <div class='row'>
      <div class='col-md-12'>
        <div class='has-error' class='text-danger' *ngIf='errorMessage'>
          <button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
          {{ errorMessage }}
        </div>
      </div>
    </div> 
    <div class="row" style="display: flex; justify-content: center;">
      <div class="col-md-8" class="contact-container">
        <div class="row" style="width: 100%;">
          <div class="col-sm-12 col-md-7" style="margin-bottom: 16px;">
            <div class="row">
              <form class='form-horizontal' novalidate [formGroup]='contactForm' style="padding-top: 20px;">
                <fieldset>
                  <div class='card-body'>
                 
                    <div class="row">
                      <p class="description">Online Inquiry</p>
                      
                      
                      <div class='row mt-2'>
                        <label class='col-md-4 form-label'>First Name</label>
                          <div class='col-md-8'>
                            <mat-form-field appearance="fill">
                              <input type="text" class="box-size" matInput formControlName='FirstName' placeholder="First Name">
                              <mat-error *ngIf="contactForm.controls['FirstName'].invalid && contactForm.controls['FirstName'].errors.required">First Name is <strong>required</strong></mat-error>
                            </mat-form-field>  
                        </div>
                      </div>
                    
                      <div class='row mt-2'>
                        <label class='col-md-4 form-label'>Last Name</label>
                          <div class='col-md-8'>
                            <mat-form-field appearance="fill">
                              <input type="text" class="box-size" matInput formControlName='LastName' placeholder="Last Name">
                              <mat-error *ngIf="contactForm.controls['LastName'].invalid && contactForm.controls['LastName'].errors.required">Last Name is <strong>required</strong></mat-error>
                            </mat-form-field>  
                        </div>
                      </div>
                      
                      <div class='row mt-2'>
                        <label class='col-md-4 form-label'>Email</label>
                          <div class='col-md-8'>
                            <mat-form-field appearance="fill">
                              <input type="email"class="box-size" matInput formControlName='Email' placeholder="Email">
                              <mat-error *ngIf="contactForm.controls['Email'].invalid && contactForm.controls['Email'].errors.required">Email is <strong>required</strong></mat-error>
                              <mat-error *ngIf="contactForm.controls['Email'].invalid && !contactForm.controls['Email'].errors.required">Please enter a valid email address</mat-error>                            
                            </mat-form-field>  
                        </div>
                      </div>
                   
                      <div class='row mt-2'>
                        <label class='col-md-4 form-label'>Phone</label>
                          <div class='col-md-8'>
                            <mat-form-field appearance="fill">
                              <input type="tel" class="box-size" matInput formControlName='Phone' placeholder="1234567890">
                              <mat-error *ngIf="contactForm.controls['Phone'].invalid">Phone is not valid</mat-error>
                            </mat-form-field>  
                        </div>
                      </div>

                      <div class='row mt-2'>
                        <label class='col-md-4 form-label'>Company</label>
                          <div class='col-md-8'>
                            <mat-form-field appearance="fill">
                              <input type="text" class="box-size" matInput formControlName='Company' placeholder="Company">
                            </mat-form-field>  
                        </div>
                      </div>
                   
                      <div class='row mt-2'>
                        <label class='col-md-4 form-label'>Message</label>
                          <div class='col-md-8'>
                            <mat-form-field class="box-size" appearance="outline">
                              <textarea matInput matInput formControlName='Message' placeholder="Message" rows="6" style="padding: 5px;"></textarea>
                            </mat-form-field>  
                        </div>
                      </div>
                     
                                          
                      <span style="width: 100%;">
                        <button class="button" color="primary"
                          [disabled]='!contactForm.valid'
                          (click)="saveContact()">
                             Submit
                        </button>
                      </span>
                    </div>
                  
                  
                  
                  </div>
                </fieldset>
              </form>
            </div>
          </div>




          <div class="col-sm-12 col-md-5" style="padding: 24px;">
            <div class="details-container">
              <div class="details">
                <div class="details-title">
                  General Inquiries
                </div>
                <div class="details-option">
                  <a style="color: black; text-decoration: none" href="mailto:sales@eiar.ca">sales&#64;eiar.ca</a>
                </div>
                <div class="details-option">
                  <a style="color: black; text-decoration: none" href="tel:780-292-2501">780-292-2501</a>
                </div>
              </div>
            </div>
            <div class="details-container">
              <div class="details">
                <div class="details-title">
                  Location
                </div>
                <div class="details-option">
                  Edmonton, Alberta
                </div>
              </div>
            </div>
            <div class="details-container">
              <div class="details">
                <div class="details-title">
                  Business Hours
                </div>
                <div class="details-option">
                  Our regular business hours are from 7AM to 4PM MST.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="formSubmitStatus" class="text-center" style="margin: 50px;">
    <h1><span class="text-success">Thank you for getting in touch!</span></h1>
    <br>
    We appreciate you contacting EIAR. One of our representative will get back in touch with you soon! Have a great day!
  </div>
</section>
