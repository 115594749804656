import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControlName, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IAnswer } from '../answer-model/answer';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';
import { Subscription } from 'rxjs/Subscription';
import { AnswerService } from '../answer-service';
import { IAnswers } from '../Answer-model/Answers';
import { IAnswerSearch } from '../Answer-model/AnswerSearch';


import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { QuestionService } from '../../questions/question-service';
import { ApplicationSettings } from '../../../shared/application-settings';
import { IUsersearch } from '../../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../../admin/usersearches/usersearch-service';

@Component({
	templateUrl: './answer-edit.component.html',
	styleUrls: ['answer-edit.component.css']
})

export class AnswerEditComponent implements OnInit, OnDestroy {
	@ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

	public page = 'Edit Answers';
	errorMessage: string;
	answerForm: FormGroup;
	answer: IAnswer;
	private sub: Subscription;
	public buttonPushed = false;
	public record: number = 0;
	public recordTotal: number = 0;
	public UserSearches: IUsersearch[];
	public AnswerSearch: IAnswerSearch;
	public ListData: IAnswers;
	public showNextRecordButton: boolean = true;
	public progressStatus=false;

	public lessonId: number;
	public courseId: number;
	public questionId: number;

	public questionsList;
	public progressQuestionsStatus=false;
	public Editor: any = ClassicEditor;

	constructor(private fb: FormBuilder
		, private route: ActivatedRoute
		, private userSearchService: UsersearchService
		, private router: Router
		, private answerService: AnswerService
		, private questionService: QuestionService
		, public settings: ApplicationSettings
		) {
	}

	ngOnInit(): void {
		this.answerForm = this.fb.group({
			Id: '',
			QuestionId: '',
			Name: ['',[Validators.maxLength(250)]],
			Description: [''],
			Result: ['',[Validators.maxLength(256)]],
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		});
		this.getQuestions();
		this.sub = this.route.params.subscribe(
			params => {
				const id = +params['Id'];
				this.courseId = +params['courseId'];
				this.lessonId = +params['lessonId'];
				this.questionId = +params['questionId'];
				this.getAnswer(id);
				this.progressStatus = true;
			}
		);
	}

	public getSelectedRecordSet():void{
		this.answerService.getRecordSet(this.AnswerSearch)
			.subscribe((obj: IAnswers) => this.onSelectedRecordSetReceived(obj),
			(error: any) => {
				this.errorMessage = < any > error;
		});
	}

	private onSelectedRecordSetReceived(obj: IAnswers){
		this.ListData = obj;
		this.recordTotal = obj.total;
		let count = 0;
		let record = 0;
		obj.data.forEach(el => {
			count += 1;
			if (el.Id == this.answer.Id)
				record = count;
		});

		this.record = +obj.skip + record;

		if (this.record == obj.total)
			this.showNextRecordButton = false;
		else
			this.showNextRecordButton = true;

		this.progressStatus = false;

	}

	saveAnswer(returnToList: boolean, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		this.buttonPushed = true;
		this.progressStatus = true;

		if (this.answerForm.valid) {
			const obj = Object.assign({ }, this.answer, this.answerForm.value);
			this.answerService.saveAnswer(obj)
				.subscribe((data) => this.onSaveComplete(returnToList, data, advanceToNextRecord,addNewRecord)
				, (error: any) => this.errorMessage = < any > error);
		}
	}

	onSaveComplete(returnToList: boolean, data: any, advanceToNextRecord: boolean,addNewRecord:boolean): void {
		if (returnToList) {
			this.answerForm.reset();
			this.router.navigate(['/training/question-details', this.questionId, this.courseId , this.lessonId]);
		} else {
			// when the record id is zero then it is a new record.
			if (data !== null && this.answer.Id === 0) {
				this.router.navigate(['/training/answer-details', data.Id, this.courseId, this.lessonId, this.questionId]);
			} else {
				if (advanceToNextRecord) {
					this.advanceToNextRecord();
				}
			}
		}

		this.buttonPushed = false;
	}

	advanceToNextRecord(): void {
		console.log('advanceToNextRecord()');

		let count = 0;
		let record = 0;

		this.ListData.data.forEach(el => {
			count += 1;
			if (el.Id == this.answer.Id)
				record = count;
		});

		this.getAnswer(this.ListData.data[record].Id)
	}

	getAnswer(id: number): void {
		this.answerService.getAnswer(id)
			.subscribe((obj: IAnswer) => this.onAnswerRetrieved(obj),
		(error: any) => {
		this.errorMessage = <any>error;
		// this.spinner.stop();
		});
	}

	onAnswerRetrieved(answer: IAnswer): void {
		if (this.answerForm) {
			this.answerForm.reset();
		}
		this.answer = answer;
		if (this.answer.Id === 0) {
			this.answer.QuestionId = this.questionId;
			this.page = 'Add Answers';
		} else {
			this.page = 'Edit Answers';
		}

		this.answerForm.patchValue({
			Id: this.answer.Id,
			QuestionId: this.answer.QuestionId,
			Name: this.answer.Name,
			Description: this.answer.Description,
			Result: this.answer.Result,
			CreatedDate: this.answer.CreatedDate,
			CreatedBy: this.answer.CreatedBy,
			UpdatedDate: this.answer.UpdatedDate,
			UpdatedBy: this.answer.UpdatedBy,
		});

		this.getUserSearchData();
	}

	ngOnDestroy(): void {
	}

	deleteAnswer(): void {
		this.buttonPushed = true;

		if (confirm(`Are you sure you want to delete the item: ${this.answer.QuestionId}?`)) {
			this.answerService.deleteAnswer(this.answer.Id)
				.subscribe(() => this.onSaveComplete(true, null, false,false)
				, (error: any) => this.errorMessage = <any>error);
		} else {
			this.buttonPushed = false;
		}
	}

	cancel(): void {
		//this.router.navigate(['/training/answers']);
		this.router.navigate(['/training/question-details', this.questionId, this.courseId , this.lessonId]);
	}

	public getUserSearchData(): void {
		this.userSearchService.getUserSearchesByForm('Answers List')
			.subscribe((data) => {
			this.UserSearches = data;
			this.setUserFilters();
		}
		, (error: any) => this.errorMessage = < any > error);
	}

	setUserFilters(): void {
		this.AnswerSearch = this.answerService.getFreshSearch();
		this.UserSearches.forEach(i => {
			if (i.Item == 'QuestionId')
				this.AnswerSearch.QuestionId = i.Value;

			if (i.Item == 'Name')
				this.AnswerSearch.Name = i.Value;

			if (i.Item == 'Description')
				this.AnswerSearch.Description = i.Value;

			if (i.Item == 'Result')
				this.AnswerSearch.Result = i.Value;

			if (i.Item == 'orderByString')
				this.AnswerSearch.orderByString = i.Value;

			if (i.Item == 'Skip')
				this.AnswerSearch.skip = i.Value;

		});
		this.getSelectedRecordSet();
	}


	resetError() : void {
		this.errorMessage = '';
		this.buttonPushed = false;
	}

	getQuestions() {
		this.questionService.getAll().subscribe(response => {
			this.questionsList = response;
			this.progressQuestionsStatus = false;
		},(error: any) => {
			this.progressQuestionsStatus = true;
			this.errorMessage = <any> error;
		});
	}
}
