import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material-module'

import { GreenListComponent } from './green-list/green-list.component';
import { GreenEditComponent } from './green-edit/green-edit.component';
import { GreenService } from './green-service';

@NgModule({
	declarations: [
		GreenListComponent
		, GreenEditComponent
	],
	imports: [
		MaterialModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		CommonModule,
		RouterModule.forChild([
			{ path: 'greens', component: GreenListComponent },
			{ path: 'green-details/:Id', component: GreenEditComponent },
		])
	],
	providers: [
		GreenService
	]
})
export class GreenModule { }
