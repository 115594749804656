import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { ITest} from './Test-model/Test';
import { ITests } from './Test-model/Tests';
import { ITestSearch } from './Test-model/TestSearch';

import { ApplicationSettings } from '../../shared/application-settings';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';

@Injectable()
export class TestService {
	private baseUrl;
	public pageTitle = 'Tests List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Tests';
	}

	getTest(id: number):  Observable<ITest> {
		if (id === 0) {
			return of(this.initializeTest());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<ITest>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveTest(test: ITest): Observable<any> {
		if (test.Id === 0) {
			return this.createTest(test);
		}
		return this.updateTest(test);
	}

	private createTest(test: ITest): Observable<any> {
		test.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/testcreate', test)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateTest(test: ITest): Observable<ITest> {
		return this.authHttp.put<ITest>(this.baseUrl, test)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteTest(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeTest(): ITest {
		return {
			Id: 0,
			LessonsId: 0,
			Employee: '',
			ScoreNumerator: 0,
			ScoreDenominator: 0,
			Notes: '',
			LessonStartDate: '',
			LessonEndDate: '',
			QuizStartDate: '',
			QuizEndDate: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as ITest;
	}

	public getRecordSet(search: ITestSearch): Observable<ITests> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('LessonsId', search.LessonsId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Employee', search.Employee, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('ScoreNumerator', search.ScoreNumerator, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('ScoreDenominator', search.ScoreDenominator, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Notes', search.Notes, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('LessonStartDate', search.LessonStartDate, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('LessonEndDate', search.LessonEndDate, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('QuizStartDate', search.QuizStartDate, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('QuizEndDate', search.QuizEndDate, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('LessonsId', search.LessonsId ? search.LessonsId : '')
			.set('Employee', search.Employee ? search.Employee : '')
			.set('ScoreNumerator', search.ScoreNumerator ? search.ScoreNumerator : '')
			.set('ScoreDenominator', search.ScoreDenominator ? search.ScoreDenominator : '')
			.set('Notes', search.Notes ? search.Notes : '')
			.set('LessonStartDate', search.LessonStartDate ? search.LessonStartDate : '')
			.set('LessonEndDate', search.LessonEndDate ? search.LessonEndDate : '')
			.set('QuizStartDate', search.QuizStartDate ? search.QuizStartDate : '')
			.set('QuizEndDate', search.QuizEndDate ? search.QuizEndDate : '')
		};

		return this.authHttp.get<ITests>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<ITests>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : ITestSearch { 
		let search: ITestSearch = {
			Id: 1,
			LessonsId: '',
			Employee: '',
			ScoreNumerator: '',
			ScoreDenominator: '',
			Notes: '',
			LessonStartDate: '',
			LessonEndDate: '',
			QuizStartDate: '',
			QuizEndDate: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.error.ErrorDescription}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: ITestSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('LessonsId', search.LessonsId ? search.LessonsId : '')
			.set('Employee', search.Employee ? search.Employee : '')
			.set('ScoreNumerator', search.ScoreNumerator ? search.ScoreNumerator : '')
			.set('ScoreDenominator', search.ScoreDenominator ? search.ScoreDenominator : '')
			.set('Notes', search.Notes ? search.Notes : '')
			.set('LessonStartDate', search.LessonStartDate ? search.LessonStartDate : '')
			.set('LessonEndDate', search.LessonEndDate ? search.LessonEndDate : '')
			.set('QuizStartDate', search.QuizStartDate ? search.QuizStartDate : '')
			.set('QuizEndDate', search.QuizEndDate ? search.QuizEndDate : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
}