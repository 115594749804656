import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { ITestresult} from './Testresult-model/Testresult';
import { ITestresults } from './Testresult-model/Testresults';
import { ITestresultSearch } from './Testresult-model/TestresultSearch';

import { ApplicationSettings } from '../../shared/application-settings';
import { IUsersearch } from '../../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../../admin/usersearches/usersearch-service';

@Injectable()
export class TestresultService {
	private baseUrl;
	public pageTitle = 'TestResults List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Testresults';
	}

	getTestresult(id: number):  Observable<ITestresult> {
		if (id === 0) {
			return of(this.initializeTestresult());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<ITestresult>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveTestresult(testresult: ITestresult): Observable<any> {
		if (testresult.Id === 0) {
			return this.createTestresult(testresult);
		}
		return this.updateTestresult(testresult);
	}

	private createTestresult(testresult: ITestresult): Observable<any> {
		testresult.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/testresultcreate', testresult)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateTestresult(testresult: ITestresult): Observable<ITestresult> {
		return this.authHttp.put<ITestresult>(this.baseUrl, testresult)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteTestresult(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeTestresult(): ITestresult {
		return {
			Id: 0,
			TestId: 0,
			AnswerId: 0,
			Result: '',
			Notes: '',
			Answer: '',
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as ITestresult;
	}

	public getRecordSet(search: ITestresultSearch): Observable<ITestresults> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('TestId', search.TestId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('AnswerId', search.AnswerId, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Result', search.Result, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Notes', search.Notes, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Answer', search.Answer, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, this.pageTitle));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), this.pageTitle));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('TestId', search.TestId ? search.TestId : '')
			.set('AnswerId', search.AnswerId ? search.AnswerId : '')
			.set('Result', search.Result ? search.Result : '')
			.set('Notes', search.Notes ? search.Notes : '')
			.set('Answer', search.Answer ? search.Answer : '')
		};

		return this.authHttp.get<ITestresults>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<ITestresults>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : ITestresultSearch { 
		let search: ITestresultSearch = {
			Id: 1,
			TestId: '',
			AnswerId: '',
			Result: '',
			Notes: '',
			Answer: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} else {
		message = `Error Code: ${err.status}\nMessage: ${err.error.ErrorDescription}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: ITestresultSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('TestId', search.TestId ? search.TestId : '')
			.set('AnswerId', search.AnswerId ? search.AnswerId : '')
			.set('Result', search.Result ? search.Result : '')
			.set('Notes', search.Notes ? search.Notes : '')
			.set('Answer', search.Answer ? search.Answer : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
}