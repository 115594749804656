
<body>
	<header>
	</header>
	<div class="row">
		<section class="about">
			<div class="col-md-12" align="center">
				<h1>Edmonton Integration and Robotics</h1>
			</div>
			<div class="col-md-12" align="center">
				<I>My name is Craig Duguid and my passion is to</I>
			</div>
			<div class="col-md-12" align="center">
				<I>solve business problems with innovation and integration.</I>
			</div>
			<div class="col-md-12">
				<br />
				<br />
				<br />
			</div>
		</section>
	</div>
	<section class="about">
		<div class="container">
			<div class="row">
					<div class="col-md-5 ">
						<img src="../assets/eiarshop.jpg" alt="FANUC Robot Arm" />
					</div>
					<div class="col-md-7 col-12 text-center">
						<h1 class="text-center">Innovation</h1>
						<h1 class="text-center">Integration</h1>
						<h1 class="text-center">Impact</h1>
						<p>We are in the business of turning opportunities into revenue generating solutions through 
							innovation and integration.</p>
						<a [routerLink]="['/about-us']" class="btn-hmabt">Learn More</a>
					</div>
			</div>
		</div>
	</section>
	<section class="services my-5" >
		<div class="container">
			<div class="row text-center">
				<div class="col-md-12 col-12">
				<h1 class="text-center">SERVICES</h1>
				<p class="text-center">We offer a wide variety of software and database development. We 
					can provide your business with a variety of hardware automation, PLC Programing and 
					<a target="_blank" href="https://www.fanucamerica.com/product/robots/">FANUC</a> Robotics 
					equipment and service.</p>
				<h3 class="text-center">What can we do for you? Do you have an opportunity that you know would make money for your business?</h3>
				<a [routerLink]="['/services']" class="btn-hmsrc text-center">Learn More</a>
			</div>
			</div>
		</div>
	</section>
	<header-version></header-version> 
</body>