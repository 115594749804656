
<div class='panel panel-primary animaPlaceholder' *ngIf='job'>
	<div class='panel-heading'>
		<span *ngIf = 'job' >{{ page }} :{{ job.Id }}</span>
		<span class= 'pull-right'>
			{{ record }} of {{ recordTotal }}
		</span>
	</div>
	<div class= 'panel-body'>
		<div class= 'row'>
			<div class= 'col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>
		<div class= 'row'>
			<div class= 'col-md-6'>
				<form class='form-horizontal' novalidate [formGroup]='jobForm'>
					<fieldset>
						<div class='panel-body'>

							<div class='form-group'>
								<label class='col-md-2 control-label'>Company</label>
								<div class='col-md-10'>
									<ng-select
										formControlName='CompanyId'
										placeholder="Select company...">
										<ng-option [value]="0"></ng-option>
										<ng-option *ngFor="let company of companies" [value]="company.Id">
											{{company.CompanyName}}
										</ng-option>
									</ng-select>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'>Schedule</label>
								<div class='col-md-10'>
									<ng-select
										formControlName='ScheduleId'
										placeholder="Select schedule..."
										(change)="onScheduleChange($event)">
										<ng-option [value]="0"></ng-option>
										<ng-option *ngFor="let schedule of schedules" [value]="schedule.Id">
											{{schedule.Title}}
										</ng-option>
									</ng-select>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'>Part</label>
								<div class='col-md-10'>
									<ng-select
										formControlName='PartId'
										placeholder="Select part...">
										<ng-option [value]="0"></ng-option>
										<ng-option *ngFor="let part of parts" [value]="part.Id">
											{{part.PartNumber}}
										</ng-option>
									</ng-select>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'>Job Number</label>
								<div class='col-md-10'>
									<input class='form-control' type='text' formControlName='JobNumber' />
									<span *ngIf = "jobForm.controls['JobNumber'].invalid  && jobForm.controls['JobNumber'].errors.required" class="text-danger">
											Must include job number.
									</span>
									<span *ngIf = "jobForm.controls['JobNumber'].invalid  && jobForm.controls['JobNumber'].errors.maxlength" class="text-danger">
											Only 255 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Description</label>
								<div class='col-md-10'>
									<input class='form-control' type='text' formControlName='Description' />
									<span *ngIf = "jobForm.controls['Description'].invalid  && jobForm.controls['Description'].errors.maxlength" class="text-danger">
											Only 1000 characters are allowed.
									</span>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Quantity</label>
								<div class='col-md-10'>
									<input class='form-control' type='number' formControlName='Quantity' />
									<span *ngIf = "jobForm.controls['Quantity'].invalid  && jobForm.controls['Quantity'].errors.min" class="text-danger">
											Must be greater than 0.
									</span>
								</div>
							</div>
							<div class='form-group'>
								<label class='col-md-2 control-label'> Due Date</label>
								<div class='col-md-10'>
									<input class='form-control' type='date' formControlName='DueDate' />
								</div>
							</div>
						</div>
					</fieldset>
				</form>
			</div>
		</div>
		<div class='row' style='padding: 15px;'>
			<div class= 'col-md-12'>
				<span>
					<button class='btn btn-primary btn-edit-sml'
						[disabled]='buttonPushed'
						(click)='saveJob(false, false,false)'
						style='width: 110px; margin-right:10px'
						[disabled]='!jobForm.valid || progressStatus'>
						<span class='glyphicon glyphicon-floppy-save'></span> Save &nbsp;
					</button>
					<button class='btn btn-primary btn-edit-sml'
						[disabled]='buttonPushed'
						(click)='saveJob(true, false,false)'
						style='width: 150px; margin-right:10px'
						[disabled]='!jobForm.valid || progressStatus'>
						<span class='glyphicon glyphicon-floppy-save'></span> Save & Return &nbsp;
					</button>
					<button *ngIf='showNextRecordButton && job.Id ' class='btn btn-primary btn-edit-sml'
						[disabled]='buttonPushed'
						(click)='saveJob(false, true,false)'
						style='width: 190px; margin-right:10px'
						[disabled]='!jobForm.valid || progressStatus'>
						<span class='glyphicon glyphicon-floppy-save'></span> Save & Next Record &nbsp;
					</button>
					<button  class='btn btn-primary btn-edit-sml' *ngIf="!job.Id"  
						[disabled]='buttonPushed'
						(click)='saveJob(false, false,true)'
						style ='width: 200px; margin-right:10px'
						[disabled]='!jobForm.valid || progressStatus'>
						<span class='glyphicon glyphicon-floppy-save'></span> Save & Add New Record &nbsp;
					</button>
				</span>
				<span  class='pull-right remove-float-sml'>
					<button class='btn btn-primary btn-edit-sml'
						[disabled]='buttonPushed'
						(click)='cancel()'
						style='width: 150px; margin-right:10px'
						[disabled]='!jobForm.valid || progressStatus'>
						Cancel &nbsp;
					</button>
					<button class='btn btn-primary btn-edit-sml' *ngIf="job.Id" 
						[disabled]='buttonPushed'
						(click)='deleteJob()'
						style='width: 150px; margin-right:10px'
						[disabled]='!jobForm.valid || progressStatus'>
						<span class='glyphicon glyphicon-remove-circle'></span> Delete &nbsp;
					</button>
				</span>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<span> Updated By: <label *ngIf='job'>{{job.UpdatedBy}}</label></span>
				<span> Time: <label *ngIf='job'>{{job.UpdatedDate}}</label></span>
			</div>
		</div>
	</div>
</div>
<div>
	<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>