<hr />
<table>
    <tbody>
        <tr *ngFor="let a of answerList">
            <td>
                <input type="radio" [name]="a.Id" (click)="setValue('1', a.Id, a.Result)" [checked]="a.Answer == 'true'" /> True &nbsp;&nbsp;
                <input type="radio" [name]="a.Id" (click)="setValue('0', a.Id, a.Result)" [checked]="a.Answer == 'false'"> False
            </td>
            <td style="width: 50px;"></td>
            <td><span [innerHTML]='a.Description'></span></td>
        </tr>
    </tbody>
</table>
