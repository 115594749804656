import { FormGroup } from '@angular/forms';

// Generic validator for Reactive forms
// Implemented as a class, not a service, so it can retain state for multiple forms.
export class GenericValidator {

    constructor(private validationMessages: { [key: string]: { [key: string]: string } }) {

    }

    // Processes each control within a FormGroup
    // And returns a set of validation messages to display
    // Structure
    // controlName1: 'Validation Message.',
    // controlName2: 'Validation Message.'
    processMessages(container: FormGroup): { [key: string]: string } {
        let messages = {};
        // for (let controlKey in container.controls) {
        //     if (container.controls.hasOwnProperty(controlKey)) {
        //         let c = container.controls[controlKey];
        //         // If it is a FormGroup, process its child controls.
        //         if (c instanceof FormGroup) {
        //             let childMessages = this.processMessages(c);
        //             Object.assign(messages, childMessages);
        //         } else {
        //             // Only validate if there are validation messages for the control
        //             if (this.validationMessages[controlKey]) {
                        
        //                 messages[controlKey] = '';
        //                 if ((c.dirty || c.touched) && c.errors) {
        //                     Object.keys(c.errors).map(messageKey => {
        //                         if (this.validationMessages[controlKey][messageKey]) {
        //                             messages[controlKey] += this.validationMessages[controlKey][messageKey] + ' ';
        //                         }
        //                     });
        //                 }
        //             }
        //         }
        //     }
        // }
        return messages;
    }
}
