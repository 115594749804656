import { Component, OnInit } from '@angular/core';
import { ApplicationSettings } from '../shared/application-settings';
import { DailylogService } from './dailylog-service';
import { DailyLog, DailyLogArray } from './dailylog-model';
import { DatePipe} from '@angular/common'



@Component({
  selector: 'app-dailylog',
  templateUrl: './dailylog.component.html',
  styleUrls: ['./dailylog.component.css']
})
export class DailylogComponent implements OnInit {
  
  public dailyLogs: DailyLog[];
  public weekLogs: DailyLog[];

  public days: DailyLogArray[];
  
  public errorMessage: string;
  public AssignedUser: string;

  public currentDate: Date;
  public WorkDate: string;
  public dayOfWeek: string;
  


  constructor( 
      public applicationSettings: ApplicationSettings
      , public datepipe: DatePipe
      , private service: DailylogService) { 

      }

  ngOnInit() {
    this.currentDate = new Date();
    this.WorkDate = this.datepipe.transform(this.currentDate, 'yyyy-MM-dd');
    this.dayOfWeek = this.applicationSettings.getDayOfWeek(this.currentDate);
  }

  ngAfterViewInit() {
    this.getData();
  }

  getData() {
 
  let startDate = this.applicationSettings.AddDaysReturnAsString(this.WorkDate, -7)
  let endDate = this.applicationSettings.AddDaysReturnAsString(this.WorkDate, 0)
  let person = this.AssignedUser;

    this.service.getRecordSet(startDate, endDate, person).subscribe(
      (retVal: DailyLog[]) => {
        this.weekLogs=retVal;
        this.parseWeek();
        console.log('retVal');
        console.log(retVal);
        }, 
      (error: any) => { this.errorMessage = <any> error;}
    );

  }
  
  parseWeek(){
    this.days = new Array(0);

    //Set the day of week and the day of month
    this.weekLogs.forEach(e => {
        let d = new Date(e.workdate);
          e.DayOfMonth = d.getDate();
          e.DayOfWeek = this.applicationSettings.getDayOfWeek(d);
      });
     
      let dayOfMonth = 0;
      var log: DailyLog[] = new Array(0);
      var dayOfWeek: string = "";
      var total: number = 0;
      var workDate: string = "";

      this.weekLogs.forEach(e => {

        if (dayOfMonth==0||dayOfMonth == e.DayOfMonth){
          log.push(e);
          dayOfWeek = e.DayOfWeek;
        }
        else {
          this.days.push({DailyLog: log, DayOfWeek: dayOfWeek, WorkDate: workDate,Total: total});
          total = 0;
          log  = new Array(0);
          log.push(e);
        }

        dayOfWeek = e.DayOfWeek;
        dayOfMonth = e.DayOfMonth;
        total = total + e.Hours;
        workDate = this.applicationSettings.returnDateAsString(e.workdate);

      });

      this.days.push({DailyLog: log, DayOfWeek: dayOfWeek, WorkDate: workDate,Total: total});
      console.log("Arrays");
      console.log(this.days);
  }
 

  dateChanged() {
      this.getData();
  }
  prevDay() {
    let d = new Date(this.WorkDate + " ")
    d.setDate(d.getDate() -1)
    this.currentDate = d;
    this.dayOfWeek = this.applicationSettings.getDayOfWeek(this.currentDate);
    this.WorkDate = this.datepipe.transform(d, 'yyyy-MM-dd');
    this.getData();
  }
  nextDay() {
    let d = new Date(this.WorkDate + " ")
    d.setDate(d.getDate() +1)
    this.currentDate = d;
    this.dayOfWeek = this.applicationSettings.getDayOfWeek(this.currentDate);
    this.WorkDate = this.datepipe.transform(d, 'yyyy-MM-dd');
    this.getData();
  }
}

