import { Injectable } from '@angular/core';
import { Observer } from 'rxjs';
import { Observable, Subject } from 'rxjs-compat';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { catchError, map, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

const CHAT_URL = "ws://localhost:5001/chathub";

export interface Message {
	source: string;
	content: any[];
	message: string;
	function: string;
}

@Injectable({
	providedIn: 'root'
})
export class PLCServiceConfigService {

	trnsfrData = {
		source: '',
		content: [],
		message: '',
		function: ''
	};

	public serverStatus = false;
	private subject: AnonymousSubject<MessageEvent>;
	public messages: Subject<Message>;
	private serviceId: string;
    private machine="ConfigService";
	private isClientRegistered = false;

	ip = "";
	port = "";
	modbusData: any;
	private timer: any;
	servTempArr = [];
	
	clientName: any;
	
	GetServerStatusSubject: Subject<any> = new Subject<any>() ;
	GetDeviceErrorSubject : Subject<any> = new Subject<any>() ;


	GetServerPropertiesSubject : Subject<any> = new Subject<any>() ;
	GetlocalLogSubject : Subject<any> = new Subject<any>() ;


	constructor() { 
		this.serviceId = uuidv4();
		console.log('Service ID:', this.serviceId);
	}

	private create(url): AnonymousSubject<MessageEvent> {
		let ws = new WebSocket(url);
		ws.onopen = openevent => {
			console.log("Successfully connected: " + url);
			this.serverStatus = true;
			this.GetServerStatusSubject.next(this.serverStatus);
			this.trnsfrData = {
				source: this.clientName,
				content: [],
				message: 'register client',
				function: 'registerAngularClient'
			};

			this.trnsfrData.content.push(this.ip);
			this.trnsfrData.content.push(this.port);

			this.messages.next(this.trnsfrData);

			this.trnsfrData = {
				source: this.clientName,
				content: [],
				message: 'Get Server Properties',
				function: 'getServerProperties'
			};
			this.messages.next(this.trnsfrData);

			this.trnsfrData = {
				source: this.clientName,
				content: [],
				message: 'Get Local Log',
				function: 'getLocalLog'
			};
			this.messages.next(this.trnsfrData);
		}

		let observable = new Observable((obs: Observer<MessageEvent>) => {
			ws.onmessage = obs.next.bind(obs);
			ws.onerror = obs.error.bind(obs);
			ws.onclose = obs.complete.bind(obs);
			return ws.close.bind(ws);
		});
		let observer = {
			error: null,
			complete: null,
			next: (data: Object) => {  //sending messge to websocket
				//console.log('Message sent to websocket: ', data);
				if (ws.readyState === WebSocket.OPEN) {
					ws.send(JSON.stringify(data));
				}
				else {
					this.serverStatus = false;
					this.GetServerStatusSubject.next(this.serverStatus);
				}
			}
		};
		return new AnonymousSubject<MessageEvent>(observer, observable);
	}

	public connect(url): AnonymousSubject<MessageEvent> {
		this.subject = this.create(url);
		console.log(this.subject)
		return this.subject;
	}

	public saveServerProperties(plcip:string,urllog:string,secondslog:number,logenabled:boolean,plcport:number ) {
		let ws = new WebSocket(CHAT_URL);
		ws.onopen = openevent => {
		this.trnsfrData = {
			source: this.clientName,
			content: [plcip,urllog,secondslog,logenabled,plcport],
			message: 'update server properties',
			function: 'updateServerProperties'
		};
		this.messages.next(this.trnsfrData);
	},error=> {
		console.log(error);
	}
	}

	getLogDataFromServer() {
		

		this.messages = <Subject<Message>>this.connect(CHAT_URL).pipe(
			map(
				(response: MessageEvent): Message => {
					let data = JSON.parse(response.data)
					return data;
				}
			),
			catchError(error => { throw error }),
			tap({
				error: error => console.log('Error in WebSocket:', error),
				complete: () => {
					this.serverStatus = false;
				}
			})
		);
		
		this.messages.subscribe(msg => {
			if (msg.function == "receiveLocalLog") {
				this.GetlocalLogSubject.next(msg.content);
			}
		});
	}

	connectServer() {
		this.isClientRegistered = false;
		this.clientName=this.machine + " client " + this.serviceId;
		
		this.messages = <Subject<Message>>this.connect(CHAT_URL).pipe(
			map(
				(response: MessageEvent): Message => {
					let data = JSON.parse(response.data)
					return data;
				}
			),
			catchError(error => { throw error }),
			tap({
				error: error => console.log('Error in WebSocket:', error),
				complete: () => {
					this.serverStatus = false;
				}
			})
		);
		
		this.messages.subscribe(msg => {
			if (msg.function == "receiveServerProperties") {
				//console.log("receiveServerProperties");
				this.GetServerPropertiesSubject.next(msg.content);
			}
			else if (msg.function == "receiveLocalLog") {
				//console.log("receiveLocalLog");
				this.GetlocalLogSubject.next(msg.content);
			}
			else if (msg.function == "errorMessage") {
				console.error(msg.message);
				this.GetDeviceErrorSubject.next(false);

			}
			else {
				console.log("Response from websocket: " + msg.message);
			}
		});
	}

	startReadingLocalLog() {
		this.timer = setInterval(() => {
			this.getLogData();
		}, 5000);
	}

	getLogData() {
		this.trnsfrData = {
			source: this.clientName,
			content: [],
			message: 'Get Local Log',
			function: 'getLocalLog'
		};
		this.messages.next(this.trnsfrData);
		this.isClientRegistered = true;
	}
}
