import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { IVideo} from './video-model/video';
import { IVideos } from './video-model/videos';
import { IVideoSearch } from './video-model/videoSearch';



import { ApplicationSettings } from '../shared/application-settings';
import { IUsersearch } from '../admin/usersearches/usersearch-model/usersearch';
import { UsersearchService } from '../admin/usersearches/usersearch-service';

@Injectable()
export class VideoService {
	private baseUrl;
	public pageTitle = 'Videos List';

	constructor(private authHttp: HttpClient
		, private settings: ApplicationSettings
		, private userSearchService: UsersearchService) {
		this.baseUrl = this.settings.retrieveUrl() + '/api/Videos';
	}

	getVideo(id: number):  Observable<IVideo> {
		if (id === 0) {
			return of(this.initializeVideo());
		}

		const url = `${this.baseUrl}/${id}`;

		return this.authHttp.get<IVideo>(url)
			.pipe(retry(1),
			catchError(this.processError))
	}

	saveVideo(video: IVideo): Observable<any> {
		if (video.Id === 0) {
			return this.createVideo(video);
		}
		return this.updateVideo(video);
	}

	private createVideo(video: IVideo): Observable<any> {
		video.Id = undefined;
		return this.authHttp.post<number>(this.baseUrl + '/videocreate', video)
			.pipe(retry(1), catchError(this.processError));
	}

	private updateVideo(video: IVideo): Observable<IVideo> {
		return this.authHttp.put<IVideo>(this.baseUrl, video)
			.pipe(retry(1), catchError(this.processError));
	}

	deleteVideo(id: number): Observable<Response> {
		const url = `${ this.baseUrl}/${ id}`;
		return this.authHttp.delete<Response>(url)
			.pipe(retry(1), catchError(this.processError));
	}

	initializeVideo(): IVideo {
		return {
			Id: 0,
			Title: '',
			Category: '',
			FileName: '',
			Notes: '',
			VimeoId: 0,
			CreatedDate: '',
			CreatedBy: '',
			UpdatedDate: '',
			UpdatedBy: '',
		} as IVideo;
	}

	public getRecordSet(search: IVideoSearch,pagename): Observable<IVideos> {

		var uSearch: IUsersearch[] = [];
		uSearch.push(this.userSearchService.setSearch('Title', search.Title, pagename));
		uSearch.push(this.userSearchService.setSearch('Category', search.Category, pagename));
		uSearch.push(this.userSearchService.setSearch('FileName', search.FileName, pagename));
		uSearch.push(this.userSearchService.setSearch('Notes', search.Notes, pagename));
		uSearch.push(this.userSearchService.setSearch('VimeoId', search.VimeoId, pagename));
		uSearch.push(this.userSearchService.setSearch('orderByString', search.orderByString, pagename));
		uSearch.push(this.userSearchService.setSearch('Skip', search.skip.toString(), pagename));

		this.userSearchService.saveUsersearches(uSearch).subscribe(() => { });

		const options = {
		params: new HttpParams()
			.set('skip', search.skip.toString())
			.set('take', search.take.toString())
			.set('orderByString', search.orderByString)
			.set('Title', search.Title ? search.Title : '')
			.set('Category', search.Category ? search.Category : '')
			.set('FileName', search.FileName ? search.FileName : '')
			.set('Notes', search.Notes ? search.Notes : '')
			.set('VimeoId', search.VimeoId ? search.VimeoId : '')
		};

		return this.authHttp.get<IVideos>(this.baseUrl + '/grid', options)
			.pipe(retry(1),
			catchError(this.processError),
			map(response => (<IVideos>{
				data: response.data,
				total: response.total,
				skip: search.skip
			})));
	}

	public getFreshSearch() : IVideoSearch { 
		let search: IVideoSearch = {
			Id: 1,
			Title: '',
			Category: '',
			FileName: '',
			Notes: '',
			VimeoId: '',
			skip: 0,
			orderByString: '',
			take: 10
		};
		return search;
	};

	processError(err: any) {
		let message = '';
		if(err.error instanceof ErrorEvent) {
			message = err.error.message;
		} 
		else {
		message = `Error Code: ${err.status}\nMessage: ${err.error.ErrorDescription}`;
		}
		console.error(message);
		return throwError(message);
	}
	public toExcel(search: IVideoSearch): Observable<string> {
		const options = {
		params: new HttpParams()
			.set('orderByString', search.orderByString)
			.set('Title', search.Title ? search.Title : '')
			.set('Category', search.Category ? search.Category : '')
			.set('FileName', search.FileName ? search.FileName : '')
			.set('Notes', search.Notes ? search.Notes : '')
			.set('VimeoId', search.VimeoId ? search.VimeoId : '')
		};
		return this.authHttp.get<string>(this.baseUrl + '/toexcel', options)
			.pipe(retry(1), catchError(this.processError));
	}
	createVideoLog(videoId: number): Observable<any> {
		const url = `${this.baseUrl}` + '/CreateVideoLog';
		return this.authHttp.post(url, videoId)
		.pipe(retry(1), catchError(this.processError));
	}
	vimeoUpload(formData){
		return this.authHttp
			.post<any>(`${this.baseUrl}/vimeoUpload`,formData)
			.pipe(retry(1), catchError(this.processError));
	}
}