import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { HelpService } from '../help-service';
import { ApplicationSettings } from '../../shared/application-settings';
import { AuthService } from '../../admin/auth/auth.service';

@Component({
  selector: 'app-help-view',
  templateUrl: './help-view.component.html',
  styleUrls: ['./help-view.component.css']
})
export class HelpViewComponent implements OnInit {

  private sub: Subscription;
  errorMessage: string;
  helpViewData: any;
  public fileList: any;
  helpId: any;
  pagename: any;
  helpDeveloperData: any;

  constructor(
    private route: ActivatedRoute
    , private router: Router
    , private helpService: HelpService
    , public applicationSettings: ApplicationSettings
    , public auth: AuthService
  ) { }

  ngOnInit(): void {

    // console.log(this.router.url);
    // console.log(window.location.href);
    this.sub = this.route.params.subscribe(
      params => {
        const pagename = params['pagename'];
        this.pagename = pagename;
        this.getViewData();
      }
    );


  }
  getViewData() {
    let link = this.pagename;

    this.helpService.getByLink(link).subscribe(resp => {
      this.helpViewData = resp.Content;
      this.helpDeveloperData = resp.DeveloperContent;
      this.helpId = resp.Id;
      this.getAllFiles(this.helpId);
    }, (error: any) => this.errorMessage = <any>error);

  }
  getAllFiles(helpid) {
    this.helpService.getAllFilesHelp(helpid).subscribe(response => {
      this.fileList = response;
      if (this.helpViewData != null) {
        this.helpViewData = this.replaceFileTag(this.helpViewData);
      }
      if (this.helpDeveloperData != null) {
        this.helpDeveloperData = this.replaceFileTag(this.helpDeveloperData);
      }
    });
  }

  replaceFileTag(cdata) {
    var contentdata = cdata;
    var datalist = contentdata.split('>>>');
    datalist.forEach(e => {
      if (e.indexOf("<<<") != -1) {
        var d1 = e.split('<<<')[1];
        var d2 = d1.split(',');
        var d3 = d2[0];
        let index = this.fileList.findIndex(x => x.FileName === d3);
        if (index != -1) {
          contentdata = contentdata.replace("<<<" + d1 + ">>>", "<img src='" + this.applicationSettings.retrieveUrl() + this.fileList[index].FilePath + this.fileList[index].GeneratedFileName + "'  width='" + d2[1] + "' height='" + d2[2] + "'/>");
        }
      }
    })
    return contentdata;
  }
}
