
<div class='panel panel-primary animaPlaceholder'>
	<div class='panel-heading'>
	<div class='row'>
		<div class='col-md-2'>
			{{pageTitle}}
		</div>
		<div class='col-md-2 text-right'>
			<button class='btn btn-default btn-sm' [routerLink]="['/usersearche-details/0']" style='width:auto;' [disabled]="progressStatus">Add New UserSearche</button>
		</div>
		<div class='col-md-1 text-right'>
		<button class='btn btn-primary btn-sm' style='width: auto;' (click)='showHideSearchClick()' [disabled]="progressStatus">{{SearchText}}</button>
		</div>
		<div class='col-md-2 text-right'>
			<button class='btn btn-default btn-sm' style='width: 100px' (click)='toExcel()' [disabled]="progressStatus">To Excel</button>
		</div>
		<div class='col-md-2 text-right'>
			<label>Rows</label>&nbsp;&nbsp;
			<select [(ngModel)]='pageSize' style='width: 100px; color: black;' (change)='pageSizeValueChanged()'>
				<option *ngFor='let item of applicationSettings.pageSizes'>{{item}}</option>
			</select>
		</div>
		<div class='col-md-1 text-right'>
			<button class='btn btn-primary btn-sm' style='width: auto;' (click)='help()'>how-to</button>
		</div>
	</div>
	</div>

	<div class='panel-body'>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='excelFile'>
					<button class='btn btn-primary btn-sm' (click)='hideFileLink()'>hide link</button>
					<span>excel File:<a href = "javascript:void(0)"(click) = "openExcelFile()" > {{ excelFile}}</a></span>
				</div>
			</div>
		</div>
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
		</div>

		<div class='row filters' *ngIf='showHideSearch'>
		<div class='col-md-9'>
			<div class='row'>
			<div class='col-md-4'>
				<label class='inline-label'> User Id</label>
				<input type='text' class='form-control block-element' placeholder=' User Id' [(ngModel)]='UserId' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Form</label>
				<input type='text' class='form-control block-element' placeholder=' Form' [(ngModel)]='Form' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Item</label>
				<input type='text' class='form-control block-element' placeholder=' Item' [(ngModel)]='Item' />
			</div>
			<div class='col-md-4'>
				<label class='inline-label'> Value</label>
				<input type='text' class='form-control block-element' placeholder=' Value' [(ngModel)]='Value' />
			</div>
			</div>
		</div>
		<div class='col-md-3'>
			<div class='row'>
				<div class='col-md-12 col-xs-4 text-right' >
					<button class='btn btn-primary btn-sm btn-search' (click)='searchClick()' [disabled]="progressStatus">Search</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right' >
					<button class='btn btn-primary btn-sm btn-search' (click)='searchAndHideClick()' [disabled]="progressStatus">Search and Hide</button>
				</div>
				<div class='col-md-12 col-xs-4 text-right' >
					<button class='btn btn-primary btn-sm btn-search' (click)='searchClearClick()' [disabled]="progressStatus">Clear-Search</button>
				</div>
			</div>
		</div>
		</div>
	</div>
</div>

<div class='table-container'>
<table mat-table [dataSource]='dataSource' class='mat-elevation-z8 table-head' matSort >
	<ng-container matColumnDef='Id'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col1'>#</th>
		<td mat-cell *matCellDef='let e'>
			<button class='btn btn-default' style='min-width: 80px;' [routerLink]="['/usersearche-details', e.Id]">{{e.Id}}</button>
		</td>
	</ng-container>
	<ng-container matColumnDef='UserId'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col2'> User Id</th>
		<td mat-cell *matCellDef='let e' class='col2' >{{e.UserId}}</td>
	</ng-container>
	<ng-container matColumnDef='Form'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col3'> Form</th>
		<td mat-cell *matCellDef='let e' class='col3' >{{e.Form}}</td>
	</ng-container>
	<ng-container matColumnDef='Item'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col4'> Item</th>
		<td mat-cell *matCellDef='let e' class='col4' >{{e.Item}}</td>
	</ng-container>
	<ng-container matColumnDef='Value'>
		<th mat-header-cell *matHeaderCellDef mat-sort-header class='col5'> Value</th>
		<td mat-cell *matCellDef='let e' class='col5' >{{e.Value}}</td>
	</ng-container>
	<tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
	<tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
</table>
</div>
<mat-paginator [length]='recordTotal' [pageSize]='pageSize'></mat-paginator>
<div>
	<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>